import { CoursesCategories } from "../modules";
import { AdminLayout } from "../shared";

export function CoursesCategoriesPage() {
  return (
    <AdminLayout>
        <CoursesCategories />
    </AdminLayout>
  )
}
