import { FormProvider } from "react-hook-form";
import { Button, Col, Row } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import useEditMarketingMaterial from "../lib/useEditMarketingMaterial";
import MarketingMaterialForms from "./components/MarketingMaterialForm";

export default function EditMarketingMaterial() {
  const navigate = useNavigate();

  const params = useParams();

  const { formMethods, loading, handleSubmit } = useEditMarketingMaterial(
    params?.recordId ? Number(params.recordId) : null,
    {
      onSuccess() {
        navigate("/corresponsales/material-de-marketing");
      },
    }
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit}>
        <Row gutter={[8, 16]} justify={"space-between"}>
          <Col xs={24}>
            <MarketingMaterialForms />
          </Col>
          <Col xs={24}>
            <Button
              icon={<SaveOutlined />}
              block
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
}
