import { Controller, useFormContext } from "react-hook-form";
import { IHomePopups } from "../../../../../@types/dto/home-popups";
import { Button, Col, Input, Row, Typography, theme } from "antd";
import {
  InputData,
  InputNumber,
  InputSelect,
  InputText,
} from "../../../../../shared";
import FileUPload from "../../../../../shared/components/ui/FileUpload";
import { PATH_S3_BUCKET } from "../../../../../shared/utils/constants";
import { DownloadOutlined } from "@ant-design/icons";

export default function PopupForm() {
  const { control } = useFormContext<IHomePopups>();

  const themeToken = theme.useToken();

  return (
    <Row gutter={[8, 16]}>
      <Col xs={24}>
        <InputText control={control} name="name" labelText="Nombre" />
      </Col>
      <Col xs={24}>
        <InputText
          control={control}
          name="redirect_url"
          labelText="Url redirección"
        />
      </Col>
      <Col xs={24}>
        <InputText control={control} name="text" labelText="Texto" />
      </Col>
      <Col span={24}>
        <Typography.Text style={{ fontWeight: 600 }}>Imagen</Typography.Text>
        <Controller
          control={control}
          name="image_path"
          shouldUnregister
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div>
              <FileUPload
                path="popups/images"
                onUploadFinish={({ fileKey }) => {
                  onChange(fileKey);
                }}
              />
              {!!value && (
                <Button
                  style={{ marginTop: 10 }}
                  block
                  type="primary"
                  href={`${PATH_S3_BUCKET}/${value}`}
                  target="_blank"
                  icon={<DownloadOutlined />}
                >
                  Descargar
                </Button>
              )}
              {!!error && (
                <Typography.Text style={{ color: themeToken.token.colorError }}>
                  {error.message}
                </Typography.Text>
              )}
            </div>
          )}
        />
      </Col>
      <Col xs={24}>
        <InputData control={control} name="start_at" labelText="Fecha inicio" />
      </Col>
      <Col xs={24}>
        <InputData control={control} name="end_at" labelText="Fecha fin" />
      </Col>
      <Col xs={24}>
        <Input.Group>
          <InputNumber
            control={control}
            name="time_between_opens"
            labelText="Tiempo de espera entre aperturas"
            addonAfter="Minutos"
          />
        </Input.Group>
      </Col>
      <Col xs={24}>
        <InputSelect
          control={control}
          name="status"
          dataOptions={[
            { title: "Activo", value: "active" },
            { title: "Inactivo", value: "inactive" },
          ]}
          label="Estado"
        />
      </Col>
    </Row>
  );
}
