import * as yup from 'yup'

export const validateCreateProjects = yup.object().shape({
    name:yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El titulo debe tener mínimo de 2 caracteres.'),
    // image_path_cover:yup
    // .string()
    // .required('Campo requerido.')
    // .min(1, 'Debes de incluir mínimo una imagen.'),
    short_description: yup
    .string()
    .required('Campo requerido.')
    .min(2, 'la descropcion del proyecto debe tener mínimo de 2 caracteres.'),
    status: yup
    .string()
    .required('Campo requerido.')
    .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones.'),
})