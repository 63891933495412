import React from "react";
import { useForm } from "react-hook-form";
import { message } from "antd";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { studyCertificatesService } from "../../../../shared";
import { IModelStudy } from "../../@types";

export default function UsekeyAccountManagersNew(props?: {
  onSuccess?: (record: IModelStudy) => void;
}) {
  const [loading, setLoading] = React.useState(false);

  const formMethods = useForm<IModelStudy>({
    resolver: yupResolver(
      yup.object().shape({
        // first_name: yup.string().required("Campo requerido"),
        // last_name: yup.string().required("Campo requerido"),
        // email: yup.string().required("Campo requerido"),
        // phone: yup.string().required("Campo requerido"),
        // dni: yup.string().required("Campo requerido"),
        // status: yup.string().oneOf(["active", "inactive"]).required("Campo requerido"),
      })   
    ),
  });

  const loadingRef = React.useRef(false);

  const updateLoading = (status: boolean) => {
    setLoading(status);
    loadingRef.current = status;
  };


  const handleSubmit = formMethods.handleSubmit(async (data) => {
    if (loadingRef.current) return;

    updateLoading(true);

    await studyCertificatesService
      .create(data)
      .then((record) => {
        message.success("Registro creado correctamente");
        props?.onSuccess?.(record as IModelStudy);
      })
      .catch((err) => {
        alert(err?.message);
        message.error(err?.message);
      })
      .finally(() => {
        updateLoading(false);
      });
  });


  return {
    formMethods,
    loading,
    handleSubmit,
  };
}
