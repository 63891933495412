
import { IModelProjectsObjectives } from '../../modules';
import { IProjectsObjectivesState } from '../@types/projectsObjectives';

import { projectsObjectivesActions } from '../action-types'

type projectsObjectivesActionType =
| { type: typeof projectsObjectivesActions.PROJECTS_OBJECTIVES_MODAL_STATE; }
| { type: typeof projectsObjectivesActions.GET_PROJECTS_OBJECTIVES_FOR_UPDATE; payload: IModelProjectsObjectives }
| { type: typeof projectsObjectivesActions.IS_PROJECTS_OBJECTIVES_FOR_UPDATE; payload: boolean }
| { type: typeof projectsObjectivesActions.SWITCH_PROJECTS_OBJECTIVES_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IProjectsObjectivesState = {
    projectsObjectivesModalIsOpen: false,
    isUpdateProjectsObjectives: false,
    projectsObjectivesForUpdate: null,
    refreshTableProjectsObjectives: false,
}

export default function projectsObjectivesReducer(state: IProjectsObjectivesState = INITIAL_STATE, action: projectsObjectivesActionType): IProjectsObjectivesState {
  switch (action.type) {
    case projectsObjectivesActions.PROJECTS_OBJECTIVES_MODAL_STATE:
        return {
            ...state,
            projectsObjectivesModalIsOpen: !state.projectsObjectivesModalIsOpen,
        }
    case projectsObjectivesActions.GET_PROJECTS_OBJECTIVES_FOR_UPDATE:
        return {
            ...state,
            projectsObjectivesForUpdate: action.payload,
        }
    case projectsObjectivesActions.IS_PROJECTS_OBJECTIVES_FOR_UPDATE:
        return {
            ...state,
            isUpdateProjectsObjectives: action.payload,
        }
    case projectsObjectivesActions.SWITCH_PROJECTS_OBJECTIVES_REFRESH_TABLE:
        return {
            ...state,
            refreshTableProjectsObjectives: action.payload,
        }
    default:
        return state
  }
}
