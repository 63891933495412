import React from "react";

export default function useDraggable({
  draggableNodeRef,
  onChange,
}: {
  draggableNodeRef: React.MutableRefObject<HTMLDivElement | null>;
  onChange: (position: { left: number; top: number }) => void;
}) {
  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!draggableNodeRef.current) return;

    const parent =
      draggableNodeRef.current.parentElement!.getBoundingClientRect();
    const child = draggableNodeRef.current.getBoundingClientRect();

    const downX =
      e.pageX -
      draggableNodeRef.current.offsetLeft -
      parent.left -
      window.scrollX;
    const downY =
      e.pageY -
      draggableNodeRef.current.offsetTop -
      parent.top -
      window.scrollY;

    const onMouseMove = (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      if (!draggableNodeRef.current) return;

      const left = Math.max(
        0,
        Math.min(
          100 - (child.width / parent.width) * 100,
          ((e.pageX - parent.left - window.scrollX - downX) / parent.width) *
            100
        )
      );
      const top = Math.max(
        0,
        Math.min(
          100 - (child.height / parent.height) * 100,
          ((e.pageY - parent.top - window.scrollY - downY) / parent.height) *
            100
        )
      );

      draggableNodeRef.current.style.left = `${left}%`;
      draggableNodeRef.current.style.top = `${top}%`;
    };

    const onMouseUp = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (!draggableNodeRef.current) return;

      const left = Number(draggableNodeRef.current.style.left.replace("%", ""));
      const top = Number(draggableNodeRef.current.style.top.replace("%", ""));

      onChange({ left, top });

      window.removeEventListener("mousemove", onMouseMove, false);
      window.removeEventListener("mouseup", onMouseUp, false);
    };

    if (draggableNodeRef.current) {
      window.addEventListener("mousemove", onMouseMove, false);
      window.addEventListener("mouseup", onMouseUp, false);
    }
  };

  React.useEffect(() => {
    return () => {
      window.removeEventListener("mousemove", () => {}, false);
      window.removeEventListener("mouseup", () => {}, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    listeners: {
      onMouseDown,
    },
  };
}
