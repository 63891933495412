import CreateUser from "../modules/users/ui/users/CreateUser";
import { AdminLayout } from "../shared";

export function CreateUserPage() {
  return (
    <AdminLayout>
      <CreateUser />
    </AdminLayout>
  )
}
