import React from "react";
import { IHomePopups } from "../../../@types/dto/home-popups";
import { homePopupsService } from "../../../shared";
import { message } from "antd";

export default function useUpdatePopupStatus(props?: {
  onSuccess: (record: IHomePopups) => void;
}) {
  const [loading, setLoading] = React.useState(false);

  const loadingRef = React.useRef(false);

  const updateLoading = (status: boolean) => {
    setLoading(status);
    loadingRef.current = status;
  };

  const updateStatus = async (
    recordId: number,
    status: IHomePopups["status"]
  ) => {
    if (loadingRef.current) return;

    updateLoading(true);

    await homePopupsService
      .patch(recordId, { status })
      .then((record) => {
        props?.onSuccess?.(record as IHomePopups);
        message.success("Estado actualizado correctamente");
      })
      .catch((err) => {
        message.error(err?.message);
      })
      .finally(() => {
        updateLoading(false);
      });
  };

  return {
    loading,
    updateStatus,
  };
}
