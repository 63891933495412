import { Button, Drawer } from "antd";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { CiuuManagersNew } from "../modules/ciuu/ui/resource/keyAccountManagerNew";

export function CiuuNewPage() {
  return (
    <Drawer
      title="Crear CIUU"
      open
      width={350}
      destroyOnClose
      closeIcon={
        <Link to="/ciuu">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <CiuuManagersNew />
    </Drawer>
  )
}
