import { Card, Col, Layout } from "antd";
import styled from "styled-components";

const { Header, Sider } = Layout;

export const WrapperLayout = styled(Layout)`
  height: 100vh;
  width: 100vw;
`;

export const ContentLayout = styled(Layout)`
  flex-direction: column;
  width: 100vw;
  height: 100%;
`;

export const WrapperHeader = styled(Header)`
  display: flex;
  width: 100vw;
  flex-direction: row;
  text-align: left;
  font-variant: tabular-nums;
  font-feature-settings: "tnum", "tnum";
  font-size: 1rem;
  font-weight: 400;
  line-height: 40px !important;
  -webkit-box-align: center;
  align-items: center;
  box-shadow: 0 2px 8px #c8ccd1;
  z-index: 102;
  box-sizing: border-box;
  flex: 0 0 auto;
  color: var(--primary);
  background-color: var(--white);
  padding: 0px 20px 0px 20px;
  height: 64px;
  position: sticky;
  top: 0px;
`;

export const WrapperActionsRight = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const WrapperSider = styled(Sider)`
  & > span {
    display: none;
  }

  .ant-layout-sider-zero-width-trigger, .ant-layout-sider-zero-width-trigger-left:where(.css-dev-only-do-not-override-1fzfh95).ant-layout .ant-layout-sider-zero-width-trigger {
    display: none;
  }
  .ant-layout-sider-children {
    & > ul {
      height: 100%;
      /* & > li {
        & > ul {
          & > li {
            padding-left: 0px;
          }
        }
      } */
      .ant-menu-submenu-inline{
        padding-left: 0;

      }
    }
  }
`;

export const WrapperCard = styled(Card)`
  display: flex;
  width: 100%;
  height: auto;
  text-align: left;
  font-weight: 400;
  /* font-family: "Poppins", "font-awesome"; */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  border: 1px solid #f0f0f0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background: var(--white);
  border-radius: 1rem !important;
  min-height: 100%;

  .ant-card-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;