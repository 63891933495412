import { useFormContext } from "react-hook-form";
import { Col, Row } from "antd";
import { InputText } from "../../../../shared";
import { IPressResourceCategory } from "../../@types/press-resource-category";

export default function PressResourceCategoriesForm() {
  const { control } = useFormContext<IPressResourceCategory>();

  return (
    <Row gutter={[8, 16]}>
      <Col xs={24}>
        <InputText control={control} name="name" labelText="Nombre" />
      </Col>
    </Row>
  );
}
