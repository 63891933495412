import { message } from "antd";

import { coursesRequirementsService } from "../../shared";
import { coursesRequirementsActions } from "../action-types";
import { IModelCoursesRequirements } from "../../modules";

export const changeModalStateCoursesRequirements = (): coursesRequirementsActions.ICoursesRequirementsModalState =>({
    type: coursesRequirementsActions.COURSES_REQUIREMENTS_MODAL_STATE
})

export const getSingleCoursesRequirements = (val:IModelCoursesRequirements ): coursesRequirementsActions.IGetCoursesRequirementsForUpdate=> ({
    type: coursesRequirementsActions.GET_COURSES_REQUIREMENTS_FOR_UPDATE,
    payload: val
})

export const setIsCoursesRequirementsForUpdate =(val: boolean): coursesRequirementsActions.IIsCoursesRequirementsForUpdate =>({
    type: coursesRequirementsActions.IS_COURSES_REQUIREMENTS_FOR_UPDATE,
    payload: val,
})

export const fetchSingleCoursesRequirements = async(id: number) =>{
    return await coursesRequirementsService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createCourseRequirements = async(data:Partial<IModelCoursesRequirements>) =>{
    return await coursesRequirementsService.create(data)
    .then((res: IModelCoursesRequirements)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateCourseRequirements = async(data:Partial<IModelCoursesRequirements>) =>{
    return await coursesRequirementsService.patch(data?.id!, data)
    .then((res: IModelCoursesRequirements)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteCourseRequirements = async(id: number) =>{
    return await coursesRequirementsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableCoursesRequirements = (val: boolean): coursesRequirementsActions.ISwitchCoursesRequirementsRefreshTable =>({
    type: coursesRequirementsActions.SWITCH_COURSES_REQUIREMENTS_REFRESH_TABLE,
    payload: val
})

