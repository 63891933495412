import React from "react";

import { useForm } from "react-hook-form";
import { message } from "antd";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IPressResource } from "../@types";
import { pressResourcesService } from "../../../shared";

export default function useEditPressResource(
  recordId?: number | null,
  props?: { onSuccess?: (record: IPressResource) => void }
) {
  const [loading, setLoading] = React.useState(false);

  const formMethods = useForm<IPressResource>({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required("Campo requerido"),
        category_id: yup.number().required("Campo requerido"),
        status: yup.string().required("Campo requerido"),
        path_file: yup.string().required("Campo requerido"),
        path_thumbnail: yup.string().required("Campo requerido"),
      })
    ),
  });

  const loadingRef = React.useRef(false);

  const updateLoading = (status: boolean) => {
    setLoading(status);
    loadingRef.current = status;
  };

  const getRecord = async () => {
    if (loadingRef.current || !recordId) return;

    updateLoading(true);

    await pressResourcesService
      .get(recordId)
      .then((record) => {
        formMethods.reset({
          name: record.name,
          category_id: record.category_id,
          path_file: record.path_file,
          path_thumbnail: record.path_thumbnail,
          status: record.status,
        });
      })
      .finally(() => {
        updateLoading(false);
      });
  };

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    if (loadingRef.current || !recordId) return;

    updateLoading(true);

    await pressResourcesService
      .patch(recordId, data)
      .then((record) => {
        message.success("Registro actualizado correctamente");
        props?.onSuccess?.(record as IPressResource);
      })
      .catch((err) => {
        alert(err?.message);
        message.error(err?.message);
      })
      .finally(() => {
        updateLoading(false);
      });
  });

  React.useEffect(() => {
    if (recordId) {
      getRecord();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId]);

  return {
    formMethods,
    loading,
    handleSubmit,
  };
}
