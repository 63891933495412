import { TRootState } from '../@types/redux';

export const selectModalProjects = (state: TRootState) => state.project.projectsModalIsOpen


export const selectIsUpdateProjects = (state: TRootState) => state.project.isUpdateProjects


export const selectSingleProjects = (state: TRootState) => state.project.projectsForUpdate


export const selectRefreshTableProjects = (state: TRootState) => state.project.refreshTableProjects