import { Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { changeModalStateCoursesCategories, deleteCourseCategories, fetchSingleCoursesCategories, getSingleCoursesCategories, refreshTableCoursesCategories, setIsCoursesCategoriesForUpdate } from "../../../../redux";

const { confirm } = Modal;

export function useTableCoursesCategories() {
  const dispatch = useAppDispatch();

  const changeCoursesCategoriesForUpdate = (valId: number) => {
    fetchSingleCoursesCategories(valId)
    .then((res)=>{
    dispatch(getSingleCoursesCategories(res.data[0]));
    dispatch(setIsCoursesCategoriesForUpdate(true));
    dispatch(changeModalStateCoursesCategories());
    })
}

const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar la Categoría de curso con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar categoría" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar categoría",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        return await deleteCourseCategories(valId)
          .then((res) => {
            dispatch(refreshTableCoursesCategories(true));
            if(res.name){
              return message.success(`Se ha borrado con éxito la Categoría con id:${valId}`);
            }else if(res){
              console.log({res})
              return message.error(`${res}`);
            }
          })
          .catch((err: any) => {
            console.log({ err });
            return message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return{
    //state
    //methods
    //functions
    changeCoursesCategoriesForUpdate,
    showDeleteConfirm,
  }
}
