import React from "react";
import { useForm } from "react-hook-form";
import { message } from "antd";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { virtualLibrariesService, ciuuService } from "../../../../shared";
import { IModelInfograpgies } from "../../@types";

export default function UsekeyAccountManagersById(
  recordId?: number | null,
  props?: { onSuccess?: (record: IModelInfograpgies) => void }
) {
  const [loading, setLoading] = React.useState(false);

  const formMethods = useForm<IModelInfograpgies>({
    resolver: yupResolver(
      yup.object().shape({
      })   
    ),
  });

  const loadingRef = React.useRef(false);

  const updateLoading = (status: boolean) => {
    setLoading(status);
    loadingRef.current = status;
  };

  const getRecord = async () => {
    if (loadingRef.current || !recordId) return;

    updateLoading(true);

    await virtualLibrariesService
      .get(recordId)
      .then((record) => {
        formMethods.reset({
            category_id: record.category_id,
            name: record.name,
            path_file: record.path_file,
            status: record.status,
            path_thumbnail: record.path_thumbnail,
        });
      })
      .finally(() => {
        updateLoading(false);
      });
  };

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    if (loadingRef.current || !recordId) return;

    updateLoading(true);

    await virtualLibrariesService
      .patch(recordId, data)
      .then((record) => {
        message.success("Registro actualizado correctamente");
        props?.onSuccess?.(record as IModelInfograpgies);
      })
      .catch((err) => {
        alert(err?.message);
        message.error(err?.message);
      })
      .finally(() => {
        updateLoading(false);
      });
  });

  React.useEffect(() => {
    if (recordId) {
      getRecord();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId]);

  return {
    formMethods,
    loading,
    handleSubmit,
  };
}
