import { Button, Col, Typography } from "antd";
import { useFormContext } from 'react-hook-form';
import { WrapperModalProjects } from "../styled";
import { InputNumber, InputText } from "../../../../shared";
import { useSelector } from "react-redux";
import { selectModalProjectsBenefits } from "../../../../redux";
import { useProjectsBenefits } from "../../hooks";
import { IModelProjectsBenefits } from "../../@types";

const { Title } = Typography;

export function ModalProjectsBenefits() {
  const modalProjectsBenefits = useSelector(
    selectModalProjectsBenefits
  );

  const {
    //state
    isLoading,
    isUpdateProjectsBenefits,
    dataUpdateProjectsBenefits,
    dataUpdateProjects,
    //methods
    //functions
    changeModalProjectsBenefitsStateForCreate,
    onSubmitCreateOrUpdate
  } = useProjectsBenefits()

  const { control, handleSubmit: onSubmit } = useFormContext<IModelProjectsBenefits>();

  return (
    <WrapperModalProjects
      title={ isUpdateProjectsBenefits
          ? `Actualizando beneficios del Proyecto : '${dataUpdateProjects?.id! || ''}'`
          : `Creando beneficio del Proyecto` }
      open={modalProjectsBenefits}
      width={"320px"}
      destroyOnClose
      onClose={changeModalProjectsBenefitsStateForCreate}>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Titulo:
          </Title>
          <InputText placeHolder={dataUpdateProjectsBenefits?.title} name="title" control={control} />
        </Col>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Items:
          </Title>
          <InputText placeHolder={dataUpdateProjectsBenefits?.title} name="item" control={control} />
        </Col>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Tipo:
          </Title>
          <InputText placeHolder={dataUpdateProjectsBenefits?.type} name="type" control={control} />
        </Col>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Path:
          </Title>
          <InputText placeHolder={dataUpdateProjectsBenefits?.path} name="path" control={control} />
        </Col>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Posición:
          </Title>
          <InputNumber
            placeHolder="123"
            name="position"
            control={control}
          />
        </Col>
        <Button
            style={{
            width: "100%",
            marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
        >
            {isUpdateProjectsBenefits
            ? "Actualizar información"
            : "Crear beneficio del Project"}
        </Button>
    </WrapperModalProjects>
  )
}
