 
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { TableCustom, webinarsBenefitsService } from "../../../../shared"
import { IModelWebinarsBenefits } from "../../@types"
import { useTableWebinarsBenefits, useWebinarsBenefits } from "../../hooks"
import { Button, Col } from "antd"
import { useSelector } from "react-redux";
import { selectSingleWebinars } from "../../../../redux";
import { TableColumns, TableProperties } from "../../../../shared/components/ui/table/types";
import React from "react";


export function TableWebinarsBenefits() {
  const dataUpdateWebinars = useSelector( selectSingleWebinars );

  const {
    //state
    //methods
    //functions
    changeWebinarsBenefitsForUpdate,
    showDeleteConfirm,
  } = useTableWebinarsBenefits()

  const {
    //state
    isRefreshTableWebinarsBenefits,
    //methods
    //functions
  }= useWebinarsBenefits()

  const tableRef = React.useRef<TableProperties>()

    React.useEffect(() => {
      tableRef.current?.refresh()
    }, [isRefreshTableWebinarsBenefits])

  const columns: TableColumns<IModelWebinarsBenefits> = [
    {
        title: 'ID',
        dataIndex: 'id',
        align: 'left',
        sorter:true
    },
    {
        title: 'Nombre',
        dataIndex: 'name',
        align: 'left',
    },
    {
        title: 'Posición',
        dataIndex: 'position',
        align: 'left',
    },
    {
      title: 'Webinar',
      dataIndex: 'webinar_id',
      align: 'left',
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 140,
      render: (_:any, item:any) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeWebinarsBenefitsForUpdate(item.id)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
]

  return (
    <TableCustom fetchQueryProps={{webinar_id: dataUpdateWebinars?.id!,}} columns={columns}  service={webinarsBenefitsService} ref={tableRef}/>
  )
}
