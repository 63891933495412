import { message } from "antd";

import { eventsService } from "../../shared";
import { eventsActions } from "../action-types";
import { IModelEvents } from "../../modules";

export const getSingleEvents = (
  val: IModelEvents
): eventsActions.IGetEventsForUpdate => ({
  type: eventsActions.GET_EVENTS_FOR_UPDATE,
  payload: val,
});

export const setIsEventsForUpdate = (
  val: boolean
): eventsActions.IIsEventsForUpdate => ({
  type: eventsActions.IS_EVENTS_FOR_UPDATE,
  payload: val,
});

export const fetchSingleEvents = async (id: number) => {
  return await eventsService
    .find({ query: { id } })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      console.log(err);
      message.error(err.message);
      return err;
    });
};

export const createEvent = async (data: Partial<IModelEvents>) => {
  return await eventsService
    .create(data)
    .then((res: IModelEvents) => {
      return res;
    })
    .catch((err: any) => {
      return err.message;
    });
};

export const updateEvent = async ({ id, ...data }: Partial<IModelEvents>) => {
  return await eventsService
    .patch(id!, data)
    .then((res: IModelEvents) => {
      return res;
    })
    .catch((err: any) => {
      return err.message;
    });
};

export const deleteEvent = async (id: number) => {
  return await eventsService
    .remove(id.toString())
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      return err.message;
    });
};

export const refreshTableEvents = (
  val: boolean
): eventsActions.ISwitchEventsRefreshTable => ({
  type: eventsActions.SWITCH_EVENTS_REFRESH_TABLE,
  payload: val,
});
