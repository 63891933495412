import { FC } from 'react'

import { IFileUploadComponentProps } from './types'

const FileUPload: FC<IFileUploadComponentProps> = ({ handleChange }) => {
    return (
        <div>
            <div className="select-file">
                <input type="file" onChange={handleChange} />
            </div>
        </div>
    )
}

export default FileUPload
