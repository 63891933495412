import { TableCustom, webinarsCategoriesService } from "../../../../shared"
 
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { IModelWebinarsCategories } from "../../@types"
import { useWebinarsCategories } from "../../hooks"
import { useTableWebinarsCategories } from "../../hooks/webinars-categories/useTableWebinarsCategories"
import { Avatar, Button, Col, Typography } from "antd";
import { PATH_S3_BUCKET } from "../../../../shared/utils/constants";
import { TableColumns, TableProperties } from "../../../../shared/components/ui/table/types";
import React from "react";

const {Title} = Typography

export function TableWebinarsCategories() {
  const {
      //state
      //methods
      //functions
      changeWebinarsCategoriesForUpdate,
      showDeleteConfirm,
  } =  useTableWebinarsCategories()
  const {
      //state
      isRefreshTableWebinarsCategories,
      isLoading,
      //methods
      //functions
      updateStatus,
  }= useWebinarsCategories()

  const tableRef = React.useRef<TableProperties>()

    React.useEffect(() => {
      tableRef.current?.refresh()
    }, [isRefreshTableWebinarsCategories])

    const columns: TableColumns<IModelWebinarsCategories> = [
        {
            title: 'ID',
            dataIndex: 'id',
            align: 'left',
            sorter:true
        },
        {
            title: 'Nombre',
            dataIndex: 'name',
            align: 'left',
        },
        {
            title: "Estado",
            // 
            dataIndex: "status",
            key: "status",
            render: (_:any, item:any) => {
              return (
                <Col
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    flexDirection: "row",
                  }}
                >
                  <button
                  disabled={isLoading}
                    onClick={()=>{
                      if(item.status === "active"){
                        updateStatus("inactive", item.id)
                      }else if( item.status === "inactive"){
                        updateStatus("active", item.id)
                      }
                    }}
                    style={{
                      display: "flex",
                      padding: "5px 27px",
                      borderWidth: 0,
                      cursor: "pointer",
                      borderRadius: "20px",
                      backgroundColor:
                        item.status === "active" ? "#4bd542" : "#ff4d4f",
                    }}
                  >
                    <Title
                      style={{
                        marginBottom: "1.5px",
                        color: "white",
                      }}
                      level={5}
                    >
                      {item.status}
                    </Title>
                  </button>
                </Col>
              );
            },
        },
        {
            title: 'Posición',
            dataIndex: 'position',
            align: 'left',
            sorter:true
        },
        {
            title: "Acciones",
            key: "operation",
            fixed: "right",
            width: 140,
            render: (_:any, item:any) => {
              return (
                <Col
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    flexDirection: "row",
                  }}
                >
                  <Button
                    type="primary"
                    shape="circle"
                    onClick={() => changeWebinarsCategoriesForUpdate(item.id)}
                    icon={<EditOutlined />}
                    size="large"
                  />
                  <Button
                    type="primary"
                    danger
                    onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
                    shape="circle"
                    icon={<DeleteOutlined />}
                    size="large"
                  />
                </Col>
              );
            },
          },
    ]

  return (
    <TableCustom columns={columns}  service={webinarsCategoriesService} ref={tableRef}/>
  )
}
