import moment from "moment";
import { IFeedbackGeneralSurvey } from "../../../@types/dto/feedback-general-servey";
import { feedbackGeneralSurveyService } from "../../../shared";
import Table from "../../../shared/components/ui/table/TableCustom";

export default function Comments() {
  return (
    <Table
      download
      service={feedbackGeneralSurveyService}
      columns={[
        { title: "ID", dataIndex: "id", sorter: true, filterType: "text" },
        {
          title: "Usuario",
          render: (record: IFeedbackGeneralSurvey) =>
            `${record.meta_user_first_name} ${record.meta_user_last_name}`,
        },
        {
          title: "Calificación",
          dataIndex: "rating",
          sorter: true,
          filterType: "number_range",
        },
        {
          title: "Mensaje",
          dataIndex: 'message',
        },
        {
          title: "Url página",
          dataIndex: "current_url_path",
          filterType: "text",
        },
        {
          title: "Fecha",
          dataIndex: "createdAt",
          filterType: "date_range",
          sorter: true,
          render: (date: string) => moment(date).format("DD-MM-YYYY"),
        },
      ]}
    />
  );
}
