import { Link, useParams } from "react-router-dom";
import { AdminLayout } from "../shared";
import TemplateConfig from "../modules/studyCertificates/ui/template-config";
import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";

export default function StudyCertificateTemplateFieldsPage() {
  const { recordId: templateId } = useParams();

  return (
    <AdminLayout>
      <div style={{ width: "100%" }}>
        <div>
          <Button type="link" icon={<LeftOutlined />}>
            <Link to="/certificates/templates">Volver</Link>
          </Button>
        </div>
        <TemplateConfig
          templateId={templateId ? Number(templateId) : undefined}
        />
      </div>
    </AdminLayout>
  );
}
