 
import { DeleteOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Row, Typography } from "antd";
import { TableCustom, appointmentSchedulingService } from "../../../shared"
// import { useAuthors, useTableAuthors } from "../hooks"
// import { IModelAuthors } from "../@types";
import moment, { Moment } from "moment";
import { useState } from "react";
import { SimpleInputData } from "../../../shared/components/ui/inputs/SimpleInputData";
import { TableColumns } from "../../../shared/components/ui/table/types";

const { Title } = Typography;

const initialVal = {
  startDate: null,
  endDate: null,
  filter: false,
};

export function Sheduling() {

  const [formState, setFormState] = useState<{
    startDate: null | Moment | string;
    endDate: null | Moment | string;
    filter: boolean
  }>(initialVal);

  const { endDate, startDate, filter } = formState;

  const handleDonwload = async () => {
    await appointmentSchedulingService.find({
      query: {
        $client: {download: 'false'}
      }
    }).then((res) => {
      window.open(res?.data?.[0]?.objectUrl)
    })
  }

  const columns: TableColumns<any> = [
    {
        title: 'ID',
        dataIndex: 'id',
        align: 'left',
        sorter:true
    },
    {
        title: 'Nombre Completo',
        dataIndex: 'full_name',
        align: 'left',
    },
    {
        title: 'Nombre de la compañia',
        dataIndex: 'company_name',
        align: 'left',
        filterType: 'text'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: 'left',
      filterType: 'text'
    },
    {
      title: 'NIT',
      dataIndex: 'nit',
      align: 'left',
      filterType: 'text',
    },
    {
      title: 'Rol de compañia',
      dataIndex: 'company_rol',
      align: 'left',
    },
    {
      title: 'Teléfono',
      dataIndex: 'phone',
      align: 'left',
      filterType: 'text'
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
    }
]

  return (
    <>
    {/* <Row
        gutter={[8, 8]}
        style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: 30 }}
      >
        <Col span={6}>
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Fecha de Inicio:
          </Title>
          <SimpleInputData
            placeHolder="YYYY-MM-DD"
            value={startDate}
            name="startDate"
            onChangeDate={(val: Moment) => {
              setFormState({
                ...formState,
                startDate: val.format("YYYY-MM-DD"),
                filter: true,
              });
            }}
          />
        </Col>
        <Col span={6}>
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Fecha Final:
          </Title>
          <SimpleInputData 
            placeHolder="YYYY-MM-DD"
            value={endDate}
            name="startDate"
            onChangeDate={(val: Moment) => {
              setFormState({
                ...formState,
                endDate: val.format("YYYY-MM-DD"),
                filter: true,
              });
            }}
          />
        </Col>
        <Col
          span={2}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            paddingTop: "8px",
          }}
        >
          <Button
            type="primary"
            onClick={() => setFormState(initialVal)}
            shape="circle"
            icon={<CloseOutlined />}
            size="large"
            danger
          />
        </Col>
        <Col style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            paddingTop: "8px",
          }} span={10}>
          <Button type="primary" onClick={() => handleDonwload()}>Descargar</Button>
        </Col>
      </Row> */}
    <TableCustom columns={columns}  service={appointmentSchedulingService}/>
    </>
  )
}
