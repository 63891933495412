import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { Link } from "react-router-dom";
import EditCmsLawTransparencyCategory from "../modules/cms-law-transparency-page/ui/edit-cms-law-transparency-category";

export default function EditLawTransparencyCategoryPage() {
  return (
    <Drawer
      title="Editar categoría"
      open
      width={350}
      destroyOnClose
      closeIcon={
        <Link to="/ley-de-transparencia-categorias">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <EditCmsLawTransparencyCategory />
    </Drawer>
  );
}
