
import { IModelCoursesSections } from '../../modules';
import { ICoursesSectionsState } from '../@types/coursesSections';

import { coursesSectionsActions } from '../action-types'

type coursesSectionsActionType =
| { type: typeof coursesSectionsActions.COURSES_SECTIONS_MODAL_STATE; }
| { type: typeof coursesSectionsActions.GET_COURSES_SECTIONS_FOR_UPDATE; payload: IModelCoursesSections }
| { type: typeof coursesSectionsActions.IS_COURSES_SECTIONS_FOR_UPDATE; payload: boolean }
| { type: typeof coursesSectionsActions.SWITCH_COURSES_SECTIONS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: ICoursesSectionsState = {
    coursesSectionsModalIsOpen: false,
    isUpdateCoursesSections: false,
    coursesSectionsForUpdate: null,
    refreshTableCoursesSections: false,
}

export default function coursesSectionsReducer(state: ICoursesSectionsState = INITIAL_STATE, action: coursesSectionsActionType): ICoursesSectionsState {
  switch (action.type) {
    case coursesSectionsActions.COURSES_SECTIONS_MODAL_STATE:
        return {
            ...state,
            coursesSectionsModalIsOpen: !state.coursesSectionsModalIsOpen,
        }
    case coursesSectionsActions.GET_COURSES_SECTIONS_FOR_UPDATE:
        return {
            ...state,
            coursesSectionsForUpdate: action.payload,
        }
    case coursesSectionsActions.IS_COURSES_SECTIONS_FOR_UPDATE:
        return {
            ...state,
            isUpdateCoursesSections: action.payload,
        }
    case coursesSectionsActions.SWITCH_COURSES_SECTIONS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableCoursesSections: action.payload,
        }
    default:
        return state
  }
}
