import { message } from "antd";

import { administrativeResourcesService } from "../../shared";
import { resourcesAdminActions } from "../action-types";
import { IModelResources } from "../../modules/resources-admin";

export const getSingleResource = (val:IModelResources ): resourcesAdminActions.IGetResourceForUpdate=> ({
    type: resourcesAdminActions.GET_RESOURCE_FOR_UPDATE,
    payload: val
})

export const setIsResourceForUpdate =(val: boolean): resourcesAdminActions.IIsResourceForUpdate =>({
    type: resourcesAdminActions.IS_RESOURCE_FOR_UPDATE,
    payload: val,
})

export const fetchSingleResource = async(id: number) =>{
    return await administrativeResourcesService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createResource = async(data: IModelResources) =>{
    return await administrativeResourcesService.create(data)
    .then((res: IModelResources)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateResource = async(data: IModelResources) =>{
    return await administrativeResourcesService.patch(data?.id!, data)
    .then((res: any)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteResource = async(id: number) =>{
    return await administrativeResourcesService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableResource = (val: boolean): resourcesAdminActions.ISwitchResourceRefreshTable =>({
    type: resourcesAdminActions.SWITCH_RESOURCE_REFRESH_TABLE,
    payload: val
})

