import { FormProvider } from "react-hook-form";
import { Button, Col, Row } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import useEditFormAndModelCategories from "../lib/useEditFormAndModelCategories";
import FormsAndModelsCategoriesForm from "./components/FormAndModelsFormCategories";

export default function EditFormsAndModelsCategories() {
  const params = useParams();
  const navigate = useNavigate();

  const { formMethods, loading, handleSubmit } = useEditFormAndModelCategories(
    params?.recordId ? Number(params.recordId) : null,
    {
      onSuccess() {
        navigate("/corresponsales/formatos-y-modelos-categorias");
      },
    }
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit}>
        <Row gutter={[8, 16]} justify={"space-between"}>
          <Col xs={24}>
            <FormsAndModelsCategoriesForm />
          </Col>
          <Col xs={24}>
            <Button
              icon={<SaveOutlined />}
              block
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
}
