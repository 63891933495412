import { Button, Drawer } from "antd";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { StudyCerticatesNew } from "../modules/studyCertificates/ui/resource/keyAccountManagerNew";

export function StudyCerticatesNewPage() {
  return (
    <Drawer
      title="Crear Certificado"
      open
      width={350}
      destroyOnClose
      closeIcon={
        <Link to="/certificates">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <StudyCerticatesNew />
    </Drawer>
  )
}
