import { message } from "antd";
import { projectsActions } from "../action-types";
import { IModelProjects } from "../../modules";
import { projectsService } from "../../shared";


export const changeModalStateProjects = (): projectsActions.IProjectsModalState =>({
    type: projectsActions.PROJECTS_MODAL_STATE
})

export const getSingleProjects = (val:IModelProjects ): projectsActions.IGetProjectsForUpdate=> ({
    type: projectsActions.GET_PROJECTS_FOR_UPDATE,
    payload: val
})

export const setIsProjectsForUpdate =(val: boolean): projectsActions.IIsProjectsForUpdate =>({
    type: projectsActions.IS_PROJECTS_FOR_UPDATE,
    payload: val,
})

export const fetchSingleProjects = async(id: number) =>{
    return await projectsService.find({ query: {id} })
      .then((res) => {
        return res;
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createProject = async(data:Partial<IModelProjects>) =>{
    return await projectsService.create(data)
    .then((res: IModelProjects)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateProject = async(data:Partial<IModelProjects>) =>{

    console.log(44, data);
    for (const clave in data) {
        if (data.hasOwnProperty(clave)) {
            const valor = data[clave as keyof IModelProjects];
            if(valor == null){
                delete data[clave as keyof IModelProjects];
            }
        }
    }
    console.log(22, data);
    return await projectsService.patch(data?.id!, data)
    .then((res: IModelProjects)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteProject = async(id: number) =>{
    return await projectsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableProjects = (val: boolean): projectsActions.ISwitchProjectsRefreshTable =>({
    type: projectsActions.SWITCH_PROJECTS_REFRESH_TABLE,
    payload: val
})

