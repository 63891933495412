import { eventsTagsService } from "../../shared"

export default function Dashboard() {
  async function sss() {
    return eventsTagsService.find().then((res)=>{
      console.log({res})
    }).catch((err)=>{
      console.log({err})
    })
  }
  sss()
  return (
    <div>Selecciona una pestaña</div>
  )
}
