import type { ICmsLawTransparency } from "../../../../@types/dto/cms-law-transparenct";

import { Col, Row } from "antd";
import { useFormContext } from "react-hook-form";
import { InputText } from "../../../../shared";

export default function CmsLawTransparencyCategoryForm() {
  const { control } =
    useFormContext<ICmsLawTransparency>();

  return (
    <Row gutter={[8, 16]}>
      <Col span={24}>
        <InputText
          labelText="Nombre"
          control={control}
          name="name"
          placeHolder="Nombre"
        />
      </Col>
    </Row>
  );
}
