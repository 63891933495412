import { message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { createWebinar, getSingleWebinars, refreshTableWebinars, selectIsUpdateWebinars, selectRefreshTableWebinars, selectSingleWebinars, setIsWebinarsForUpdate, updateWebinar } from "../../../../redux";
import { IModelWebinars } from "../../@types";
import { yupResolver } from "@hookform/resolvers/yup";
import { validateCreateWebinars, validateUpdateWebinars } from "../../../../shared/utils/yupValidation";

export function useWebinars() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const isUpdateWebinars = useSelector(
      selectIsUpdateWebinars
    );

    const dataUpdateWebinars = useSelector(
      selectSingleWebinars
    );


    const isRefreshTableWebinars = useSelector(
      selectRefreshTableWebinars
    );

    const [isLoading, setIsLoading] = useState(false);

    const changeNavigateForCreateWebinar = () => {
        dispatch(setIsWebinarsForUpdate(false))
        navigate(`/webinar/new`);
    }

    const formMethodsCreate = useForm<IModelWebinars>({
        resolver: yupResolver(validateCreateWebinars),
    });

    const formMethodsUpdate = useForm<IModelWebinars>({
        resolver: yupResolver(validateUpdateWebinars),
    });


    useEffect(() => {
        if (isUpdateWebinars) {
            formMethodsUpdate.reset({
              ...dataUpdateWebinars,
              start_date: moment(dataUpdateWebinars?.start_date).utcOffset(0)
          });
        }

        return () => {
            formMethodsUpdate.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdateWebinars, dataUpdateWebinars]);

    const updateStatus = async(value: "active" | "inactive", id: any) =>{
      if (isLoading) return;
      setIsLoading(true);
      await updateWebinar({
        id,
        status: value,
      })
      .then((res: Partial<IModelWebinars>) => {
        setIsLoading(false);
        if (res.id) {
          message.success(
            `Se ha actualizado con éxito el estado del webinar con id:${res.id}`
          );
          dispatch(refreshTableWebinars(true));
        } else {
          message.error(res as unknown as string);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
        message.error(err.message);
      });
    }

    const onSubmitCreateOrUpdate = async (data: IModelWebinars) => {
        // console.log({ data });
        if (isLoading) return;
        setIsLoading(true);
        if (isUpdateWebinars) {
          await updateWebinar({
            id: data.id,
            name: data.name,
            category_id: data.category_id,
            status: data.status,
            small_description: data.small_description,
            path_image: data.path_image,
            description: data.description,
            level: data.level,
            position: data.position,
            start_date: data.start_date,
            link: data.link,
            assistance_type: data.assistance_type,
            max_capacity: data.max_capacity,
            base_price: data.base_price,
            tax_rule_id: data.tax_rule_id,
            affiliate_offer: data.affiliate_offer,
            affiliate_offer_discount_percentage: data.affiliate_offer_discount_percentage,
            affiliate_offer_quantity: data.affiliate_offer_quantity,
          })
            .then((res: Partial<IModelWebinars>) => {
              if (res.id) {
                message.success(
                  `Se ha actualizado con éxito el webinar con id:${res.id}`
                );
                dispatch(getSingleWebinars(res as any))
                dispatch(refreshTableWebinars(true));
              } else {
                message.error(res as unknown as string);
              }
            })
            .catch((err: any) => {
              console.log(err);
              message.error(err.message);
            })
            .finally(()=>{
                setIsLoading(false);
            });
        } else {
          await createWebinar({...data})
            .then((res: IModelWebinars) => {
              if (res.id) {
                //navegar a la tabla
                // dispatch(changeModalStateWebinars());
                message.success(`Se ha creado con éxito el webinar con id:${res.id}`);
                dispatch(refreshTableWebinars(true));
                navigate(`/webinar`)
                window.scrollTo(0, 0)
              } else {
                message.error(res as any);
              }
            })
            .catch((err: any) => {
              console.log(err);
              message.error(err.message);
            })
            .finally(()=>{
                setIsLoading(false);
            });
        }
    };

    useEffect(() => {
        if (isRefreshTableWebinars) {
          dispatch(refreshTableWebinars(false));
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isRefreshTableWebinars]);

  return {
    //state
    isLoading,
    isUpdateWebinars,
    dataUpdateWebinars,
    isRefreshTableWebinars,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeNavigateForCreateWebinar,
    onSubmitCreateOrUpdate,
    updateStatus,
  }
}
