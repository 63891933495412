import { message } from "antd";
import { useEffect, useState } from "react";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { changeModalStateEventTags, createEventTags, refreshTableEventTags, selectIsUpdateEventTags, selectRefreshTableEventTags, selectSingleEventTags, selectSingleEvents, setIsEventTagsForUpdate, updateEventTags } from "../../../../redux";
import { IModelEventTags } from "../../@types";
import { validateCreateEventTags, validateUpdateEventTags } from "../../../../shared/utils/yupValidation";

export function useEventTags() {
  const dispatch = useAppDispatch();

  const isUpdateEventTags = useSelector(
      selectIsUpdateEventTags
  );

  const dataUpdateEventTags = useSelector(
      selectSingleEventTags
  );

  const isRefreshTableEventTags = useSelector(
      selectRefreshTableEventTags
  );

  const dataUpdateEvents = useSelector( selectSingleEvents );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalEventTagsStateForCreate = () => {
    dispatch(setIsEventTagsForUpdate(false))
    dispatch(changeModalStateEventTags())
  }

  const formMethodsCreate = useForm<IModelEventTags>({
    resolver: yupResolver(validateCreateEventTags),
  });

  const formMethodsUpdate = useForm<IModelEventTags>({
    resolver: yupResolver(validateUpdateEventTags),
  });

  useEffect(() => {
    if (isUpdateEventTags) {
        formMethodsCreate.reset();
        formMethodsUpdate.reset({ ...dataUpdateEventTags });
    }

    return () => {
        formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateEventTags, dataUpdateEventTags]);

  const onSubmitCreateOrUpdate = async (data: IModelEventTags) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateEventTags) {
      await updateEventTags({
        id: data.id,
        events_tags_id: data.events_tags_id,
        event_id: dataUpdateEvents?.id,
      })
        .then((res: Partial<IModelEventTags>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito los tags del evento con id:${res.id}`
            );
            dispatch(refreshTableEventTags(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createEventTags({...data, event_id: dataUpdateEvents?.id,})
        .then((res: IModelEventTags) => {
          if (res.id) {
            dispatch(changeModalStateEventTags());
            message.success(
              `Se ha creado con éxito los tags del evento id:${res.id}`
            );
            dispatch(refreshTableEventTags(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableEventTags) {
      dispatch(refreshTableEventTags(false));
    }
  }, [isRefreshTableEventTags]);

  return{
    //state
    isLoading,
    isUpdateEventTags,
    dataUpdateEventTags,
    isRefreshTableEventTags,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalEventTagsStateForCreate,
    onSubmitCreateOrUpdate,
  }
}
