import { RegistryServicesConsultNews } from "../modules/registry-services-consult-news";
import { AdminLayout } from "../shared";

export function RegistryServicesConsultNewPage() {
  return (
    <AdminLayout>
      <RegistryServicesConsultNews />
    </AdminLayout>
  )
}
