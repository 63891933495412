import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { message } from "antd";
import { getSingleProjects, setIsProjectsForUpdate } from "../../../../redux";
import { projectsService } from "../../../../shared";

export function useProjectsById() {
    const [isValidate, setIsValidate ] = useState(false)

    const { pathname } = useLocation()
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const getSingleProjectsFetch = async ( id: number) =>{
        return await projectsService
        .get(id)
        .then((res) => {
            return res;
        })
        .catch((err: any) => {
            console.log(err);
            navigate(`/project`);
            message.error(err.message);
        });
    }

    useEffect(() => {
        if( pathname !== "/project/new" ){
          if (isNaN(Number(pathname.split("/")[2]))) return navigate(`/project`);
            dispatch(setIsProjectsForUpdate(true));
            getSingleProjectsFetch(Number(pathname.split("/")[2])).then((res) =>
                dispatch(getSingleProjects(res as any))
            );
            setIsValidate(true)
        }else if(pathname === "/project/new" ){
            dispatch(setIsProjectsForUpdate(false));
            setIsValidate(true)
        }
    }, [])

  return {
    //state
    isValidate,
    //methods
    //functions
  }
}
