import React from "react";
import { message } from "antd";
import { pressNewsletterService } from "../../../shared";
import { IPressNewsletter } from "../@types/press-newsletter";

export default function useUpdatePressNewsletterStatus(props?: {
  onSuccess: (record: IPressNewsletter) => void;
}) {
  const [loading, setLoading] = React.useState(false);

  const loadingRef = React.useRef(false);

  const updateLoading = (status: boolean) => {
    setLoading(status);
    loadingRef.current = status;
  };

  const updateStatus = async (
    recordId: number,
    status: IPressNewsletter["status"]
  ) => {
    if (loadingRef.current) return;

    updateLoading(true);

    await pressNewsletterService
      .patch(recordId, { status })
      .then((record) => {
        props?.onSuccess?.(record as IPressNewsletter);
        message.success("Estado actualizado correctamente");
      })
      .catch((err) => {
        message.error(err?.message);
      })
      .finally(() => {
        updateLoading(false);
      });
  };

  return {
    loading,
    updateStatus,
  };
}
