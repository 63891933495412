import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectModalAuthors } from "../../../redux/selectors/authors.selectors";
import { useAuthors } from "../hooks";
import { FormProvider } from "react-hook-form";
import { TableAuthors } from "./TableAuthors";
import { ModalAuthors } from "./ModalAuthors";

export function Authors() {
  const modalAuthors = useSelector(
    selectModalAuthors
  );
  const {
    //state
    isUpdateAuthors,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalAuthorsStateForCreate,
  } = useAuthors()
  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
        >
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          size="large"
          onClick={changeModalAuthorsStateForCreate}
        >
          Agregar nuevo autor
        </Button>
      </Col>
      <TableAuthors />
      {
        modalAuthors && isUpdateAuthors && (
            <FormProvider {...formMethodsUpdate}>
                <ModalAuthors />
            </FormProvider>
        )
      }
      {
        modalAuthors && !isUpdateAuthors && (
            <FormProvider {...formMethodsCreate}>
                <ModalAuthors />
            </FormProvider>
        )
      }
    </Row>
  )
}
