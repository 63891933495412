import * as yup from 'yup'

export const validateCreateWebinarsIncludes = yup.object().shape({
    // webinar_id:yup
    // .number()
    // .required('Campo requerido.'),
    // path_icon: yup
    // .string()
    // .required('Campo requerido.')
    // .min(2, 'El ICONO es necesario.'),
    include_text:yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El TEXTO REQUERIDO debe tener mínimo de 2 caracteres.'),
    position:yup
    .number()
    .required('Campo requerido.'),
})