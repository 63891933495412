import React from "react";
import { IWidget } from "../../../widgets/widget";
import { IField, fieldDefaultStyles } from "../../field";

export default function useDroppable(props: {
  onDrop: (field: IField) => void;
}) {
  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    // TODO
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    // TODO
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    // TODO
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const position = {
      width: 0,
      height: 0,
      left: 0,
      top: 0,
    };

    const positionParent = e.currentTarget.getBoundingClientRect();
    const data = e.dataTransfer.getData("widget");

    if (!data) return;

    const widget = JSON.parse(data) as IWidget;

    position.width = Math.min(
      (widget.offsetWidth / positionParent.width) * 100,
      100
    );
    if (widget.ratio) {
      position.height = Math.min(
        100,
        ((widget.offsetWidth * widget.ratio) / positionParent.height) * 100
      );
    } else {
      position.height = Math.min(
        100,
        (widget.offsetHeight / positionParent.height) * 100
      );
    }

    position.left = Math.max(
      0,
      Math.min(
        100 - position.width,
        ((e.pageX - positionParent.left - window.pageXOffset) /
          positionParent.width) *
          100
      )
    );
    position.top = Math.max(
      0,
      Math.min(
        100 - position.height,
        ((e.pageY - positionParent.top - window.pageYOffset) /
          positionParent.height) *
          100
      )
    );

    const field: IField = {
      id: crypto.randomUUID(),
      description: widget.description,
      key_name: widget.key_name,
      name: widget.name,
      placeholder: widget.placeholder,
      styles: fieldDefaultStyles,
      ...position,
    };

    props.onDrop(field);
  };
  return {
    listeners: {
      onDragEnter,
      onDragLeave,
      onDragOver,
      onDrop,
    },
  };
}
