import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { Link } from "react-router-dom";
import EditFormsAndModels from "../modules/Corresponsales/ui/EditFormsAndModels";

export default function EditCorresponsalesFormsAndModelsPage() {
  return (
    <Drawer
      title="Editar formato/modelo"
      open
      width={350}
      destroyOnClose
      closeIcon={
        <Link to="/corresponsales/formatos-y-modelos">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <EditFormsAndModels />
    </Drawer>
  );
}
