import { IModelProjectsRequirements } from "../../modules"


export const PROJECTS_REQUIREMENTS_MODAL_STATE = '[ PROJECTS REQUIREMENTS ] Webinars Requirements Modal State'

export interface IProjectsRequirementsModalState {
    type: typeof PROJECTS_REQUIREMENTS_MODAL_STATE
}

export const GET_PROJECTS_REQUIREMENTS_FOR_UPDATE = '[ PROJECTS REQUIREMENTS ] Get Projects Requirements For Update'

export interface IGetProjectsRequirementsForUpdate {
    type: typeof GET_PROJECTS_REQUIREMENTS_FOR_UPDATE
    payload: IModelProjectsRequirements
}

export const IS_PROJECTS_REQUIREMENTS_FOR_UPDATE = '[ PROJECTS REQUIREMENTS ] Is Webinars Requirements For Update'

export interface IIsProjectsRequirementsForUpdate {
    type: typeof IS_PROJECTS_REQUIREMENTS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_PROJECTS_REQUIREMENTS_REFRESH_TABLE = '[ PROJECTS REQUIREMENTS ] Switch Webinars Requirements Refresh Table'

export interface ISwitchProjectsRequirementsRefreshTable {
    type: typeof SWITCH_PROJECTS_REQUIREMENTS_REFRESH_TABLE
    payload: boolean
}