import { message } from "antd";

import {  projectsService } from "../../shared";
import { projectsManagersActions } from "../action-types";
import { IModelProjectsManagers } from "../../modules";

export const changeModalStateProjectsManagers = (): projectsManagersActions.IProjectsManagersModalState =>({
    type: projectsManagersActions.PROJECTS_MANAGERS_MODAL_STATE
})

export const getSingleProjectsManagers = (val:IModelProjectsManagers ): projectsManagersActions.IGetProjectsManagersForUpdate=> ({
    type: projectsManagersActions.GET_PROJECTS_MANAGERS_FOR_UPDATE,
    payload: val
})

export const setIsProjectsManagersForUpdate =(val: boolean): projectsManagersActions.IIsProjectsManagersForUpdate =>({
    type: projectsManagersActions.IS_PROJECTS_MANAGERS_FOR_UPDATE,
    payload: val,
})

export const fetchSingleProjectsManagers = async(id: number) =>{
    return await projectsService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createProjectManagers = async(data:Partial<IModelProjectsManagers>) =>{
    return await projectsService.patch(data?.project_id!, { meta_managers: JSON.stringify(data) })
    .then((res: IModelProjectsManagers)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateProjectManagers = async(data:Partial<IModelProjectsManagers>) =>{
    return await projectsService.patch(data?.project_id!, { meta_managers: JSON.stringify(data) })
    .then((res: IModelProjectsManagers)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteProjectManagers = async(id: number) =>{
    return await projectsService.patch(id, {meta_managers: 'null'})
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableProjectsManagers = (val: boolean): projectsManagersActions.ISwitchProjectsManagersRefreshTable =>({
    type: projectsManagersActions.SWITCH_PROJECTS_MANAGERS_REFRESH_TABLE,
    payload: val
})

