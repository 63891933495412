import { Button, Col, Row, Spin } from "antd";
import { FormProvider } from "react-hook-form";
import { CompaniesForm } from "./CompaniesForm";
import { SaveOutlined } from "@ant-design/icons";
import useEditCompanies from "../../hooks/companies/useEditCompanies";
import { useNavigate, useParams } from "react-router-dom";

export function CompaniesById() {
  const navigate = useNavigate();

  const params = useParams();
  const { formMethods, loading, handleSubmit } = useEditCompanies(
    params?.recordId ? Number(params.recordId) : null,
    {
      onSuccess() {
        navigate("/companies");
      },
    }
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit}>
        <Row gutter={[8, 16]} justify={"space-between"}>
          <Col xs={24}>
            <CompaniesForm />
          </Col>
          <Col xs={24}>
            <Button
              icon={<SaveOutlined />}
              block
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              Actualizar
            </Button>
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
}
