 
import { DeleteOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Typography, Row } from "antd";
import moment, {Moment} from "moment";
import { TableCustom, userEventService } from "../../../shared"
import { useState } from "react";
// import { useAuthors, useTableAuthors } from "../hooks"
// import { IModelAuthors } from "../@types";
import { PATH_S3_BUCKET } from "../../../shared/utils/constants";
import { SimpleInputData } from "../../../shared/components/ui/inputs/SimpleInputData";
import { TableColumns } from "../../../shared/components/ui/table/types";

const { Title } = Typography;

const initialVal = {
  startDate: null,
  endDate: null,
  filter: false,
};

export function TableEvents() {

  const [formState, setFormState] = useState<{
    startDate: null | Moment | string;
    endDate: null | Moment | string;
    filter: boolean
  }>(initialVal);

  const { endDate, startDate, filter } = formState;

  const handleDonwload = async () => {
    await userEventService.find({
      query: {
        $client: {download: 'false'}
      }
    }).then((res) => {
      console.log(44, res?.data);
      window.open(res?.data?.[0]?.objectUrl)
    })
  }

  const columns: TableColumns<any> = [
    {
        title: 'ID',
        dataIndex: 'id',
        align: 'left',
        sorter:true
    },
    {
        title: 'Usuario',
        dataIndex: 'name',
        align: 'left',
        render: (_:any, item:any) => (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Title level={5}>
              {`${item?.user?.first_name + ' ' + item?.user?.last_name}`}
            </Title>
          </Col>
        ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: 'left',
      render: (_:any, item:any) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
            flexDirection: "row",
          }}
        >
          <Title level={5}>
            {`${item?.user?.email}`}
          </Title>
        </Col>
      ),
  },
    {
        title: 'Evento',
        dataIndex: 'name',
        align: 'left',
        render: (_:any, item:any) => (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Title level={5}>
              {`${item?.event?.name}`}
            </Title>
          </Col>
        ),
    },
    {
      title: 'Fecha de Creación',
      width: 250,
      dataIndex: 'createdAt',
      align: 'left',
      render: (_:any, item:any) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
            flexDirection: "row",
          }}
        >
          <Title level={5}>
            {moment(item.createdAt).utcOffset(0).format("DD/MM/YYYY HH:mm:ss")}
          </Title>
        </Col>
      ),
    },
]

  return (
    <>
      <Row
        gutter={[8, 8]}
        style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: 30 }}
      >
        <Col span={6}>
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Fecha de Inicio:
          </Title>
          <SimpleInputData
            placeHolder="YYYY-MM-DD"
            value={startDate}
            name="startDate"
            onChangeDate={(val: Moment) => {
              setFormState({
                ...formState,
                startDate: val.format("YYYY-MM-DD"),
                filter: true,
              });
            }}
          />
        </Col>
        <Col span={6}>
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Fecha Final:
          </Title>
          <SimpleInputData 
            placeHolder="YYYY-MM-DD"
            value={endDate}
            name="startDate"
            onChangeDate={(val: Moment) => {
              setFormState({
                ...formState,
                endDate: val.format("YYYY-MM-DD"),
                filter: true,
              });
            }}
          />
        </Col>
        <Col
          span={2}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            paddingTop: "8px",
          }}
        >
          <Button
            type="primary"
            onClick={() => setFormState(initialVal)}
            shape="circle"
            icon={<CloseOutlined />}
            size="large"
            danger
          />
        </Col>
        <Col style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            paddingTop: "8px",
          }} span={10}>
          <Button type="primary" onClick={() => handleDonwload()}>Descargar</Button>
        </Col>
      </Row>
    <TableCustom columns={columns}  service={userEventService}/>
    </>
  )
}
