import { BrowserRouter } from "react-router-dom";

import { ConfigProvider } from "antd";
import { AppRouter } from "./router";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  :root{
      --primary: #004A98;
      --white: #FFF
  }`;

function App() {
  return (
    <BrowserRouter>
      <AppRouter />

      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#004A98",
          },
        }}
      >
        <GlobalStyle />
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
