import { Tabs } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function MarketingMaterialLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const activeKey = React.useMemo(() => {
    if (
      [
        "/corresponsales/material-de-marketing",
        "/corresponsales/material-de-marketing/agregar",
        "/corresponsales/material-de-marketing/:recordId",
      ].includes(location.pathname)
    ) {
      return "/corresponsales/material-de-marketing";
    }
    return "/corresponsales/material-de-marketing-categorias";
  }, [location.pathname]);

  return (
    <>
      <Title level={3}>Material de marketing</Title>
      <div style={{ width: "100%" }}>
        <Tabs
          size="large"
          type="card"
          activeKey={activeKey}
          onChange={(activeKey) => {
            navigate(activeKey);
          }}
          items={[
            {
              key: "/corresponsales/material-de-marketing",
              label: "Material de marketing",
              children,
            },
            {
              key: "/corresponsales/material-de-marketing-categorias",
              label: "Categorías",
              children,
            },
          ]}
        />
      </div>
    </>
  );
}
