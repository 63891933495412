import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { selectModalEventTags } from "../../../../redux";
import { useEventTags } from "../../hooks";
import { TableEventTags } from "./TableEventTags";
import { ModalEventTags } from "./ModalEventTags";


export function EventTags() {
  const modalEventTags = useSelector(
    selectModalEventTags
  );

  const {
    //state
    isUpdateEventTags,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalEventTagsStateForCreate,
  } = useEventTags();

  return (
    <Row
      gutter={[8, 8]}
      style={{
      padding: "10px",
      width: "100%",
      }}>
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
            onClick={changeModalEventTagsStateForCreate}>
              Agregar nuevo tags de evento
          </Button>
        </Col>
        <TableEventTags />
        {
          modalEventTags && (
            <FormProvider { ...isUpdateEventTags ?{...formMethodsUpdate} : {...formMethodsCreate}}>
              <ModalEventTags />
            </FormProvider>
          )
        }
    </Row>
  )
}
