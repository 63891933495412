
import { IModelCoursesAuthors } from '../../modules/courses/@types/IModelCoursesAuthors';
import { ICoursesAuthorsState } from '../@types/coursesAuthors';

import { coursesAuthorsActions } from '../action-types'

type coursesAuthorsActionType =
| { type: typeof coursesAuthorsActions.COURSES_AUTHORS_MODAL_STATE; }
| { type: typeof coursesAuthorsActions.GET_COURSES_AUTHORS_FOR_UPDATE; payload: IModelCoursesAuthors }
| { type: typeof coursesAuthorsActions.IS_COURSES_AUTHORS_FOR_UPDATE; payload: boolean }
| { type: typeof coursesAuthorsActions.SWITCH_COURSES_AUTHORS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: ICoursesAuthorsState = {
    coursesAuthorsModalIsOpen: false,
    isUpdateCoursesAuthors: false,
    coursesAuthorsForUpdate: null,
    refreshTableCoursesAuthors: false,
}

export default function coursesAuthorsReducer(state: ICoursesAuthorsState = INITIAL_STATE, action: coursesAuthorsActionType): ICoursesAuthorsState {
  switch (action.type) {
    case coursesAuthorsActions.COURSES_AUTHORS_MODAL_STATE:
        return {
            ...state,
            coursesAuthorsModalIsOpen: !state.coursesAuthorsModalIsOpen,
        }
    case coursesAuthorsActions.GET_COURSES_AUTHORS_FOR_UPDATE:
        return {
            ...state,
            coursesAuthorsForUpdate: action.payload,
        }
    case coursesAuthorsActions.IS_COURSES_AUTHORS_FOR_UPDATE:
        return {
            ...state,
            isUpdateCoursesAuthors: action.payload,
        }
    case coursesAuthorsActions.SWITCH_COURSES_AUTHORS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableCoursesAuthors: action.payload,
        }
    default:
        return state
  }
}
