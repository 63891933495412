import * as yup from "yup";

export const validateCreateWebinars = yup.object().shape({
  name: yup
    .string()
    .required("Campo requerido.")
    .min(2, "El nombre debe tener mínimo de 2 caracteres."),
  status: yup
    .string()
    .required("Campo requerido.")
    .oneOf(["active", "inactive"], "Debes elegir entre estas opciones."),
  category_id: yup.number().required("Campo requerido."),
  small_description: yup
    .string()
    .min(2, "La descripción corta debe tener mínimo de 2 caracteres."),
  description: yup
    .string()
    .required("Campo requerido.")
    .min(2, "La descripción debe tener mínimo de 2 caracteres."),
  level: yup
    .number()
    .required("Campo requerido.")
    .min(1, "El nivel debe tener mínimo de 1 carácter."),
  // duration: yup.string().min(2, "La duración debe ser en un formato valido."),
  // path_video:yup
  // .string()
  // .required('Campo requerido.')
  // .min(1, 'Debes de incluir mínimo un video.'),
  // path_image:yup
  // .string()
  // .required('Campo requerido.')
  // .min(1, 'Debes de incluir mínimo una imagen.'),
  position: yup.number(),

  start_date: yup
    .string()
    .required("Campo requerido.")
    .min(1, "La fecha de inicio debe tener más de 1 carácter"),
});
