 
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { Button, Col, Typography } from "antd";
import { useRegistryServicesConsultNews, useTableRegistryServicesConsultNews } from "../../hooks";
import { TableCustom, registryServicesConsultNewsService } from "../../../../shared";
import { IModelRegistryServicesConsultNews } from "../../@types";
import { TableColumns, TableProperties } from "../../../../shared/components/ui/table/types";
import React from "react";

const { Title } = Typography;

export function TableEvents() {
  const {
    //state
    //methods
    //functions
    changeEventsForUpdate,
    showDeleteConfirm,
} =  useTableRegistryServicesConsultNews()

const {
    //state
    isRefreshTableEvents,
    isLoading,
}= useRegistryServicesConsultNews()

const tableRef = React.useRef<TableProperties>()

    React.useEffect(() => {
      tableRef.current?.refresh()
    }, [isRefreshTableEvents])

const columns: TableColumns<any> = [
    {
        title: 'ID',
        dataIndex: 'id',
        align: 'left',
        sorter:true
    },
    {
        title: 'Nobre del servicio',
        
        dataIndex: 'name',
        align: 'left',
    },
    {
        title: 'Años',
        width: 200,
        dataIndex: 'age',
        align: 'left',
    },
    {
        title: 'Action Link',
        width: 100,
        dataIndex: 'action_link',
        align: 'left',
    },
    {
      title: 'Fecha de Creación',
      dataIndex: 'createdAt',
      align: 'left',
      render: (_: any, item: any) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
            flexDirection: "row",
          }}
        >
          <Title level={5}>
            {moment(item.createdAt).utcOffset(0).format("DD/MM/YYYY HH:mm:ss")}
          </Title>
        </Col>
      ),
    },
    {
        title: "Acciones",
        key: "operation",
        fixed: "right",
        
        render: (_:any, item:any) => {
          return (
            <Col
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "start",
                flexDirection: "row",
              }}
            >
              <Button
                type="primary"
                shape="circle"
                onClick={() => changeEventsForUpdate(item.id)}
                icon={<EditOutlined />}
                size="large"
              />
              <Button
                type="primary"
                danger
                onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
                shape="circle"
                icon={<DeleteOutlined />}
                size="large"
              />
            </Col>
          );
        },
    },
]

  return (
    <TableCustom columns={columns}  service={registryServicesConsultNewsService} ref={tableRef} />
  )
}
