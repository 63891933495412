import { Avatar, Button, Col, Typography } from "antd"
 
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { PATH_S3_BUCKET } from "../../../../shared/utils/constants";
import { TableCustom, eventsAuthorsService } from "../../../../shared";
import { selectSingleEvents } from "../../../../redux";
import { useEventsAuthors, useTableEventsAuthors } from "../../hooks";
import { IModelEventsAuthors } from "../../@types";
import { TableColumns, TableProperties } from "../../../../shared/components/ui/table/types";
import React from "react";

const { Title } = Typography;

export function TableEventsAuthors() {
  const dataUpdateEvents = useSelector( selectSingleEvents );

  const {
    //state
    //methods
    //functions
    changeEventsAuthorsForUpdate,
    showDeleteConfirm,
  } = useTableEventsAuthors()

  const {
    //state
    isRefreshTableEventsAuthors,
    //methods
    //functions
  }= useEventsAuthors()

  const tableRef = React.useRef<TableProperties>()

    React.useEffect(() => {
      tableRef.current?.refresh()
    }, [isRefreshTableEventsAuthors])

  const columns: TableColumns<IModelEventsAuthors> = [
    {
        title: 'ID',
        dataIndex: 'id',
        align: 'left',
        sorter:true
    },
    {
      title: "Avatar",
      dataIndex: "meta_author_avatar_path",
      key: "meta_author_avatar_path",
      render: (_:any, item:any) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Avatar size={64} src={`${PATH_S3_BUCKET}/${item.meta_author_avatar_path}`} />
          </Col>
        );
      },
    },
    {
      title: 'Autor',
      dataIndex: 'author_id',
      align: 'left',
      render: (_:any, item:any) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
            flexDirection: "row",
          }}
        >
          <Title level={5}>
            {`${item.meta_author_first_name} ${item.meta_author_last_name}`}
          </Title>
        </Col>
      ),
    },
    {
      title: 'Event',
      dataIndex: 'event_id',
      align: 'left',
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 140,
      render: (_:any, item:any) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeEventsAuthorsForUpdate(item.id)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.author_id}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ]

  return (
    <TableCustom fetchQueryProps={{event_id: dataUpdateEvents?.id!,}} columns={columns}  service={eventsAuthorsService} ref={tableRef}/>
  )
}
