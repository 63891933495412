import { IModelProjectsBenefits } from "../../modules"


export const PROJECTS_BENEFITS_MODAL_STATE = '[ ProjectS BENEFITS ] PROJECTs Benefits Modal State'

export interface IProjectsBenefitsModalState {
    type: typeof PROJECTS_BENEFITS_MODAL_STATE
}

export const GET_PROJECTS_BENEFITS_FOR_UPDATE = '[ PROJECTS BENEFITS ] Get PROJECTs Benefits For Update'

export interface IGetProjectsBenefitsForUpdate {
    type: typeof GET_PROJECTS_BENEFITS_FOR_UPDATE
    payload: IModelProjectsBenefits
}

export const IS_PROJECTS_BENEFITS_FOR_UPDATE = '[ PROJECTS BENEFITS ] Is Projects Benefits For Update'

export interface IIsProjectsBenefitsForUpdate {
    type: typeof IS_PROJECTS_BENEFITS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_PROJECTS_BENEFITS_REFRESH_TABLE = '[ PROJECTS BENEFITS ] Switch Projects Benefits Refresh Table'

export interface ISwitchProjectsBenefitsRefreshTable {
    type: typeof SWITCH_PROJECTS_BENEFITS_REFRESH_TABLE
    payload: boolean
}