
import { IModelWebinarsIncludes } from '../../modules';
import { IWebinarsIncludesState } from '../@types/webinarsIncludes';

import { webinarsIncludesActions } from '../action-types'

type webinarsIncludesActionType =
| { type: typeof webinarsIncludesActions.WEBINARS_INCLUDES_MODAL_STATE; }
| { type: typeof webinarsIncludesActions.GET_WEBINARS_INCLUDES_FOR_UPDATE; payload: IModelWebinarsIncludes }
| { type: typeof webinarsIncludesActions.IS_WEBINARS_INCLUDES_FOR_UPDATE; payload: boolean }
| { type: typeof webinarsIncludesActions.SWITCH_WEBINARS_INCLUDES_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IWebinarsIncludesState = {
    webinarsIncludesModalIsOpen: false,
    isUpdateWebinarsIncludes: false,
    webinarsIncludesForUpdate: null,
    refreshTableWebinarsIncludes: false,
}

export default function webinarsIncludesReducer(state: IWebinarsIncludesState = INITIAL_STATE, action: webinarsIncludesActionType): IWebinarsIncludesState {
  switch (action.type) {
    case webinarsIncludesActions.WEBINARS_INCLUDES_MODAL_STATE:
        return {
            ...state,
            webinarsIncludesModalIsOpen: !state.webinarsIncludesModalIsOpen,
        }
    case webinarsIncludesActions.GET_WEBINARS_INCLUDES_FOR_UPDATE:
        return {
            ...state,
            webinarsIncludesForUpdate: action.payload,
        }
    case webinarsIncludesActions.IS_WEBINARS_INCLUDES_FOR_UPDATE:
        return {
            ...state,
            isUpdateWebinarsIncludes: action.payload,
        }
    case webinarsIncludesActions.SWITCH_WEBINARS_INCLUDES_REFRESH_TABLE:
        return {
            ...state,
            refreshTableWebinarsIncludes: action.payload,
        }
    default:
        return state
  }
}
