import { Col, DatePicker, Input, Row, Typography } from "antd";
import useForm from "../lib/useForm";
import moment from "moment";

export default function FormDetail({ recordId }: { recordId?: number | null }) {
  const { form, parseData } = useForm(recordId);

  return (
    <div>
      <Row gutter={[8, 16]}>
        <Col xs={24}>
          <Typography.Text strong>Nombre del formulario</Typography.Text>
          <Input value={form?.form_name} disabled />
        </Col>
        <Col xs={24}>
          <Typography.Text strong>Fecha</Typography.Text>
          <div>
            <Typography.Text>{form?.createdAt}</Typography.Text>
          </div>
        </Col>
        {Object.entries(parseData(form?.form_data || "{}")).map(
          ([key, value]) => (
            <Col key={key} xs={24}>
              <Typography.Text strong>{key}</Typography.Text>
              <Input value={value as string} disabled />
            </Col>
          )
        )}
      </Row>
    </div>
  );
}
