import { Drawer, Tabs } from "antd";
import styled from "styled-components";

//modal Courses

export const WrapperModalCourses = styled(Drawer)``;


export const WrapperTabNavCourses = styled(Tabs)`
  width: 100%;
`;