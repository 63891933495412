import React from "react";

import { useForm } from "react-hook-form";
import { pressNewsletterService } from "../../../shared";
import { message } from "antd";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IPressNewsletter } from "../@types/press-newsletter";

export default function useCreatePressNewsletter(props?: {
  onSuccess?: (record: IPressNewsletter) => void;
}) {
  const [loading, setLoading] = React.useState(false);

  const formMethods = useForm<IPressNewsletter>({
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().required("Campo requerido"),
        image_path_cover: yup.string().required("Campo requerido"),
        body: yup.string().required("Campo requerido"),
        short_description: yup.string().required("Campo requerido"),
        position: yup.number().required("Campo requerido"),
        status: yup
          .string()
          .required("Campo requerido")
          .oneOf(["active", "inactive"]),
      })
    ),
  });

  const loadingRef = React.useRef(false);

  const updateLoading = (status: boolean) => {
    setLoading(status);
    loadingRef.current = status;
  };

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    if (loadingRef.current) return;

    updateLoading(true);

    await pressNewsletterService
      .create(data)
      .then((record) => {
        props?.onSuccess?.(record);
        message.success("Boletín creado correctamente");
      })
      .catch((err) => {
        message.error(err?.message);
      })
      .finally(() => {
        updateLoading(false);
      });
  });

  return {
    formMethods,
    loading,
    handleSubmit,
  };
}
