import { message } from "antd";
import { useEffect, useState } from "react";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { changeModalStateEventsSchedules, createEventSchedules, refreshTableEventsSchedules, selectIsUpdateEventsSchedules, selectRefreshTableEventsSchedules, selectSingleEventsSchedules, selectSingleEvents, setIsEventsSchedulesForUpdate, updateEventSchedules } from "../../../../redux";
import { IModelEventsSchedules } from "../../@types";
import { validateCreateEventsSchedules, validateUpdateEventsSchedules } from "../../../../shared/utils/yupValidation";

export function useEventsSchedules() {
  const dispatch = useAppDispatch();

  const isUpdateEventsSchedules = useSelector(
    selectIsUpdateEventsSchedules
  );

  const dataUpdateEventsSchedules = useSelector(
    selectSingleEventsSchedules
  );

  const isRefreshTableEventsSchedules = useSelector(
    selectRefreshTableEventsSchedules
  );

  const dataUpdateEvents = useSelector( selectSingleEvents );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalEventsSchedulesStateForCreate = () => {
    dispatch(setIsEventsSchedulesForUpdate(false))
    dispatch(changeModalStateEventsSchedules())
  }

  const formMethodsCreate = useForm<IModelEventsSchedules>({
    resolver: yupResolver(validateCreateEventsSchedules),
  });

  const formMethodsUpdate = useForm<IModelEventsSchedules>({
    resolver: yupResolver(validateUpdateEventsSchedules),
  });

  useEffect(() => {
  if (isUpdateEventsSchedules) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateEventsSchedules });
  }

  return () => {
      formMethodsUpdate.reset();
  };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateEventsSchedules, dataUpdateEventsSchedules]);

  const onSubmitCreateOrUpdate = async (data: IModelEventsSchedules) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateEventsSchedules) {
      await updateEventSchedules({
        id: data.id,
        name: data.name,
        description: data.description,
        day: data.day,
        start_time: data.start_time,
        end_time: data.end_time,
        event_id: dataUpdateEvents?.id,
      })
        .then((res: Partial<IModelEventsSchedules>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito el horario de evento con id:${res.id}`
            );
            dispatch(refreshTableEventsSchedules(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createEventSchedules({...data, event_id: dataUpdateEvents?.id,})
        .then((res: IModelEventsSchedules) => {
          if (res.id) {
            dispatch(changeModalStateEventsSchedules());
            message.success(
              `Se ha creado con éxito el horario con id:${res.id}`
            );
            dispatch(refreshTableEventsSchedules(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableEventsSchedules) {
      dispatch(refreshTableEventsSchedules(false));
    }
  }, [isRefreshTableEventsSchedules]);

  return{
      //state
      isLoading,
      isUpdateEventsSchedules,
      dataUpdateEventsSchedules,
      isRefreshTableEventsSchedules,
      //methods
      formMethodsCreate,
      formMethodsUpdate,
      //functions
      changeModalEventsSchedulesStateForCreate,
      onSubmitCreateOrUpdate,
  }
}
