import { AdminLayout } from "../shared";
import { Button, Flex, Typography } from "antd";
import { Link, Outlet } from "react-router-dom";
import InfographicCategories from "../modules/infographic-categories/ui/infographic-categories";

export const InfographicsCategoriesPage = () => {
  return (
    <AdminLayout>
    <Typography.Title>Categorías de infografias</Typography.Title>
    <div style={{ width: "100%" }}>
      <Flex justify="flex-end">
        <Link to="/infographic-categories/new">
          <Button type="primary">+ Categoría</Button>
        </Link>
      </Flex>
      <InfographicCategories />
      <Outlet />
    </div>
  </AdminLayout>
  );
};
