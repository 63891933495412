 
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { Button, Col, Typography } from "antd";
import { UsekeyAccountManagers, useTablekeyAccountManagers } from "../../hooks";
import { TableCustom, categoriesInfographiesService } from "../../../../shared";
import { TableColumns, TableProperties } from "../../../../shared/components/ui/table/types";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const { Title } = Typography;

export function TableCategoriesInfographics() {
  const {
    //state
    //methods
    //functions
    changeEventsForUpdate,
    showDeleteConfirm,
} =  useTablekeyAccountManagers()

const {
    //state
    isRefreshTablekeyAccountManagers,
    isLoading,
    updateStatus,
}= UsekeyAccountManagers()

const location = useLocation();
const navigate = useNavigate();

const tableRef = React.useRef<TableProperties>()

    React.useEffect(() => {
      tableRef.current?.refresh()
    }, [isRefreshTablekeyAccountManagers])

    React.useEffect(() => {
      tableRef.current?.refresh();
    }, [location.pathname]);

const columns: TableColumns<any> = [
    {
        title: 'ID',
        dataIndex: 'id',
        align: 'left',
        sorter:true
    },
    {
        title: 'Name',
        
        dataIndex: 'name',
        align: 'left',
    },
    {
      title: 'Fecha de Creación',
      width: 250,
      dataIndex: 'createdAt',
      align: 'left',
      render: (_:any, item:any) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
            flexDirection: "row",
          }}
        >
          <Title level={5}>
            {moment(item.createdAt).utcOffset(0).format("DD/MM/YYYY HH:mm:ss")}
          </Title>
        </Col>
      ),
    },
    {
        title: "Acciones",
        key: "operation",
        fixed: "right",
        
        render: (_:any, item:any) => {
          return (
            <Col
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "start",
                flexDirection: "row",
              }}
            >
              <Button
                type="primary"
                shape="circle"
                onClick={() => navigate(`/virtual-library-categories/${item.id}`)}
                icon={<EditOutlined />}
                size="large"
              />
            </Col>
          );
        },
    },
]

  return (
    <TableCustom columns={columns}  service={categoriesInfographiesService} ref={tableRef} fetchQueryProps={{$sort: {id: -1}}}/>
  )
}
