
import { IModelWebinarsCategories } from '../../modules';
import { IWebinarsCategoriesState } from '../@types/webinarsCategories';

import { webinarsCategoriesActions } from '../action-types'

type webinarsCategoriesActionType =
| { type: typeof webinarsCategoriesActions.WEBINARS_CATEGORIES_MODAL_STATE; }
| { type: typeof webinarsCategoriesActions.GET_WEBINARS_CATEGORIES_FOR_UPDATE; payload: IModelWebinarsCategories }
| { type: typeof webinarsCategoriesActions.IS_WEBINARS_CATEGORIES_FOR_UPDATE; payload: boolean }
| { type: typeof webinarsCategoriesActions.SWITCH_WEBINARS_CATEGORIES_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IWebinarsCategoriesState = {
    webinarsCategoriesModalIsOpen: false,
    isUpdateWebinarsCategories: false,
    webinarsCategoriesForUpdate: null,
    refreshTableWebinarsCategories: false,
}

export default function webinarsCategoriesReducer(state: IWebinarsCategoriesState = INITIAL_STATE, action: webinarsCategoriesActionType): IWebinarsCategoriesState {
  switch (action.type) {
    case webinarsCategoriesActions.WEBINARS_CATEGORIES_MODAL_STATE:
        return {
            ...state,
            webinarsCategoriesModalIsOpen: !state.webinarsCategoriesModalIsOpen,
        }
    case webinarsCategoriesActions.GET_WEBINARS_CATEGORIES_FOR_UPDATE:
        return {
            ...state,
            webinarsCategoriesForUpdate: action.payload,
        }
    case webinarsCategoriesActions.IS_WEBINARS_CATEGORIES_FOR_UPDATE:
        return {
            ...state,
            isUpdateWebinarsCategories: action.payload,
        }
    case webinarsCategoriesActions.SWITCH_WEBINARS_CATEGORIES_REFRESH_TABLE:
        return {
            ...state,
            refreshTableWebinarsCategories: action.payload,
        }
    default:
        return state
  }
}
