import { IModelProjectsPartners } from "../../modules"


export const PROJECTS_PARTNERS_MODAL_STATE = '[ ProjectS PARTNERS ] PROJECTs PARTNERS Modal State'

export interface IProjectsPartnersModalState {
    type: typeof PROJECTS_PARTNERS_MODAL_STATE
}

export const GET_PROJECTS_PARTNERS_FOR_UPDATE = '[ PROJECTS PARTNERS ] Get PROJECTs PARTNERS For Update'

export interface IGetProjectsPartnersForUpdate {
    type: typeof GET_PROJECTS_PARTNERS_FOR_UPDATE
    payload: IModelProjectsPartners
}

export const IS_PROJECTS_PARTNERS_FOR_UPDATE = '[ PROJECTS PARTNERS ] Is Projects PARTNERS For Update'

export interface IIsProjectsPartnersForUpdate {
    type: typeof IS_PROJECTS_PARTNERS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_PROJECTS_PARTNERS_REFRESH_TABLE = '[ PROJECTS PARTNERS ] Switch Projects PARTNERS Refresh Table'

export interface ISwitchProjectsPartnersRefreshTable {
    type: typeof SWITCH_PROJECTS_PARTNERS_REFRESH_TABLE
    payload: boolean
}