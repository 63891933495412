import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { selectModalCoursesChapters } from "../../../../redux";
import { useCoursesChapters } from "../../hooks";
import { TableCoursesChapters } from "./TableCoursesChapters";
import { ModalCoursesChapters } from "./ModalCoursesChapters";

export function CoursesChapters() {
  const modalCoursesChapters = useSelector(
    selectModalCoursesChapters
  );

  const {
    //state
    isUpdateCoursesChapters,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalCoursesChaptersStateForCreate,
  } = useCoursesChapters();

  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalCoursesChaptersStateForCreate}
            >
              Agregar nuevo capitulo para curso
            </Button>
        </Col>
        <TableCoursesChapters />
        {
            modalCoursesChapters && (
                <FormProvider { ...isUpdateCoursesChapters ?{...formMethodsUpdate} : {...formMethodsCreate}}>
                    <ModalCoursesChapters />
                </FormProvider>
            )
        }
    </Row>
  )
}
