import { IModelResources } from "../../modules/resources-admin"

export const GET_RESOURCE_FOR_UPDATE = '[ RESOURCE ] Get Resource  For Update'

export interface IGetResourceForUpdate {
    type: typeof GET_RESOURCE_FOR_UPDATE
    payload: IModelResources
}

export const IS_RESOURCE_FOR_UPDATE = '[ RESOURCE ] Is Resources  For Update'

export interface IIsResourceForUpdate {
    type: typeof IS_RESOURCE_FOR_UPDATE
    payload: boolean
}

export const SWITCH_RESOURCE_REFRESH_TABLE = '[ RESOURCE ] Switch Resources  Refresh Table'

export interface ISwitchResourceRefreshTable {
    type: typeof SWITCH_RESOURCE_REFRESH_TABLE
    payload: boolean
}