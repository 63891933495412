import { Users } from "../modules/users";
import { AdminLayout } from "../shared";

export function UsersPage() {
  return (
    <AdminLayout>
      <Users />
    </AdminLayout>
  )
}
