import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

// import { validateCreateBanners, validateUpdateBanners } from "../../../helpers";
import { DataBanner } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { selectBannersForUpdate, getSingleBanners, selectBannersRefreshTable, selectIsUpdateBanners } from "../../../redux";
import { changeModalStateBanners, createBanners, refreshTableBanners, setIsBannersForUpdate, updateBanners } from "../../../redux/actions/banners.actions";
import { validateCreateBanners } from "../../../shared/utils/yupValidation/validateCreateBanners";
import { validateUpdateBanners } from "../../../shared/utils/yupValidation/validateUpdateBanners";
// import { TableProperties } from "../../../shared/components/ui/Table/types"; 


export const useBanners = () => {
  const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const isUpdateBanner = useSelector(
        selectIsUpdateBanners
      );
      const dataUpdateBanner = useSelector(
        selectBannersForUpdate
      );
      const isRefreshTableBanners = useSelector(
        selectBannersRefreshTable
      );

      const [isLoading, setIsLoading] = useState(false);
      const [tableProps, setTableProps] = useState<any>();


      const changeModalBannersState = () => {
        dispatch(setIsBannersForUpdate(false));
        dispatch(changeModalStateBanners());
      };

      
  const changeBannersForCreate = () => {
        navigate(`/banner/new`);
  }

      const changeNavToBannersProducts = (id: number) => {
        navigate(`/bannersProducts/${id}`);
      };

      const formMethodsCreate = useForm<DataBanner>({
        resolver: yupResolver(validateCreateBanners),
      });

      const formMethodsUpdate = useForm<DataBanner>({
        resolver: yupResolver(validateUpdateBanners),
      });

      useEffect(() => {
        if (isUpdateBanner) {
          formMethodsCreate.reset();
          formMethodsUpdate.reset({ ...dataUpdateBanner,
            // start_date: moment(dataUpdateBanner?.start_date).add(5, 'hours').format(),
            // end_date: moment(dataUpdateBanner?.end_date).add(5, 'hours').format(), 
          });
        }

        return () => {
          formMethodsUpdate.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isUpdateBanner, dataUpdateBanner]);

      const updateStatus = async(value: "active" | "inactive", id: any) =>{
        if (isLoading) return;
        setIsLoading(true);
        await updateBanners({
          id,
          // status: value,
        })
        .then((res: Partial<DataBanner>) => {
          setIsLoading(false);
          if (res.id) {
            // dispatch(bannersChangeModalState());
            message.success(
              `Se ha actualizado con éxito el estado del banner con id:${res.id}`
            );
            dispatch(refreshTableBanners(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
      }

    const onSubmitCreateOrUpdate = async (data: DataBanner, id: number | undefined) => {
        console.log(id,{ data });
        if (isLoading) return;
        setIsLoading(true);
        if (isUpdateBanner) {
          console.log("Actualiando===>", data);
          await updateBanners({
            ...data,
            // start_date: moment(data.start_date).format(),
            // end_date: moment(data.end_date).format(),
          })
            .then((res: Partial<DataBanner>) => {
              setIsLoading(false);
              if (res.id) {
                // dispatch(bannersChangeModalState());
                message.success(
                  `Se ha actualizado con éxito el banner con id:${res.id}`
                );
                dispatch(refreshTableBanners(true));
              } else {
                message.error(res as string);
              }
            })
            .catch((err: any) => {
              setIsLoading(false);
              console.log(err);
              message.error(err.message);
            });
        } else {
          // console.log('Creando===>',data)
          await createBanners({
            ...data,
            // start_date: null,
            // end_date: moment(data.end_date).format(),
          })
            .then((res: DataBanner) => {
              setIsLoading(false);
              if (res.id) {
                dispatch(changeModalStateBanners());
                message.success(
                  `Se ha creado con éxito el banner con id:${res.id}`
                );
                dispatch(refreshTableBanners(true));
              } else {
                message.error(res as any);
              }
            })
            .catch((err: any) => {
              setIsLoading(false);
              console.log(err);
              message.error(err.message);
            });
        }
      };

      useEffect(() => {
        if (isRefreshTableBanners) {
          tableProps?.refresh();
          dispatch(refreshTableBanners(false));
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isRefreshTableBanners]);
  return {
    //state
    isUpdateBanner,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    changeBannersForCreate,
    changeModalBannersState,
    onSubmitCreateOrUpdate,
    changeNavToBannersProducts,
    updateStatus,
  }
}
