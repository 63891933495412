
import { IModelProjectsPartners } from '../../modules';
import { IProjectsPartnersState } from '../@types/projectsPartners';

import { projectsPartnersActions } from '../action-types'

type projectsPartnersActionType =
| { type: typeof projectsPartnersActions.PROJECTS_PARTNERS_MODAL_STATE; }
| { type: typeof projectsPartnersActions.GET_PROJECTS_PARTNERS_FOR_UPDATE; payload: IModelProjectsPartners }
| { type: typeof projectsPartnersActions.IS_PROJECTS_PARTNERS_FOR_UPDATE; payload: boolean }
| { type: typeof projectsPartnersActions.SWITCH_PROJECTS_PARTNERS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IProjectsPartnersState = {
    projectsPartnersModalIsOpen: false,
    isUpdateProjectsPartners: false,
    projectsPartnersForUpdate: null,
    refreshTableProjectsPartners: false,
}

export default function projectsPartnersReducer(state: IProjectsPartnersState = INITIAL_STATE, action: projectsPartnersActionType): IProjectsPartnersState {
  switch (action.type) {
    case projectsPartnersActions.PROJECTS_PARTNERS_MODAL_STATE:
        return {
            ...state,
            projectsPartnersModalIsOpen: !state.projectsPartnersModalIsOpen,
        }
    case projectsPartnersActions.GET_PROJECTS_PARTNERS_FOR_UPDATE:
        return {
            ...state,
            projectsPartnersForUpdate: action.payload,
        }
    case projectsPartnersActions.IS_PROJECTS_PARTNERS_FOR_UPDATE:
        return {
            ...state,
            isUpdateProjectsPartners: action.payload,
        }
    case projectsPartnersActions.SWITCH_PROJECTS_PARTNERS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableProjectsPartners: action.payload,
        }
    default:
        return state
  }
}
