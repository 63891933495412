// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import feathers from "@feathersjs/client";

const app = feathers();
app.configure(feathers.rest('https://anwt6pvh8s.us-east-1.awsapprunner.com').fetch(window.fetch.bind(window)));
app.configure(
  feathers.authentication({
    path: "/authentication",
    entity: "user",
    service: "users",
    cookie: "feathers-jwt",
    storageKey: "feathers-jwt",
    storage: window.localStorage
  })
);

export const galleryService = app.service("gallery");
export const getService = (service: string) => app.service(service);

export default app;
