 
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Typography } from "antd";
import { TableCustom, authorsService } from "../../../shared"
import { useAuthors, useTableAuthors } from "../hooks"
import { IModelAuthors } from "../@types";
import { PATH_S3_BUCKET } from "../../../shared/utils/constants";
import { TableColumns, TableProperties } from "../../../shared/components/ui/table/types";
import React, { useRef } from "react";

const { Title } = Typography;

export function TableAuthors() {
  const {
    //state
    //methods
    //functions
    changeAuthorsForUpdate,
    showDeleteConfirm,
  } =  useTableAuthors()
  const {
    //state
    isRefreshTableAuthors,
    //methods
    //functions
  }= useAuthors()

  const tableRef = useRef<TableProperties>()

  React.useEffect(() => {
    tableRef.current?.refresh()
  }, [isRefreshTableAuthors])
  

  const columns: TableColumns<IModelAuthors> = [
    {
        title: 'ID',
        dataIndex: 'id',
        align: 'left',
        sorter:true
    },
    {
      title: "Avatar",
      
      dataIndex: "avatar_path",
      key: "avatar_path",
      render: (_:any, item:any) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Avatar size={64} src={`${PATH_S3_BUCKET}/${item.avatar_path}`} />
          </Col>
        );
      },
    },
    {
        title: 'Nombre',
        dataIndex: 'name',
        align: 'left',
        render: (_:any, item:any) => (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Title level={5}>
              {`${item.first_name} ${item.last_name}`}
            </Title>
          </Col>
        ),
    },
    {
        title: 'Usuario relacionado',
        dataIndex: 'user_id',
        align: 'left',
    },
    {
      title: 'Profesión',
      dataIndex: 'profession',
      align: 'left',
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 140,
      render: (_:any, item:any) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeAuthorsForUpdate(item.id)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.first_name} ${item.last_name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
]

  return (
    <TableCustom columns={columns}  service={authorsService} ref={tableRef}/>
  )
}
