import * as yup from 'yup'

export const validateCreateCoursesSections = yup.object().shape({
    name:yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El NOMBRE debe tener mínimo de 2 caracteres.'),
    position:yup
    .number()
    .required('Campo requerido.'),
    type: yup
    .string()
    .required('Campo requerido.')
    .oneOf(['free', 'paid'], 'Debes elegir entre estas opciones.'),
    description: yup
    .string()
    .required('Campo requerido.')
    .min(2, 'La descripción debe tener mínimo de 2 caracteres.'),
})