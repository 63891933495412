import { AdminLayout } from "../shared";
import { Companies } from "../modules";

export const CompaniesPage = () => {
  return (
    <AdminLayout>
      <Companies />
    </AdminLayout>
  );
};
