
import { IModelWebinarsAuthors } from '../../modules/webinars/@types/IModelWebinarsAuthors';
import { IWebinarsAuthorsState } from '../@types/webinarsAuthors';

import { webinarsAuthorsActions } from '../action-types'

type webinarsAuthorsActionType =
| { type: typeof webinarsAuthorsActions.WEBINARS_AUTHORS_MODAL_STATE; }
| { type: typeof webinarsAuthorsActions.GET_WEBINARS_AUTHORS_FOR_UPDATE; payload: IModelWebinarsAuthors }
| { type: typeof webinarsAuthorsActions.IS_WEBINARS_AUTHORS_FOR_UPDATE; payload: boolean }
| { type: typeof webinarsAuthorsActions.SWITCH_WEBINARS_AUTHORS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IWebinarsAuthorsState = {
    webinarsAuthorsModalIsOpen: false,
    isUpdateWebinarsAuthors: false,
    webinarsAuthorsForUpdate: null,
    refreshTableWebinarsAuthors: false,
}

export default function webinarsAuthorsReducer(state: IWebinarsAuthorsState = INITIAL_STATE, action: webinarsAuthorsActionType): IWebinarsAuthorsState {
  switch (action.type) {
    case webinarsAuthorsActions.WEBINARS_AUTHORS_MODAL_STATE:
        return {
            ...state,
            webinarsAuthorsModalIsOpen: !state.webinarsAuthorsModalIsOpen,
        }
    case webinarsAuthorsActions.GET_WEBINARS_AUTHORS_FOR_UPDATE:
        return {
            ...state,
            webinarsAuthorsForUpdate: action.payload,
        }
    case webinarsAuthorsActions.IS_WEBINARS_AUTHORS_FOR_UPDATE:
        return {
            ...state,
            isUpdateWebinarsAuthors: action.payload,
        }
    case webinarsAuthorsActions.SWITCH_WEBINARS_AUTHORS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableWebinarsAuthors: action.payload,
        }
    default:
        return state
  }
}
