import { Button, Flex, Typography } from "antd";
import { AdminLayout } from "../shared";
import Popups from "../modules/popups/ui/Popups";
import { Link, Outlet } from "react-router-dom";

export default function PopupsPage() {
  return (
    <AdminLayout>
      <Typography.Title>Popups</Typography.Title>
      <div style={{ width: "100%" }}>
        <Flex justify="flex-end">
          <Link to="/popups/agregar">
            <Button type="primary">+ Popup</Button>
          </Link>
        </Flex>
        <Popups />
        <Outlet />
      </div>
    </AdminLayout>
  );
}
