import { Spin } from "antd";
import { FormProvider } from 'react-hook-form';
import { useRegistryServicesConsultNews, useRegistryServicesConsultNewsById } from "../../hooks";
import { RegistryServicesConsultNewsForm } from "./RegistryServicesConsultNewForm";
import { WrapperTabNavEvents } from "../styled";


export function RegistryServicesConsultNewById() {
  const { isUpdateRegistryServicesConsultNews, formMethodsCreate, formMethodsUpdate } =
  useRegistryServicesConsultNews();
  const { isValidate } = useRegistryServicesConsultNewsById();

  return (
    <div style={{ width: "100%" }}>
      {!isValidate && <Spin size="large" />}
      {isValidate &&(
        <>
          {!isUpdateRegistryServicesConsultNews && (
            <FormProvider {...formMethodsCreate}>
              <RegistryServicesConsultNewsForm />
            </FormProvider>
          )}
          {isUpdateRegistryServicesConsultNews && (
            <FormProvider {...formMethodsUpdate}>
              <RegistryServicesConsultNewsForm />
            </FormProvider>
          )}
      </>
    )}
  </div>
  )
}
