import { Typography } from "antd";
import FormServicesOnlineInformation from "../modules/form-services-online-information/ui/Forms";
import { AdminLayout } from "../shared";


export default function FormServicesOnlineInformationPage() {
  return (
    <AdminLayout>
      <div style={{ width: "100%" }}>
        <Typography.Title>Formulario Información Personal</Typography.Title>
        <FormServicesOnlineInformation />
      </div>
    </AdminLayout>
  );
}
