import { useSelector } from "react-redux";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useEffect, useState } from "react";
import { message } from "antd";
import { changeModalStateWebinarsRequirements, createWebinarRequirements, refreshTableWebinarsRequirements, selectIsUpdateWebinarsRequirements, selectRefreshTableWebinarsRequirements, selectSingleWebinars, selectSingleWebinarsRequirements, setIsWebinarsRequirementsForUpdate, updateWebinarRequirements } from "../../../../redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { IModelWebinarsRequirements } from "../../@types";
import { useForm } from "react-hook-form";
import { validateCreateWebinarsRequirements, validateUpdateWebinarsRequirements } from "../../../../shared/utils/yupValidation";

export function useWebinarsRequirements() {
  const dispatch = useAppDispatch();

  const isUpdateWebinarsRequirements = useSelector(
      selectIsUpdateWebinarsRequirements
  );

  const dataUpdateWebinarsRequirements = useSelector(
      selectSingleWebinarsRequirements
  );

  const isRefreshTableWebinarsRequirements = useSelector(
      selectRefreshTableWebinarsRequirements
  );

  const dataUpdateWebinars = useSelector( selectSingleWebinars );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalWebinarsRequirementsStateForCreate = () => {
    dispatch(setIsWebinarsRequirementsForUpdate(false))
    dispatch(changeModalStateWebinarsRequirements())
  }

  const formMethodsCreate = useForm<IModelWebinarsRequirements>({
    resolver: yupResolver(validateCreateWebinarsRequirements),
  });

  const formMethodsUpdate = useForm<IModelWebinarsRequirements>({
    resolver: yupResolver(validateUpdateWebinarsRequirements),
  });

  useEffect(() => {
  if (isUpdateWebinarsRequirements) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateWebinarsRequirements });
  }

  return () => {
      formMethodsUpdate.reset();
  };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateWebinarsRequirements, dataUpdateWebinarsRequirements]);

  const onSubmitCreateOrUpdate = async (data: IModelWebinarsRequirements) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateWebinarsRequirements) {
      await updateWebinarRequirements({
        id: data.id,
        requirement_text: data.requirement_text,
        position: data.position,
        webinar_id: dataUpdateWebinars?.id,
      })
        .then((res: Partial<IModelWebinarsRequirements>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito los requerimientos con id:${res.id}`
            );
            dispatch(refreshTableWebinarsRequirements(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createWebinarRequirements({...data, webinar_id: dataUpdateWebinars?.id})
        .then((res: IModelWebinarsRequirements) => {
          if (res.id) {
            dispatch(changeModalStateWebinarsRequirements());
            message.success(
              `Se ha creado con éxito los requerimientos con id:${res.id}`
            );
            dispatch(refreshTableWebinarsRequirements(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableWebinarsRequirements) {
      dispatch(refreshTableWebinarsRequirements(false));
    }
  }, [isRefreshTableWebinarsRequirements]);

  return{
      //state
      isLoading,
      isUpdateWebinarsRequirements,
      dataUpdateWebinarsRequirements,
      isRefreshTableWebinarsRequirements,
      //methods
      formMethodsCreate,
      formMethodsUpdate,
      //functions
      changeModalWebinarsRequirementsStateForCreate,
      onSubmitCreateOrUpdate,
  }
}
