import { message } from "antd";

import {  projectsService } from "../../shared";
import { projectsPartnersActions } from "../action-types";
import { IModelProjectsPartners } from "../../modules";

export const changeModalStateProjectsPartners = (): projectsPartnersActions.IProjectsPartnersModalState =>({
    type: projectsPartnersActions.PROJECTS_PARTNERS_MODAL_STATE
})

export const getSingleProjectsPartners = (val:IModelProjectsPartners ): projectsPartnersActions.IGetProjectsPartnersForUpdate=> ({
    type: projectsPartnersActions.GET_PROJECTS_PARTNERS_FOR_UPDATE,
    payload: val
})

export const setIsProjectsPartnersForUpdate =(val: boolean): projectsPartnersActions.IIsProjectsPartnersForUpdate =>({
    type: projectsPartnersActions.IS_PROJECTS_PARTNERS_FOR_UPDATE,
    payload: val,
})

export const fetchSingleProjectsPartners = async(id: number) =>{
    return await projectsService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createProjectPartners = async(data:Partial<IModelProjectsPartners>) =>{
    return await projectsService.patch(data?.project_id!, { meta_partners: JSON.stringify(data) })
    .then((res: IModelProjectsPartners)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateProjectPartners = async(data:Partial<IModelProjectsPartners>) =>{
    return await projectsService.patch(data?.project_id!, { meta_partners: JSON.stringify(data) })
    .then((res: IModelProjectsPartners)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteProjectPartners = async(id: number) =>{
    return await projectsService.patch(id, {meta_partners: 'null'})
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableProjectsPartners = (val: boolean): projectsPartnersActions.ISwitchProjectsPartnersRefreshTable =>({
    type: projectsPartnersActions.SWITCH_PROJECTS_PARTNERS_REFRESH_TABLE,
    payload: val
})

