import { Webinars } from "../modules";
import { AdminLayout } from "../shared";

export function WebinarsPage() {
  return (
    <AdminLayout>
      <Webinars />
    </AdminLayout>
  )
}
