import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from 'react-hook-form';
import { selectModalEventsCategories } from "../../../../redux";
import { useEventsCategories } from "../../hooks";
import { TableEventsCategories } from "./TableEventsCategories";
import { ModalEventsCategories } from "./ModalEventsCategories";

export function EventsCategories() {
  const modalEventsCategories = useSelector(
    selectModalEventsCategories
  );

  const {
    //state
    isUpdateEventsCategories,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalEventsCategoriesStateForCreate,
  } = useEventsCategories();

  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalEventsCategoriesStateForCreate}
            >
              Agregar nueva categoría de evento
            </Button>
        </Col>
        <TableEventsCategories />
        {
            modalEventsCategories && (
                <FormProvider { ...isUpdateEventsCategories ?{...formMethodsUpdate} : {...formMethodsCreate}}>
                    <ModalEventsCategories />
                </FormProvider>
            )
        }
    </Row>
  )
}
