import { AdminLayout } from "../shared";
import { CompaniesById } from "../modules";
import { Button, Drawer } from "antd";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";

export const CompaniesByidPage = () => {
  return (
    <Drawer
      title="Editar compañía"
      open
      width={350}
      destroyOnClose
      closeIcon={
        <Link to="/companies">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <CompaniesById />
    </Drawer>
  );
};
