import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { Link } from "react-router-dom";
import CreateInfographic from "../modules/infographics/ui/create-infographic";

export default function CreateInfographicPage() {
  return (
    <Drawer
      open
      width={350}
      destroyOnClose
      title="Crear infografia"
      closeIcon={
        <Link to="/infographics">
          <Button type="link" icon={<CloseOutlined />} />
        </Link>
      }
    >
      <CreateInfographic />
    </Drawer>
  );
}
