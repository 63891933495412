import { Tabs } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom";

export default function StudyCertificatesLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const matchEdit = useMatch("/certificates/:recordId");

  const navigate = useNavigate();
  const location = useLocation();

  const activeKey = React.useMemo(() => {
    if (location.pathname === "/certificates/templates") {
      return "/certificates/templates";
    }
    if (
      ["/certificates", "/certificates/add"].includes(location.pathname) ||
      matchEdit
    ) {
      return "/certificates";
    }
    return "/certificates/templates";
  }, [location.pathname, matchEdit]);

  return (
    <>
      <Title level={3}>Certificados de estudio</Title>
      <div style={{ width: "100%" }}>
        <Tabs
          size="large"
          type="card"
          activeKey={activeKey}
          onChange={(activeKey) => {
            navigate(activeKey);
          }}
          items={[
            {
              key: "/certificates",
              label: "Certificados",
              children,
            },
            {
              key: "/certificates/templates",
              label: "Plantillas",
              children,
            },
          ]}
        />
      </div>
    </>
  );
}
