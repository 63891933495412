import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { selectModalCoursesIncludes } from "../../../../redux";
import { useCoursesIncludes } from "../../hooks";
import { ModalCoursesIncludes } from "./ModalCoursesIncludes";
import { TableCoursesIncludes } from "./TableCoursesIncludes";


export function CoursesIncludes() {
  const modalCoursesIncludes = useSelector(
    selectModalCoursesIncludes
  );

  const {
    //state
    isUpdateCoursesIncludes,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalCoursesIncludesStateForCreate,
  } = useCoursesIncludes();

  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
            onClick={changeModalCoursesIncludesStateForCreate}
          >
            Agregar inclusion a curso
          </Button>
        </Col>
        <TableCoursesIncludes />
        {
          modalCoursesIncludes && (
            <FormProvider { ...isUpdateCoursesIncludes ? {...formMethodsUpdate} : {...formMethodsCreate}}>
              <ModalCoursesIncludes />
            </FormProvider>
          )
        }
    </Row>
  )
}
