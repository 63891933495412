import { FormProvider } from "react-hook-form";
import { Button, Col, Row } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import useEditMarketingMaterialCategories from "../lib/useEditMarketingMaterialCategories";
import MarketingMaterialCategoriesForms from "./components/MarketingMaterialFormCategories";

export default function EditMarketingMaterialCategories() {
  const params = useParams();
  const navigate = useNavigate();

  const { formMethods, loading, handleSubmit } =
    useEditMarketingMaterialCategories(
      params?.recordId ? Number(params.recordId) : null,
      {
        onSuccess() {
          navigate("/corresponsales/material-de-marketing-categorias");
        },
      }
    );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit}>
        <Row gutter={[8, 16]} justify={"space-between"}>
          <Col xs={24}>
            <MarketingMaterialCategoriesForms />
          </Col>
          <Col xs={24}>
            <Button
              icon={<SaveOutlined />}
              block
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
}
