// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { keyAccountManagersService } from "../../shared/services"
import { DatakeyAccountManagers } from "../@types"
import { keyAccountManagersActions } from "../action-types"

export const keyAccountManagersChangeModalState = (): keyAccountManagersActions.IkeyAccountManagersChangeModalState => ({
    type: keyAccountManagersActions.KEY_ACCOUNT_MANAGERS_CHANGE_MODAL_STATE
})

export const fetchSinglekeyAccountManagers = async(id: number) =>{
    return await keyAccountManagersService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const setkeyAccountManagersForUpdate = (val: DataBanner): keyAccountManagersActions. IGetkeyAccountManagersForUpdate => ({
    type: keyAccountManagersActions.GET_BANNER_FOR_UPDATE,
    payload: val
})

export const setIskeyAccountManagersForUpdate = (val: boolean): keyAccountManagersActions.IIskeyAccountManagersForUpdate =>({
    type: keyAccountManagersActions.IS_BANNER_FOR_UPDATE,
    payload: val
})

export const createkeyAccountManagers = async(data: Partial<DatakeyAccountManagers>) => {
    return keyAccountManagersService.create(data)
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const updatekeyAccountManagers = async(data:Partial<DataBanner>) => {
    return await keyAccountManagersService.patch(data?.id!, data)
    .then((res: any) => {
        // console.log({res});
        return res
    })
    .catch((err: any) => { return err.message })
}

export const deletekeyAccountManagers = async (id: number ) =>{
    return  await keyAccountManagersService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const getSinglekeyAccountManagers = (val:IModelkeyAccountManagers ): keyAccountManagersActions.IGetkeyAccountManagersForUpdate=> ({
    type: keyAccountManagersActions.GET_KEY_ACCOUNT_MANAGERS_FOR_UPDATE,
    payload: val
})

export const refreshTablekeyAccountManagers =( val: boolean ): keyAccountManagersActions.ISwitchkeyAccountManagersRefreshTable=>({
    type: keyAccountManagersActions.SWITCH_KEY_ACCOUNT_MANAGERS_REFRESH_TABLE,
    payload: val
})

export const refreshTablekeyAccountManagersProducts =( val: boolean ): keyAccountManagersActions.ISwitchkeyAccountManagersProductsRefreshTable=>({
    type: keyAccountManagersActions.SWITCH_KEY_ACCOUNT_MANAGERS_PRODUCTS_REFRESH_TABLE,
    payload: val
})