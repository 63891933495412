import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { useProjectsRequirements } from "../../hooks";
import { selectModalProjectsRequirements } from "../../../../redux";
import { TableProjectsRequirements } from "./TableProjectsRequirements";
import { ModalProjectsRequirements } from "./ModalProjectsRequirements";

export function ProjectsRequirements() {
  const modalProjectsRequirements = useSelector(
    selectModalProjectsRequirements
  );

  const {
    //state
    isUpdateProjectsRequirements,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalProjectsRequirementsStateForCreate,
  } = useProjectsRequirements();

  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
            onClick={changeModalProjectsRequirementsStateForCreate}
          >
            Agregar nuevo requerimiento a Proyectos
          </Button>
      </Col>
      <TableProjectsRequirements />
        {
            modalProjectsRequirements && (
                <FormProvider { ...isUpdateProjectsRequirements ?{...formMethodsUpdate} : {...formMethodsCreate}}>
                    <ModalProjectsRequirements />
                </FormProvider>
            )
        }
    </Row>
  )
}
