import { Modal, message } from "antd";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { changeModalStateProjectsBenefits, deleteProjectBenefits, fetchSingleProjectsBenefits, getSingleProjectsBenefits, refreshTableProjectsBenefits, setIsProjectsBenefitsForUpdate } from "../../../../redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

export function useTableProjectsBenefits() {
  const dispatch = useAppDispatch();

  const changeProjectsBenefitsForUpdate = (valId: number) => {
      fetchSingleProjectsBenefits(valId)
      .then((res)=>{
      dispatch(getSingleProjectsBenefits(res));
      dispatch(setIsProjectsBenefitsForUpdate(true));
      dispatch(changeModalStateProjectsBenefits());
      })
  }

  const showDeleteConfirm = (valId: number, val: string) => {
    console.log(88, valId);
      confirm({
        title: `Estás seguro de borrar el beneficio de Project con el id: ${valId}`,
        icon: <ExclamationCircleOutlined />,
        content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar beneficio" en caso tal solo oprime en "Cancelar".`,
        okText: "Borrar beneficio",
        okType: "danger",
        cancelText: "Cancelar",
        keyboard: true,
        maskClosable: true,
        async onOk() {
          return await deleteProjectBenefits(valId)
            .then((res) => {
              dispatch(refreshTableProjectsBenefits(true));
              if(res.name){
                return message.success(`Se ha borrado con éxito el beneficio con id:${valId}`);
              }else if(res){
                console.log({res})
                return message.error(`${res}`);
              }
            })
            .catch((err: any) => {
              console.log({ err });
              return message.error(err.message);
            });
        },
        onCancel() {},
      });
    };
    return{
      //state
      //methods
      //functions
      changeProjectsBenefitsForUpdate,
      showDeleteConfirm,
    }
}
