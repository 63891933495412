
import { IModelCoursesChapters } from '../../modules';
import { ICoursesChaptersState } from '../@types/coursesChapters';

import { coursesChaptersActions } from '../action-types'

type coursesChaptersActionType =
| { type: typeof coursesChaptersActions.COURSES_CHAPTERS_MODAL_STATE; }
| { type: typeof coursesChaptersActions.GET_COURSES_CHAPTERS_FOR_UPDATE; payload: IModelCoursesChapters }
| { type: typeof coursesChaptersActions.IS_COURSES_CHAPTERS_FOR_UPDATE; payload: boolean }
| { type: typeof coursesChaptersActions.SWITCH_COURSES_CHAPTERS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: ICoursesChaptersState = {
    coursesChaptersModalIsOpen: false,
    isUpdateCoursesChapters: false,
    coursesChaptersForUpdate: null,
    refreshTableCoursesChapters: false,
}

export default function coursesChaptersReducer(state: ICoursesChaptersState = INITIAL_STATE, action: coursesChaptersActionType): ICoursesChaptersState {
  switch (action.type) {
    case coursesChaptersActions.COURSES_CHAPTERS_MODAL_STATE:
        return {
            ...state,
            coursesChaptersModalIsOpen: !state.coursesChaptersModalIsOpen,
        }
    case coursesChaptersActions.GET_COURSES_CHAPTERS_FOR_UPDATE:
        return {
            ...state,
            coursesChaptersForUpdate: action.payload,
        }
    case coursesChaptersActions.IS_COURSES_CHAPTERS_FOR_UPDATE:
        return {
            ...state,
            isUpdateCoursesChapters: action.payload,
        }
    case coursesChaptersActions.SWITCH_COURSES_CHAPTERS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableCoursesChapters: action.payload,
        }
    default:
        return state
  }
}
