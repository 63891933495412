import { Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { deleteWebinar, fetchSingleWebinars, getSingleWebinars, refreshTableWebinars, setIsWebinarsForUpdate } from "../../../../redux";

const { confirm } = Modal;

export function useTableWebinars() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const changeWebinarsForUpdate = (valId: number) => {
        fetchSingleWebinars(valId)
        .then((res)=>{
            dispatch(getSingleWebinars(res.data[0]));
            dispatch(setIsWebinarsForUpdate(true));
            navigate(`/webinar/${res.data[0].id}`);
        })
    }

    const showDeleteConfirm = (valId: number, val: string) => {
        confirm({
          title: `Estás seguro de borrar el webinar con el id: ${valId}`,
          icon: <ExclamationCircleOutlined />,
          content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar webinar" en caso tal solo oprime en "Cancelar".`,
          okText: "Borrar webinar",
          okType: "danger",
          cancelText: "Cancelar",
          keyboard: true,
          maskClosable: true,
          async onOk() {
            return await deleteWebinar(valId)
            .then((res) => {
                dispatch(refreshTableWebinars(true));
                if(res.name){
                    return message.success(`Se ha borrado con éxito el webinar con id:${valId}`);
                }else if(res){
                    console.log({res})
                    return message.error(`${res}`);
                }
            })
            .catch((err: any) => {
                console.log({ err });
                return message.error(err.message);
            });
        },
            onCancel() {},
        });
    };

  return{
    //state
    //methods
    //functions
    changeWebinarsForUpdate,
    showDeleteConfirm,
  }
}
