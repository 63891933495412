import { message } from "antd";
import { useEffect, useState } from "react";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { changeModalStateCoursesBenefits, createCourseBenefits, refreshTableCoursesBenefits, selectIsUpdateCoursesBenefits, selectRefreshTableCoursesBenefits, selectSingleCourses, selectSingleCoursesBenefits, setIsCoursesBenefitsForUpdate, updateCourseBenefits } from "../../../../redux";
import { IModelCoursesBenefits } from "../../@types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import { validateCreateCoursesBenefits, validateUpdateCoursesBenefits } from "../../../../shared/utils/yupValidation";

export function useCoursesBenefits() {
  const dispatch = useAppDispatch();

  const isUpdateCoursesBenefits = useSelector(
      selectIsUpdateCoursesBenefits
  );

  const dataUpdateCoursesBenefits = useSelector(
      selectSingleCoursesBenefits
  );

  const isRefreshTableCoursesBenefits = useSelector(
      selectRefreshTableCoursesBenefits
  );

  const dataUpdateCourses = useSelector( selectSingleCourses );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalCoursesBenefitsStateForCreate = () => {
    dispatch(setIsCoursesBenefitsForUpdate(false))
    dispatch(changeModalStateCoursesBenefits())
  }

  const formMethodsCreate = useForm<IModelCoursesBenefits>({
    resolver: yupResolver(validateCreateCoursesBenefits),
  });

  const formMethodsUpdate = useForm<IModelCoursesBenefits>({
    resolver: yupResolver(validateUpdateCoursesBenefits),
  });

  useEffect(() => {
  if (isUpdateCoursesBenefits) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateCoursesBenefits });
  }

  return () => {
      formMethodsUpdate.reset();
  };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateCoursesBenefits, dataUpdateCoursesBenefits]);

  const onSubmitCreateOrUpdate = async (data: IModelCoursesBenefits) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateCoursesBenefits) {
      await updateCourseBenefits({
        id: data.id,
        name: data.name,
        position: data.position,
        course_id: dataUpdateCourses?.id,
      })
        .then((res: Partial<IModelCoursesBenefits>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito la beneficios del curso con id:${res.id}`
            );
            dispatch(refreshTableCoursesBenefits(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createCourseBenefits({...data, course_id: dataUpdateCourses?.id})
        .then((res: IModelCoursesBenefits) => {
          if (res.id) {
            dispatch(changeModalStateCoursesBenefits());
            message.success(
              `Se ha creado con éxito la beneficios del curso con id:${res.id}`
            );
            dispatch(refreshTableCoursesBenefits(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableCoursesBenefits) {
      dispatch(refreshTableCoursesBenefits(false));
    }
  }, [isRefreshTableCoursesBenefits]);

  return{
      //state
      isLoading,
      isUpdateCoursesBenefits,
      dataUpdateCoursesBenefits,
      isRefreshTableCoursesBenefits,
      //methods
      formMethodsCreate,
      formMethodsUpdate,
      //functions
      changeModalCoursesBenefitsStateForCreate,
      onSubmitCreateOrUpdate,
  }
}
