import type { ICmsLawTransparency } from "../../../../@types/dto/cms-law-transparenct";

import { Col, Row } from "antd";
import { useFormContext } from "react-hook-form";
import { InputSelectDynamic, InputText, cmsLawTransparencyCategoriesService } from "../../../../shared";

export default function CmsLawTransparencyForm() {
  const { control } =
    useFormContext<ICmsLawTransparency>();

  return (
    <Row gutter={[8, 16]}>
      <Col span={24}>
        <InputText
          labelText="Nombre"
          control={control}
          name="name"
          placeHolder="Nombre"
        />
      </Col>
      <Col span={24}>
        <InputSelectDynamic
          name="category_id"
          control={control}
          serviceName={cmsLawTransparencyCategoriesService}
          labelName="name"
          valName="id"
          labelText="Categoría"
        />
      </Col>
      <Col span={24}>
        <InputText
          labelText="Posición"
          control={control}
          name="position"
          placeHolder="Posición"
        />
      </Col>
      <Col span={24}>
        <InputText
          rows
          labelText="Contenido"
          control={control}
          name="content"
          placeHolder="Contenido"
        />
      </Col>
    </Row>
  );
}
