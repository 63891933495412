import { Col, Row } from "antd";
import { useFormContext } from "react-hook-form";
import { IKeywordSearch } from "../../../../@types/dto/keyword-search";
import { InputText } from "../../../../shared";

export default function KeywordSearchForm() {
  const { control } = useFormContext<IKeywordSearch>();

  return (
    <Row gutter={[8, 16]}>
      <Col xs={24}>
        <InputText
          control={control}
          name="keywords"
          labelText="Palabra clave"
          placeHolder="Crear empresa, renovar matrícula"
        />
      </Col>
      <Col xs={24}>
        <InputText control={control} name="url" labelText="Url" />
      </Col>

      <Col xs={24}>
        <InputText
          control={control}
          name="description"
          labelText="Descripción"
          rows
        />
      </Col>
    </Row>
  );
}
