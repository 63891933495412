import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import StudyCertificateTemplateForm, {
  TFormValues,
} from "../modules/studyCertificates/components/study-certificate-template-form";
import useStudyCertificateTemplate from "../modules/studyCertificates/hooks/templates/useStudyCertificateTemplate";
import useEditStudyCertificateTemplate from "../modules/studyCertificates/hooks/templates/useEditStudyCertificateTemplate";

export default function EditStudyCertificateTemplatePage() {
  const { recordId } = useParams();
  const navigate = useNavigate();

  const { loading: loadingFetch, studyCertificateTemplate } =
    useStudyCertificateTemplate(recordId ? +recordId : undefined);

  const { loading: loadingEdit, handleEditStudyCertificateTemplate } =
    useEditStudyCertificateTemplate(recordId ? +recordId : undefined, {
      onSuccess() {
        navigate("/certificates/templates");
      },
    });

  const defaultFormValues = React.useMemo((): TFormValues | undefined => {
    if (!studyCertificateTemplate) return undefined;

    return {
      name: studyCertificateTemplate.name,
      image_template_path: studyCertificateTemplate.image_template_path,
    };
  }, [studyCertificateTemplate]);

  return (
    <Drawer
      open
      width={350}
      destroyOnClose
      title="Editar plantilla de certificado"
      closeIcon={
        <Link to="/certificates/templates">
          <Button type="link" icon={<CloseOutlined />} />
        </Link>
      }
    >
      <StudyCertificateTemplateForm
        loading={loadingFetch || loadingEdit}
        defaultValues={defaultFormValues}
        onSubmit={(data) => {
          handleEditStudyCertificateTemplate(data);
        }}
      />
    </Drawer>
  );
}
