import { useSelector } from "react-redux";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import { useEffect, useState } from "react";
import { changeModalStateWebinarsCategories, createWebinarCategories, refreshTableWebinarsCategories, setIsWebinarsCategoriesForUpdate, updateWebinarCategories } from "../../../../redux/actions/webinarsCategories.actions";
import { IModelWebinarsCategories } from "../../@types";

import { selectIsUpdateWebinarsCategories, selectRefreshTableWebinarsCategories, selectSingleWebinarsCategories } from "../../../../redux/selectors/webinarsCategories.selectors";
import { useForm } from 'react-hook-form';
import { validateCreateWebinarsCategories, validateUpdateWebinarsCategories } from "../../../../shared/utils/yupValidation";

export function useWebinarsCategories() {
    const dispatch = useAppDispatch();

    const isUpdateWebinarsCategories = useSelector(
        selectIsUpdateWebinarsCategories
    );
    const dataUpdateWebinarsCategories = useSelector(
        selectSingleWebinarsCategories
    );
    const isRefreshTableWebinarsCategories = useSelector(
        selectRefreshTableWebinarsCategories
    );

    const [isLoading, setIsLoading] = useState(false);

    const changeModalWebinarsCategoriesStateForCreate = () => {
        dispatch(setIsWebinarsCategoriesForUpdate(false))
        dispatch(changeModalStateWebinarsCategories())
    }

    const formMethodsCreate = useForm<IModelWebinarsCategories>({
      resolver: yupResolver(validateCreateWebinarsCategories),
    });

    const formMethodsUpdate = useForm<IModelWebinarsCategories>({
      resolver: yupResolver(validateUpdateWebinarsCategories),
    });

    useEffect(() => {
    if (isUpdateWebinarsCategories) {
        formMethodsCreate.reset();
        formMethodsUpdate.reset({ ...dataUpdateWebinarsCategories });
    }

    return () => {
        formMethodsUpdate.reset();
    };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdateWebinarsCategories, dataUpdateWebinarsCategories]);

    const updateStatus = async(value: "active" | "inactive", id: any) =>{
      if (isLoading) return;
      setIsLoading(true);
      await updateWebinarCategories({
        id,
        status: value,
      })
      .then((res: Partial<IModelWebinarsCategories>) => {
        setIsLoading(false);
        if (res.id) {
          message.success(
            `Se ha actualizado con éxito el estado del webinar con id:${res.id}`
          );
          dispatch(refreshTableWebinarsCategories(true));
        } else {
          message.error(res as string);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
        message.error(err.message);
      });
    }

    const onSubmitCreateOrUpdate = async (data: IModelWebinarsCategories) => {
      // console.log({ data });
      if (isLoading) return;
      setIsLoading(true);
      if (isUpdateWebinarsCategories) {
        await updateWebinarCategories({
          id: data.id,
          name: data.name,
          status: data.status,
          position: data.position,
          path_image: data.path_image,
        })
          .then((res: Partial<IModelWebinarsCategories>) => {
            if (res.id) {
              message.success(
                `Se ha actualizado con éxito la categoría de webinar con id:${res.id}`
              );
              dispatch(refreshTableWebinarsCategories(true));
            } else {
              message.error(res as string);
            }
          })
          .catch((err: any) => {
            console.log(err);
            message.error(err.message);
          })
          .finally(()=>{
              setIsLoading(false);
          });
      } else {
        await createWebinarCategories({...data})
          .then((res: IModelWebinarsCategories) => {
            if (res.id) {
              dispatch(changeModalStateWebinarsCategories());
              message.success(
                `Se ha creado con éxito la categoría de webinar con id:${res.id}`
              );
              dispatch(refreshTableWebinarsCategories(true));
            } else {
              message.error(res as any);
            }
          })
          .catch((err: any) => {
            console.log(err);
            message.error(err.message);
          })
          .finally(()=>{
              setIsLoading(false);
          });
      }
    };

    useEffect(() => {
      if (isRefreshTableWebinarsCategories) {
        dispatch(refreshTableWebinarsCategories(false));
      }
    }, [isRefreshTableWebinarsCategories]);

    return{
        //state
        isLoading,
        isUpdateWebinarsCategories,
        dataUpdateWebinarsCategories,
        isRefreshTableWebinarsCategories,
        //methods
        formMethodsCreate,
        formMethodsUpdate,
        //functions
        changeModalWebinarsCategoriesStateForCreate,
        onSubmitCreateOrUpdate,
        updateStatus,
    }
}
