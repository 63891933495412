import { UsersById } from "../modules/users";
import UpdateUser from "../modules/users/ui/users/UpdateUser";
import { AdminLayout } from "../shared";

export function UserByIdPage() {
  return (
    <AdminLayout>
      <UpdateUser />
    </AdminLayout>
  )
}
