import React from "react";

import type { IHomePopups } from "../../../@types/dto/home-popups";

import { useForm } from "react-hook-form";
import { homePopupsService } from "../../../shared";
import { message } from "antd";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export default function useCreatePopup(props?: {
  onSuccess?: (record: IHomePopups) => void;
}) {
  const [loading, setLoading] = React.useState(false);

  const formMethods = useForm<IHomePopups>({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required("Campo requerido"),
        redirect_url: yup.string().nullable(),
        text: yup.string().nullable(),
        image_path: yup.string().nullable(),
        start_at: yup.string().nullable(),
        end_at: yup.string().nullable(),
        time_between_opens: yup.number().integer().required("Campo requerido"),
        status: yup
          .string()
          .oneOf(["active", "inactive"], "Seleccione un opción válida")
          .required("Campo requerido"),
      })
    ),
  });

  const loadingRef = React.useRef(false);

  const updateLoading = (status: boolean) => {
    setLoading(status);
    loadingRef.current = status;
  };

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    if (loadingRef.current) return;

    updateLoading(true);

    await homePopupsService
      .create(data)
      .then((record) => {
        props?.onSuccess?.(record);
        message.success("Popup creado correctamente");
      })
      .catch((err) => {
        message.error(err?.message);
      })
      .finally(() => {
        updateLoading(false);
      });
  });

  return {
    formMethods,
    loading,
    handleSubmit,
  };
}
