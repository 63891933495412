import { Button, Col, Typography } from "antd";
import { useFormContext } from 'react-hook-form';
import { useSelector } from "react-redux";
import { useCoursesSections } from "../../hooks";
import { WrapperModalCourses } from "../styled";
import { IModelCoursesSections } from "../../@types";
import { selectModalCoursesSections } from "../../../../redux";
import { InputNumber, InputSelect, InputText, InputTimePicker } from "../../../../shared";


const { Title } = Typography;

export function ModalCoursesSections() {
  const modalCoursesSections = useSelector(
    selectModalCoursesSections
  );

  const {
    //state
    isLoading,
    isUpdateCoursesSections,
    dataUpdateCoursesSections,
    //methods
    //functions
    changeModalCoursesSectionsStateForCreate,
    onSubmitCreateOrUpdate
  } = useCoursesSections()

  const { control, handleSubmit: onSubmit } = useFormContext<IModelCoursesSections>();

  return (
    <WrapperModalCourses
      title={ isUpdateCoursesSections
          ? `Actualizando sección del curso: '${dataUpdateCoursesSections?.id!}'`
          : `Creando sección del curso` }
      open={modalCoursesSections}
      width={"320px"}
      destroyOnClose
      onClose={changeModalCoursesSectionsStateForCreate}>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Nombre:
          </Title>
          <InputText placeHolder="Categoría de webinar" name="name" control={control} />
        </Col>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Posición:
          </Title>
          <InputNumber
            placeHolder="123"
            name="position"
            control={control}
          />
        </Col>
        <Col
          span={24}
          style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
          }}>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Tipo:
            </Title>
            <InputSelect
              name="type"
              control={control}
              dataOptions={[
                { title: "gratis", value: "free" },
                { title: "Pago", value: "paid" },
              ]}
              label="Tipo"
            />
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Descripción
            </Title>
            <InputText placeHolder="Lorem ipsum dolor sit amet consectetur adipiscing..." rows name="description" control={control} />
        </Col>
        <Button
            style={{
            width: "100%",
            marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
        >
            {isUpdateCoursesSections
            ? "Actualizar información"
            : "Crear sección del curso"}
        </Button>
    </WrapperModalCourses>
  )
}
