import { Spin } from "antd";
import { FormProvider } from 'react-hook-form';
import { useResources, useResourcesById } from "../../hooks";
import { ResourcesForm } from "./ResourceForm";
import { WrapperTabNavEvents } from "../styled";


export function ResourceById() {
  const { isUpdateResources, formMethodsCreate, formMethodsUpdate } =
  useResources();
  const { isValidate } = useResourcesById();

  return (
    <div style={{ width: "100%" }}>
      {!isValidate && <Spin size="large" />}
      {isValidate &&(
        <>
          {!isUpdateResources && (
            <FormProvider {...formMethodsCreate}>
              <ResourcesForm />
            </FormProvider>
          )}
          {isUpdateResources && (
            <FormProvider {...formMethodsUpdate}>
              <ResourcesForm />
            </FormProvider>
          )}
      </>
    )}
  </div>
  )
}
