import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { createEvent, getSingleEvents, refreshTableEvents, selectIsUpdateEvents, selectRefreshTableEvents, selectSingleEvents, setIsEventsForUpdate, updateEvent } from "../../../../redux";
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { IModelEvents } from "../../@types";
import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import moment from "moment";
import { validateCreateEvents, validateUpdateEvents } from "../../../../shared/utils/yupValidation";

export function useEvents() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isUpdateEvents = useSelector(
    selectIsUpdateEvents
  );

  const dataUpdateEvents = useSelector(
    selectSingleEvents
  );

  const isRefreshTableEvents = useSelector(
    selectRefreshTableEvents
  );

  const [isLoading, setIsLoading] = useState(false);

  const changeNavigateForCreateEvent = () => {
      dispatch(setIsEventsForUpdate(false))
      navigate(`/event/new`);
  }

  const formMethodsCreate = useForm<IModelEvents>({
      resolver: yupResolver(validateCreateEvents),
  });

  const formMethodsUpdate = useForm<IModelEvents>({
        resolver: yupResolver(validateUpdateEvents),
  });

  useEffect(() => {
      if (isUpdateEvents) {
          formMethodsCreate.reset();
          formMethodsUpdate.reset({ ...dataUpdateEvents, date_start: moment(dataUpdateEvents?.date_start).utcOffset(0), date_end: moment(dataUpdateEvents?.date_end).utcOffset(0)});
      }

      return () => {
          formMethodsUpdate.reset();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateEvents, dataUpdateEvents]);

  const updateStatus = async(value: "active" | "inactive", id: any) =>{
    if (isLoading) return;
    setIsLoading(true);
    await updateEvent({
      id,
      status: value,
    })
    .then((res: Partial<IModelEvents>) => {
      setIsLoading(false);
      if (res.id) {
        message.success(
          `Se ha actualizado con éxito el estado del evento con id:${res.id}`
        );
        dispatch(refreshTableEvents(true));
      } else {
        message.error(res as unknown as string);
      }
    })
    .catch((err: any) => {
      setIsLoading(false);
      console.log(err);
      message.error(err.message);
    });
  }

  const onSubmitCreateOrUpdate = async (data: IModelEvents) => {
    
      if (isLoading) return;
      setIsLoading(true);
      if (isUpdateEvents) {
        await updateEvent({
          id: data.id,
          path_cover_video: data.path_cover_video,
          name: data.name,
          date_start: data.date_start,
          date_end: data.date_end,
          description: data.description,
          category_id: data.category_id,
          status: data.status,
          path_banner_image: data.path_banner_image,
          path_cover_image: data.path_cover_image,
          assistance_type: data.assistance_type,
          location_name: data.location_name,
          location_latitude: data.location_latitude,
          location_longitude: data.location_longitude,
          location_description: data.location_description,
          max_capacity:  data.max_capacity,
          base_price: data.base_price,
          tax_rule_id: data.tax_rule_id,
          affiliate_offer: data.affiliate_offer,
          affiliate_offer_discount_percentage: data.affiliate_offer_discount_percentage,
          affiliate_offer_quantity: +(data.affiliate_offer_quantity || '0'),
        })
          .then((res: Partial<IModelEvents>) => {
            if (res.id) {
              message.success(
                `Se ha actualizado con éxito el evento con id:${res.id}`
              );
              dispatch(getSingleEvents(res as any))
              dispatch(refreshTableEvents(true));
            } else {
              message.error(res as unknown as string);
            }
          })
          .catch((err: any) => {
            console.log(err);
            message.error(err.message);
          })
          .finally(()=>{
              setIsLoading(false);
          });
      } else {
        await createEvent({...data})
          .then((res: IModelEvents) => {
            if (res.id) {
              //navegar a la tabla
              // dispatch(changeModalStateEvents());
              message.success(`Se ha creado con éxito el evento con id:${res.id}`);
              dispatch(refreshTableEvents(true));
              navigate(`/event`)
              window.scrollTo(0, 0)
            } else {
              message.error(res as any);
            }
          })
          .catch((err: any) => {
            console.log(err);
            message.error(err.message);
          })
          .finally(()=>{
              setIsLoading(false);
          });
      }
  };

  useEffect(() => {
      if (isRefreshTableEvents) {
        dispatch(refreshTableEvents(false));
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRefreshTableEvents]);

return {
  //state
  isLoading,
  isUpdateEvents,
  dataUpdateEvents,
  isRefreshTableEvents,
  //methods
  formMethodsCreate,
  formMethodsUpdate,
  //functions
  changeNavigateForCreateEvent,
  onSubmitCreateOrUpdate,
  updateStatus,
}
}
