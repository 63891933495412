
import { IModelWebinarsBenefits } from '../../modules';
import { IWebinarsBenefitsState } from '../@types/webinarsBenefits';

import { webinarsBenefitsActions } from '../action-types'

type webinarsBenefitsActionType =
| { type: typeof webinarsBenefitsActions.WEBINARS_BENEFITS_MODAL_STATE; }
| { type: typeof webinarsBenefitsActions.GET_WEBINARS_BENEFITS_FOR_UPDATE; payload: IModelWebinarsBenefits }
| { type: typeof webinarsBenefitsActions.IS_WEBINARS_BENEFITS_FOR_UPDATE; payload: boolean }
| { type: typeof webinarsBenefitsActions.SWITCH_WEBINARS_BENEFITS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IWebinarsBenefitsState = {
    webinarsBenefitsModalIsOpen: false,
    isUpdateWebinarsBenefits: false,
    webinarsBenefitsForUpdate: null,
    refreshTableWebinarsBenefits: false,
}

export default function webinarsBenefitsReducer(state: IWebinarsBenefitsState = INITIAL_STATE, action: webinarsBenefitsActionType): IWebinarsBenefitsState {
  switch (action.type) {
    case webinarsBenefitsActions.WEBINARS_BENEFITS_MODAL_STATE:
        return {
            ...state,
            webinarsBenefitsModalIsOpen: !state.webinarsBenefitsModalIsOpen,
        }
    case webinarsBenefitsActions.GET_WEBINARS_BENEFITS_FOR_UPDATE:
        return {
            ...state,
            webinarsBenefitsForUpdate: action.payload,
        }
    case webinarsBenefitsActions.IS_WEBINARS_BENEFITS_FOR_UPDATE:
        return {
            ...state,
            isUpdateWebinarsBenefits: action.payload,
        }
    case webinarsBenefitsActions.SWITCH_WEBINARS_BENEFITS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableWebinarsBenefits: action.payload,
        }
    default:
        return state
  }
}
