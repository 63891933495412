import { Button, Col, Typography } from "antd";
import { useFormContext } from 'react-hook-form';
import { WrapperModalWebinars } from "../styled";
import { InputNumber, InputText } from "../../../../shared";
import { useSelector } from "react-redux";
import { selectModalWebinarsRequirements } from "../../../../redux";
import { useWebinarsRequirements } from "../../hooks";
import { IModelWebinarsRequirements } from "../../@types";

const { Title } = Typography;

export function ModalWebinarsRequirements() {
  const modalWebinarsRequirements = useSelector(
    selectModalWebinarsRequirements
  );

  const {
    //state
    isLoading,
    isUpdateWebinarsRequirements,
    dataUpdateWebinarsRequirements,
    //methods
    //functions
    changeModalWebinarsRequirementsStateForCreate,
    onSubmitCreateOrUpdate
  } = useWebinarsRequirements()

  const { control, handleSubmit: onSubmit } = useFormContext<IModelWebinarsRequirements>();

  return (
    <WrapperModalWebinars
      title={ isUpdateWebinarsRequirements
          ? `Actualizando requerimientos de webinar: '${dataUpdateWebinarsRequirements?.id!}'`
          : `Creando requerimientos de webinar` }
      open={modalWebinarsRequirements}
      width={"320px"}
      destroyOnClose
      onClose={changeModalWebinarsRequirementsStateForCreate}>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Text requerido:
          </Title>
          <InputText placeHolder="Requerimiento de webinar...." name="requirement_text" rows control={control} />
        </Col>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Posición:
          </Title>
          <InputNumber
            placeHolder="123"
            name="position"
            control={control}
          />
        </Col>
        {/* <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}>
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Webinar asignado:
          </Title>
          <InputSelectDynamic name="webinar_id" control={control} serviceName={webinarsService} labelName="name" valName="id"/>
        </Col> */}
        <Button
            style={{
            width: "100%",
            marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
        >
            {isUpdateWebinarsRequirements
            ? "Actualizar información"
            : "Crear requerimiento de webinar"}
        </Button>
    </WrapperModalWebinars>
  )
}
