import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { useForm } from "react-hook-form";
import { validateLogin } from "../../../shared/utils/yupValidation";
import { authLogin, authSetToken, authSetUserCredentials } from "../../../redux/actions/auth.actions";

export interface FormData {
    email: string;
    password: string;
  }

export function useLogin() {
    const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const formsMethods = useForm<FormData>({
    resolver: yupResolver(validateLogin)
  });


  const onLoginUser =({ email, password }: FormData) => {
    if (isLoading) return;
    setIsLoading(true);
    authLogin(email, password)
    .then((res )=>{
      if(res?.accessToken){
        dispatch(authSetToken(res?.accessToken))
        dispatch(authSetUserCredentials(res?.user))
          navigate("/news-categories")
      }else{
        console.log({res})
        message.error('Invalid email or password.')
      }
    })
    .catch((err)=>{
      message.error(err.message)
    })
    setIsLoading(false);
  };
  return {
    //state
    isLoading,
    //methods
    formsMethods,
    //functions
    onLoginUser,
  }
}
