import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { createCompanies, getSingleCompanies, refreshTableCompanies, selectIsUpdateCompanies , selectRefreshTableCompanies, selectSingleCompanies, setIsCompaniesForUpdate , updateCompanies } from "../../../../redux";
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { IModelCompanies } from "../../@types";
import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import moment from "moment";
import { validateCreateEvents, validateUpdateEvents } from "../../../../shared/utils/yupValidation";

export function UseCompanies() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isUpdateCompanies = useSelector(
    selectIsUpdateCompanies
  );

  const dataUpdateCompanies = useSelector(
    selectSingleCompanies
  );

  const isRefreshTableCompanies = useSelector(
    selectRefreshTableCompanies
  );

  const [isLoading, setIsLoading] = useState(false);

  const changeNavigateForCreateCompanies = () => {
      dispatch(setIsCompaniesForUpdate(false))
      navigate(`/key-account-manager/new`);
  }

  const formMethodsCreate = useForm<IModelCompanies>({
      // resolver: yupResolver(validateCreateEvents),
  });

  const formMethodsUpdate = useForm<IModelCompanies>({
        // resolver: yupResolver(validateUpdateEvents),
  });

  useEffect(() => {
      if (isUpdateCompanies) {
          formMethodsCreate.reset();
          // formMethodsUpdate.reset({ ...dataUpdateEvents, date_recived: moment(dataUpdateEvents?.date_recived).utcOffset(0), inscription_date: moment(dataUpdateEvents?.inscription_date).utcOffset(0)});
      }

      return () => {
          formMethodsUpdate.reset();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateCompanies, dataUpdateCompanies]);

  const updateStatus = async(value: "active" | "inactive", id: any) =>{
    if (isLoading) return;
    setIsLoading(true);
    await updateCompanies({
      id,
      status: value,
    })
    .then((res: Partial<IModelCompanies>) => {
      setIsLoading(false);
      if (res.id) {
        message.success(
          `Se ha actualizado con éxito el estado del Project con id:${res.id}`
        );
        dispatch(refreshTableCompanies(true));
      } else {
        message.error(res as unknown as string);
      }
    })
    .catch((err: any) => {
      setIsLoading(false);
      console.log(err);
      message.error(err.message);
    });
  }  


  const onSubmitCreateOrUpdate = async (data: IModelCompanies) => {
        const filteredData:any = Object.fromEntries(
          Object.entries(data).filter(
              ([key, value]) =>
                  value !== undefined && value !== '' && value !== null 
          ),
      )
      if (Object.keys(filteredData).length === 0) {
          return
      }
      const dataEve = {id: dataUpdateCompanies?.id, ...filteredData}
      // console.log(dataUpdateEvents);
      if (isLoading) return;
      setIsLoading(true);
      if (isUpdateCompanies) {
        await updateCompanies(dataEve)
          .then((res: Partial<IModelCompanies>) => {
            if (res.id) {
              message.success(
                `Se ha actualizado con éxito el evento con id:${res.id}`
              );
              dispatch(getSingleCompanies(res as any))
              dispatch(refreshTableCompanies(true));
            } else {
              message.error(res as unknown as string);
            }
          })
          .catch((err: any) => {
            console.log(err);
            message.error(err.message);
          })
          .finally(()=>{
              setIsLoading(false);
          });
      } else {
        await createCompanies({...filteredData})
          .then((res: IModelCompanies) => {
            if (res.id) {
              //navegar a la tabla
              // dispatch(changeModalStateEvents());
              message.success(`Se ha creado con éxito el evento con id:${res.id}`);
              dispatch(refreshTableCompanies(true));
              navigate(`/registry-services-consult-news`)
              window.scrollTo(0, 0)
            } else {
              message.error(res as any);
            }
          })
          .catch((err: any) => {
            console.log(err);
            message.error(err.message);
          })
          .finally(()=>{
              setIsLoading(false);
          });
      }
  };

  useEffect(() => {
      if (isRefreshTableCompanies) {
        dispatch(refreshTableCompanies(false));
      }
    }, [isRefreshTableCompanies]);

return {
  //state
  isLoading,
  isUpdateCompanies,
  dataUpdateCompanies,
  updateStatus,
  isRefreshTableCompanies,
  //methods
  formMethodsCreate,
  formMethodsUpdate,
  //functions
  changeNavigateForCreateCompanies,
  onSubmitCreateOrUpdate,
}
}
