import { EventsCategories } from "../modules";
import { AdminLayout } from "../shared";

export function EventsCategoriesPage() {
  return (
    <AdminLayout>
      <EventsCategories />
    </AdminLayout>
  )
}
