import { IkeyAccountManagersState } from "../@types";
import { keyAccountManagersActions } from "../action-types";

type keyAccountManagersActionType =
| { type: typeof keyAccountManagersActions.KEY_ACCOUNT_MANAGERS_CHANGE_MODAL_STATE }
| { type: typeof keyAccountManagersActions.GET_KEY_ACCOUNT_MANAGERS_FOR_UPDATE; payload: any }
| { type: typeof keyAccountManagersActions.IS_KEY_ACCOUNT_MANAGERS_FOR_UPDATE; payload: boolean }
| { type: typeof keyAccountManagersActions.SWITCH_KEY_ACCOUNT_MANAGERS_REFRESH_TABLE; payload: boolean }
| { type: typeof keyAccountManagersActions.SWITCH_KEY_ACCOUNT_MANAGERS_PRODUCTS_REFRESH_TABLE; payload: boolean }

const INITIAL_STATE: IkeyAccountManagersState = {
    keyAccountManagersForUpdate: undefined,
    isUpdatekeyAccountManagers: false,
    keyAccountManagersModalIsOpen: false,
    refreshTablekeyAccountManagers: false,
    refreshTablekeyAccountManagersProducts: false,
}

export default function keyAccountManagersReducer(state: IkeyAccountManagersState = INITIAL_STATE, action: keyAccountManagersActionType): IkeyAccountManagersState {
    switch ( action.type ) {
        case keyAccountManagersActions.KEY_ACCOUNT_MANAGERS_CHANGE_MODAL_STATE:
            return{
                ...state,
                keyAccountManagersModalIsOpen: !state.keyAccountManagersModalIsOpen
            }
        case keyAccountManagersActions.GET_KEY_ACCOUNT_MANAGERS_FOR_UPDATE:
            return{
                ...state,
                keyAccountManagersForUpdate: action.payload
            }
        case keyAccountManagersActions.IS_KEY_ACCOUNT_MANAGERS_FOR_UPDATE:
            return{
                ...state,
                isUpdatekeyAccountManagers: action.payload
            }
        case keyAccountManagersActions.SWITCH_KEY_ACCOUNT_MANAGERS_REFRESH_TABLE:
            return{
                ...state,
                refreshTablekeyAccountManagers: action.payload
            }
        case keyAccountManagersActions.SWITCH_KEY_ACCOUNT_MANAGERS_PRODUCTS_REFRESH_TABLE:
            return{
                ...state,
                refreshTablekeyAccountManagersProducts: action.payload
            }
        default:
            return state
    }
}