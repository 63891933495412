import React from "react";
import { TableProperties } from "../../../shared/components/ui/table/types";
import useDeletePopup from "../lib/useDeletePopup";
import { Link, useLocation } from "react-router-dom";
import Table from "../../../shared/components/ui/table/TableCustom";
import { homePopupsService } from "../../../shared";
import { Avatar, Button, Space, Tag } from "antd";
import { getPicture } from "../../../shared/utils/imageHandler";
import moment from "moment";
import { IHomePopups } from "../../../@types/dto/home-popups";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import useUpdatePopupStatus from "../lib/useUpdatePopupStatus";

export default function Popups() {
  const location = useLocation();

  const tableRef = React.useRef<TableProperties>();

  const { updateStatus } = useUpdatePopupStatus({
    onSuccess() {
      tableRef.current?.refresh();
    },
  });

  const { handleDelete } = useDeletePopup({
    onSuccess() {
      tableRef.current?.refresh();
    },
  });

  React.useEffect(() => {
    tableRef.current?.refresh();
  }, [location.pathname]);

  return (
    <Table
      ref={tableRef}
      service={homePopupsService}
      columns={[
        { title: "ID", dataIndex: "id", sorter: true, filterType: "text" },
        {
          title: "Imagen",
          dataIndex: "image_path",
          render: (imagePath: string | null) =>
            imagePath ? (
              <Avatar
                size="large"
                shape="square"
                src={getPicture(imagePath, 100, 100)}
              />
            ) : null,
        },
        { title: "Nombre", dataIndex: "name", filterType: "text" },
        // {
        //   title: "Url redirección",
        //   dataIndex: "redirect_url",
        //   filterType: "text",
        // },
        {
          title: "Fecha inicio",
          dataIndex: "start_at",
          filterType: "date_range",
          render: (startAt: string | null) =>
            startAt ? moment(startAt).format("DD-MM-YYY hh:mm a") : "",
        },
        {
          title: "Fecha fin",
          dataIndex: "end_at",
          filterType: "date_range",
          render: (endAt: string | null) =>
            endAt ? moment(endAt).format("DD-MM-YYY hh:mm a") : "",
        },
        {
          title: 'Prioridad',
          dataIndex: 'priority',
          filterType: 'number_range',
        },
        {
          title: "T. espera entre aperturas",
          dataIndex: "time_between_opens",
          sorter: true,
          filterType: "number_range",
        },
        {
          title: "Estado",
          dataIndex: "status",
          render: (status: IHomePopups["status"], record: IHomePopups) => {
            return (
              <Button
                type="text"
                onClick={() =>
                  updateStatus(
                    record.id,
                    status === "active" ? "inactive" : "active"
                  )
                }
              >
                <Tag color={status === "active" ? "success" : "error"}>
                  {status === "active" ? "Activo" : "Inactivo"}
                </Tag>
              </Button>
            );
          },
        },
        {
          title: "Acciones",
          render: (record: IHomePopups) => {
            return (
              <Space>
                <Link to={`/popups/${record.id}`}>
                  <Button type="link" icon={<EditOutlined />} />
                </Link>
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() => handleDelete(record.id)}
                />
              </Space>
            );
          },
        },
      ]}
    />
  );
}
