import { FormProvider } from "react-hook-form";
import { useNews } from "../hooks";
import { NewsForm } from "./NewsForm";
import { useNewsById } from "../hooks/useNewsById";
import { Spin, Tabs } from "antd";
import React from "react";
import Clusters from "./Clusters";
import { useParams } from "react-router-dom";

export function NewsById() {
  const { isUpdateNews, formMethodsCreate, formMethodsUpdate } = useNews();
  const { isValidate } = useNewsById();

  const { id: blogId } = useParams();

  const [activeKey, setActiveKey] = React.useState("blog");

  return (
    <div style={{ width: "100%" }}>
      {!isValidate ? (
        <Spin size="large" />
      ) : (
        <>
          {!isUpdateNews && (
            <FormProvider {...formMethodsCreate}>
              <NewsForm />
            </FormProvider>
          )}
          {isUpdateNews && (
            <Tabs
              size="large"
              type="card"
              activeKey={activeKey}
              onChange={(key) => {
                setActiveKey(key);
              }}
              items={[
                {
                  key: "blog",
                  label: "Noticia",
                  children: (
                    <FormProvider {...formMethodsUpdate}>
                      <NewsForm />
                    </FormProvider>
                  ),
                },
                {
                  key: "clusters",
                  label: "Clusters",
                  children: <Clusters blogId={blogId ? +blogId : undefined} />,
                },
              ]}
            />
          )}
        </>
      )}
    </div>
  );
}
