// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { Input, Typography } from "antd";
import { Controller, FieldErrorsImpl } from "react-hook-form";
import { InputError } from "./styled";

const { TextArea } = Input;

interface Props {
  width?: string;
  placeHolder?: string;
  name: string;
  control: any;
  rows?: boolean;
  labelText?: string;
  disabled?: boolean;
  defaultValue?: any;
}

interface PropsContents {
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  name: string;
  rows: boolean;
  width: string;
  onChange: (...event: any[]) => void;
  value: any;
  placeHolder: string;
  disabled: boolean;
}

const TextContent = ({
  errors,
  name,
  rows,
  onChange,
  value,
  width,
  disabled,
  placeHolder,
}: PropsContents) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width,
        height: "80%",
        flexDirection: "column",
      }}
    >
      {!rows ? (
        <Input
          status={errors[name] ? "error" : ""}
          value={value}
          placeholder={placeHolder}
          disabled={disabled}
          onChange={onChange}
        />
      ) : (
        <TextArea
          status={errors[name] ? "error" : ""}
          value={value}
          placeholder={placeHolder}
          disabled={disabled}
          onChange={onChange}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      )}
      {!!errors[name] && <InputError>{errors?.[name]?.message}</InputError>}
    </div>
  );
};

export const InputText = ({
  width = "100%",
  placeHolder,
  name,
  control,
  rows = false,
  labelText,
  disabled,
  defaultValue,
}: Props) => {
  return (
    <Controller
      shouldUnregister
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        return (
          <div style={{ width: "100%" }}>
            {labelText && (
              <Typography.Text style={{ fontWeight: "600" }}>
                {labelText}
              </Typography.Text>
            )}
            <TextContent
              disabled={disabled}
              placeHolder={placeHolder}
              errors={errors}
              name={name}
              onChange={onChange}
              rows={rows}
              value={value}
              width={width}
            />
          </div>
        );
      }}
    />
  );
};
