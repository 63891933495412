import { IModelCoursesSections } from "../../modules"


export const COURSES_SECTIONS_MODAL_STATE = '[ COURSES SECTIONS ] Courses Sections Modal State'

export interface ICoursesSectionsModalState {
    type: typeof COURSES_SECTIONS_MODAL_STATE
}

export const GET_COURSES_SECTIONS_FOR_UPDATE = '[ COURSES SECTIONS ] Get Courses Sections For Update'

export interface IGetCoursesSectionsForUpdate {
    type: typeof GET_COURSES_SECTIONS_FOR_UPDATE
    payload: IModelCoursesSections
}

export const IS_COURSES_SECTIONS_FOR_UPDATE = '[ COURSES SECTIONS ] Is Courses Sections For Update'

export interface IIsCoursesSectionsForUpdate {
    type: typeof IS_COURSES_SECTIONS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_COURSES_SECTIONS_REFRESH_TABLE = '[ COURSES SECTIONS ] Switch Courses Sections Refresh Table'

export interface ISwitchCoursesSectionsRefreshTable {
    type: typeof SWITCH_COURSES_SECTIONS_REFRESH_TABLE
    payload: boolean
}