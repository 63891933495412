import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { Link } from "react-router-dom";
import CreatePressResource from "../modules/press/ui/create-press-resource";

export default function CreatePressResourcePage() {
  return (
    <Drawer
      title="Agregar recurso"
      open
      width={350}
      destroyOnClose
      closeIcon={
        <Link to="/press/resources">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
      >
        <CreatePressResource />
      </Drawer>
  );
}