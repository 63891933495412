
import { IModelCoursesRequirements } from '../../modules';
import { ICoursesRequirementsState } from '../@types/coursesRequirements';

import { coursesRequirementsActions } from '../action-types'

type coursesRequirementsActionType =
| { type: typeof coursesRequirementsActions.COURSES_REQUIREMENTS_MODAL_STATE; }
| { type: typeof coursesRequirementsActions.GET_COURSES_REQUIREMENTS_FOR_UPDATE; payload: IModelCoursesRequirements }
| { type: typeof coursesRequirementsActions.IS_COURSES_REQUIREMENTS_FOR_UPDATE; payload: boolean }
| { type: typeof coursesRequirementsActions.SWITCH_COURSES_REQUIREMENTS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: ICoursesRequirementsState = {
    coursesRequirementsModalIsOpen: false,
    isUpdateCoursesRequirements: false,
    coursesRequirementsForUpdate: null,
    refreshTableCoursesRequirements: false,
}

export default function coursesRequirementsReducer(state: ICoursesRequirementsState = INITIAL_STATE, action: coursesRequirementsActionType): ICoursesRequirementsState {
  switch (action.type) {
    case coursesRequirementsActions.COURSES_REQUIREMENTS_MODAL_STATE:
        return {
            ...state,
            coursesRequirementsModalIsOpen: !state.coursesRequirementsModalIsOpen,
        }
    case coursesRequirementsActions.GET_COURSES_REQUIREMENTS_FOR_UPDATE:
        return {
            ...state,
            coursesRequirementsForUpdate: action.payload,
        }
    case coursesRequirementsActions.IS_COURSES_REQUIREMENTS_FOR_UPDATE:
        return {
            ...state,
            isUpdateCoursesRequirements: action.payload,
        }
    case coursesRequirementsActions.SWITCH_COURSES_REQUIREMENTS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableCoursesRequirements: action.payload,
        }
    default:
        return state
  }
}
