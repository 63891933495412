import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { createUsers, getSingleUsers, refreshTableUsers, selectIsUpdateUsers , selectRefreshTableUsers, selectSingleUsers, setIsUsersForUpdate , updateUsers } from "../../../../redux";
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { IModelUsers } from "../../@types";
import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import moment from "moment";
import { validateCreateUsers, validateUpdateUsers } from "../../../../shared/utils/yupValidation";

export function useUsers() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const dateUsers = useSelector(selectSingleUsers);

  const isUpdateUsers = useSelector(
    selectIsUpdateUsers
  );

  const dataUpdateUsers = useSelector(
    selectSingleUsers
  );

  const isRefreshTableUsers = useSelector(
    selectRefreshTableUsers
  );

  const [isLoading, setIsLoading] = useState(false);

  const changeNavigateForCreateEvent = () => {
      dispatch(setIsUsersForUpdate(false))
      navigate(`/user/new`);
  }

  const formMethodsCreate = useForm<IModelUsers>({
      // resolver: yupResolver(validateCreateUsers),
  });

  const formMethodsUpdate = useForm<IModelUsers>({
        // resolver: yupResolver(validateUpdateEvents),
  });

  useEffect(() => {
      if (isUpdateUsers) {
          formMethodsUpdate.reset({ ...dataUpdateUsers });
      }

      return () => {
          formMethodsUpdate.reset();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateUsers, dataUpdateUsers]);

  const updateStatus = async(value: "active" | "inactive", id: any) =>{
    if (isLoading) return;
    setIsLoading(true);
    await updateUsers({
      id,
      status: value,
    })
    .then((res: Partial<IModelUsers>) => {
      setIsLoading(false);
      if (res.id) {
        message.success(
          `Se ha actualizado con éxito el estado del usuario con id:${res.id}`
        );
        dispatch(refreshTableUsers(true));
      } else {
        message.error(res as unknown as string);
      }
    })
    .catch((err: any) => {
      setIsLoading(false);
      console.log(err);
      message.error(err.message);
    });
  }

  const onSubmitCreateOrUpdate = async (data: IModelUsers, id :any) => {
    // console.log("data", data, id);
    const filteredData:any = Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) =>
        value !== undefined && value !== '' && value !== null 
        ),
        )
        // console.log("filter", filteredData);
      if (Object.keys(filteredData).length === 0) {
          return
      }
      if (isLoading) return;
      setIsLoading(true);
      if (isUpdateUsers) {
        await updateUsers({id, ...filteredData})
          .then((res: Partial<IModelUsers>) => {
            if (res.id) {
              message.success(
                `Se ha actualizado con éxito el evento con id:${res.id}`
              );
              dispatch(getSingleUsers(res as any))
              dispatch(refreshTableUsers(true));
            } else {
              message.error(res as unknown as string);
            }
          })
          .catch((err: any) => {
            console.log(err);
            message.error(err.message);
          })
          .finally(()=>{
              setIsLoading(false);
          });
      } else {
        await createUsers({...filteredData})
          .then((res: IModelUsers) => {
            if (res.id) {
              //navegar a la tabla
              // dispatch(changeModalStateUsers());
              message.success(`Se ha creado con éxito el usuari con id:${res.id}`);
              dispatch(refreshTableUsers(true));
              navigate(`/users`)
              window.scrollTo(0, 0)
            } else {
              message.error(res as any);
            }
          })
          .catch((err: any) => {
            console.log(err);
            message.error(err.message);
          })
          .finally(()=>{
              setIsLoading(false);
          });
      }
  };

  useEffect(() => {
      if (isRefreshTableUsers) {
        dispatch(refreshTableUsers(false));
      }
    }, [isRefreshTableUsers]);

return {
  //state
  isLoading,
  isUpdateUsers,
  dataUpdateUsers,
  isRefreshTableUsers,
  updateStatus,
  //methods
  formMethodsCreate,
  formMethodsUpdate,
  //functions
  changeNavigateForCreateEvent,
  onSubmitCreateOrUpdate,
}
}
