import { IModelProjects } from "../../modules";
import { IProjectsState } from "../@types/projects";
import { projectsActions } from "../action-types";


type projectsActionType =
| { type: typeof projectsActions.PROJECTS_MODAL_STATE; }
| { type: typeof projectsActions.GET_PROJECTS_FOR_UPDATE; payload: IModelProjects }
| { type: typeof projectsActions.IS_PROJECTS_FOR_UPDATE; payload: boolean }
| { type: typeof projectsActions.SWITCH_PROJECTS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IProjectsState = {
    projectsModalIsOpen: false,
    isUpdateProjects: false,
    projectsForUpdate: null,
    refreshTableProjects: false,
}

export default function projectsReducer(state: IProjectsState = INITIAL_STATE, action: projectsActionType): IProjectsState {
  switch (action.type) {
    case projectsActions.PROJECTS_MODAL_STATE:
        return {
            ...state,
            projectsModalIsOpen: !state.projectsModalIsOpen,
        }
    case projectsActions.GET_PROJECTS_FOR_UPDATE:
        return {
            ...state,
            projectsForUpdate: action.payload,
        }
    case projectsActions.IS_PROJECTS_FOR_UPDATE:
        return {
            ...state,
            isUpdateProjects: action.payload,
        }
    case projectsActions.SWITCH_PROJECTS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableProjects: action.payload,
        }
    default:
        return state
  }
}
