import * as yup from 'yup'

export const validateUpdateEventsSchedules = yup.object().shape({
    event_id:yup
    .number(),
    name:yup
    .string()
    .min(2, 'El NOMBRE debe tener mínimo de 2 caracteres.'),
    description: yup
    .string()
    .min(2, 'La descripción debe tener mínimo de 2 caracteres.'),
    day: yup
    .number(),
    start_time: yup
    .string()
    .min(2, 'El tiempo de inicio debe ser en un formato valido.'),
    end_time: yup
    .string()
    .min(2, 'El tiempo de finalización debe ser en un formato valido.'),
})