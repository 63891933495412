import React from "react";
import { TableProperties } from "../../../shared/components/ui/table/types";
import { Link, useLocation } from "react-router-dom";
import Table from "../../../shared/components/ui/table/TableCustom";
import { Button, Col, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { pressResourceCategoriesService } from "../../../shared";
import moment from "moment";
import Title from "antd/es/typography/Title";
import useDeletePressResourceCategory from "../lib/useDeletePressResourceCategory";
import { IPressResourceCategory } from "../@types/press-resource-category";

export default function PressResourceCategories() {
  const location = useLocation();

  const tableRef = React.useRef<TableProperties>();

  const { handleDelete } = useDeletePressResourceCategory({
    onSuccess() {
      tableRef.current?.refresh();
    },
  });

  const columns = React.useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        align: "left",
        sorter: true,
      },
   
      {
        title: "Nombre",
        dataIndex: "name",
        align: "left",
      },
      {
        title: "Fecha de Creación",
        width: 250,
        dataIndex: "createdAt",
        align: "left",
        render: (_: any, item: any) => (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Title level={5}>
              {moment(item.createdAt)
                .utcOffset(0)
                .format("DD/MM/YYYY HH:mm:ss")}
            </Title>
          </Col>
        ),
      },
      {
        title: "Acciones",
        render: (record: IPressResourceCategory) => {
          return (
            <Space>
              <Link to={`/press/resources/categories/${record.id}`}>
                <Button type="link" icon={<EditOutlined />} />
              </Link>
              <Button
                type="text"
                icon={<DeleteOutlined />}
                danger
                onClick={() => handleDelete(record.id)}
              />
            </Space>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  React.useEffect(() => {
    tableRef.current?.refresh();
  }, [location.pathname]);

  return (
    <Table
      ref={tableRef}
      service={pressResourceCategoriesService}
      columns={columns}
    />
  );
}
