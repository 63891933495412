import { IModelNews } from "../../modules"

export const NEWS_MODAL_STATE = '[ NEWS ] News Modal State'

export interface INewsModalState {
    type: typeof NEWS_MODAL_STATE
}

export const GET_NEWS_FOR_UPDATE = '[ NEWS ] Get News For Update'

export interface IGetNewsForUpdate {
    type: typeof GET_NEWS_FOR_UPDATE
    payload: IModelNews
}

export const IS_NEWS_FOR_UPDATE = '[ NEWS ] Is News For Update'

export interface IIsNewsForUpdate {
    type: typeof IS_NEWS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_NEWS_REFRESH_TABLE = '[ NEWS ] Switch News Refresh Table'

export interface ISwitchNewsRefreshTable {
    type: typeof SWITCH_NEWS_REFRESH_TABLE
    payload: boolean
}