import { useSelector } from "react-redux";
import { WrapperModalWebinars } from "../styled";
import { selectModalWebinarsCategories } from "../../../../redux/selectors/webinarsCategories.selectors";
import { useWebinarsCategories } from "../../hooks";
import { IModelWebinarsCategories } from "../../@types";
import { useFormContext } from 'react-hook-form';
import { Button, Col, Typography } from "antd";
import { InputNumber, InputSelect, InputText } from "../../../../shared";

const { Title } = Typography;

export function ModalWebinarsCategories() {
    const modalWebinarsCategories = useSelector(
        selectModalWebinarsCategories
    );

    const {
        //state
        isLoading,
        isUpdateWebinarsCategories,
        dataUpdateWebinarsCategories,
        //methods
        //functions
        changeModalWebinarsCategoriesStateForCreate,
        onSubmitCreateOrUpdate
    } = useWebinarsCategories()

    const { control, handleSubmit: onSubmit } =
    useFormContext<IModelWebinarsCategories>();
  return (
    <WrapperModalWebinars
    title={ isUpdateWebinarsCategories ? `Actualizando categoría de webinar: '${dataUpdateWebinarsCategories?.id!}'` : `Creando categoría de webinar`}
      open={modalWebinarsCategories}
      width={"320px"}
      destroyOnClose
      onClose={changeModalWebinarsCategoriesStateForCreate}>
        <Col
            span={24}
            style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            }}
        >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
                Nombre:
            </Title>
            <InputText placeHolder="Categoría de webinar" name="name" control={control} />
        </Col>
        <Col
            span={24}
            style={{
                display: "flex",
                marginTop: "10px",
                padding: 5,
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
            }}
            >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
                Estado:
            </Title>
            <InputSelect
                name="status"
                control={control}
                dataOptions={[
                    { title: "Activo", value: "active" },
                    { title: "Inactivo", value: "inactive" },
                ]}
                label="Estado"
            />
        </Col>
        <Col
            span={24}
            style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            }}
        >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
                Posición:
            </Title>
            <InputNumber
                placeHolder="123"
                name="position"
                control={control}
                />
        </Col>
        <Button
            style={{
            width: "100%",
            marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
        >
            {isUpdateWebinarsCategories
            ? "Actualizar información"
            : "Crear categoría de trabajo"}
        </Button>
    </WrapperModalWebinars>
  )
}
