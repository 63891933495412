import { message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { createProject, getSingleProjects, refreshTableProjects, selectIsUpdateProjects, selectRefreshTableProjects, selectSingleProjects, setIsProjectsForUpdate, updateProject } from "../../../../redux";
import { IModelProjects } from "../../@types";
import { yupResolver } from "@hookform/resolvers/yup";
import { validateCreateProjects, validateUpdateProjects } from "../../../../shared/utils/yupValidation";

export function useProjects() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const isUpdateProjects = useSelector(
      selectIsUpdateProjects
    );

    const dataUpdateProjects = useSelector(
      selectSingleProjects
    );

    const isRefreshTableProjects = useSelector(
      selectRefreshTableProjects
    );

    const [isLoading, setIsLoading] = useState(false);

    const changeNavigateForCreateProject = () => {
        dispatch(setIsProjectsForUpdate(false))
        navigate(`/project/new`);
    }

    
    const formMethodsCreate = useForm<IModelProjects>({
        resolver: yupResolver(validateCreateProjects),
    });

    const formMethodsUpdate = useForm<IModelProjects>({
        resolver: yupResolver(validateUpdateProjects),
    });

    useEffect(() => {
        if (isUpdateProjects) {
            formMethodsCreate.reset();
            formMethodsUpdate.reset({ ...dataUpdateProjects});
        }

        return () => {
            formMethodsUpdate.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdateProjects, dataUpdateProjects]);

    const updateStatus = async(value: "active" | "inactive", id: any) =>{
      if (isLoading) return;
      setIsLoading(true);
      await updateProject({
        id,
        status: value,
      })
      .then((res: Partial<IModelProjects>) => {
        setIsLoading(false);
        if (res.id) {
          message.success(
            `Se ha actualizado con éxito el estado del Project con id:${res.id}`
          );
          dispatch(refreshTableProjects(true));
        } else {
          message.error(res as unknown as string);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
        message.error(err.message);
      });
    }

    const onSubmitCreateOrUpdate = async (data: IModelProjects) => {
        // console.log({ data });
        if (isLoading) return;
        setIsLoading(true);
        if (isUpdateProjects) {
          await updateProject({...data})
            .then((res: Partial<IModelProjects>) => {
              if (res.id) {
                message.success(
                  `Se ha actualizado con éxito el Proyecto con id:${res.id}`
                );
                dispatch(getSingleProjects(res as any))
                dispatch(refreshTableProjects(true));
              } else {
                message.error(res as unknown as string);
              }
            })
            .catch((err: any) => {
              console.log(err);
              message.error(err.message);
            })
            .finally(()=>{
                setIsLoading(false);
            });
        } else {
          await createProject({...data})
            .then((res: IModelProjects) => {
              if (res.id) {
                //navegar a la tabla
                // dispatch(changeModalStateProjects());
                message.success(`Se ha creado con éxito el Project con id:${res.id}`);
                dispatch(refreshTableProjects(true));
                navigate(`/projects`)
                window.scrollTo(0, 0)
              } else {
                message.error(res as any);
              }
            })
            .catch((err: any) => {
              console.log(err);
              message.error(err.message);
            })
            .finally(()=>{
                setIsLoading(false);
            });
        }
    };

    useEffect(() => {
        if (isRefreshTableProjects) {
          dispatch(refreshTableProjects(false));
        }
      }, [isRefreshTableProjects]);

  return {
    //state
    isLoading,
    isUpdateProjects,
    dataUpdateProjects,
    isRefreshTableProjects,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeNavigateForCreateProject,
    onSubmitCreateOrUpdate,
    updateStatus,
  }
}
