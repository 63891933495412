import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { selectModalCoursesRequirements } from "../../../../redux";
import { useCoursesRequirements } from "../../hooks";
import { TableCoursesRequirements } from "./TableCoursesRequirements";
import { ModalCoursesRequirements } from "./ModalCoursesRequirements";


export function CoursesRequirements() {
  const modalCoursesRequirements = useSelector(
    selectModalCoursesRequirements
  );

  const {
    //state
    isUpdateCoursesRequirements,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalCoursesRequirementsStateForCreate,
  } = useCoursesRequirements();

  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
            onClick={changeModalCoursesRequirementsStateForCreate}
          >
            Agregar nuevo requerimiento a curso
          </Button>
      </Col>
      <TableCoursesRequirements />
        {
            modalCoursesRequirements && (
                <FormProvider { ...isUpdateCoursesRequirements ?{...formMethodsUpdate} : {...formMethodsCreate}}>
                    <ModalCoursesRequirements />
                </FormProvider>
            )
        }
    </Row>
  )
}
