import { message } from "antd";

import { coursesCategoriesService } from "../../shared";
import { coursesCategoriesActions } from "../action-types";
import { IModelCoursesCategories } from "../../modules";

export const changeModalStateCoursesCategories = (): coursesCategoriesActions.ICoursesCategoriesModalState =>({
    type: coursesCategoriesActions.COURSES_CATEGORIES_MODAL_STATE
})

export const getSingleCoursesCategories = (val:IModelCoursesCategories ): coursesCategoriesActions.IGetCoursesCategoriesForUpdate=> ({
    type: coursesCategoriesActions.GET_COURSES_CATEGORIES_FOR_UPDATE,
    payload: val
})

export const setIsCoursesCategoriesForUpdate =(val: boolean): coursesCategoriesActions.IIsCoursesCategoriesForUpdate =>({
    type: coursesCategoriesActions.IS_COURSES_CATEGORIES_FOR_UPDATE,
    payload: val,
})

export const fetchSingleCoursesCategories = async(id: number) =>{
    return await coursesCategoriesService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createCourseCategories = async(data:Partial<IModelCoursesCategories>) =>{
    return await coursesCategoriesService.create(data)
    .then((res: IModelCoursesCategories)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateCourseCategories = async(data:Partial<IModelCoursesCategories>) =>{
    return await coursesCategoriesService.patch(data?.id!, data)
    .then((res: IModelCoursesCategories)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteCourseCategories = async(id: number) =>{
    return await coursesCategoriesService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableCoursesCategories = (val: boolean): coursesCategoriesActions.ISwitchCoursesCategoriesRefreshTable =>({
    type: coursesCategoriesActions.SWITCH_COURSES_CATEGORIES_REFRESH_TABLE,
    payload: val
})

