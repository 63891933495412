import { Button, Col, Row } from "antd"
import { PlusOutlined } from "@ant-design/icons";
// import { TablekeyAccountManagers } from "./TablekeyAccountManager";
import { Outlet, useNavigate } from "react-router-dom";
import { TableCategoriesInfographics } from "./TablekeyAccountManager";


export function Infographies() {
    const navigate = useNavigate();

    return (
    <Row
    gutter={[8, 8]}
    style={{
    padding: "10px",
    width: "100%",
    }}
    >
        <Col
            span={24}
            style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            }}
        >
            <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
            onClick={() => navigate(`/virtual-library/new`)}
            >
            Agregar nueva biblioteca virtual
            </Button>
        </Col>
        <TableCategoriesInfographics />
        <Outlet />
    </Row>
    )
}
