import { message } from "antd";
import { UsersActions } from "../action-types";
import { IModelUsers } from "../../modules";
import { usersService } from "../../shared";


export const changeModalStateUsers = (): UsersActions.IUsersModalState =>({
    type: UsersActions.USERS_MODAL_STATE
})

export const getSingleUsers = (val:IModelUsers ): UsersActions.IGetUsersForUpdate=> ({
    type: UsersActions.GET_USERS_FOR_UPDATE,
    payload: val
})

export const setIsUsersForUpdate =(val: boolean): UsersActions.IIsUsersForUpdate =>({
    type: UsersActions.IS_USERS_FOR_UPDATE,
    payload: val,
})

export const fetchSingleUsers = async(id: number) =>{
    return await usersService.find({ query: {id} })
      .then((res) => {
        return res;
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createUsers = async(data:Partial<IModelUsers>) =>{
    return await usersService.create(data)
    .then((res: IModelUsers)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateUsers = async(data:Partial<IModelUsers>) =>{

    // console.log(44, data);
    for (const clave in data) {
        if (data.hasOwnProperty(clave)) {
            const valor = data[clave as keyof IModelUsers];
            if(valor == null){
                delete data[clave as keyof IModelUsers];
            }
        }
    }
    console.log(22, data);
    return await usersService.patch(data?.id!, data)
    .then((res: IModelUsers)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteUsers = async(id: number) =>{
    return await usersService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableUsers = (val: boolean): UsersActions.ISwitchUsersRefreshTable =>({
    type: UsersActions.SWITCH_USERS_REFRESH_TABLE,
    payload: val
})

