import { IModelCoursesAuthors } from "../../modules"


export const COURSES_AUTHORS_MODAL_STATE = '[ COURSES AUTHORS ] Courses Authors Modal State'

export interface ICoursesAuthorsModalState {
    type: typeof COURSES_AUTHORS_MODAL_STATE
}

export const GET_COURSES_AUTHORS_FOR_UPDATE = '[ COURSES AUTHORS ] Get Courses Authors For Update'

export interface IGetCoursesAuthorsForUpdate {
    type: typeof GET_COURSES_AUTHORS_FOR_UPDATE
    payload: IModelCoursesAuthors
}

export const IS_COURSES_AUTHORS_FOR_UPDATE = '[ COURSES AUTHORS ] Is Courses Authors For Update'

export interface IIsCoursesAuthorsForUpdate {
    type: typeof IS_COURSES_AUTHORS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_COURSES_AUTHORS_REFRESH_TABLE = '[ COURSES AUTHORS ] Switch Courses Authors Refresh Table'

export interface ISwitchCoursesAuthorsRefreshTable {
    type: typeof SWITCH_COURSES_AUTHORS_REFRESH_TABLE
    payload: boolean
}