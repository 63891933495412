import React from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Image, Row, Typography, theme } from "antd";
import { InputText } from "../../../../shared";
import FileUPload from "../../../../shared/components/ui/FileUpload";
import { getPicture } from "../../../../shared/utils/imageHandler";
import { DownloadOutlined, SaveOutlined } from "@ant-design/icons";
import { PATH_S3_BUCKET } from "../../../../shared/utils/constants";
import { IStudyCertificateTemplate } from "../../../../@types/dto/study-certificate-template";

const validationSchema = yup.object().shape({
  name: yup.string().required("Campo requerido"),
  image_template_path: yup.string().required("Campo requerido"),
});

export type TFormValues = Pick<
  IStudyCertificateTemplate,
  "name" | "image_template_path"
>;

export default function StudyCertificateTemplateForm({
  defaultValues,
  loading,
  onSubmit,
}: {
  defaultValues?: Partial<TFormValues>;
  loading?: boolean;
  onSubmit: (formValues: TFormValues) => void;
}) {
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const themeToken = theme.useToken();

  React.useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data as TFormValues))}>
      <Row gutter={[12, 12]}>
        <Col xs={24}>
          <InputText
            control={control}
            name="name"
            labelText="Nombre de la plantilla"
          />
        </Col>
        <Col xs={24}>
          <Typography.Text style={{ fontWeight: 600 }}>
            Imagen de plantilla
          </Typography.Text>
          <Controller
            control={control}
            name="image_template_path"
            shouldUnregister
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div>
                <div style={{ marginBottom: 12 }}>
                  <FileUPload
                    path="study-certificate-templates"
                    onUploadFinish={({ fileKey }) => {
                      onChange(fileKey);
                    }}
                  />
                </div>

                {!!value && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 12,
                    }}
                  >
                    <Image width={200} src={getPicture(value, 200, 200)} />
                    <Button
                      style={{ marginTop: 10 }}
                      type="default"
                      size="small"
                      href={`${PATH_S3_BUCKET}/${value}`}
                      target="_blank"
                      icon={<DownloadOutlined />}
                    >
                      Descargar
                    </Button>
                  </div>
                )}
                {!!error && (
                  <Typography.Text
                    style={{ color: themeToken.token.colorError }}
                  >
                    {error.message}
                  </Typography.Text>
                )}
              </div>
            )}
          />
        </Col>
        <Col xs={24}>
          <Button
            loading={loading}
            block
            icon={<SaveOutlined />}
            htmlType="submit"
            type="primary"
          >
            Guardar
          </Button>
        </Col>
      </Row>
    </form>
  );
}
