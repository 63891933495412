import { NewsCategories } from "../modules";
import { AdminLayout } from "../shared";

export function NewsCategoriesPage() {
    return (
        <AdminLayout>
            <NewsCategories/>
        </AdminLayout>
      )
}
