
import { IModelAuthors } from '../../modules';
import { IAuthorsState } from '../@types/authors';

import { authorsActions } from '../action-types'

type authorsActionType =
| { type: typeof authorsActions.AUTHORS_MODAL_STATE; }
| { type: typeof authorsActions.GET_AUTHORS_FOR_UPDATE; payload: IModelAuthors }
| { type: typeof authorsActions.IS_AUTHORS_FOR_UPDATE; payload: boolean }
| { type: typeof authorsActions.SWITCH_AUTHORS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IAuthorsState = {
    authorsModalIsOpen: false,
    isUpdateAuthors: false,
    authorsForUpdate: null,
    refreshTableAuthors: false,
}

export default function authorsReducer(state: IAuthorsState = INITIAL_STATE, action: authorsActionType): IAuthorsState {
  switch (action.type) {
    case authorsActions.AUTHORS_MODAL_STATE:
        return {
            ...state,
            authorsModalIsOpen: !state.authorsModalIsOpen,
        }
    case authorsActions.GET_AUTHORS_FOR_UPDATE:
        return {
            ...state,
            authorsForUpdate: action.payload,
        }
    case authorsActions.IS_AUTHORS_FOR_UPDATE:
        return {
            ...state,
            isUpdateAuthors: action.payload,
        }
    case authorsActions.SWITCH_AUTHORS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableAuthors: action.payload,
        }
    default:
        return state
  }
}
