import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { useWebinarsRequirements } from "../../hooks";
import { selectModalWebinarsRequirements } from "../../../../redux";
import { TableWebinarsRequirements } from "./TableWebinarsRequirements";
import { ModalWebinarsRequirements } from "./ModalWebinarsRequirements";

export function WebinarsRequirements() {
  const modalWebinarsRequirements = useSelector(
    selectModalWebinarsRequirements
  );

  const {
    //state
    isUpdateWebinarsRequirements,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalWebinarsRequirementsStateForCreate,
  } = useWebinarsRequirements();

  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
            onClick={changeModalWebinarsRequirementsStateForCreate}
          >
            Agregar nuevo requerimiento a webinar
          </Button>
      </Col>
      <TableWebinarsRequirements />
        {
            modalWebinarsRequirements && (
                <FormProvider { ...isUpdateWebinarsRequirements ?{...formMethodsUpdate} : {...formMethodsCreate}}>
                    <ModalWebinarsRequirements />
                </FormProvider>
            )
        }
    </Row>
  )
}
