
import { IModelProjectsComponents } from '../../modules';
import { IProjectsComponentsState } from '../@types/projectsComponents';

import { projectsComponentsActions } from '../action-types'

type projectsComponentsActionType =
| { type: typeof projectsComponentsActions.PROJECTS_COMPONENTS_MODAL_STATE; }
| { type: typeof projectsComponentsActions.GET_PROJECTS_COMPONENTS_FOR_UPDATE; payload: IModelProjectsComponents }
| { type: typeof projectsComponentsActions.IS_PROJECTS_COMPONENTS_FOR_UPDATE; payload: boolean }
| { type: typeof projectsComponentsActions.SWITCH_PROJECTS_COMPONENTS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IProjectsComponentsState = {
    projectsComponentsModalIsOpen: false,
    isUpdateProjectsComponents: false,
    projectsComponentsForUpdate: null,
    refreshTableProjectsComponents: false,
}

export default function projectsComponentsReducer(state: IProjectsComponentsState = INITIAL_STATE, action: projectsComponentsActionType): IProjectsComponentsState {
  switch (action.type) {
    case projectsComponentsActions.PROJECTS_COMPONENTS_MODAL_STATE:
        return {
            ...state,
            projectsComponentsModalIsOpen: !state.projectsComponentsModalIsOpen,
        }
    case projectsComponentsActions.GET_PROJECTS_COMPONENTS_FOR_UPDATE:
        return {
            ...state,
            projectsComponentsForUpdate: action.payload,
        }
    case projectsComponentsActions.IS_PROJECTS_COMPONENTS_FOR_UPDATE:
        return {
            ...state,
            isUpdateProjectsComponents: action.payload,
        }
    case projectsComponentsActions.SWITCH_PROJECTS_COMPONENTS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableProjectsComponents: action.payload,
        }
    default:
        return state
  }
}
