import { message } from "antd";

import { webinarsBenefitsService } from "../../shared";
import { webinarsBenefitsActions } from "../action-types";
import { IModelWebinarsBenefits } from "../../modules";

export const changeModalStateWebinarsBenefits = (): webinarsBenefitsActions.IWebinarsBenefitsModalState =>({
    type: webinarsBenefitsActions.WEBINARS_BENEFITS_MODAL_STATE
})

export const getSingleWebinarsBenefits = (val:IModelWebinarsBenefits ): webinarsBenefitsActions.IGetWebinarsBenefitsForUpdate=> ({
    type: webinarsBenefitsActions.GET_WEBINARS_BENEFITS_FOR_UPDATE,
    payload: val
})

export const setIsWebinarsBenefitsForUpdate =(val: boolean): webinarsBenefitsActions.IIsWebinarsBenefitsForUpdate =>({
    type: webinarsBenefitsActions.IS_WEBINARS_BENEFITS_FOR_UPDATE,
    payload: val,
})

export const fetchSingleWebinarsBenefits = async(id: number) =>{
    return await webinarsBenefitsService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createWebinarBenefits = async(data:Partial<IModelWebinarsBenefits>) =>{
    return await webinarsBenefitsService.create(data)
    .then((res: IModelWebinarsBenefits)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateWebinarBenefits = async(data:Partial<IModelWebinarsBenefits>) =>{
    return await webinarsBenefitsService.patch(data?.id!, data)
    .then((res: IModelWebinarsBenefits)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteWebinarBenefits = async(id: number) =>{
    return await webinarsBenefitsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableWebinarsBenefits = (val: boolean): webinarsBenefitsActions.ISwitchWebinarsBenefitsRefreshTable =>({
    type: webinarsBenefitsActions.SWITCH_WEBINARS_BENEFITS_REFRESH_TABLE,
    payload: val
})

