
import { IModelCourses } from '../../modules/courses/@types/IModelCourses';
import { ICoursesState } from '../@types/courses';

import { coursesActions } from '../action-types'

type coursesActionType =
| { type: typeof coursesActions.GET_COURSES_FOR_UPDATE; payload: IModelCourses }
| { type: typeof coursesActions.IS_COURSES_FOR_UPDATE; payload: boolean }
| { type: typeof coursesActions.SWITCH_COURSES_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: ICoursesState = {
    isUpdateCourses: false,
    coursesForUpdate: null,
    refreshTableCourses: false,
}

export default function coursesReducer(state: ICoursesState = INITIAL_STATE, action: coursesActionType): ICoursesState {
  switch (action.type) {
    case coursesActions.GET_COURSES_FOR_UPDATE:
        return {
            ...state,
            coursesForUpdate: action.payload,
        }
    case coursesActions.IS_COURSES_FOR_UPDATE:
        return {
            ...state,
            isUpdateCourses: action.payload,
        }
    case coursesActions.SWITCH_COURSES_REFRESH_TABLE:
        return {
            ...state,
            refreshTableCourses: action.payload,
        }
    default:
        return state
  }
}
