import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { Link, useNavigate } from "react-router-dom";
import StudyCertificateTemplateForm from "../modules/studyCertificates/components/study-certificate-template-form";
import useCreateStudyCertificateTemplate from "../modules/studyCertificates/hooks/templates/useCreateStudyCertificateTemplate";
import { IStudyCertificateTemplate } from "../@types/dto/study-certificate-template";

export default function CreateStudyCertificateTemplatePage() {
  const navigate = useNavigate();

  const { loading, handleCreateStudyCertificateTemplate } =
    useCreateStudyCertificateTemplate({
      onSuccess() {
        navigate("/certificates/templates");
      },
    });

  return (
    <Drawer
      open
      width={350}
      destroyOnClose
      title="Crear plantilla de certificado"
      closeIcon={
        <Link to="/certificates/templates">
          <Button type="link" icon={<CloseOutlined />} />
        </Link>
      }
    >
      <StudyCertificateTemplateForm
        loading={loading}
        onSubmit={(data) =>
          handleCreateStudyCertificateTemplate(
            data as IStudyCertificateTemplate
          )
        }
      />
    </Drawer>
  );
}
