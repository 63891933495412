import { TableUserEvents } from "../modules/userEvents";
import { AdminLayout } from "../shared";

export function UserEventsPage() {
  return (
    <AdminLayout>
      <TableUserEvents />
    </AdminLayout>
  );
}
