 
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col } from "antd";
import { useSelector } from "react-redux";
import { selectSingleCourses } from "../../../../redux";
import { useCoursesRequirements, useTableCoursesRequirements } from "../../hooks";
import { IModelCoursesRequirements } from "../../@types";
import { TableCustom, coursesRequirementsService } from "../../../../shared";
import { TableColumns, TableProperties } from "../../../../shared/components/ui/table/types";
import React from "react";

export function TableCoursesRequirements() {
  const dataUpdateCourses = useSelector( selectSingleCourses );

  const {
    //state
    //methods
    //functions
    changeCoursesRequirementsForUpdate,
    showDeleteConfirm,
  } = useTableCoursesRequirements()

  const {
    //state
    isRefreshTableCoursesRequirements,
    //methods
    //functions
  }= useCoursesRequirements()

  const tableRef = React.useRef<TableProperties>()

    React.useEffect(() => {
      tableRef.current?.refresh()
    }, [isRefreshTableCoursesRequirements])

  const columns: TableColumns<IModelCoursesRequirements> = [
    {
        title: 'ID',
        dataIndex: 'id',
        align: 'left',
        sorter:true
    },
    {
        title: 'Texto requerido',
        dataIndex: 'requirement_text',
        align: 'left',
    },
    {
        title: 'Posición',
        dataIndex: 'position',
        align: 'left',
    },
    {
      title: 'Curso',
      dataIndex: 'course_id',
      align: 'left',
    },
    {
        title: "Acciones",
        key: "operation",
        fixed: "right",
        width: 140,
        render: (_:any, item:any) => {
          return (
            <Col
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "start",
                flexDirection: "row",
              }}
            >
              <Button
                type="primary"
                shape="circle"
                onClick={() => changeCoursesRequirementsForUpdate(item.id)}
                icon={<EditOutlined />}
                size="large"
              />
              <Button
                type="primary"
                danger
                onClick={() => showDeleteConfirm(item.id, `${item.requirement_text.slice(0, 10) + "..."}`)}
                shape="circle"
                icon={<DeleteOutlined />}
                size="large"
              />
            </Col>
          );
        },
    },
  ]

  return (
    <TableCustom fetchQueryProps={{course_id: dataUpdateCourses?.id!,}} columns={columns}  service={coursesRequirementsService} ref={tableRef}/>
  )
}
