import moment from "moment";
import { formsService } from "../../../shared";
import Table from "../../../shared/components/ui/table/TableCustom";
import { IForm } from "../../../@types/dto/forms";
import { Button, Space } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function Forms() {
  return (
    <Table
      service={formsService}
      download
      columns={[
        { title: "ID", dataIndex: "id", sorter: true, filterType: "text" },
        { title: "Formulario", dataIndex: "form_name", filterType: "text" },
        {
          title: "Total campos",
          render: (record: IForm) =>
            Object.entries(JSON.parse(record.form_data)).length,
        },
        {
          title: "Fecha",
          dataIndex: "createdAt",
          sorter: true,
          filterType: "date_range",
          render: (date: string) => moment(date).format("DD-MM-YYYY"),
        },
        {
          title: "Acciones",
          render: (record: IForm) => (
            <Space>
              <Link to={`/formularios/${record.id}`}>
                <Button icon={<EyeOutlined />} size="small" type="link" />
              </Link>
            </Space>
          ),
        },
      ]}
    />
  );
}
