// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { FieldErrorsImpl, useController } from "react-hook-form";
import SunEditor from "suneditor-react";
import plugins from "suneditor/src/plugins";
import "suneditor/dist/css/suneditor.min.css";
import lang from "suneditor/src/lang";

import { InputError } from "./styled";

interface Props {
  width?: string;
  placeHolder: string;
  name: string;
  control: any;
  isUpdating?: boolean;
}

interface PropsContent {
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  name: string;
}

const ErrorComponent = ({ errors, name }: PropsContent) => {
  return (
    <>{!!errors[name] && <InputError>{errors?.[name]?.message}</InputError>}</>
  );
};

export const InputHTML = ({
  width = "100%",
  placeHolder,
  name,
  control,
  isUpdating,
}: Props) => {
  const {
    field: { value, onChange },
    formState: { errors },
  } = useController({
    name,
    control,
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width,
        flexDirection: "column",
      }}
    >
      <SunEditor
        name={name}
        setContents={value}
        placeholder={placeHolder}
        width="100%"
        height="100%"
        setOptions={{
          plugins: plugins,
          buttonList: [
            [
              ":p-More Paragraph-default.more_paragraph",
              "font",
              "fontSize",
              "formatBlock",
              "paragraphStyle",
              "blockquote",
            ],
            [
              "font",
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
            ],
            ["fontColor", "hiliteColor", "textStyle"],
            ["removeFormat"],
            ["outdent", "indent"],
            ["align", "horizontalRule", "list", "lineHeight"],
            ["fullScreen", "showBlocks", "codeView", "preview"],
          ],
          lang: lang.es,
        }}
        onChange={onChange}
      />
      <ErrorComponent errors={errors} name={name} />
    </div>
  );
};
