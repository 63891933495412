import { Banners } from "../modules";
import { AdminLayout } from "../shared";

export const BannersPage = () => {
  return (
    <AdminLayout>
      <Banners />
    </AdminLayout>
  )
};
