import * as authService from "./authentication.service";
import { getService } from "../../clients/feathers.clients";

import Service from "./service.class";
import { IModelResources } from "../../modules/resources-admin";
import {
  ICorresponsalesFormsAndModels,
  ICorresponsalesFormsAndModelsCategories,
} from "../../@types/dto/corresponsales-forms-and-models";
import {
  ICorresponsalesVideosAndTutorials,
  ICorresponsalesVideosAndTutorialsCategories,
} from "../../@types/dto/corresponsales-videos-and-tutorials";
import {
  ICorresponsalesMarketingMaterial,
  ICorresponsalesMarketingMaterialCategories,
} from "../../@types/dto/corresponsales-marketing-material";
import { IKeywordSearch } from "../../@types/dto/keyword-search";
import { IHomePopups } from "../../@types/dto/home-popups";
import { IFeedbackGeneralSurvey } from "../../@types/dto/feedback-general-servey";
import { IForm } from "../../@types/dto/forms";
import { IModelCiuu } from "../../modules/ciuu";
import { IModelVirtualLibraryCategory } from "../../modules/virtual-library-categories";
import { IModelInfograpgies } from "../../modules/virtual-library";
import {
  ICmsLawTransparency,
  ICmsLawTransparencyCategory,
} from "../../@types/dto/cms-law-transparenct";
import { IInfographicCategory } from "../../modules/infographic-categories/@types";
import { IInfographic } from "../../modules/infographics/@types";
import { IPressNewsletter } from "../../modules/press/@types/press-newsletter";
import { IPressResourceCategory } from "../../modules/press/@types/press-resource-category";
import { IPressResource } from "../../modules/press/@types";
import { IStudyCertificateTemplate } from "../../@types/dto/study-certificate-template";
import { IStudyCertificateTemplateField } from "../../@types/dto/study-certificate-template-field";

//authenticate
export const authenticationService = authService;

//services
//user
export const usersService = new Service<any>(getService("users"));
//projects
export const projectsRequirementsService = new Service<any>(
  getService("project-requirements")
);
export const projectsService = new Service<any>(getService("projects"));
export const clustersService = new Service<any>(getService("clusters"));
export const CompaniesService = new Service<any>(getService("companies"));
export const keyAccountManagersService = new Service<any>(
  getService("key-account-managers")
);

//news
export const newsService = new Service<any>(getService("blogs"));
export const newsCategoriesService = new Service<any>(
  getService("blogs-categories")
);

//authors
export const authorsService = new Service<any>(getService("authors"));

//inscriptions
export const userCoursesService = new Service<any>(getService("user-courses"));
export const usersWebinarsService = new Service<any>(
  getService("users-webinars")
);
export const userProjectsService = new Service<any>(
  getService("user-projects")
);
export const userEventService = new Service<any>(getService("user-events"));

//agendamiento de citas
export const appointmentSchedulingService = new Service<any>(
  getService("appointment-scheduling")
);

//administrative resources

export const administrativeResourcesService = new Service<IModelResources>(
  getService("administrative-resources")
);

export const registryServicesConsultNewsService = new Service<any>(
  getService("registry-services-consult-news")
);

//webinars
export const webinarsService = new Service<any>(getService("webinars"));
export const webinarsAuthorsService = new Service<any>(
  getService("webinar-authors")
);
export const webinarsIncludesService = new Service<any>(
  getService("webinar-includes")
);
export const webinarsRequirementsService = new Service<any>(
  getService("webinar-requirements")
);
export const webinarsBenefitsService = new Service<any>(
  getService("webinars-benefits")
);
export const projectsBenefitsService = new Service<any>(
  getService("projects-benefits")
);
export const webinarsCategoriesService = new Service<any>(
  getService("webinars-categories")
);
// export const webinarsRatingsService = new Service<any>(getService('webinar-ratings'))

//courses
export const coursesService = new Service<any>(getService("courses"));
export const coursesAuthorsService = new Service<any>(
  getService("course-authors")
);
export const coursesIncludesService = new Service<any>(
  getService("course-includes")
);
export const coursesRequirementsService = new Service<any>(
  getService("course-requirements")
);
export const coursesBenefitsService = new Service<any>(
  getService("course-benefits")
);
export const coursesCategoriesService = new Service<any>(
  getService("courses-categories")
);
// export const coursesRatingsService = new Service<any>(getService('webinar-ratings'))
export const coursesSectionsService = new Service<any>(
  getService("course-sections")
);
export const coursesChaptersService = new Service<any>(
  getService("course-chapters")
);

//events
export const eventsService = new Service<any>(getService("events"));
export const eventsAuthorsService = new Service<any>(
  getService("event-authors")
);
export const eventsCategoriesService = new Service<any>(
  getService("events-categories")
);
export const eventsSchedulesService = new Service<any>(
  getService("events-schedules")
);
export const eventTagsService = new Service<any>(getService("event-tags"));
export const eventsTagsService = new Service<any>(getService("events-tags"));

//s3 SERVICES
export const s3FileUploadService = new Service(getService("s3-file-uploader"));

export const bannersService = new Service(getService("banners"));
export const coursesRatingsService = new Service(getService("course-rating"));
export const eventsRatingsService = new Service(getService("events-ratings"));
export const webinarsRatingsService = new Service(
  getService("webinars-ratings")
);
export const corresponsalesFormsAndModelsService =
  new Service<ICorresponsalesFormsAndModels>(
    getService("corresponsales-forms-and-models")
  );
export const corresponsalesFormsAndModelsCategoriesService =
  new Service<ICorresponsalesFormsAndModelsCategories>(
    getService("corresponsales-forms-and-models-categories")
  );
export const corresponsalesVideosAndTutorialsService =
  new Service<ICorresponsalesVideosAndTutorials>(
    getService("corresponsales-videos-and-tutorials")
  );
export const corresponsalesVideosAndTutorialsCategoriesService =
  new Service<ICorresponsalesVideosAndTutorialsCategories>(
    getService("corresponsales-videos-and-tutorials-categories")
  );
export const corresponsalesMarketingMaterialService =
  new Service<ICorresponsalesMarketingMaterial>(
    getService("corresponsales-marketing-material")
  );
export const corresponsalesMarketingMaterialCategoriesService =
  new Service<ICorresponsalesMarketingMaterialCategories>(
    getService("corresponsales-marketing-material-categories")
  );
// export const usersService = app.service('users')

export const registrationEventService = new Service<any>(
  getService("registration-services-events")
);

export const keywordSearchService = new Service<IKeywordSearch>(
  getService("key-word-search")
);

export const homePopupsService = new Service<IHomePopups>(
  getService("home-popups")
);

export const feedbackGeneralSurveyService = new Service<IFeedbackGeneralSurvey>(
  getService("feedback-general-survey")
);

export const formsService = new Service<IForm>(getService("forms"));

export const formExperienceRegistrationalServices = new Service<any>(
  getService("form-experience-registrational-services")
);

export const formsBrandService = new Service<IForm>(
  getService("form-brand-registration")
);

export const formServicesOnlineInformation = new Service<IForm>(
  getService("form-services-online-information")
);

export const taxRulesService = new Service(getService("tax-rule"));

export const ciuuService = new Service<IModelCiuu>(getService("matriz-ciuu"));

export const categoriesInfographiesService =
  new Service<IModelVirtualLibraryCategory>(
    getService("virtual-library-categories")
  );

export const virtualLibrariesService = new Service<IModelInfograpgies>(
  getService("virtual-library")
);

export const studyCertificatesService = new Service<any>(
  getService("study-certificates")
);

export const blogClusters = new Service<{
  id: number;
  blog_id: number;
  cluster_id: number;
}>(getService("blog-clusters"));

export const projectClusters = new Service<{
  id: number;
  project_id: number;
  cluster_id: number;
}>(getService("project-clusters"));

export const webinarClusters = new Service<{
  id: number;
  webinar_id: number;
  cluster_id: number;
}>(getService("webinar-clusters"));

export const courseClusters = new Service<{
  id: number;
  course_id: number;
  cluster_id: number;
}>(getService("course-clusters"));

export const eventClusters = new Service<{
  id: number;
  event_id: number;
  cluster_id: number;
}>(getService("event-clusters"));

export const cmsLawTransparencyService = new Service<ICmsLawTransparency>(
  getService("cms-law-transparency")
);

export const cmsLawTransparencyCategoriesService =
  new Service<ICmsLawTransparencyCategory>(
    getService("cms-law-transparency-categories")
  );

export const CompanyUsersService = new Service<any>(
  getService("company-users")
);

export const CompanyUsersJobAreaService = new Service<any>(
  getService("company-users-job-title-area")
);

export const CompanyUsersJobAreaPosicionService = new Service<any>(
  getService("company-users-job-title-area-position")
);
export const infographicCategoriesService = new Service<IInfographicCategory>(
  getService("infographic-categories")
);

export const infographicsService = new Service<IInfographic>(
  getService("infographics")
);

export const pressNewsletterService = new Service<IPressNewsletter>(
  getService("press-newsletter")
);

export const pressResourceCategoriesService =
  new Service<IPressResourceCategory>(getService("press-resource-categories"));

export const pressResourcesService = new Service<IPressResource>(
  getService("press-resources")
);

export const studyCertificateTemplatesService = new Service<IStudyCertificateTemplate>(
  getService("study-certificate-templates")
);

export const studyCertificateTemplateFieldsService = new Service<IStudyCertificateTemplateField>(
  getService('study-certificate-template-fields')
)
