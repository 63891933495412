import { IModelEventsSchedules } from "../../modules"


export const EVENTS_SCHEDULES_MODAL_STATE = '[ EVENTS SCHEDULES ] Events Schedules Modal State'

export interface IEventsSchedulesModalState {
    type: typeof EVENTS_SCHEDULES_MODAL_STATE
}

export const GET_EVENTS_SCHEDULES_FOR_UPDATE = '[ EVENTS SCHEDULES ] Get Events Schedules For Update'

export interface IGetEventsSchedulesForUpdate {
    type: typeof GET_EVENTS_SCHEDULES_FOR_UPDATE
    payload: IModelEventsSchedules
}

export const IS_EVENTS_SCHEDULES_FOR_UPDATE = '[ EVENTS SCHEDULES ] Is Events Schedules For Update'

export interface IIsEventsSchedulesForUpdate {
    type: typeof IS_EVENTS_SCHEDULES_FOR_UPDATE
    payload: boolean
}

export const SWITCH_EVENTS_SCHEDULES_REFRESH_TABLE = '[ EVENTS SCHEDULES ] Switch Events Schedules Refresh Table'

export interface ISwitchEventsSchedulesRefreshTable {
    type: typeof SWITCH_EVENTS_SCHEDULES_REFRESH_TABLE
    payload: boolean
}