
import { IModelWebinars } from '../../modules/webinars/@types/IModelWebinars';
import { IWebinarsState } from '../@types/webinars';

import { webinarsActions } from '../action-types'

type webinarsActionType =
| { type: typeof webinarsActions.GET_WEBINARS_FOR_UPDATE; payload: IModelWebinars }
| { type: typeof webinarsActions.IS_WEBINARS_FOR_UPDATE; payload: boolean }
| { type: typeof webinarsActions.SWITCH_WEBINARS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IWebinarsState = {
    isUpdateWebinars: false,
    webinarsForUpdate: null,
    refreshTableWebinars: false,
}

export default function webinarsReducer(state: IWebinarsState = INITIAL_STATE, action: webinarsActionType): IWebinarsState {
  switch (action.type) {
    case webinarsActions.GET_WEBINARS_FOR_UPDATE:
        return {
            ...state,
            webinarsForUpdate: action.payload,
        }
    case webinarsActions.IS_WEBINARS_FOR_UPDATE:
        return {
            ...state,
            isUpdateWebinars: action.payload,
        }
    case webinarsActions.SWITCH_WEBINARS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableWebinars: action.payload,
        }
    default:
        return state
  }
}
