import { ArrowLeftOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Typography } from "antd";
import { TableColumns } from "../../shared/components/ui/table/types";
import { TableCustom, usersWebinarsService } from "../../shared";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
// import { useAuthors, useTableAuthors } from "../hooks"
// import { IModelAuthors } from "../@types";

const { Title } = Typography;

export function TableUserWebinar() {
  const columns: TableColumns<any> = [
    {
      title: "ID",
      dataIndex: "id",
      align: "left",
      sorter: true,
    },
    {
      title: "Usuario",
      dataIndex: ["user"],
      align: "left",
      render: (record: any) => (
        <>
          {record?.first_name} {record?.last_name}
        </>
      ),
    },
    {
      title: "Compañía",
      dataIndex: ["company", "legal_name"],
      align: "left",
    },
    {
      title: "Progreso",
      dataIndex: "progress",
      align: "left",
    },
    {
      title: "Fecha de Creación",
      dataIndex: "createdAt",
      align: "left",
      filterType: "date_range",
      render: (_: any, item: any) => (
        <>{moment(item.createdAt).utcOffset(0).format("DD/MM/YYYY")}</>
      ),
    },
  ];

  const { id } = useParams();

  const navigate = useNavigate();

  return (
    <>
      <div style={{ width: "100%" }}>
        <Button onClick={() => navigate("/webinar")}>
          <ArrowLeftOutlined /> Volver a webinars
        </Button>
      </div>
      <h2>Usuarios en el webinar id: {id}</h2>
      <TableCustom
        columns={columns}
        service={usersWebinarsService}
        fetchQueryProps={{ webinar_id: id }}
        fetch$ClientProps={{ withAllData: true }}
      />
    </>
  );
}
