import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { Button, Col, Row, Typography } from "antd";
import { useSelector } from "react-redux";
import { useRegistryServicesConsultNews } from "../../hooks";
import { IModelRegistryServicesConsultNews } from "../../@types";
import { selectIsUpdateRegistryServicesConsultNew, selectSingleRegistryServicesConsultNew } from "../../../../redux";
import { InputData, InputNumber, InputSelect, InputSelectDynamic, InputText, eventsCategoriesService } from "../../../../shared";

const { Title } = Typography;

export function RegistryServicesConsultNewsForm() {
  const isUpdateRegistryServicesConsultNews = useSelector(selectIsUpdateRegistryServicesConsultNew);
  const dateRegistryServicesConsultNews = useSelector(selectSingleRegistryServicesConsultNew);

  const { control, handleSubmit: onSubmit, watch, setValue } = useFormContext<IModelRegistryServicesConsultNews>();

  const [isValidate, setIsValidate] = useState(false);

  useEffect(() => {
      setTimeout(() => {
          setIsValidate(!isValidate);
      }, 1500);
  }, []);

  const { isLoading, onSubmitCreateOrUpdate } = useRegistryServicesConsultNews();
  return (
    <>
      {isValidate && (
        <Row justify="start">
          <Col
            span={24}
            style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            }}>
              <Title style={{ marginBottom: "1.5px" }} level={2}>
                {dateRegistryServicesConsultNews?.id ? `Actualizando Servicio Registral ${dateRegistryServicesConsultNews?.id}` : "Creando Servicio Registral"}
              </Title>
          </Col>
            <Col style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            }} span={12}>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Nombre del Servicio:
            </Title>
            <InputText
              placeHolder=""
              name="name"
              control={control}
            />
            </Col>
            {/* <Col style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            }} span={12}>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Comercial:
            </Title>
            <InputText
              placeHolder=""
              name="commercial_registration"
              control={control}
            />
            </Col> */}
          {/* <Col
            span={12}
            style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            }}>
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                Fecha Recibida:
              </Title>
              <InputData
                dateFormat="YYYY-MM-DD HH:mm:ss"
                placeHolder="29/10/2022 00:00:00"
                name="date_recived"
                control={control}
              />
          </Col>
          <Col
            span={12}
            style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            }}>
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                Fecha de inscripción:
              </Title>
              <InputData
                dateFormat="YYYY-MM-DD HH:mm:ss"
                placeHolder="29/10/2022 00:00:00"
                name="inscription_date"
                control={control}
              />
          </Col> */}
          <Col style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            }} span={12}>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              link:
            </Title>
            <InputText
              placeHolder=""
              name="action_link"
              control={control}
            />
            </Col>
            <Col
            span={12}
            style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            }}>
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                Año:
              </Title>
              <InputText
                placeHolder="2023"
                name="age"
                control={control}
            
              />
          </Col>
          <Button
            style={{
            width: "100%",
            marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}>
            {isUpdateRegistryServicesConsultNews ? `Actualizar información` : `Crear Servicio Registral`}
          </Button>
        </Row>
      )}
    </>
  )
}
