import { TRootState } from '../@types/redux';

export const selectModalUsers = (state: TRootState) => state.user.UsersModalIsOpen


export const selectIsUpdateUsers = (state: TRootState) => state.user.isUpdateUsers


export const selectSingleUsers = (state: TRootState) => state.user.UsersForUpdate


export const selectRefreshTableUsers = (state: TRootState) => state.user.refreshTableUsers