import { ReactNode } from "react";

import { MenuProps } from "antd";
import {
  NumberOutlined,
  PaperClipOutlined,
  InboxOutlined,
  ProjectOutlined,
  ReconciliationOutlined,
  FolderOpenOutlined,
  UsergroupAddOutlined,
  FileProtectOutlined,
  CalendarOutlined,
  HighlightOutlined,
  StarOutlined,
  BankOutlined,
  ShopOutlined,
  PlayCircleOutlined,
  TagsOutlined,
  SearchOutlined,
  KeyOutlined,
  CommentOutlined,
  FormOutlined,
  FileOutlined
} from "@ant-design/icons";
// import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
// import { useSelector } from "react-redux";
// import { selectCollapsed, uiChangeCollapsedState } from "../../../../redux";
import { Link, useLocation } from "react-router-dom";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { selectCollapsed, uiChangeCollapsedState } from "../../../../redux";

type MenuItem = Required<MenuProps>["items"][number];

export function useAdminLayout() {
  const dispatch = useAppDispatch();
  const collapsed = useSelector(selectCollapsed);
  let { pathname } = useLocation();

  function getItem(
    icon: ReactNode,
    label: ReactNode,
    key: string,
    children?: MenuItem[]
  ): MenuItem {
    return {
      icon,
      label,
      key,
      children,
    } as MenuItem;
  }

  let items: MenuItem[] = [
    getItem(
      <UsergroupAddOutlined />,
      <Link to={"/users"}>Usuarios</Link>,
      "/users"
    ),
    getItem(
      <BankOutlined />,
      <Link to={"/companies"}>Compañías</Link>,
      "/companies"
    ),
    getItem(
      <UsergroupAddOutlined />,
      <Link to={"/key-account-managers"}>key account managers</Link>,
      "/key-account-managers"
    ),
    getItem(
      <FileOutlined />,
      <Link to={"/ley-de-transparencia"}>Ley de transparencia</Link>,
      "/ley-de-transparencia"
    ),
    {
      type: "divider",
    },
    getItem(
      <UsergroupAddOutlined />,
      <Link to={"/authors"}>Autores</Link>,
      "/authors"
    ),
    // getItem(<NumberOutlined />, <>Noticias</>, "/newsList", [
    //   getItem(
    //     <PaperClipOutlined />,
    //     <Link to={"/news"}>Noticias</Link>,
    //     "/news"
    //   ),
    //   getItem(
    //     <FileProtectOutlined />,
    //     <Link to={"/news-categories"}>Categorías de noticias</Link>,
    //     "/news-categories"
    //   ),
    // ]),

    getItem(<NumberOutlined />, <>Biblioteca virtual</>, "/virtualLibrariesList", [
      getItem(
        <PaperClipOutlined />,
        <Link to={"/virtual-library"}>Biblioteca virtual</Link>,
        "/virtual-library"
      ),
      getItem(
        <FileProtectOutlined />,
        <Link to={"/virtual-library-categories"}>Categorías de biblioteca virtual</Link>,
        "/virtual-library-categories"
      ),
    ]),
    getItem(<NumberOutlined />, <>Infografias</>, "/infographicsList", [
      getItem(
        <PaperClipOutlined />,
        <Link to={"/infographics"}>Infografias</Link>,
        "/infographics"
      ),
      getItem(
        <FileProtectOutlined />,
        <Link to={"/infographic-categories"}>Categorías de Infografias</Link>,
        "/infographic-categories"
      ),
    ]),
    getItem(<NumberOutlined />, <>Sala de prensa</>, "/pressList", [
      getItem(
        <PaperClipOutlined />,
        <Link to={"/press/resources"}>Recursos</Link>,
        "/press/resources"
      ),
      getItem(
        <FileProtectOutlined />,
        <Link to={"/press/newsletter"}>Boletines</Link>,
        "/press/newsletter"
      ),
    ]),

    getItem(<ReconciliationOutlined />, <>Webinars</>, "/webinarsList", [
      getItem(
        <PaperClipOutlined />,
        <Link to={"/webinar"}>Webinars</Link>,
        "/webinars"
      ),
      getItem(
        <FileProtectOutlined />,
        <Link to={"/webinar-categories"}>Categorías de los webinars</Link>,
        "/webinar-categories"
      ),
    ]),
    getItem(<NumberOutlined />, <>Cursos</>, "/coursesList", [
      getItem(
        <PaperClipOutlined />,
        <Link to={"/course"}>Cursos</Link>,
        "/courses"
      ),
      getItem(
        <FileProtectOutlined />,
        <Link to={"/course-categories"}>Categorías de los cursos</Link>,
        "/course-categories"
      ),
    ]),
    getItem(<NumberOutlined />, <>Eventos</>, "/eventsList", [
      getItem(
        <PaperClipOutlined />,
        <Link to={"/event"}>Eventos</Link>,
        "/events"
      ),
      getItem(
        <FileProtectOutlined />,
        <Link to={"/event-categories"}>Categorías de los eventos</Link>,
        "/event-categories"
      ),
      getItem(
        <PaperClipOutlined />,
        <Link to={"/events-tags"}>Tags de eventos</Link>,
        "/events-tags"
      ),
    ]),
    getItem(<ProjectOutlined />, <>Proyectos</>, "/projectList", [
      getItem(
        <PaperClipOutlined />,
        <Link to={"/projects"}>Proyectos</Link>,
        "/projects"
      ),
    ]),
    {
      type: "divider",
    },
    getItem(<ShopOutlined />, <>Corresponsales</>, "/corresponsales", [
      getItem(
        <FileProtectOutlined />,
        <Link to="/corresponsales/formatos-y-modelos">Formatos y modelos</Link>,
        "/corresponsales/formatos-y-modelos"
      ),
      getItem(
        <PlayCircleOutlined />,
        <Link to="/corresponsales/videos-y-tutoriales">
          Videos y tutoriales
        </Link>,
        "/corresponsales/videos-y-tutoriales"
      ),
      getItem(
        <TagsOutlined />,
        <Link to="/corresponsales/material-de-marketing">
          Material de marketing
        </Link>,
        "/corresponsales/material-de-marketing"
      ),
    ]),
    getItem(<SearchOutlined />, <>Buscador</>, "/buscador", [
      getItem(
        <KeyOutlined />,
        <Link to="/buscador/palabras-claves">Palabras claves</Link>,
        "/buscador/palabras-claves"
      ),
    ]),
    { type: "divider" },
    getItem(
      <StarOutlined />,
      <Link to="/calificaciones/cursos">Calificación formación</Link>,
      "/calificaciones/cursos"
    ),
    getItem(
      <CommentOutlined />,
      <Link to="/comentarios">Comentarios</Link>,
      "/comentarios"
    ),
    { type: "divider" },
    getItem(
      <UsergroupAddOutlined />,
      <Link to={"/inscripciones"}>Inscripciones</Link>,
      "/inscripciones"
    ),
    getItem(
      <InboxOutlined />,
      <Link to={"/registry-services-consult-news"}>
        Servicio de registro de consulta
      </Link>,
      "/registry-services-consult-news"
    ),

    getItem(
      <FolderOpenOutlined />,
      <Link to={"/recursos-administrativos"}>Recursos Administrativos</Link>,
      "/recursos-administrativos"
    ),

    getItem(
      <CalendarOutlined />,
      <Link to={"/appointment-scheduling"}>Agendamiento de Citas</Link>,
      "/appointment-scheduling"
    ),

    getItem(
      <HighlightOutlined />,
      <Link to={"/banners"}>Banners</Link>,
      "/banners"
    ),
    getItem(
      <HighlightOutlined />,
      <Link to={"/popups"}>Popups</Link>,
      "/popups"
    ),

    getItem(
      <HighlightOutlined />,
      <Link to={"/registration-events"}>Log Servicios registrales</Link>,
      "/registration-events"
    ),

    getItem(<NumberOutlined />, <>Formularios</>, "/formularios-all", [
      getItem(
        <FormOutlined />,
        <Link to={"/formularios"}>Otros</Link>,
        "/formularios"
      ),
      getItem(
        <FileProtectOutlined />,
        <Link to={"/form-brand-registration"}>Registro de Marca</Link>,
        "/form-brand-registration"
      ),
      getItem(
        <FileProtectOutlined />,
        <Link to={"/form-services-online-information"}>Registro Información Personal </Link>,
        "/form-services-online-information"
      ),
      getItem(
        <FileProtectOutlined />,
        <Link to={"/form-experience-registrational-services"}>Valoración servicios registrales</Link>,
        "/form-experience-registrational-services"
      ),
    ]),

    getItem(
      <FormOutlined />,
      <Link to={"/ciuu"}>CIUU</Link>,
      "/ciuu"
    ),
    getItem(
      <FormOutlined />,
      <Link to={"/certificates"}>Certificados</Link>,
      "/ciuu"
    ),
  ];

  function changeCollapsed() {
    dispatch(uiChangeCollapsedState());
  }

  return {
    //state
    collapsed,
    items,
    pathname,
    //methods
    //functions
    changeCollapsed,
  };
}
