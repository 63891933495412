import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Typography } from "antd";
import { TableColumns } from "../../shared/components/ui/table/types";
import { TableCustom, registrationEventService } from "../../shared";
import moment from "moment";
// import { useAuthors, useTableAuthors } from "../hooks"
// import { IModelAuthors } from "../@types";

const { Title } = Typography;

export function TableRegistration() {
  const columns: TableColumns<any> = [
    {
      title: "ID",
      dataIndex: "id",
      align: "left",
      sorter: true,
    },
    {
      title: "Nombre del servicio",
      dataIndex: "service_name",
      align: "left",
    },
    {
      title: "En nombre de la compañia",
      dataIndex: ["in_name_of_company", "legal_name"],
      align: "left",
    },
    {
      title: "En nombre del usuario",
      dataIndex: ["in_name_of_user"],
      align: "left",
      render: (record: any) => (
        <>
          {record?.first_name} {record?.last_name}
        </>
      ),
    },
    {
      title: "Iniciado por la compañia",
      dataIndex: ["initiated_by_company", "legal_name"],
      align: "left",
    },
    {
      title: "Iniciado por el usuario",
      dataIndex: ["initiated_by_user"],
      align: "left",
      render: (record: any) => (
        <>
          {record?.first_name} {record?.last_name}
        </>
      ),
    },
    {
      title: "Es Corresponsal",
      dataIndex: "is_corresponsal",
      align: "left",
    },
    {
      title: "Fecha de Creación",
      dataIndex: "createdAt",
      align: "left",
      filterType: "date_range",
      render: (_: any, item: any) => (
        <>{moment(item.createdAt).utcOffset(0).format("DD/MM/YYYY")}</>
      ),
    },
  ];

  return (
    <TableCustom
      columns={columns}
      service={registrationEventService}
      fetch$ClientProps={{ withAllData: true }}
      download
    />
  );
}
