import React from "react";
import { useForm } from "react-hook-form";
import { message } from "antd";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CompanyUsersService } from "../../../../shared";

export default function useEditCompanies(
  recordId?: number | null,
  props?: { onSuccess?: (record: any) => void }
) {
  const [loading, setLoading] = React.useState(false);

  const formMethods = useForm<any>({
    resolver: yupResolver(
      yup.object().shape({
        user_id: yup.number().required("Campo requerido"),
        company_id: yup.number().required("Campo requerido"),
      })
    ),
  });

  const loadingRef = React.useRef(false);

  const updateLoading = (status: boolean) => {
    setLoading(status);
    loadingRef.current = status;
  };

  const getRecord = async () => {
    if (loadingRef.current || !recordId) return;

    updateLoading(true);

    await CompanyUsersService
      .get(recordId)
      .then((record) => {
        formMethods.reset({
          company_id: record.company_id,
          user_id: record.user_id,
          role: record.role,
          access_status: record.access_status,
          company_users_job_title_area_id: record.company_users_job_title_area_id,
          company_users_job_title_area_position_id: record.company_users_job_title_area_position_id,
        });
        console.log(record)
      })
      .finally(() => {
        updateLoading(false);
      });
  };

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    if (loadingRef.current || !recordId) return;

    updateLoading(true);

    await CompanyUsersService
      .patch(recordId, data)
      .then((record) => {
        message.success("Registro actualizado correctamente");
        props?.onSuccess?.(record);
      })
      .catch((err) => {
        message.error(err?.message);
      })
      .finally(() => {
        updateLoading(false);
      });
  });

  React.useEffect(() => {
    if (recordId) {
      getRecord();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId]);

  return {
    formMethods,
    loading,
    handleSubmit,
  };
}
