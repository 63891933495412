import { Modal, message } from "antd";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { changeModalStateWebinarsAuthors, deleteWebinarAuthors, fetchSingleWebinarsAuthors, getSingleWebinarsAuthors, refreshTableWebinarsAuthors, setIsWebinarsAuthorsForUpdate } from "../../../../redux/actions/webinarsAuthors.actions";

const { confirm } = Modal;

export function useTableWebinarsAuthors() {
  const dispatch = useAppDispatch();

  const changeWebinarsAuthorsForUpdate = (valId: number) => {
      fetchSingleWebinarsAuthors(valId)
      .then((res)=>{
      dispatch(getSingleWebinarsAuthors(res.data[0]));
      dispatch(setIsWebinarsAuthorsForUpdate(true));
      dispatch(changeModalStateWebinarsAuthors());
      })
  }

  const showDeleteConfirm = (valId: number, val: string) => {
      confirm({
        title: `Estás seguro de borrar el/la autor/a del webinar con el id: ${valId}`,
        icon: <ExclamationCircleOutlined />,
        content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar autor/a" en caso tal solo oprime en "Cancelar".`,
        okText: "Borrar autor/a",
        okType: "danger",
        cancelText: "Cancelar",
        keyboard: true,
        maskClosable: true,
        async onOk() {
          return await deleteWebinarAuthors(valId)
            .then((res) => {
              dispatch(refreshTableWebinarsAuthors(true));
              if(res.name){
                return message.success(`Se ha borrado con éxito el/la autor/a con id:${valId}`);
              }else if(res){
                console.log({res})
                return message.error(`${res}`);
              }
            })
            .catch((err: any) => {
              console.log({ err });
              return message.error(err.message);
            });
        },
        onCancel() {},
      });
    };
    return{
      //state
      //methods
      //functions
      changeWebinarsAuthorsForUpdate,
      showDeleteConfirm,
    }
}
