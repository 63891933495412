// import { encode } from 'base-64'

import { encode } from 'js-base64'
import { BUCKET, S3_PATH_IMAGE_HANDLER } from './constants'


export const S3Photos = {
  bucket: BUCKET,
  base: S3_PATH_IMAGE_HANDLER,
}

export const getFullPicture = (path?: string) => {
  if (!path) return ' '
  const json = JSON.stringify({
    bucket: BUCKET,
    key: path,
  })
  const base64 = encode(json)
  const url = `${S3_PATH_IMAGE_HANDLER}/${base64}`

  if (!path) return ' '

  return url
}

export const getPicture = (path?: string, w?: number, h?: number) => {
  if (!path) return ' '
  const json = JSON.stringify({
    bucket: BUCKET,
    key: path,
    edits: {
      resize: {
        width: w,
        ...(h ? { height: h } : {}),
      },
      // format: path.split('.').reverse()[0],
    },
  })
  const base64 = encode(json)
  const url = `${S3_PATH_IMAGE_HANDLER}/${base64}`

  return url
}
