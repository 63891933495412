import { IModelCoursesIncludes } from "../../modules"


export const COURSES_INCLUDES_MODAL_STATE = '[ COURSES INCLUDES ] Courses Includes Modal State'

export interface ICoursesIncludesModalState {
    type: typeof COURSES_INCLUDES_MODAL_STATE
}

export const GET_COURSES_INCLUDES_FOR_UPDATE = '[ COURSES INCLUDES ] Get Courses Includes For Update'

export interface IGetCoursesIncludesForUpdate {
    type: typeof GET_COURSES_INCLUDES_FOR_UPDATE
    payload: IModelCoursesIncludes
}

export const IS_COURSES_INCLUDES_FOR_UPDATE = '[ COURSES INCLUDES ] Is Courses Includes For Update'

export interface IIsCoursesIncludesForUpdate {
    type: typeof IS_COURSES_INCLUDES_FOR_UPDATE
    payload: boolean
}

export const SWITCH_COURSES_INCLUDES_REFRESH_TABLE = '[ COURSES INCLUDES ] Switch Courses Includes Refresh Table'

export interface ISwitchCoursesIncludesRefreshTable {
    type: typeof SWITCH_COURSES_INCLUDES_REFRESH_TABLE
    payload: boolean
}