import { Button, Col, Row, message } from "antd";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
// import { TablekeyAccountManagers } from "./TablekeyAccountManager";
import { Outlet, useNavigate } from "react-router-dom";
import { TableCategoriesInfographics } from "./TablekeyAccountManager";
import React from "react";
import axios from "axios";

export function StudyCerticates() {
  const [loadingUpload, setLoadingUpload] = React.useState(false);
  const [fileValue, setFileValue] = React.useState();

  const navigate = useNavigate();

  const handleUploadFile = async (e: any) => {
    if (loadingUpload || !e?.target?.files?.length) return;
    setLoadingUpload(true);

    const formData = new FormData();
    formData.append("excel_file", e.target.files[0]);

    await axios
      .post(
        "https://anwt6pvh8s.us-east-1.awsapprunner.com/study-certificates/import",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .catch((error) => {
        message.error(error?.message);
      })
      .finally(() => {
        setFileValue(undefined);
        setLoadingUpload(false);
      });
  };

  return (
    <Row
      gutter={[8, 8]}
      style={{
        padding: "10px",
        width: "100%",
      }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          gap: 14,
        }}
      >
        <input
          type="file"
          value={fileValue}
          style={{ display: "none" }}
          multiple={false}
          id="upload-excel"
          onChange={handleUploadFile}
        />
        <Button.Group>
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
            onClick={() => document.getElementById("upload-excel")?.click?.()}
          >
            Cargar archivo de importación
          </Button>
          <Button
            size="large"
            shape="round"
            type="primary"
            onClick={() => {
              window.open(
                "https://ccbq-webapp-static.s3.us-east-1.amazonaws.com/resources/formato-certificados-de-estudio.xlsx",
                "_blank"
              );
            }}
            icon={<DownloadOutlined />}
            title="Descarga plantilla de importación"
          />
        </Button.Group>
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          size="large"
          onClick={() => navigate(`/certificates/new`)}
        >
          Agregar nuevo certificado
        </Button>
      </Col>
      <TableCategoriesInfographics />
      <Outlet />
    </Row>
  );
}
