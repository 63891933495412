import { Button, Drawer } from "antd";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { VirtualLibraryCategoryById } from "../modules/virtual-library-categories";

export function VirtualLibraryCategoryByIdPage() {
  return (
    <Drawer
      title="Editar Categoría"
      open
      width={350}
      destroyOnClose
      closeIcon={
        <Link to="/virtual-library-categories">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <VirtualLibraryCategoryById />
    </Drawer>
  )
}
