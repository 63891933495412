import { Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { changeModalStateCoursesIncludes, deleteCourseIncludes, fetchSingleCoursesIncludes, getSingleCoursesIncludes, refreshTableCoursesIncludes, setIsCoursesIncludesForUpdate } from "../../../../redux";

const { confirm } = Modal;

export function useTableCoursesIncludes() {
  const dispatch = useAppDispatch();

  const changeCoursesIncludesForUpdate = (valId: number) => {
    fetchSingleCoursesIncludes(valId)
    .then((res)=>{
    dispatch(getSingleCoursesIncludes(res.data[0]));
    dispatch(setIsCoursesIncludesForUpdate(true));
    dispatch(changeModalStateCoursesIncludes());
    })
  }

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar la inclusion de curso con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar inclusion" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar inclusion",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        return await deleteCourseIncludes(valId)
          .then((res) => {
            dispatch(refreshTableCoursesIncludes(true));
            if(res.name){
              return message.success(`Se ha borrado con éxito la inclusion con id:${valId}`);
            }else if(res){
              console.log({res})
              return message.error(`${res}`);
            }
          })
          .catch((err: any) => {
            console.log({ err });
            return message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return{
    //state
    //methods
    //functions
    changeCoursesIncludesForUpdate,
    showDeleteConfirm,
  }
}
