import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectModalWebinarsCategories } from "../../../../redux/selectors/webinarsCategories.selectors";
import { useWebinarsCategories } from "../../hooks";
import { FormProvider } from 'react-hook-form';
import { TableWebinarsCategories } from "./TableWebinarsCategories";
import { ModalWebinarsCategories } from "./ModalWebinarsCategories";

export function WebinarsCategories() {
  const modalWebinarsCategories = useSelector(
    selectModalWebinarsCategories
  );

  const {
    //state
    isUpdateWebinarsCategories,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalWebinarsCategoriesStateForCreate,
  } = useWebinarsCategories();

  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalWebinarsCategoriesStateForCreate}
            >
              Agregar nueva categoría de webinar
            </Button>
        </Col>
        <TableWebinarsCategories />
        {
            modalWebinarsCategories && (
                <FormProvider { ...isUpdateWebinarsCategories ?{...formMethodsUpdate} : {...formMethodsCreate}}>
                    <ModalWebinarsCategories />
                </FormProvider>
            )
        }
    </Row>
  )
}
