import { FormProvider } from "react-hook-form";
import { Button, Col, Row } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useCreateCmsLawTransparency from "../lib/useCreateCmsLawTransparency";
import CmsLawTransparencyForm from "../components/cms-law-transparency-form";

export default function CreateCmsLawTransparency() {
  const navigate = useNavigate();

  const { formMethods, loading, handleSubmit } =
    useCreateCmsLawTransparency({
      onSuccess() {
        navigate("/ley-de-transparencia");
      },
    });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit}>
        <Row gutter={[8, 16]} justify={"space-between"}>
          <Col xs={24}>
            <CmsLawTransparencyForm />
          </Col>
          <Col xs={24}>
            <Button
              icon={<SaveOutlined />}
              block
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
}
