import type { TableProperties } from "../../../shared/components/ui/table/types";

import { Button, Space } from "antd";
import { cmsLawTransparencyService } from "../../../shared";
import Table from "../../../shared/components/ui/table/TableCustom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import useDeleteCmsLawTransparency from "../lib/useDeleteCmsLawTransparency";
import { ICmsLawTransparency } from "../../../@types/dto/cms-law-transparenct";

export default function CmsLawTransparency() {
  const tableRef = React.useRef<TableProperties>();

  const location = useLocation();

  const { handleDelete } = useDeleteCmsLawTransparency({
    onSuccess() {
      tableRef.current?.refresh();
    },
  });

  React.useEffect(() => {
    tableRef.current?.refresh();
  }, [location.pathname]);

  return (
    <Table
      ref={tableRef}
      service={cmsLawTransparencyService}
      columns={[
        { title: "ID", dataIndex: "id", sorter: true, filterType: "text" },
        { title: "Nombre", dataIndex: "name", filterType: "text" },
        { title: 'Categoría', dataIndex: ['category','name'] },
        { title: "Posición", dataIndex: "position", filterType: "text", sorter: true },

        {
          title: "Acciones",
          render: (record: ICmsLawTransparency) => (
            <Space>
              <Link
                to={`/ley-de-transparencia/${record.id}`}
              >
                <Button type="text" icon={<EditOutlined />} />
              </Link>
              <Button
                onClick={() => handleDelete(record.id)}
                type="text"
                danger
                icon={<DeleteOutlined />}
              />
            </Space>
          ),
        },
      ]}
    />
  );
}
