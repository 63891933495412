import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { useProjectsBenefits } from "../../hooks/projects-benefits/useProjectsBenefits";
import { selectModalProjectsBenefits } from "../../../../redux";
import { ModalProjectsBenefits } from "./ModalProjectsBenefits";
import { TableProjectsBenefits } from "./TableProjectsBenefits";

export function ProjectsBenefits() {
  const modalProjectsBenefits = useSelector(
    selectModalProjectsBenefits
  );

  const {
    //state
    isUpdateProjectsBenefits,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalProjectsBenefitsStateForCreate,
  } = useProjectsBenefits();

  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalProjectsBenefitsStateForCreate}
            >
              Agregar nuevo beneficio a Project
            </Button>
        </Col>
        <TableProjectsBenefits />
        {
            modalProjectsBenefits && (
              <FormProvider { ...isUpdateProjectsBenefits ?{...formMethodsUpdate} : {...formMethodsCreate}}>
                <ModalProjectsBenefits />
              </FormProvider>
            )
        }
    </Row>
  )
}
