import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { selectModalCoursesSections } from "../../../../redux";
import { useCoursesSections } from "../../hooks";
import { TableCoursesSections } from "./TableCoursesSections";
import { ModalCoursesSections } from "./ModalCoursesSections";


export function CoursesSections() {
  const modalCoursesSections = useSelector(
    selectModalCoursesSections
  );

  const {
    //state
    isUpdateCoursesSections,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalCoursesSectionsStateForCreate,
  } = useCoursesSections();

  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalCoursesSectionsStateForCreate}
            >
              Agregar nueva sección para curso
            </Button>
        </Col>
        <TableCoursesSections />
        {
            modalCoursesSections && (
                <FormProvider { ...isUpdateCoursesSections ?{...formMethodsUpdate} : {...formMethodsCreate}}>
                    <ModalCoursesSections />
                </FormProvider>
            )
        }
    </Row>
  )
}
