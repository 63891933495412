import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { createResource, getSingleResource, refreshTableResource, selectIsUpdateResource , selectRefreshTableResource, selectSingleResource, setIsResourceForUpdate , updateResource } from "../../../../redux";
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { IModelResources } from "../../@types";
import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import moment from "moment";
import { validateCreateEvents, validateUpdateEvents } from "../../../../shared/utils/yupValidation";

export function useResources() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isUpdateResources = useSelector(
    selectIsUpdateResource
  );

  const dataUpdateEvents = useSelector(
    selectSingleResource
  );

  const isRefreshTableEvents = useSelector(
    selectRefreshTableResource
  );

  const [isLoading, setIsLoading] = useState(false);

  const changeNavigateForCreateEvent = () => {
      dispatch(setIsResourceForUpdate(false))
      navigate(`/recursos-administrativos/new`);
  }

  const formMethodsCreate = useForm<IModelResources>({
      // resolver: yupResolver(validateCreateEvents),
  });

  const formMethodsUpdate = useForm<IModelResources>({
        // resolver: yupResolver(validateUpdateEvents),
  });

  useEffect(() => {
      if (isUpdateResources) {
          formMethodsCreate.reset();
          formMethodsUpdate.reset({ ...dataUpdateEvents, date_recived: moment(dataUpdateEvents?.date_recived).utcOffset(0), inscription_date: moment(dataUpdateEvents?.inscription_date).utcOffset(0)});
      }

      return () => {
          formMethodsUpdate.reset();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateResources, dataUpdateEvents]);


  const onSubmitCreateOrUpdate = async (data: IModelResources) => {
        const filteredData:any = Object.fromEntries(
          Object.entries(data).filter(
              ([key, value]) =>
                  value !== undefined && value !== '' && value !== null 
          ),
      )
      if (Object.keys(filteredData).length === 0) {
          return
      }
      if (isLoading) return;
      setIsLoading(true);
      if (isUpdateResources) {
        await updateResource({...filteredData})
          .then((res: Partial<IModelResources>) => {
            if (res.id) {
              message.success(
                `Se ha actualizado con éxito el evento con id:${res.id}`
              );
              dispatch(getSingleResource(res as any))
              dispatch(refreshTableResource(true));
            } else {
              message.error(res as unknown as string);
            }
          })
          .catch((err: any) => {
            console.log(err);
            message.error(err.message);
          })
          .finally(()=>{
              setIsLoading(false);
          });
      } else {
        await createResource({...filteredData})
          .then((res: IModelResources) => {
            if (res.id) {
              //navegar a la tabla
              // dispatch(changeModalStateEvents());
              message.success(`Se ha creado con éxito el evento con id:${res.id}`);
              dispatch(refreshTableResource(true));
              navigate(`/recursos-administrativos`)
              window.scrollTo(0, 0)
            } else {
              message.error(res as any);
            }
          })
          .catch((err: any) => {
            console.log(err);
            message.error(err.message);
          })
          .finally(()=>{
              setIsLoading(false);
          });
      }
  };

  useEffect(() => {
      if (isRefreshTableEvents) {
        dispatch(refreshTableResource(false));
      }
    }, [isRefreshTableEvents]);

return {
  //state
  isLoading,
  isUpdateResources,
  dataUpdateEvents,
  isRefreshTableEvents,
  //methods
  formMethodsCreate,
  formMethodsUpdate,
  //functions
  changeNavigateForCreateEvent,
  onSubmitCreateOrUpdate,
}
}
