import { Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import {
  deleteCompanies,
  fetchSingleCompanies,
  getSingleCompanies,
  refreshTableCompanies,
  setIsCompaniesForUpdate,
} from "../../../../redux";

const { confirm } = Modal;

export function useTableCompanies() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const changeCompaniesForUpdate = (valId: number) => {
    fetchSingleCompanies(valId).then((res) => {
      dispatch(getSingleCompanies(res.data[0]));
      dispatch(setIsCompaniesForUpdate(true));
      navigate(`/company/${res.data[0].id}`);
    });
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar la compañia con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar compañia" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar Compañia",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        return await deleteCompanies(valId)
          .then((res) => {
            dispatch(refreshTableCompanies(true));
            if (res.name) {
              return message.success(
                `Se ha borrado con éxito la compañia con id:${valId}`
              );
            } else if (res) {
              console.log({ res });
              return message.error(`${res}`);
            }
          })
          .catch((err: any) => {
            console.log({ err });
            return message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    //methods
    //functions
    changeCompaniesForUpdate,
    showDeleteConfirm,
  };
}
