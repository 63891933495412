import { AdminLayout } from "../shared";
import { StudyCerticates } from "../modules/studyCertificates";
import StudyCertificatesLayout from "../modules/studyCertificates/components/study-certificates-layout";
import { Outlet } from "react-router-dom";

export const StudyCerticatesPage = () => {
  return (
    <AdminLayout>
      <StudyCertificatesLayout>
        <StudyCerticates />
      </StudyCertificatesLayout>
      <Outlet />
    </AdminLayout>
  );
};
