
import { IModelEventsCategories } from '../../modules';
import { IEventsCategoriesState } from '../@types/eventsCategories';

import { eventsCategoriesActions } from '../action-types'

type eventsCategoriesActionType =
| { type: typeof eventsCategoriesActions.EVENTS_CATEGORIES_MODAL_STATE; }
| { type: typeof eventsCategoriesActions.GET_EVENTS_CATEGORIES_FOR_UPDATE; payload: IModelEventsCategories }
| { type: typeof eventsCategoriesActions.IS_EVENTS_CATEGORIES_FOR_UPDATE; payload: boolean }
| { type: typeof eventsCategoriesActions.SWITCH_EVENTS_CATEGORIES_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IEventsCategoriesState = {
    eventsCategoriesModalIsOpen: false,
    isUpdateEventsCategories: false,
    eventsCategoriesForUpdate: null,
    refreshTableEventsCategories: false,
}

export default function eventsCategoriesReducer(state: IEventsCategoriesState = INITIAL_STATE, action: eventsCategoriesActionType): IEventsCategoriesState {
  switch (action.type) {
    case eventsCategoriesActions.EVENTS_CATEGORIES_MODAL_STATE:
        return {
            ...state,
            eventsCategoriesModalIsOpen: !state.eventsCategoriesModalIsOpen,
        }
    case eventsCategoriesActions.GET_EVENTS_CATEGORIES_FOR_UPDATE:
        return {
            ...state,
            eventsCategoriesForUpdate: action.payload,
        }
    case eventsCategoriesActions.IS_EVENTS_CATEGORIES_FOR_UPDATE:
        return {
            ...state,
            isUpdateEventsCategories: action.payload,
        }
    case eventsCategoriesActions.SWITCH_EVENTS_CATEGORIES_REFRESH_TABLE:
        return {
            ...state,
            refreshTableEventsCategories: action.payload,
        }
    default:
        return state
  }
}
