import { Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { deleteResource, fetchSingleResource, getSingleResource, refreshTableResource, setIsResourceForUpdate } from "../../../../redux";

const { confirm } = Modal;

export function useTableResources() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const changeEventsForUpdate = (valId: number) => {
    fetchSingleResource(valId)
      .then((res)=>{
          dispatch(getSingleResource(res.data[0]));
          dispatch(setIsResourceForUpdate(true));
          navigate(`/recursos-administrativos/${res.data[0].id}`);
      })
  }

  const showDeleteConfirm = (valId: number, val: string) => {
      confirm({
        title: `Estás seguro de borrar el Recurso con el id: ${valId}`,
        icon: <ExclamationCircleOutlined />,
        content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar Recurso" en caso tal solo oprime en "Cancelar".`,
        okText: "Borrar Recurso",
        okType: "danger",
        cancelText: "Cancelar",
        keyboard: true,
        maskClosable: true,
        async onOk() {
          return await deleteResource(valId)
          .then((res) => {
              dispatch(refreshTableResource(true));
              if(res.name){
                  return message.success(`Se ha borrado con éxito el Recurso con id:${valId}`);
              }else if(res){
                  console.log({res})
                  return message.error(`${res}`);
              }
          })
          .catch((err: any) => {
              console.log({ err });
              return message.error(err.message);
          });
      },
          onCancel() {},
      });
  };

return{
  //state
  //methods
  //functions
  changeEventsForUpdate,
  showDeleteConfirm,
}
}
