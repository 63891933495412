import { useFormContext } from "react-hook-form";
import {  Col, Row, Typography } from "antd";
import { IModelUsers } from "../../@types";
import {
  InputNumber,
  InputPassword,
  InputSelect,
  InputText,
} from "../../../../shared";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux";

const { Title } = Typography;

export function UsersForm() {
  const {control, watch} = useFormContext<IModelUsers>()
  
  const dataUsers =  watch()

  const user = useSelector(selectUser);


  return (
    <>
       
        <Row justify="start">

          <Col
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
            span={12}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Nombre:
            </Title>
            <InputText placeHolder={dataUsers?.first_name ?? ""} name="first_name" control={control} />
          </Col>
          <Col
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
            span={12}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Apellido:
            </Title>
            <InputText placeHolder={dataUsers?.last_name ?? ""} name="last_name" control={control} />
          </Col>

          <Col
                span={12}
                style={{
                    display: "flex",
                    marginTop: "10px",
                    padding: 5,
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                }}
            >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
                Tipo de DNI:
            </Title>
            <InputSelect
                name="dni_type"
                control={control}
                dataOptions={[
                  { title: "NIT", value: "nit" },
                  { title: "Personería Jurídica", value: "personeria_juridica" },
                  { title: "Registro Civil de Nacimiento", value: "registro_civil_de_nacimiento" },
                  { title: "Tarjeta de Identidad", value: "tarjeta_de_identidad" },
                  { title: "Cédula de Ciudadanía", value: "cedula_de_ciudadania" },
                  { title: "Tarjeta de Extranjería", value: "tarjeta_de_extranjeria" },
                  { title: "Cédula de Extranjería", value: "cedula_de_extranjeria" },
                  { title: "Pasaporte", value: "pasaporte" },
                  { title: "Otro Documento de Extranjería", value: "otro_documento_de_extranjeria" },
                  { title: "Sin Identificación del Exterior", value: "sin_identificacion_del_exterior" },
                  { title: "Pasaporte con Visa de Negocio", value: "pasaporte_visa_negocio" },
                  { title: "Permiso Especial de Permanencia", value: "permiso_especial_de_permanencia" },
                  { title: "Permiso de Protección Temporal", value: "permiso_de_proteccion_temporal" },
              ]}
                label="Tipo de DNI"
            />
            </Col>
          <Col
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
            span={12}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              DNI:
            </Title>
            <InputText placeHolder={dataUsers?.dni ?? ""} name="dni" control={control} />
          </Col>

          <Col
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
            span={12}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Código Pais Celular( Ej 57):
            </Title>
            <InputNumber placeHolder={dataUsers?.phone_country_code ?? ""} name="phone_country_code" control={control} />
          </Col>
          <Col
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
            span={12}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Celular:
            </Title>
            <InputText placeHolder={dataUsers?.phone ?? ""} name="phone" control={control} />
          </Col>

          <Col
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
            span={12}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Email:
            </Title>
            <InputText placeHolder="" name="email" control={control} />
          </Col>
          <Col>&nbsp;</Col>
            {user?.role === 'super_admin' && (
              <>
              <Col
                    span={12}
                    style={{
                        display: "flex",
                        marginTop: "10px",
                        padding: 5,
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                    }}
                >
                <Title style={{ marginBottom: "1.5px" }} level={5}>
                    Rol de Usuario:
                </Title>
                <InputSelect
                    name="role"
                    control={control}
                    dataOptions={[
                      // { title: "Super Admin", value: "super_admin" },
                      { title: "Admin", value: "admin" },
                      // { title: "User", value: "user" },
                    ]}
                    label="Rol"
                />
                </Col>
              <Col
                    span={12}
                    style={{
                        display: "flex",
                        marginTop: "10px",
                        padding: 5,
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                    }}
                >
                <Title style={{ marginBottom: "1.5px" }} level={5}>
                    Contraseña:
                </Title>
                  <InputPassword name="password" control={control} />
                </Col>
                </>
            )}
            <Col
                span={12}
                style={{
                    display: "flex",
                    marginTop: "10px",
                    padding: 5,
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                }}
            >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
                Estado:
            </Title>
            <InputSelect
                name="status"
                control={control}
                dataOptions={[
                    { title: "Activo", value: "active" },
                    { title: "Inactivo", value: "inactive" },
                ]}
                label="Estado"
            />
            </Col>
              
            <Col
                span={12}
                style={{
                    display: "flex",
                    marginTop: "10px",
                    padding: 5,
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                }}
            >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
                Acceso a sala de prensa:
            </Title>
            <InputSelect
                name="access_sala_de_prensa"
                control={control}
                dataOptions={[
                    { title: "Activo", value: "active" },
                    { title: "Inactivo", value: "inactive" },
                ]}
                label="Estado"
            />
            </Col>

          
          
        </Row>
      
    </>
  );
}
