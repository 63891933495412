import { IModelEventsCategories } from "../../modules"


export const EVENTS_CATEGORIES_MODAL_STATE = '[ EVENTS CATEGORIES ] Events Categories Modal State'

export interface IEventsCategoriesModalState {
    type: typeof EVENTS_CATEGORIES_MODAL_STATE
}

export const GET_EVENTS_CATEGORIES_FOR_UPDATE = '[ EVENTS CATEGORIES ] Get Events Categories For Update'

export interface IGetEventsCategoriesForUpdate {
    type: typeof GET_EVENTS_CATEGORIES_FOR_UPDATE
    payload: IModelEventsCategories
}

export const IS_EVENTS_CATEGORIES_FOR_UPDATE = '[ EVENTS CATEGORIES ] Is Events Categories For Update'

export interface IIsEventsCategoriesForUpdate {
    type: typeof IS_EVENTS_CATEGORIES_FOR_UPDATE
    payload: boolean
}

export const SWITCH_EVENTS_CATEGORIES_REFRESH_TABLE = '[ EVENTS CATEGORIES ] Switch Events Categories Refresh Table'

export interface ISwitchEventsCategoriesRefreshTable {
    type: typeof SWITCH_EVENTS_CATEGORIES_REFRESH_TABLE
    payload: boolean
}