// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { InputNumber as InputNumberAnt, Typography } from "antd";
import { Controller, FieldErrorsImpl } from "react-hook-form";
import { InputError } from "./styled";
import { ReactNode } from "react";

interface Props {
  width?: string;
  placeHolder?: string;
  name: string;
  control: any;
  stringMode?: boolean;
  min?: boolean;
  max?: number;
  isDisable?: boolean;
  labelText?: string;
  addonAfter?: ReactNode;
  addonBefore?: ReactNode;
}

interface PropsContent {
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  name: string;
}

const ErrorComponent = ({ errors, name }: PropsContent) => {
  return (
    <>{!!errors[name] && <InputError>{errors?.[name]?.message}</InputError>}</>
  );
};

export const InputNumber = ({
  width = "100%",
  placeHolder,
  name,
  control,
  stringMode = false,
  min = false,
  max,
  isDisable = false,
  labelText,
  addonAfter,
  addonBefore,
}: Props) => {
  return (
    <Controller
      shouldUnregister
      control={control}
      name={name}
      render={({ field: { onChange, value = "" }, formState: { errors } }) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width,
              flexDirection: "column",
            }}
          >
            {labelText && (
              <Typography.Text style={{ fontWeight: "600" }}>
                {labelText}
              </Typography.Text>
            )}
            <InputNumberAnt
              disabled={isDisable}
              style={{ width: "100%" }}
              min={min ? -99999999 : 0}
              max={max ? max : 99999999999999999}
              status={errors[name] ? "error" : ""}
              value={value}
              placeholder={placeHolder}
              onChange={onChange}
              stringMode={stringMode}
              addonAfter={addonAfter}
              addonBefore={addonBefore}
            />
            <ErrorComponent errors={errors} name={name} />
          </div>
        );
      }}
    />
  );
};
