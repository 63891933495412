import { FC } from 'react'
import axios from 'axios'


import FileUPloadComponent from './component'
import { IFileUploadContainerProps } from './types'
import { s3FileUploadService } from '../../../services'
import { slugify } from '../../../utils/Helper'


const FileUPload: FC<IFileUploadContainerProps> = ({ path, onUploadFinish, disposition }) => {
    const presignedUrl = async (filename: string, path: string, contentType: string) => {
        return await s3FileUploadService.create({
            filename,
            disposition: disposition || 'inline',
            path,
            contentType,
        })
    }


    const handleChange = (event: any) => {
        if (event.target.files.length) {
            const file: File = event.target.files[0]
            const realFilename = slugify(file.name)
            const contentType = file.type

            presignedUrl(realFilename, path, contentType).then(async(res: any) => {
                const { signedUrl, fileKey, filename } = res
                await axios
                    .put(signedUrl, file, {
                        headers: {
                            'Content-Disposition': `${disposition || 'inline'}; filename=${realFilename}`,
                            'Content-Type': file.type,
                        },
                    })
                    .then(() => {
                        if (onUploadFinish) onUploadFinish({ fileKey, filename })
                    })
            })
        }
    }

    return <FileUPloadComponent handleChange={handleChange} />
}

export default FileUPload
