import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { useProjectsComponents } from "../../hooks";
import { selectModalProjectsComponents } from "../../../../redux";
import { TableProjectsComponents } from "./TableProjectsComponents";
import { ModalProjectsComponents } from "./ModalProjectsComponents";

export function ProjectsComponents() {
  const modalProjectsComponents = useSelector(
    selectModalProjectsComponents
  );

  const {
    //state
    isUpdateProjectsComponents,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalProjectsComponentsStateForCreate,
  } = useProjectsComponents();

  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
            onClick={changeModalProjectsComponentsStateForCreate}
          >
            Agregar nuevo componente a Proyectos
          </Button>
      </Col>
      <TableProjectsComponents />
        {
            modalProjectsComponents && (
                <FormProvider { ...isUpdateProjectsComponents ?{...formMethodsUpdate} : {...formMethodsCreate}}>
                    <ModalProjectsComponents />
                </FormProvider>
            )
        }
    </Row>
  )
}
