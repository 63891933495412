import { Button, Drawer } from "antd";
import FormDetail from "../modules/forms/ui/FormDetail";
import { Link, useParams } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";

export default function FormDetailPage() {
  const params = useParams();

  return (
    <Drawer
      title="Detalle del formulario"
      open
      destroyOnClose
      width={400}
      closeIcon={
        <Link to="/formularios">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <FormDetail
        recordId={params?.recordId ? Number(params.recordId) : null}
      />
    </Drawer>
  );
}
