import { Authors } from "../modules";
import { AdminLayout } from "../shared";

export function AuthorsPage() {
  return (
    <AdminLayout>
      <Authors />
    </AdminLayout>
  )
}
