import { IModelUsers } from "../../modules";
import { IUsersState } from "../@types/users";
import { UsersActions } from "../action-types";


type UsersActionType =
| { type: typeof UsersActions.USERS_MODAL_STATE; }
| { type: typeof UsersActions.GET_USERS_FOR_UPDATE; payload: IModelUsers }
| { type: typeof UsersActions.IS_USERS_FOR_UPDATE; payload: boolean }
| { type: typeof UsersActions.SWITCH_USERS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IUsersState = {
    UsersModalIsOpen: false,
    isUpdateUsers: false,
    UsersForUpdate: null,
    refreshTableUsers: false,
}

export default function UsersReducer(state: IUsersState = INITIAL_STATE, action: UsersActionType): IUsersState {
  switch (action.type) {
    case UsersActions.USERS_MODAL_STATE:
        return {
            ...state,
            UsersModalIsOpen: !state.UsersModalIsOpen,
        }
    case UsersActions.GET_USERS_FOR_UPDATE:
        return {
            ...state,
            UsersForUpdate: action.payload,
        }
    case UsersActions.IS_USERS_FOR_UPDATE:
        return {
            ...state,
            isUpdateUsers: action.payload,
        }
    case UsersActions.SWITCH_USERS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableUsers: action.payload,
        }
    default:
        return state
  }
}
