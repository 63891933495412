import { Button, Col, Typography } from "antd";
import { useFormContext } from 'react-hook-form';
import { WrapperModalProjects } from "../styled";
import { InputNumber, InputText } from "../../../../shared";
import { useSelector } from "react-redux";
import { selectModalProjectsComponents } from "../../../../redux";
import { useProjectsComponents } from "../../hooks";
import { IModelProjectsComponents } from "../../@types";

const { Title } = Typography;

export function ModalProjectsComponents() {
  const modalProjectsComponents = useSelector(
    selectModalProjectsComponents
  );

  const {
    //state
    isLoading,
    isUpdateProjectsComponents,
    dataUpdateProjectsComponents,
    dataUpdateProjects,
    //methods
    //functions
    changeModalProjectsComponentsStateForCreate,
    onSubmitCreateOrUpdate
  } = useProjectsComponents()

  const { control, handleSubmit: onSubmit } = useFormContext<IModelProjectsComponents>();

  return (
    <WrapperModalProjects
      title={ isUpdateProjectsComponents
          ? `Actualizando requerimientos de Project: '${dataUpdateProjects?.id!}'`
          : `Creando requerimientos de Project` }
      open={modalProjectsComponents}
      width={"320px"}
      destroyOnClose
      onClose={changeModalProjectsComponentsStateForCreate}>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Titulo:
          </Title>
          <InputText placeHolder={dataUpdateProjectsComponents?.title || ""} name="title" control={control} />
        </Col>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Items:
          </Title>
          <InputText placeHolder={dataUpdateProjectsComponents?.title || ""} name="item" control={control} />
        </Col>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Tipo:
          </Title>
          <InputText placeHolder={dataUpdateProjectsComponents?.type || ""} name="type" control={control} />
        </Col>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Path:
          </Title>
          <InputText placeHolder={dataUpdateProjectsComponents?.path || ""} name="path" control={control} />
        </Col>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
          textButtom:
          </Title>
          <InputText placeHolder={dataUpdateProjectsComponents?.textButtom || ""} name="textButtom" control={control} />
        </Col>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
          linkACtion:
          </Title>
          <InputText placeHolder={dataUpdateProjectsComponents?.linkACtion || ""} name="linkACtion" control={control} />
        </Col>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Posición:
          </Title>
          <InputNumber
            placeHolder="123"
            name="position"
            control={control}
          />
        </Col>
        <Button
            style={{
            width: "100%",
            marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
        >
            {isUpdateProjectsComponents
            ? "Actualizar información"
            : "Crear requerimiento de Project"}
        </Button>
    </WrapperModalProjects>
  )
}
