import { Button, Drawer } from "antd";
import { Link, useParams } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import EditKeywordSearch from "../modules/keyword-search/ui/EditKeywordSearch";

export default function EditKeywordSearchPage() {
  const { recordId } = useParams();
  return (
    <Drawer
      title="Editar palabra clave"
      width={350}
      open
      destroyOnClose
      closeIcon={
        <Link to="/buscador/palabras-claves">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <EditKeywordSearch recordId={recordId ? Number(recordId) : null} />
    </Drawer>
  );
}
