import { Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { changeModalStateWebinarsCategories, deleteWebinarCategories, fetchSingleWebinarsCategories, getSingleWebinarsCategories, refreshTableWebinarsCategories, setIsWebinarsCategoriesForUpdate } from "../../../../redux/actions/webinarsCategories.actions";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";

const { confirm } = Modal;

export function useTableWebinarsCategories() {
    const dispatch = useAppDispatch();

    const changeWebinarsCategoriesForUpdate = (valId: number) => {
        fetchSingleWebinarsCategories(valId)
        .then((res)=>{
        dispatch(getSingleWebinarsCategories(res.data[0]));
        dispatch(setIsWebinarsCategoriesForUpdate(true));
        dispatch(changeModalStateWebinarsCategories());
        })
    }

    const showDeleteConfirm = (valId: number, val: string) => {
        confirm({
          title: `Estás seguro de borrar la Categoría de webinar con el id: ${valId}`,
          icon: <ExclamationCircleOutlined />,
          content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar categoría" en caso tal solo oprime en "Cancelar".`,
          okText: "Borrar categoría",
          okType: "danger",
          cancelText: "Cancelar",
          keyboard: true,
          maskClosable: true,
          async onOk() {
            return await deleteWebinarCategories(valId)
              .then((res) => {
                dispatch(refreshTableWebinarsCategories(true));
                if(res.name){
                  return message.success(`Se ha borrado con éxito la Categoría con id:${valId}`);
                }else if(res){
                  console.log({res})
                  return message.error(`${res}`);
                }
              })
              .catch((err: any) => {
                console.log({ err });
                return message.error(err.message);
              });
          },
          onCancel() {},
        });
      };
      return{
        //state
        //methods
        //functions
        changeWebinarsCategoriesForUpdate,
        showDeleteConfirm,
      }
}
