import { message } from "antd";

import { coursesAuthorsService } from "../../shared";
import { coursesAuthorsActions } from "../action-types";
import { IModelCoursesAuthors } from "../../modules";


export const changeModalStateCoursesAuthors = (): coursesAuthorsActions.ICoursesAuthorsModalState =>({
    type: coursesAuthorsActions.COURSES_AUTHORS_MODAL_STATE
})

export const getSingleCoursesAuthors = (val:IModelCoursesAuthors ): coursesAuthorsActions.IGetCoursesAuthorsForUpdate=> ({
    type: coursesAuthorsActions.GET_COURSES_AUTHORS_FOR_UPDATE,
    payload: val
})

export const setIsCoursesAuthorsForUpdate =(val: boolean): coursesAuthorsActions.IIsCoursesAuthorsForUpdate =>({
    type: coursesAuthorsActions.IS_COURSES_AUTHORS_FOR_UPDATE,
    payload: val,
})

export const fetchSingleCoursesAuthors = async(id: number) =>{
    return await coursesAuthorsService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createCourseAuthors = async(data:Partial<IModelCoursesAuthors>) =>{
    return await coursesAuthorsService.create(data)
    .then((res: IModelCoursesAuthors)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateCourseAuthors = async(data:Partial<IModelCoursesAuthors>) =>{
    return await coursesAuthorsService.patch(data?.id!, data)
    .then((res: IModelCoursesAuthors)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteCourseAuthors = async(id: number) =>{
    return await coursesAuthorsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableCoursesAuthors = (val: boolean): coursesAuthorsActions.ISwitchCoursesAuthorsRefreshTable =>({
    type: coursesAuthorsActions.SWITCH_COURSES_AUTHORS_REFRESH_TABLE,
    payload: val
})

