import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { message } from "antd";
import { registryServicesConsultNewsService } from "../../../../shared";
import { getSingleRegistryServicesConsultNew,  setIsRegistryServicesConsultNewForUpdate } from "../../../../redux";

export function useRegistryServicesConsultNewsById() {
  const [isValidate, setIsValidate ] = useState(false)

  const { pathname } = useLocation()
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getSingleEventsFetch = async ( id: number) =>{
      return await registryServicesConsultNewsService
      .get(id)
      .then((res) => {
          return res;
      })
      .catch((err: any) => {
          console.log(err);
          navigate(`/registry-services-consult-news`);
          message.error(err.message);
      });
  }

  useEffect(() => {
      if( pathname !== "/registry-services-consult-news/new" ){
        if (isNaN(Number(pathname.split("/")[2]))) return navigate(`/registry-services-consult-news`);
          dispatch(setIsRegistryServicesConsultNewForUpdate(true));
          getSingleEventsFetch(Number(pathname.split("/")[2])).then((res) =>
              dispatch(getSingleRegistryServicesConsultNew(res as any))
          );
          setIsValidate(true)
      }else if(pathname === "/registry-services-consult-news/new" ){
          dispatch(setIsRegistryServicesConsultNewForUpdate(false));
          setIsValidate(true)
      }
  }, [])

  return {
    //state
    isValidate,
    //methods
    //functions
  }
}
