import { AdminLayout } from "../shared";
import { Button, Flex } from "antd";
import { Link, Outlet } from "react-router-dom";
import VideosAndTutorials from "../modules/Corresponsales/ui/VideosAndTutorials";
import VideosAndTutorialsLayout from "../modules/Corresponsales/ui/components/VideosAndTutorialsLayout";

export default function CorresponsalesVideosAndTutorialsPage() {
  return (
    <AdminLayout>
      <VideosAndTutorialsLayout>
        <Flex justify="flex-end">
          <Link to="/corresponsales/videos-y-tutoriales/agregar">
            <Button type="primary">+ Video/Tutorial</Button>
          </Link>
        </Flex>

        <VideosAndTutorials />
      </VideosAndTutorialsLayout>
      <Outlet />
    </AdminLayout>
  );
}
