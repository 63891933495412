 
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col } from "antd";
import { useTableProjectsRequirements, useProjectsRequirements } from "../../hooks";
import { IModelProjectsRequirements } from "../../@types";
import { TableCustom, projectsService } from "../../../../shared";
import { useSelector } from "react-redux";
import { selectSingleProjects } from "../../../../redux";
import { TableColumns, TableProperties } from "../../../../shared/components/ui/table/types";
import React from "react";


export function TableProjectsRequirements() {
  const dataUpdateProjects = useSelector( selectSingleProjects );
  let dataUpdateProjectsRequirements = {}
  if(dataUpdateProjects?.meta_requirements){
        dataUpdateProjectsRequirements = JSON.parse(dataUpdateProjects?.meta_requirements);
        // console.log(78, dataUpdateProjectsRequirements);
      }

  const {
    //state
    //methods
    //functions
    changeProjectsRequirementsForUpdate,
    showDeleteConfirm,
  } = useTableProjectsRequirements()

  const {
    //state
    isRefreshTableProjectsRequirements,
    //methods
    //functions
  }= useProjectsRequirements()
  // const projectsServiceData = useSelector(selectSingleProjects);
  // console.log(75, dataUpdateProjectsRequirements);

  const tableRef = React.useRef<TableProperties>()

    React.useEffect(() => {
      tableRef.current?.refresh()
    }, [isRefreshTableProjectsRequirements])

  const columns: TableColumns<IModelProjectsRequirements> = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'left',
      sorter:true
  },
  {
    title: 'Titulo',
    dataIndex: 'meta_requirements',
    align: 'left',
    render: (text: any, record: any) => (
      
        <span>{JSON.parse(text)?.title}</span>
    ),
    },  
  {
      title: 'Posición',
      dataIndex: 'meta_requirements',
      align: 'left',
      render: (text: any, record: any) => (
      
        <span>{JSON.parse(text)?.position}</span>
    ),
  },
  {
    title: 'items',
    dataIndex: 'meta_requirements',
    align: 'left',
    render: (text: any, record: any) => (
      
      <span>{JSON.parse(text)?.items}</span>
  ),
  },
  {
    title: 'tipo',
    dataIndex: 'meta_requirements',
    align: 'left',
    render: (text: any, record: any) => (
      
      <span>{JSON.parse(text)?.type}</span>
  ),
  },
  {
    title: 'path',
    dataIndex: 'meta_requirements',
    align: 'left',
    render: (text: any, record: any) => (
      
      <span>{JSON.parse(text)?.path}</span>
  ),
  },
  {
    title: 'texto button',
    dataIndex: 'meta_requirements',
    align: 'left',
    render: (text: any, record: any) => (
      
      <span>{JSON.parse(text)?.textButtom}</span>
  ),
  },
  {
    title: 'link button',
    dataIndex: 'meta_requirements',
    align: 'left',
    render: (text: any, record: any) => (
      
      <span>{JSON.parse(text)?.linkACtion}</span>
  ),
  },
    {
        title: "Acciones",
        key: "operation",
        fixed: "right",
        width: 140,
        render: (_:any, item:any) => {
          return (
            <Col
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "start",
                flexDirection: "row",
              }}
            >
              <Button
                type="primary"
                shape="circle"
                onClick={() => changeProjectsRequirementsForUpdate(item.id)}
                icon={<EditOutlined />}
                size="large"
              />
              <Button
                type="primary"
                danger
                onClick={() => showDeleteConfirm(item.id, `${item.title + "..."}`)}
                shape="circle"
                icon={<DeleteOutlined />}
                size="large"
              />
            </Col>
          );
        },
    },
  ]

  return (
    <TableCustom fetchQueryProps={{id: dataUpdateProjects?.id!,}} columns={columns}  service={projectsService} ref={tableRef}/>
  )
}
