import { Drawer, Tabs } from "antd";
import styled from "styled-components";

//modal Webinars

export const WrapperModalWebinars = styled(Drawer)``;


export const WrapperTabNav = styled(Tabs)`
  width: 100%;
`;