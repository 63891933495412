import React from "react";
import { useForm } from "react-hook-form";
import { corresponsalesVideosAndTutorialsCategoriesService } from "../../../shared";
import { message } from "antd";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ICorresponsalesVideosAndTutorialsCategories } from "../../../@types/dto/corresponsales-videos-and-tutorials";

export default function useEditVideoAndTutorialCategories(
  recordId?: number | null,
  props?: {
    onSuccess?: (record: ICorresponsalesVideosAndTutorialsCategories) => void;
  }
) {
  const [loading, setLoading] = React.useState(false);

  const formMethods = useForm<ICorresponsalesVideosAndTutorialsCategories>({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required("Campo requerido"),
      })
    ),
  });

  const loadingRef = React.useRef(false);

  const updateLoading = (status: boolean) => {
    setLoading(status);
    loadingRef.current = status;
  };

  const getRecord = async () => {
    if (loadingRef.current || !recordId) return;

    updateLoading(true);

    await corresponsalesVideosAndTutorialsCategoriesService
      .get(recordId)
      .then((record) => {
        formMethods.reset({
          name: record.name,
        });
      })
      .finally(() => {
        updateLoading(false);
      });
  };

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    if (loadingRef.current || !recordId) return;

    updateLoading(true);

    await corresponsalesVideosAndTutorialsCategoriesService
      .patch(recordId, data)
      .then((record) => {
        message.success("Registro actualizado correctamente");
        props?.onSuccess?.(
          record as ICorresponsalesVideosAndTutorialsCategories
        );
      })
      .catch((err) => {
        message.error(err?.message);
      })
      .finally(() => {
        updateLoading(false);
      });
  });

  React.useEffect(() => {
    if (recordId) {
      getRecord();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId]);

  return {
    formMethods,
    loading,
    handleSubmit,
  };
}
