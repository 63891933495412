import { useNavigate } from "react-router-dom";
import useCreatePopup from "../lib/useCreatePopup";
import { FormProvider } from "react-hook-form";
import { Button, Col, Row } from "antd";
import { PopupForm } from "../components/forms";
import { SaveOutlined } from "@ant-design/icons";

export default function CreatePopup() {
  const navigate = useNavigate();

  const { formMethods, loading, handleSubmit } = useCreatePopup({
    onSuccess() {
      navigate("/popups");
    },
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit}>
        <Row gutter={[8, 16]}>
          <Col xs={24}>
            <PopupForm />
          </Col>
          <Col xs={24}>
            <Button
              icon={<SaveOutlined />}
              type="primary"
              htmlType="submit"
              loading={loading}
              block
            >
              Crear
            </Button>
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
}
