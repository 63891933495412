import { useNavigate } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import { Button, Col, Row } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import useCreatePressResourceCategory from "../lib/useCreatePressResourceCategory";
import PressResourceCategoriesForm from "../components/press-resource-categories-form";

export default function CreatePressResourceCategory() {
  const navigate = useNavigate();

  const { formMethods, loading, handleSubmit } = useCreatePressResourceCategory({
    onSuccess() {
      navigate("/press/resources/categories");
    },
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit}>
        <Row gutter={[8, 16]}>
          <Col xs={24}>
            <PressResourceCategoriesForm />
          </Col>
          <Col xs={24}>
            <Button
              icon={<SaveOutlined />}
              type="primary"
              htmlType="submit"
              loading={loading}
              block
            >
              Crear
            </Button>
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
}
