import { TableUserWebinar } from "../modules/userWebinar";
import { AdminLayout } from "../shared";

export function UserWebinarPage() {
  return (
    <AdminLayout>
      <TableUserWebinar />
    </AdminLayout>
  );
}
