import { useFormContext } from "react-hook-form";
import { Button, Col, Row, Typography } from "antd";
import { IModelCompanies } from "../../@types";
import {
  InputNumber,
  InputSelect,
  InputSelectDynamic,
  InputText,
  clustersService,
  keyAccountManagersService,
} from "../../../../shared";

const { Title } = Typography;

export function CompaniesForm() {
  const { control } = useFormContext<IModelCompanies>();

  return (
    <>
      <Row justify="start">
        <Col
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
          span={24}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Nombre - Razón social:
          </Title>
          <InputText
            disabled
            placeHolder=""
            name="legal_name"
            control={control}
          />
        </Col>
        <Col
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
          span={24}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Nit:
          </Title>
          <InputText disabled placeHolder="" name="dni" control={control} />
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Cluster:
          </Title>
          <InputSelectDynamic
            name="cluster_id"
            control={control}
            serviceName={clustersService}
            labelName="name"
            valName="id"
          />
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Key account manger:
          </Title>
          <InputSelectDynamic
            name="key_account_manager_id"
            control={control}
            serviceName={keyAccountManagersService}
            labelName="first_name"
            labelName2="last_name"
            valName="id"
          />
        </Col>
      </Row>
    </>
  );
}
