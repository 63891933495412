import { useNavigate } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import { KeywordSearchForm } from "../components/forms";
import { Button, Col, Row } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import useEditKeywordSearch from "../lib/useEditKeywordSearch";

export default function EditKeywordSearch({
  recordId,
}: {
  recordId: number | null;
}) {
  const navigate = useNavigate();

  const { formMethods, loading, handleSubmit } = useEditKeywordSearch(
    recordId,
    {
      onSuccess() {
        navigate("/buscador/palabras-claves");
      },
    }
  );
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit}>
        <Row gutter={[8, 16]}>
          <Col xs={24}>
            <KeywordSearchForm />
          </Col>

          <Col xs={24}>
            <Button
              htmlType="submit"
              loading={loading}
              block
              type="primary"
              icon={<SaveOutlined />}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
}
