import React from 'react'
import { Avatar, Button, Col, Typography } from "antd";
import { DeleteOutlined, EditOutlined, OrderedListOutlined } from "@ant-design/icons";

import { PATH_S3_BUCKET } from "../../../shared/utils/constants";
import { DataBanner } from "../../../redux/@types";
import { useBanners, useTableBanners } from "../hooks";
import { TableCustom, bannersService } from "../../../shared";
import { TableColumns } from "../../../shared/components/ui/table/types";


const { Title } = Typography;

export const TableBanners = () => {
  const { showDeleteConfirm, changeBannersForUpdate } = useTableBanners();
  const { setTableProps, changeNavToBannersProducts, updateStatus, isLoading } = useBanners();

  const columns: TableColumns<DataBanner> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Móvil",
      width: 7,
      dataIndex: "path_movile",
      key: "path_movile",
      render: (_: any, item: any) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Avatar size={64} src={`${PATH_S3_BUCKET}/${item.path_movile}`} />
          </Col>
        );
      },
    },
    {
      title: "Escritorio",
      dataIndex: "path_desktop",
      key: "path_desktop",
      render: (_: any, item: any) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Avatar size={64} src={`${PATH_S3_BUCKET}/${item.path_desktop}`} />
          </Col>
        );
      },
    },
    {
      title: "Tipo",

      dataIndex: "type",
      key: "type",
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      render: (_: any, item: any) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <button
            disabled={isLoading}
              onClick={()=>{
                if(item.status === "active"){
                  updateStatus("inactive", item.id)
                }else if( item.status === "inactive"){
                  updateStatus("active", item.id)
                }
              }}
              style={{
                display: "flex",
                padding: "5px 27px",
                borderWidth: 0,
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor:
                  item.status === "active" ? "#4bd542" : "#ff4d4f",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "white",
                }}
                level={5}
              >
                {item.status}
              </Title>
            </button>
          </Col>
        );
      },
      filters: [
        {
          text: "Activo",
          value: "active",
        },
        {
          text: "Inactivo",
          value: "inactive",
        },
      ],
    },
    {
      title: "Posición",
      dataIndex: "priority",
      key: "priority",
      sorter:true
    },
    {
      title: "Tipo de acción",

      dataIndex: "action_type",
      key: "action_type",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",

      render: (_: any, item: any) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeBannersForUpdate(item.id)}
              icon={<EditOutlined />}
              size="large"
            />
            {
              item.action_type === "BANNER_PRODUCTS" && (
                <Button
                 type="dashed"
                 shape="circle"
                 onClick={() =>changeNavToBannersProducts(item.id)}
                 icon={<OrderedListOutlined />}
                 size="large"
               />

              )
            }
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.id}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  return (
    <TableCustom
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      service={bannersService}
      // onLoad={setTableProps}
    />
  );
};
