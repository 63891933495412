// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Select, Spin, Typography } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import lodash from "lodash";
import Service from "../../../services/service.class";
import { InputError } from "./styled";
import { Controller, FieldErrorsImpl } from "react-hook-form";

const INIT_STATE = {
  data: [],
  limit: 10,
  skip: -1,
  total: 0,
};

interface Props {
  serviceName: Service<any>;
  labelName: string;
  labelName2?: string;
  valName: string;
  name: string;
  control: any;
  placeHolder?: string;
  labelText?: string;
  allowClear?: boolean;
  disabled?: boolean
  onLoad?: (props: { refresh: () => void }) => void;
  queryFetch?: any
}

interface PropsContent {
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  name: string;
}

const ErrorComponent = ({ errors, name }: PropsContent) => {
  return (
    <>{!!errors[name] && <InputError>{errors?.[name]?.message}</InputError>}</>
  );
};

export function InputSelectDynamic({
  serviceName,
  labelName,
  valName,
  control,
  name,
  labelName2,
  placeHolder = "Select a",
  labelText,
  allowClear,
  disabled,
  onLoad,
  queryFetch,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [dataRes, setDataRes] = useState<{
    data: any[];
    limit: number;
    skip: number;
    total: number;
  }>(INIT_STATE);
  const [currentPage, setCurrentPage] = useState(1);
  const { data, limit, skip, total } = dataRes;

  const dataMemorize = useMemo(
    () =>
      lodash.map(data, (item) => ({
        label: labelName2
          ? `${item[labelName]} ${item[labelName2]}`
          : `${item[labelName]}`,
        value: item[valName],
      })),
    [data]
  );

  const loadingRef = useRef(false);

  // const onSearch = (value: string) => {
  //     console.log('search:', value);
  // };

  async function fetchData(skipCurrent?: number) {
    if (total === skip || skip > total || loadingRef.current) return;

    setLoading(true);
    loadingRef.current = true;
    return await serviceName
      .find({
        query: {
          ...(queryFetch ? queryFetch : {}),
          $skip: skipCurrent || 0,
          $sort: {
            // id: -1,
          },
        },
      })
      .then((res) => {
        setDataRes((e) => ({
          limit: res.limit,
          skip: res.skip,
          total: res.total,
          data: [...e.data, ...res.data],
        }));
        setCurrentPage((e) => ++e);
      })
      .catch((res) => {
        console.log({ res });
      })
      .finally(() => {
        setLoading(false);
        loadingRef.current = false;
      });
  }

  const handlePopupScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      fetchData(((currentPage || 1) - 1) * limit);
    }
  };

  useEffect(() => {
    if (data.length === 0) {
      setDataRes(INIT_STATE);
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (onLoad) {
      onLoad({
        refresh: () => {
          setDataRes(INIT_STATE);
          fetchData();
        },
      });
    }
  }, []);

  return (
    <Controller
      shouldUnregister
      control={control}
      disabled={disabled}
      name={name}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        return (
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            {labelText && (
              <Typography.Text style={{ fontWeight: "600" }}>
                {labelText}
              </Typography.Text>
            )}
            <Select
              disabled={disabled}
              showSearch
              placeholder={placeHolder}
              optionFilterProp="children"
              onChange={onChange}
              allowClear={allowClear}
              // onSearch={onSearch}
              onClear={() => onChange(null)}
              value={value}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onPopupScroll={handlePopupScroll}
              notFoundContent={loading ? <Spin size="small" /> : null}
              options={dataMemorize}
            />
            <ErrorComponent errors={errors} name={name} />
          </div>
        );
      }}
    />
  );
}
