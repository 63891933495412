import { message } from "antd";
import { useEffect, useState } from "react";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import { useSelector } from "react-redux";
import { changeModalStateCoursesSections, createCourseSections, refreshTableCoursesSections, selectIsUpdateCoursesSections, selectRefreshTableCoursesSections, selectSingleCourses, selectSingleCoursesSections, setIsCoursesSectionsForUpdate, updateCourseSections } from "../../../../redux";
import { IModelCoursesSections } from "../../@types";
import { validateCreateCoursesSections, validateUpdateCoursesSections } from "../../../../shared/utils/yupValidation";


export function useCoursesSections() {
  const dispatch = useAppDispatch();

  const isUpdateCoursesSections = useSelector(
      selectIsUpdateCoursesSections
  );

  const dataUpdateCoursesSections = useSelector(
      selectSingleCoursesSections
  );

  const isRefreshTableCoursesSections = useSelector(
      selectRefreshTableCoursesSections
  );

  const dataUpdateCourses = useSelector( selectSingleCourses );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalCoursesSectionsStateForCreate = () => {
    dispatch(setIsCoursesSectionsForUpdate(false))
    dispatch(changeModalStateCoursesSections())
  }

  const formMethodsCreate = useForm<IModelCoursesSections>({
    resolver: yupResolver(validateCreateCoursesSections),
  });

  const formMethodsUpdate = useForm<IModelCoursesSections>({
    resolver: yupResolver(validateUpdateCoursesSections),
  });

  useEffect(() => {
  if (isUpdateCoursesSections) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateCoursesSections });
  }

  return () => {
      formMethodsUpdate.reset();
  };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateCoursesSections, dataUpdateCoursesSections]);

  const onSubmitCreateOrUpdate = async (data: IModelCoursesSections) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateCoursesSections) {
      await updateCourseSections({
        id: data.id,
        name: data.name,
        description: data.description,
        type: data.type,
        position: data.position,
        course_id: dataUpdateCourses?.id,
      })
        .then((res: Partial<IModelCoursesSections>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito la sección del curso con id:${res.id}`
            );
            dispatch(refreshTableCoursesSections(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createCourseSections({...data, course_id: dataUpdateCourses?.id})
        .then((res: IModelCoursesSections) => {
          if (res.id) {
            dispatch(changeModalStateCoursesSections());
            message.success(
              `Se ha creado con éxito la sección del curso con id:${res.id}`
            );
            dispatch(refreshTableCoursesSections(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableCoursesSections) {
      dispatch(refreshTableCoursesSections(false));
    }
  }, [isRefreshTableCoursesSections]);

  return{
      //state
      isLoading,
      isUpdateCoursesSections,
      dataUpdateCoursesSections,
      isRefreshTableCoursesSections,
      //methods
      formMethodsCreate,
      formMethodsUpdate,
      //functions
      changeModalCoursesSectionsStateForCreate,
      onSubmitCreateOrUpdate,
  }
}


