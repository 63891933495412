import { useFormContext } from 'react-hook-form';
import { Button, Col, Typography } from "antd";
import { useSelector } from "react-redux";
import { selectModalEventsTags } from '../../../../redux';
import { useEventsTags } from '../../hooks';
import { IModelEventsTags } from '../../@types';
import { InputText } from '../../../../shared';
import { WrapperModalEvents } from '../styled';

const { Title } = Typography;

export function ModalEventsTags() {
  const modalEventsTags = useSelector(  selectModalEventsTags );

  const {
    //state
    isLoading,
    isUpdateEventsTags,
    dataUpdateEventsTags,
    //methods
    //functions
    changeModalEventsTagsStateForCreate,
    onSubmitCreateOrUpdate
  } = useEventsTags()

  const { control, handleSubmit: onSubmit } = useFormContext<IModelEventsTags>();

  return (
    <WrapperModalEvents
      title={ isUpdateEventsTags ? `Actualizando tags de eventos: '${dataUpdateEventsTags?.id!}'` : `Creando tags de eventos`}
      open={modalEventsTags}
      width={"320px"}
      destroyOnClose
      onClose={changeModalEventsTagsStateForCreate}>
        <Col
            span={24}
            style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            }}
        >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
                Nombre:
            </Title>
            <InputText placeHolder="Tag...." name="name" control={control} />
        </Col>
        <Button
            style={{
            width: "100%",
            marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
        >
            {isUpdateEventsTags
            ? "Actualizar información"
            : "Crear tags de eventos"}
        </Button>
    </WrapperModalEvents>
  )
}
