import React from "react";
import { ID, IField } from "../../ui/template-config/canvas/field";
import { studyCertificateTemplateFieldsService } from "../../../../shared";
import { message } from "antd";
import { IStudyCertificateTemplateField } from "../../../../@types/dto/study-certificate-template-field";
import { IBaseDto } from "../../../../@types/dto/base.dto";

export default function useUpdateStudyCertificateTemplateFields(
  templateId?: number,
  data?: {
    fieldsToCreate: IField[];
    fieldsToUpdate: IField[];
    fieldsToDelete: ID[];
  },
  props?: {
    onSuccess?: () => void;
  }
) {
  const [loading, setLoading] = React.useState(false);

  const loadingRef = React.useRef(false);

  const updateLoading = (value: boolean) => {
    setLoading(value);
    loadingRef.current = value;
  };

  const fieldReducer = (
    field: IField
  ): Omit<IStudyCertificateTemplateField, keyof IBaseDto | "id"> & {
    id?: number;
  } => {
    return {
      id: typeof field.id === "number" ? field.id : undefined,
      field_name: field.name,
      field_placeholder: field.description,
      height: field.height,
      left: field.left,
      key_name_to_fill: field.key_name,
      study_certificate_template_id: templateId!,
      top: field.top,
      width: field.width,
      css_styles: JSON.stringify(field.styles),
    };
  };

  const handleUpdateFields = async () => {
    if (loadingRef.current || !templateId) {
      return;
    }

    updateLoading(true);

    await studyCertificateTemplateFieldsService
      .patch(
        null,
        {
          fieldsToCreate: data?.fieldsToCreate.map(fieldReducer),
          fieldsToUpdate: data?.fieldsToUpdate.map(fieldReducer),
          fieldsToDelete: data?.fieldsToDelete,
        } as any,
        {
          query: {
            $client: {
              bulkUpdate: "true",
            },
          },
        }
      )
      .then(() => {
        props?.onSuccess?.();
        message.success("Campos actualizados");
      })
      .catch((err) => {
        message.error(err?.message);
      })
      .finally(() => {
        updateLoading(false);
      });
  };

  return { loading, handleUpdateFields };
}
