import { AdminLayout } from "../shared";
import { Button, Flex } from "antd";
import { Link, Outlet } from "react-router-dom";
import VideosAndTutorialsLayout from "../modules/Corresponsales/ui/components/VideosAndTutorialsLayout";
import VideosAndTutorialsCategories from "../modules/Corresponsales/ui/VideosAndTutorialsCategories";

export default function CorresponsalesVideosAndTutorialsCategoriesPage() {
  return (
    <AdminLayout>
      <VideosAndTutorialsLayout>
        <Flex justify="flex-end">
          <Link to="/corresponsales/videos-y-tutoriales-categorias/agregar">
            <Button type="primary">+ Categoría</Button>
          </Link>
        </Flex>

        <VideosAndTutorialsCategories />
      </VideosAndTutorialsLayout>
      <Outlet />
    </AdminLayout>
  );
}
