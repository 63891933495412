import { Typography } from "antd";
import FormsBrandRegistration from "../modules/form-experience-registrational-services/ui/Forms";
import { AdminLayout } from "../shared";

export default function FormsExperienceRegistrationalServicesPage() {
  return (
    <AdminLayout>
      <div style={{ width: "100%" }}>
        <Typography.Title>Valoración de servicios registrales</Typography.Title>
        <FormsBrandRegistration />
      </div>
    </AdminLayout>
  );
}
