import { Typography } from "antd";
import Forms from "../modules/forms/ui/Forms";
import { AdminLayout } from "../shared";
import { Outlet } from "react-router-dom";

export default function FormsPage() {
  return (
    <AdminLayout>
      <div style={{ width: "100%" }}>
        <Typography.Title>Formularios</Typography.Title>
        <Forms />
        <Outlet />
      </div>
    </AdminLayout>
  );
}
