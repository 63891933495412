import { Drawer, Tabs } from "antd";
import styled from "styled-components";

//modal Events

export const WrapperModalkeyAccountManagers = styled(Drawer)``;


export const WrapperTabNavEvents = styled(Tabs)`
  width: 100%;
`;