import { message } from "antd";
import { useForm } from 'react-hook-form';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { changeModalStateCoursesCategories, createCourseCategories, refreshTableCoursesCategories, selectIsUpdateCoursesCategories, selectRefreshTableCoursesCategories, selectSingleCoursesCategories, setIsCoursesCategoriesForUpdate, updateCourseCategories } from "../../../../redux";
import { IModelCoursesCategories } from "../../@types";
import { validateCreateCoursesCategories, validateUpdateCoursesCategories } from "../../../../shared/utils/yupValidation";



export function useCoursesCategories() {
  const dispatch = useAppDispatch();

  const isUpdateCoursesCategories = useSelector(
      selectIsUpdateCoursesCategories
  );
  const dataUpdateCoursesCategories = useSelector(
      selectSingleCoursesCategories
  );
  const isRefreshTableCoursesCategories = useSelector(
      selectRefreshTableCoursesCategories
  );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalCoursesCategoriesStateForCreate = () => {
      dispatch(setIsCoursesCategoriesForUpdate(false))
      dispatch(changeModalStateCoursesCategories())
  }

  const formMethodsCreate = useForm<IModelCoursesCategories>({
    resolver: yupResolver(validateCreateCoursesCategories),
  });

  const formMethodsUpdate = useForm<IModelCoursesCategories>({
    resolver: yupResolver(validateUpdateCoursesCategories),
  });

  useEffect(() => {
  if (isUpdateCoursesCategories) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateCoursesCategories });
  }

  return () => {
      formMethodsUpdate.reset();
  };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateCoursesCategories, dataUpdateCoursesCategories]);

  const updateStatus = async(value: "active" | "inactive", id: any) =>{
    if (isLoading) return;
    setIsLoading(true);
    await updateCourseCategories({
      id,
      status: value,
    })
    .then((res: Partial<IModelCoursesCategories>) => {
      setIsLoading(false);
      if (res.id) {
        message.success(
          `Se ha actualizado con éxito el estado del curso con id:${res.id}`
        );
        dispatch(refreshTableCoursesCategories(true));
      } else {
        message.error(res as string);
      }
    })
    .catch((err: any) => {
      setIsLoading(false);
      console.log(err);
      message.error(err.message);
    });
  }

  const onSubmitCreateOrUpdate = async (data: IModelCoursesCategories) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateCoursesCategories) {
      await updateCourseCategories({
        id: data.id,
        name: data.name,
        status: data.status,
        position: data.position,
        path_image: data.path_image,
      })
        .then((res: Partial<IModelCoursesCategories>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito la categoría de curso con id:${res.id}`
            );
            dispatch(refreshTableCoursesCategories(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createCourseCategories({...data})
        .then((res: IModelCoursesCategories) => {
          if (res.id) {
            dispatch(changeModalStateCoursesCategories());
            message.success(
              `Se ha creado con éxito la categoría de curso con id:${res.id}`
            );
            dispatch(refreshTableCoursesCategories(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableCoursesCategories) {
      dispatch(refreshTableCoursesCategories(false));
    }
  }, [isRefreshTableCoursesCategories]);

  return{
      //state
      isLoading,
      isUpdateCoursesCategories,
      dataUpdateCoursesCategories,
      isRefreshTableCoursesCategories,
      //methods
      formMethodsCreate,
      formMethodsUpdate,
      //functions
      changeModalCoursesCategoriesStateForCreate,
      onSubmitCreateOrUpdate,
      updateStatus,
  }
}
