import type { ICorresponsalesVideosAndTutorialsCategories } from "../../../../../@types/dto/corresponsales-videos-and-tutorials";

import { Col, Row } from "antd";
import { useFormContext } from "react-hook-form";
import { InputText } from "../../../../../shared";

export default function VideosAndTutorialsCategoriesForm() {
  const { control } =
    useFormContext<ICorresponsalesVideosAndTutorialsCategories>();

  return (
    <Row gutter={[8, 16]}>
      <Col span={24}>
        <InputText
          labelText="Nombre"
          control={control}
          name="name"
          placeHolder="Nombre"
        />
      </Col>
    </Row>
  );
}
