
import { IModelProjectsBenefits } from '../../modules';
import { IProjectsBenefitsState } from '../@types/projectsBenefits';

import { projectsBenefitsActions } from '../action-types'

type projectsBenefitsActionType =
| { type: typeof projectsBenefitsActions.PROJECTS_BENEFITS_MODAL_STATE; }
| { type: typeof projectsBenefitsActions.GET_PROJECTS_BENEFITS_FOR_UPDATE; payload: IModelProjectsBenefits }
| { type: typeof projectsBenefitsActions.IS_PROJECTS_BENEFITS_FOR_UPDATE; payload: boolean }
| { type: typeof projectsBenefitsActions.SWITCH_PROJECTS_BENEFITS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IProjectsBenefitsState = {
    projectsBenefitsModalIsOpen: false,
    isUpdateProjectsBenefits: false,
    projectsBenefitsForUpdate: null,
    refreshTableProjectsBenefits: false,
}

export default function projectsBenefitsReducer(state: IProjectsBenefitsState = INITIAL_STATE, action: projectsBenefitsActionType): IProjectsBenefitsState {
  switch (action.type) {
    case projectsBenefitsActions.PROJECTS_BENEFITS_MODAL_STATE:
        return {
            ...state,
            projectsBenefitsModalIsOpen: !state.projectsBenefitsModalIsOpen,
        }
    case projectsBenefitsActions.GET_PROJECTS_BENEFITS_FOR_UPDATE:
        return {
            ...state,
            projectsBenefitsForUpdate: action.payload,
        }
    case projectsBenefitsActions.IS_PROJECTS_BENEFITS_FOR_UPDATE:
        return {
            ...state,
            isUpdateProjectsBenefits: action.payload,
        }
    case projectsBenefitsActions.SWITCH_PROJECTS_BENEFITS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableProjectsBenefits: action.payload,
        }
    default:
        return state
  }
}
