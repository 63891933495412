import { Typography } from "antd";

import { InfoCircleOutlined } from "@ant-design/icons";
import Widget, { IWidget } from "./widget";

const FIELDS: IWidget[] = [
  {
    name: "Nombre",
    description: "Nombre del estudiante",
    placeholder: "John",
    key_name: "user.first_name",
    ratio: 0.15,
    offsetWidth: 0,
    offsetHeight: 0,
  },
  {
    name: "Apellido",
    description: "Apellido del estudiante",
    key_name: "user.last_name",
    placeholder: "Doe",
    ratio: 0.15,
    offsetWidth: 0,
    offsetHeight: 0,
  },
  {
    name: "Nombre completo",
    description: "Nombre completo del estudiante",
    placeholder: "John Doe",
    key_name: "user.full_name",
    ratio: 0.15,
    offsetWidth: 0,
    offsetHeight: 0,
  },
  {
    name: "DNI",
    description: "Documento Nacional de Identidad",
    placeholder: "1234567890",
    key_name: "user.dni",
    ratio: 0.15,
    offsetWidth: 0,
    offsetHeight: 0,
  },
  {
    name: "Nombre del curso",
    description: "Nombre del curso",
    placeholder: "Administra tu empresa como un experto",
    key_name: "course.name",
    ratio: 0.15,
    offsetWidth: 0,
    offsetHeight: 0,
  },
  {
    name: "Fecha de asistencia",
    description: "Fecha de asistencia",
    placeholder: "2024-02-01",
    key_name: "course.attendance_date",
    ratio: 0.15,
    offsetWidth: 0,
    offsetHeight: 0,
  },
];

export default function Widgets() {
  return (
    <div style={{}}>
      <Typography.Title level={5}>Campos</Typography.Title>
      <div style={{ display: "flex" }}>
        <span
          style={{
            marginRight: 4,
            marginTop: 2,
          }}
        >
          <InfoCircleOutlined />
        </span>
        <p
          style={{
            color: "#666",
            fontSize: "0.9em",
          }}
        >
          Arrastra los campos a la plantilla para personalizarla. Puedes
          personalizar el tamaño y la posición de los campos.
        </p>
      </div>
      <ul
        style={{
          listStyle: "none",
          padding: 0,
          margin: 0,
          display: "flex",
          flexDirection: "column",
          gap: 8,
          marginTop: 6,
        }}
      >
        {FIELDS.map((field) => (
          <Widget key={field.key_name} field={field} />
        ))}
      </ul>
    </div>
  );
}
