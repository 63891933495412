// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { Select } from "antd";
import { Controller, FieldErrorsImpl } from "react-hook-form";
import { InputError } from "./styled";

const { Option } = Select;

interface Props {
  dataOptions: { value: string | number; title: string }[];
  width?: string;
  label: string;
  name: string;
  control: any;
  showSearch?: boolean;
  defaultValue?: string;
  isClearable?: boolean;
}

interface PropsContent {
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  name: string;
}

const ErrorComponent = ({ errors, name }: PropsContent) => {
  return (
    <>{!!errors[name] && <InputError>{errors?.[name]?.message}</InputError>}</>
  );
};

export const InputSelect = ({
  width = "100%",
  dataOptions,
  label,
  name,
  control,
  showSearch = false,
  defaultValue,
  isClearable = false,
}: Props) => {
  return (
    <Controller
      shouldUnregister
      control={control}
      name={name}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        return (
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <Select
              placeholder={label}
              value={value}
              style={{ width }}
              defaultValue={defaultValue}
              showSearch={showSearch}
              optionFilterProp="children"
              onChange={(e) => {
                onChange(e ?? null);
              }}
              allowClear={isClearable}
              onClear={() => onChange(null)}
            >
              {dataOptions.map((item, idx) => (
                <Option value={item?.value} key={idx + ""}>
                  {item?.title}
                </Option>
              ))}
            </Select>
            <ErrorComponent errors={errors} name={name} />
          </div>
        );
      }}
    />
  );
};
