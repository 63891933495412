import { IModelWebinars } from "../../modules/webinars/@types/IModelWebinars"

export const GET_WEBINARS_FOR_UPDATE = '[ WEBINARS ] Get Webinars  For Update'

export interface IGetWebinarsForUpdate {
    type: typeof GET_WEBINARS_FOR_UPDATE
    payload: IModelWebinars
}

export const IS_WEBINARS_FOR_UPDATE = '[ WEBINARS ] Is Webinars  For Update'

export interface IIsWebinarsForUpdate {
    type: typeof IS_WEBINARS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_WEBINARS_REFRESH_TABLE = '[ WEBINARS ] Switch Webinars  Refresh Table'

export interface ISwitchWebinarsRefreshTable {
    type: typeof SWITCH_WEBINARS_REFRESH_TABLE
    payload: boolean
}