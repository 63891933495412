import { message } from "antd";

import { webinarsCategoriesService } from "../../shared";
import { webinarsCategoriesActions } from "../action-types";
import { IModelWebinarsCategories } from "../../modules";

export const changeModalStateWebinarsCategories = (): webinarsCategoriesActions.IWebinarsCategoriesModalState =>({
    type: webinarsCategoriesActions.WEBINARS_CATEGORIES_MODAL_STATE
})

export const getSingleWebinarsCategories = (val:IModelWebinarsCategories ): webinarsCategoriesActions.IGetWebinarsCategoriesForUpdate=> ({
    type: webinarsCategoriesActions.GET_WEBINARS_CATEGORIES_FOR_UPDATE,
    payload: val
})

export const setIsWebinarsCategoriesForUpdate =(val: boolean): webinarsCategoriesActions.IIsWebinarsCategoriesForUpdate =>({
    type: webinarsCategoriesActions.IS_WEBINARS_CATEGORIES_FOR_UPDATE,
    payload: val,
})

export const fetchSingleWebinarsCategories = async(id: number) =>{
    return await webinarsCategoriesService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createWebinarCategories = async(data:Partial<IModelWebinarsCategories>) =>{
    return await webinarsCategoriesService.create(data)
    .then((res: IModelWebinarsCategories)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateWebinarCategories = async(data:Partial<IModelWebinarsCategories>) =>{
    return await webinarsCategoriesService.patch(data?.id!, data)
    .then((res: IModelWebinarsCategories)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteWebinarCategories = async(id: number) =>{
    return await webinarsCategoriesService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableWebinarsCategories = (val: boolean): webinarsCategoriesActions.ISwitchWebinarsCategoriesRefreshTable =>({
    type: webinarsCategoriesActions.SWITCH_WEBINARS_CATEGORIES_REFRESH_TABLE,
    payload: val
})

