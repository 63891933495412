import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { Link, useParams } from "react-router-dom";
import EditInfographicCategory from "../modules/infographic-categories/ui/edit-infographic-category";

export default function EditInfoGraphicCategoryPage() {
  const { recordId } = useParams();

  return (
    <Drawer
      open
      width={350}
      destroyOnClose
      title="Editar categoría de infografía"
      closeIcon={
        <Link to="/infographic-categories">
          <Button type="link" icon={<CloseOutlined />} />
        </Link>
      }
    >
      <EditInfographicCategory recordId={recordId ? Number(recordId) : null} />
    </Drawer>
  );
}
