import { TRootState } from '../@types/redux';

export const selectModalNews = (state: TRootState) => state.new.newsModalIsOpen


export const selectIsUpdateNews = (state: TRootState) => state.new.isUpdateNews


export const selectSingleNews = (state: TRootState) => state.new.newsForUpdate


export const selectRefreshTableNews = (state: TRootState) => state.new.refreshTableNews