import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { useWebinarsBenefits } from "../../hooks/webinars-benefits/useWebinarsBenefits";
import { selectModalWebinarsBenefits } from "../../../../redux";
import { ModalWebinarsBenefits } from "./ModalWebinarsBenefits";
import { TableWebinarsBenefits } from "./TableWebinarsBenefits";

export function WebinarsBenefits() {
  const modalWebinarsBenefits = useSelector(
    selectModalWebinarsBenefits
  );

  const {
    //state
    isUpdateWebinarsBenefits,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalWebinarsBenefitsStateForCreate,
  } = useWebinarsBenefits();

  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalWebinarsBenefitsStateForCreate}
            >
              Agregar nuevo beneficio a webinar
            </Button>
        </Col>
        <TableWebinarsBenefits />
        {
            modalWebinarsBenefits && (
              <FormProvider { ...isUpdateWebinarsBenefits ?{...formMethodsUpdate} : {...formMethodsCreate}}>
                <ModalWebinarsBenefits />
              </FormProvider>
            )
        }
    </Row>
  )
}
