import { IModelNewsCategories } from "../../modules/news-categories/@types"

export const NEWS_CATEGORIES_MODAL_STATE = '[ NEWS CATEGORIES ] News Categories Modal State'

export interface INewsCategoriesModalState {
    type: typeof NEWS_CATEGORIES_MODAL_STATE
}

export const GET_NEWS_CATEGORIES_FOR_UPDATE = '[ NEWS CATEGORIES ] Get News Categories For Update'

export interface IGetNewsCategoriesForUpdate {
    type: typeof GET_NEWS_CATEGORIES_FOR_UPDATE
    payload: IModelNewsCategories
}

export const IS_NEWS_CATEGORIES_FOR_UPDATE = '[ NEWS CATEGORIES ] Is News Categories For Update'

export interface IIsNewsCategoriesForUpdate {
    type: typeof IS_NEWS_CATEGORIES_FOR_UPDATE
    payload: boolean
}

export const SWITCH_NEWS_CATEGORIES_REFRESH_TABLE = '[ NEWS CATEGORIES ] Switch News Categories Refresh Table'

export interface ISwitchNewsCategoriesRefreshTable {
    type: typeof SWITCH_NEWS_CATEGORIES_REFRESH_TABLE
    payload: boolean
}