import { AdminLayout } from "../shared";
import { Button, Flex } from "antd";
import { Link, Outlet } from "react-router-dom";
import MarketingMaterial from "../modules/Corresponsales/ui/MarketingMaterial";
import MarketingMaterialLayout from "../modules/Corresponsales/ui/components/MarketingMaterialLayout";

export default function CorresponsalesMarketingMaterial() {
  return (
    <AdminLayout>
      <MarketingMaterialLayout>
        <div style={{ width: "100%" }}>
          <Flex justify="flex-end">
            <Link to="/corresponsales/material-de-marketing/agregar">
              <Button type="primary">+ Material de marketing</Button>
            </Link>
          </Flex>

          <MarketingMaterial />
        </div>
      </MarketingMaterialLayout>
      <Outlet />
    </AdminLayout>
  );
}
