import { Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { changeModalStateAuthors, deleteAuthors, fetchSingleAuthors, getSingleAuthors, refreshTableAuthors, setIsAuthorsForUpdate } from "../../../redux/actions/authors.actions";

const { confirm } = Modal;

export function useTableAuthors() {
  const dispatch = useAppDispatch();

  const changeAuthorsForUpdate = (valId: number) => {
    fetchSingleAuthors(valId)
    .then((res)=>{
      dispatch(getSingleAuthors(res.data[0]));
      dispatch(setIsAuthorsForUpdate(true));
      dispatch(changeModalStateAuthors());
    })
  }

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar el o la autor/a de noticias con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar autor/a" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar autor/a",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        return await deleteAuthors(valId)
          .then((res) => {
            dispatch(refreshTableAuthors(true));
            if(res.name){
              return message.success(`Se ha borrado con éxito el o la autor/a con id:${valId}`);
            }else if(res){
              return message.error(`${res}`);
            }
          })
          .catch((err: any) => {
            console.log({ err });
            return message.error(err.message);
          });
      },
      onCancel() {},
    });
  };
  return{
    //state
    //methods
    //functions
    changeAuthorsForUpdate,
    showDeleteConfirm,
  }
}
