
import { IModelEventTags } from '../../modules';
import { IEventTagsState } from '../@types/eventTags';

import { eventTagsActions } from '../action-types'

type eventTagsActionType =
| { type: typeof eventTagsActions.EVENT_TAGS_MODAL_STATE; }
| { type: typeof eventTagsActions.GET_EVENT_TAGS_FOR_UPDATE; payload: IModelEventTags }
| { type: typeof eventTagsActions.IS_EVENT_TAGS_FOR_UPDATE; payload: boolean }
| { type: typeof eventTagsActions.SWITCH_EVENT_TAGS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IEventTagsState = {
    eventTagsModalIsOpen: false,
    isUpdateEventTags: false,
    eventTagsForUpdate: null,
    refreshTableEventTags: false,
}

export default function eventTagsReducer(state: IEventTagsState = INITIAL_STATE, action: eventTagsActionType): IEventTagsState {
  switch (action.type) {
    case eventTagsActions.EVENT_TAGS_MODAL_STATE:
        return {
            ...state,
            eventTagsModalIsOpen: !state.eventTagsModalIsOpen,
        }
    case eventTagsActions.GET_EVENT_TAGS_FOR_UPDATE:
        return {
            ...state,
            eventTagsForUpdate: action.payload,
        }
    case eventTagsActions.IS_EVENT_TAGS_FOR_UPDATE:
        return {
            ...state,
            isUpdateEventTags: action.payload,
        }
    case eventTagsActions.SWITCH_EVENT_TAGS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableEventTags: action.payload,
        }
    default:
        return state
  }
}
