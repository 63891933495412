import { useForm } from "react-hook-form"
import { IBannersFind } from "../../../redux/@types"
import { IModelBanners } from "../@types"
import React from "react"
import { bannersService } from "../../../shared"
import { message } from "antd"

export default function useUpdateBanner(BannerId?: number | null, props?: { onSuccess?: (res: IModelBanners) => void }) {
    const [loading, setLoading] = React.useState(false)

    const formMethods = useForm<IModelBanners>()

    const loadingRef = React.useRef(false)

    const updateLoading = (status: boolean) => {
        loadingRef.current = status
        setLoading(status)
    }

    const getBannerData = async () => {
        if (loadingRef.current || !BannerId) return

        updateLoading(true)

        await bannersService.get(BannerId)
            .then(res => res as IModelBanners)
            .then(res => {
                formMethods.reset(res)
            })
            .finally(() => {
                updateLoading(false)
            })
    }

    const handleSubmit = async (data: IModelBanners) => {
        if (loadingRef.current || !BannerId) return

        updateLoading(true)

        await bannersService.patch(BannerId, data)
            .then(res => {
                message.success('Banner actualizado')
                // props?.onSuccess?.(res)
            })
            .catch(err => {
                message.error(err?.message)
            })
            .finally(() => {
                updateLoading(false)
            })
    }

    React.useEffect(() => {
        if (BannerId) {
            getBannerData()
        }
    }, [BannerId])

    return {
        formMethods,
        loading,
        handleSubmit: formMethods.handleSubmit(handleSubmit)
    }
}