 
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { IModelNewsCategories } from "../@types"
import { TableCustom, newsCategoriesService } from "../../../shared"
import { Button, Col } from "antd";
import { useNewsCategories, useTableNewsCategories } from "../hooks";
import { TableColumns, TableProperties } from "../../../shared/components/ui/table/types";
import React from "react";

export function TableNewsCategories() {
    const {
        //state
        //methods
        //functions
        changeNewsCategoriesForUpdate,
        showDeleteConfirm,
      } =  useTableNewsCategories()
      const {
        //state
        isRefreshTableNewsCategories,
        //methods
        //functions
    }= useNewsCategories()

    const tableRef = React.useRef<TableProperties>()

    React.useEffect(() => {
      tableRef.current?.refresh()
    }, [isRefreshTableNewsCategories])

    const columns: TableColumns<IModelNewsCategories> = [
        {
            title: 'ID',
            dataIndex: 'id',
            align: 'left',
            sorter:true
        },
        {
            title: 'Nombre',
            dataIndex: 'name',
            align: 'left',
        },
        // {
        //     title: 'Categoría padre',
        //     dataIndex: 'parent_id',
        //     align: 'left',
        // },
        {
            title: "Acciones",
            key: "operation",
            fixed: "right",
            width: 140,
            render: (_:any, item:any) => {
              return (
                <Col
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    flexDirection: "row",
                  }}
                >
                  <Button
                    type="primary"
                    shape="circle"
                    onClick={() => changeNewsCategoriesForUpdate(item.id)}
                    icon={<EditOutlined />}
                    size="large"
                  />
                  <Button
                    type="primary"
                    danger
                    onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
                    shape="circle"
                    icon={<DeleteOutlined />}
                    size="large"
                  />
                </Col>
              );
            },
          },
    ]

  return (
    <TableCustom columns={columns}  service={newsCategoriesService} ref={tableRef}/>
  )
}
