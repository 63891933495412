import { Button, Col, Row } from "antd";
import { FormProvider } from "react-hook-form";
import { UsersForm } from "./UserForm";
import Title from "antd/es/typography/Title";
import useCreateUser from "../../hooks/useCreateUser";
import { useNavigate } from "react-router-dom";
import { UsersFormCreate } from "./UserFormCreate";

export default function CreateUser() {
    const navigate = useNavigate()

    const {formMethods, loading, handleSubmit} = useCreateUser({
        onSuccess(res) {
            navigate(`/users`)
        },
    })

    return (
        <div style={{width: '100%'}}>
            <div>
                <Title style={{ marginBottom: "1.5px" }} level={2}>
                  Crear usuario
                </Title>
            </div>
            <Col span={24}>
                <FormProvider {...formMethods}>
                    <form onSubmit={handleSubmit}>
                        <UsersFormCreate />
                        <div>
                            <Button
                                style={{
                                width: "100%",
                                marginTop: 10,
                                }}
                                type="primary"
                                shape="round"
                                size="large"
                                htmlType="submit"
                                loading={loading}
                            >
                                Crear
                            </Button>
                        </div>
                    </form>
                </FormProvider>
            </Col>
        </div>
    )
}