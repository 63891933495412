import { Sheduling } from "../modules/scheduling/ui";
import { AdminLayout } from "../shared";

export function ShedulingPage() {
  return (
    <AdminLayout>
      <Sheduling />
    </AdminLayout>
  )
}
