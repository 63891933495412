import { IModelWebinarsCategories } from "../../modules"


export const WEBINARS_CATEGORIES_MODAL_STATE = '[ WEBINARS CATEGORIES ] Webinars Categories Modal State'

export interface IWebinarsCategoriesModalState {
    type: typeof WEBINARS_CATEGORIES_MODAL_STATE
}

export const GET_WEBINARS_CATEGORIES_FOR_UPDATE = '[ WEBINARS CATEGORIES ] Get Webinars Categories For Update'

export interface IGetWebinarsCategoriesForUpdate {
    type: typeof GET_WEBINARS_CATEGORIES_FOR_UPDATE
    payload: IModelWebinarsCategories
}

export const IS_WEBINARS_CATEGORIES_FOR_UPDATE = '[ WEBINARS CATEGORIES ] Is Webinars Categories For Update'

export interface IIsWebinarsCategoriesForUpdate {
    type: typeof IS_WEBINARS_CATEGORIES_FOR_UPDATE
    payload: boolean
}

export const SWITCH_WEBINARS_CATEGORIES_REFRESH_TABLE = '[ WEBINARS CATEGORIES ] Switch Webinars Categories Refresh Table'

export interface ISwitchWebinarsCategoriesRefreshTable {
    type: typeof SWITCH_WEBINARS_CATEGORIES_REFRESH_TABLE
    payload: boolean
}