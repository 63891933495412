import React from "react";

import type { IHomePopups } from "../../../@types/dto/home-popups";

import { homePopupsService } from "../../../shared";
import { message } from "antd";
import confirm from "antd/es/modal/confirm";

export default function useDeletePopup(props?: {
  onSuccess?: (record: IHomePopups) => void;
}) {
  const [loading, setLoading] = React.useState(false);

  const loadingRef = React.useRef(false);

  const updateLoading = (status: boolean) => {
    setLoading(status);
    loadingRef.current = status;
  };

  const handleDelete = async (recordId: number) => {
    if (loadingRef.current) return;

    confirm({
      title: "¿Está seguro que desea eliminar este registro?",
      content: "Esta acción no se podrá deshacer",
      okText: "Si, eliminar",
      cancelText: "Cancelar",
      type: "warning",
      centered: true,
      okCancel: true,
      okButtonProps: {
        danger: true,
      },
      onOk: async () => {
        updateLoading(true);

        await homePopupsService
          .remove(recordId)
          .then((res) => {
            props?.onSuccess?.(res as IHomePopups);
            message.success("Registro eliminado correctamente.");
          })
          .catch((err) => {
            message.error(err?.message);
          })
          .finally(() => {
            updateLoading(false);
          });
      },
    });
  };

  return {
    loading,
    handleDelete,
  };
}
