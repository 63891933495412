import * as yup from 'yup'

export const validateUpdateProjects = yup.object().shape({
    name:yup
    .string()
    .typeError('Campo requerido.')
    .min(2, 'El nombre debe tener mínimo de 2 caracteres.'),
    // image_path_cover:yup
    // .string()
    // .typeError('Campo requerido.')
    // .min(1, 'Debes de incluir mínimo una imagen.'),
    short_description: yup
    .string()
    .typeError('Campo requerido.')
    .min(2, 'La descripción corta debe tener mínimo de 2 caracteres.'),
    status: yup
    .string()
    .typeError('Campo requerido.')
    .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones.'),
})