import React from "react";
import { IForm } from "../../../@types/dto/forms";
import api from "../api";
import { message } from "antd";

export default function useForm(recordId?: number | null) {
  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState<IForm | null>(null);

  const getForm = async () => {
    if (!recordId || loading) return;

    setLoading(true);

    await api.forms
      .get(recordId)
      .then(setForm)
      .catch((err) => {
        message.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const parseData = (data: string) => {
    try {
      return JSON.parse(data);
    } catch {
      return {};
    }
  };

  React.useEffect(() => {
    if (recordId) getForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId]);

  return {
    form,
    loading,
    parseData,
  };
}
