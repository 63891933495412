import { Button, Col, Row } from "antd"
import { PlusOutlined } from "@ant-design/icons";
import { useEvents } from "../../hooks";
import { TableEvents } from "./TableEvents";


export function Events() {
    const { changeNavigateForCreateEvent } = useEvents()

    return (
    <Row
    gutter={[8, 8]}
    style={{
    padding: "10px",
    width: "100%",
    }}
    >
        <Col
            span={24}
            style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            }}
        >
            <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
            onClick={changeNavigateForCreateEvent}
            >
            Agregar nuevo evento
            </Button>
        </Col>
        <TableEvents />
    </Row>
    )
}
