import { boolean } from "yup";
import { ICompaniesState } from "../@types";
import { CompaniesActions } from "../action-types";

type CompaniesActionType =
| { type: typeof CompaniesActions.COMPANIES_CHANGE_MODAL_STATE }
| { type: typeof CompaniesActions.GET_COMPANIES_FOR_UPDATE; payload: any }
| { type: typeof CompaniesActions.IS_COMPANIES_FOR_UPDATE; payload: boolean }
| { type: typeof CompaniesActions.SWITCH_COMPANIES_REFRESH_TABLE; payload: boolean }
| { type: typeof CompaniesActions.SWITCH_COMPANIES_PRODUCTS_REFRESH_TABLE; payload: boolean }

const INITIAL_STATE: ICompaniesState = {
    CompaniesForUpdate: undefined,
    selectSingleCompanies: false,
    isUpdateCompanies: false,
    CompaniesModalIsOpen: false,
    refreshTableCompanies: false,
    refreshTableCompaniesProducts: false,
}

export default function CompaniesReducer(state: ICompaniesState = INITIAL_STATE, action: CompaniesActionType): ICompaniesState {
    switch ( action.type ) {
        case CompaniesActions.COMPANIES_CHANGE_MODAL_STATE:
            return{
                ...state,
                CompaniesModalIsOpen: !state.CompaniesModalIsOpen
            }
        case CompaniesActions.GET_COMPANIES_FOR_UPDATE:
            return{
                ...state,
                CompaniesForUpdate: action.payload
            }
        case CompaniesActions.IS_COMPANIES_FOR_UPDATE:
            return{
                ...state,
                isUpdateCompanies: action.payload
            }
        case CompaniesActions.SWITCH_COMPANIES_REFRESH_TABLE:
            return{
                ...state,
                refreshTableCompanies: action.payload
            }
        case CompaniesActions.SWITCH_COMPANIES_PRODUCTS_REFRESH_TABLE:
            return{
                ...state,
                refreshTableCompaniesProducts: action.payload
            }
        default:
            return state
    }
}