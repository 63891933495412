import { IModelEventTags } from "../../modules"


export const EVENT_TAGS_MODAL_STATE = '[ EVENT TAGS ] Event Tags Modal State'

export interface IEventTagsModalState {
    type: typeof EVENT_TAGS_MODAL_STATE
}

export const GET_EVENT_TAGS_FOR_UPDATE = '[ EVENT TAGS ] Get Event Tags For Update'

export interface IGetEventTagsForUpdate {
    type: typeof GET_EVENT_TAGS_FOR_UPDATE
    payload: IModelEventTags
}

export const IS_EVENT_TAGS_FOR_UPDATE = '[ EVENT TAGS ] Is Event Tags For Update'

export interface IIsEventTagsForUpdate {
    type: typeof IS_EVENT_TAGS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_EVENT_TAGS_REFRESH_TABLE = '[ EVENT TAGS ] Switch Event Tags Refresh Table'

export interface ISwitchEventTagsRefreshTable {
    type: typeof SWITCH_EVENT_TAGS_REFRESH_TABLE
    payload: boolean
}