import * as yup from 'yup'

export const validateUpdateCoursesCategories = yup.object().shape({
    name:yup
    .string()
    .min(2, 'El NOMBRE debe tener mínimo de 2 caracteres.'),
    status: yup
    .string()
    .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones.'),
    position:yup
    .number(),
    // path_image:yup
    // .string()
    // .min(1, 'Debes de incluir mínimo una imagen.'),
})