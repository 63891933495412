import React from "react";

export default function useResizable({
  onChange,
}: {
  onChange: (size: { width: number; height: number }) => void;
}) {
  const [sizes, setSizes] = React.useState({
    width: 0,
    height: 0,
  });

  const resizableContainerRef = React.useRef<HTMLDivElement | null>(null);

  const initResize = () => {
    if (resizableContainerRef.current) {
      const rect = resizableContainerRef.current.getBoundingClientRect();

      setSizes({ width: rect.width, height: rect.height });
    }
  };

  const onResize = (
    e: any,
    { size }: { size: { width: number; height: number } }
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (!resizableContainerRef.current) return;

    const parent =
      resizableContainerRef.current.parentElement!.getBoundingClientRect();

    const width = Math.min(100, (size.width / parent.width) * 100);
    const height = Math.min(100, (size.height / parent.height) * 100);

    setSizes({ width: size.width, height: size.height });

    resizableContainerRef.current.style.width = `${width}%`;
    resizableContainerRef.current.style.height = `${height}%`;
  };

  const onResizeStop = () => {
    if (!resizableContainerRef.current) return;

    const width = Number(
      resizableContainerRef.current.style.width.replace("%", "")
    );
    const height = Number(
      resizableContainerRef.current.style.height.replace("%", "")
    );

    onChange({
      width,
      height,
    });
  };

  React.useEffect(() => {
    if (resizableContainerRef.current) initResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resizableContainerRef.current]);

  return {
    sizes,
    resizableContainerRef,
    onResize,
    onResizeStop,
  };
}
