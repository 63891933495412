import { TRootState } from '../@types/redux';

export const selectModalCompanies= ( state: TRootState) =>state.companies.CompaniesModalIsOpen

export const selectIsUpdateCompanies= ( state: TRootState) =>state.companies.isUpdateCompanies

export const selectCompaniesForUpdate= ( state: TRootState) =>state.companies.CompaniesForUpdate

export const selectCompaniesRefreshTable= ( state: TRootState) =>state.companies.refreshTableCompanies

export const selectCompaniesProductsRefreshTable= ( state: TRootState) =>state.companies.refreshTableCompaniesProducts

export const selectSingleCompanies= ( state: TRootState) =>state.companies.selectSingleCompanies

export const selectRefreshTableCompanies= ( state: TRootState) =>state.companies.refreshTableCompanies


