import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { selectUser, uiChangeCollapsedState } from "../../../../redux";
import { authUserLogout } from "../../../../redux/actions/auth.actions";

export const useNavBar = () => {
    const dispatch = useAppDispatch();
    const user = useSelector(selectUser);
    const navigate = useNavigate();

    const logout =() =>{
        localStorage.removeItem('jwt')
        localStorage.removeItem('user')
        dispatch(authUserLogout())
        navigate('/login')
    }

    const changeCollapsed =()=>{
      dispatch(uiChangeCollapsedState())
    }

  return {
    //state
    user,
    //methods
    //functions
    logout,
    changeCollapsed,
  }
}
