import { Button, Drawer } from "antd";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { VirtualLibraryCategoryNew } from "../modules/virtual-library-categories/ui/resource/keyAccountManagerNew";

export function VirtualLibraryCategoryNewPage() {
  return (
    <Drawer
      title="Crear Categoría"
      open
      width={350}
      destroyOnClose
      closeIcon={
        <Link to="/virtual-library-categories">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <VirtualLibraryCategoryNew />
    </Drawer>
  )
}
