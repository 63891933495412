
import { Dashboard } from '../modules'
import { AdminLayout } from '../shared'

export function DashboardPage () {
  return (
    <AdminLayout>
        <Dashboard/>
    </AdminLayout>
  )
}
