import { Button, Col, Row, Spin } from "antd";
import { FormProvider } from 'react-hook-form';
import { SaveOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { CompaniesForm } from "./CompaniesForm";
import UsecompaniesUserNew from "../../hooks/companies/usecompaniesUserNew";


export function CompaniesUserNew() {
  const navigate = useNavigate();
  const {companyId} = useParams();
  const { formMethods, handleSubmit, loading } = UsecompaniesUserNew(
    {
      onSuccess() {
        navigate(`/company/${companyId}/users`);
      },
    }
  );

  return (
    <>
      <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit}>
              <Row gutter={[8, 16]} justify={"space-between"}>
              <Col xs={24}>
                <CompaniesForm />
              </Col>
                <Col xs={24}>
                  <Button
                    icon={<SaveOutlined />}
                    block
                    loading={loading}
                    htmlType="submit"
                    type="primary"
                  >
                    Guardar
                  </Button>
                </Col>
              </Row>
            </form>
          </FormProvider>
  </>
  )
}
