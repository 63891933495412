import moment from "moment";
import { Button, Space, theme } from "antd";

import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { useCourses, useTableCourses } from "../../hooks";
import { IModelCourses } from "../../@types";
import { TableCustom, coursesService } from "../../../../shared";
import {
  TableColumns,
  TableProperties,
} from "../../../../shared/components/ui/table/types";
import React from "react";
import { useNavigate } from "react-router-dom";

const ASSISTANCE_TYPES = {
  on_place: "Presencial",
  hybrid: "Híbrido",
  virtual: "Virtual",
  remote: "Remoto",
};

export function TableCourses() {
  const {
    //state
    //methods
    //functions
    changeCoursesForUpdate,
    showDeleteConfirm,
  } = useTableCourses();

  const {
    //state
    isRefreshTableCourses,
    isLoading,
    //methods
    //functions
    updateStatus,
  } = useCourses();

  const tableRef = React.useRef<TableProperties>();

  const themeToken = theme.useToken();

  React.useEffect(() => {
    tableRef.current?.refresh();
  }, [isRefreshTableCourses]);

  const navigate = useNavigate();

  const columns: TableColumns<IModelCourses> = [
    {
      title: "ID",
      dataIndex: "id",
      align: "left",
      sorter: true,
    },
    {
      title: "Nombre",

      dataIndex: "name",
      align: "left",
      isLoading,
    },

    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_: any, item: any) => {
        return (
          <Button
            type="primary"
            danger={item.status === "inactive"}
            shape="round"
            style={
              item.status === "active"
                ? { backgroundColor: themeToken.token.colorSuccess }
                : {}
            }
            onClick={() => {
              if (item.status === "active") {
                updateStatus("inactive", item.id);
              } else if (item.status === "inactive") {
                updateStatus("active", item.id);
              }
            }}
          >
            {item.status === "active" ? "Activo" : "Inactivo"}
          </Button>
        );
      },
    },
    {
      title: "Tipo de asistencia",
      dataIndex: "assistance_type",
      align: "left",
      render: (_: any, item: any) => {
        return (
          <b>
            {
              ASSISTANCE_TYPES[
                item.assistance_type as unknown as keyof typeof ASSISTANCE_TYPES
              ]
            }
          </b>
        );
      },
    },
    {
      title: "Capacidad",
      dataIndex: "max_capacity",
    },
    { title: "Nro de inscripciones", dataIndex: "total_inscriptions" },
    {
      title: "Fecha de inicio",

      dataIndex: "start_date",
      align: "left",
      render: (_: any, item: any) => (
        <b
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
            flexDirection: "row",
          }}
        >
          {moment(item.start_date).utcOffset(0).format("DD/MM/YYYY HH:mm:ss")}
        </b>
      ),
    },
    {
      title: "Posición",
      dataIndex: "position",
      align: "left",
      sorter: true,
    },
    {
      title: "Ubicación del lugar",

      dataIndex: "place_location",
      align: "left",
    },

    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 25,
      render: (_: any, item: any) => {
        return (
          <Space>
            <Button
              type="primary"
              shape="circle"
              onClick={() => navigate(`/user-course/${item.id}`)}
              icon={<EyeOutlined />}
              size="large"
            />
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeCoursesForUpdate(item.id)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Space>
        );
      },
    },
  ];

  return (
    <TableCustom columns={columns} service={coursesService} ref={tableRef} />
  );
}
