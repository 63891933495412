import { Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { deleteNew, fetchSingleNews, getSingleNews, refreshTableNews, setIsNewsForUpdate } from "../../../redux";

const { confirm } = Modal;

export function useTableNews() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const changeNewsForUpdate = (valId: number) => {
        fetchSingleNews(valId)
        .then((res)=>{
            dispatch(getSingleNews(res.data[0]));
            dispatch(setIsNewsForUpdate(true));
            navigate(`/news/${res.data[0].id}`);
        })
    }

    const showDeleteConfirm = (valId: number, val: string) => {
        confirm({
          title: `Estás seguro de borrar la noticia  con el id: ${valId}`,
          icon: <ExclamationCircleOutlined />,
          content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar noticia" en caso tal solo oprime en "Cancelar".`,
          okText: "Borrar noticia",
          okType: "danger",
          cancelText: "Cancelar",
          keyboard: true,
          maskClosable: true,
          async onOk() {
            return await deleteNew(valId)
            .then((res) => {
                dispatch(refreshTableNews(true));
                if(res.name){
                    return message.success(`Se ha borrado con éxito la noticia con id:${valId}`);
                }else if(res){
                    console.log({res})
                    return message.error(`${res}`);
                }
            })
            .catch((err: any) => {
                console.log({ err });
                return message.error(err.message);
            });
        },
            onCancel() {},
        });
    };

  return{
    //state
    //methods
    //functions
    changeNewsForUpdate,
    showDeleteConfirm,
  }
}
