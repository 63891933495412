import { Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { changeModalStateWebinarsIncludes, deleteWebinarIncludes, fetchSingleWebinarsIncludes, getSingleWebinarsIncludes, refreshTableWebinarsIncludes, setIsWebinarsIncludesForUpdate } from "../../../../redux";

const { confirm } = Modal;

export function useTableWebinarsIncludes() {
  const dispatch = useAppDispatch();

  const changeWebinarsIncludesForUpdate = (valId: number) => {
    fetchSingleWebinarsIncludes(valId)
    .then((res)=>{
    dispatch(getSingleWebinarsIncludes(res.data[0]));
    dispatch(setIsWebinarsIncludesForUpdate(true));
    dispatch(changeModalStateWebinarsIncludes());
    })
  }

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar la inclusion de webinar con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar inclusion" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar inclusion",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        return await deleteWebinarIncludes(valId)
          .then((res) => {
            dispatch(refreshTableWebinarsIncludes(true));
            if(res.name){
              return message.success(`Se ha borrado con éxito la inclusion con id:${valId}`);
            }else if(res){
              console.log({res})
              return message.error(`${res}`);
            }
          })
          .catch((err: any) => {
            console.log({ err });
            return message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return{
    //state
    //methods
    //functions
    changeWebinarsIncludesForUpdate,
    showDeleteConfirm,
  }
}
