import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import { Button, Col, Typography, Avatar } from "antd";
import { UseCompanies, useTableCompanies } from "../../hooks";
import { TableCustom, CompaniesService } from "../../../../shared";
import { PATH_S3_BUCKET } from "../../../../shared/utils/constants";
import { IModelCompanies } from "../../@types";
import {
  TableColumns,
  TableProperties,
} from "../../../../shared/components/ui/table/types";
import React from "react";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

export function TableCompanies() {
  const {
    //state
    //methods
    //functions
    changeCompaniesForUpdate,
    showDeleteConfirm,
  } = useTableCompanies();

  const {
    //state
    isRefreshTableCompanies,
    isLoading,
    updateStatus,
  } = UseCompanies();

  const tableRef = React.useRef<TableProperties>();

  React.useEffect(() => {
    tableRef.current?.refresh();
  }, [isRefreshTableCompanies]);

  const navigate = useNavigate();

  const columns: TableColumns<any> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: "left",
      sorter: true,
    },
    {
      title: "DNI",
      dataIndex: "dni",
      key: "dni",
      align: "left",
      filterType: "text",
    },
    {
      title: "Matricula",
      dataIndex: "MATRICULA",
      key: "MATRICULA",
      filterType: "text",

      align: "left",
    },
    {
      title: "Nombre Legal",
      dataIndex: "legal_name",
      key: "legal_name",
      align: "left",
      filterType: "text",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "left",
      render: (_: any, item: any) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            padding: "5px 10px",
            justifyContent: "start",
            flexDirection: "row",
          }}
        >
          <button
            disabled={isLoading}
            onClick={() => {
              if (item.status === "active") {
                updateStatus("inactive", item.id);
              } else if (item.status === "inactive") {
                updateStatus("active", item.id);
              }
            }}
            style={{
              display: "flex",
              padding: "5px 10px", // Adjust the padding here
              borderWidth: 0,
              cursor: "pointer",
              borderRadius: "20px",
              backgroundColor: item.status === "active" ? "#4bd542" : "#ff4d4f",
            }}
          >
            <Title
              style={{
                marginBottom: "1.5px",
                color: "white",
                padding: "10px 15px",
                fontSize: "10px", // Adjust the font size here
              }}
              level={5}
            >
              {item.status}
            </Title>
          </button>
        </Col>
      ),
    },

    {
      title: "Corresponsal",
      dataIndex: "is_corresponsal",
      key: "is_corresponsal",

      align: "left",
    },
    {
      title: "Afiliado",
      dataIndex: "is_affiliate",
      key: "is_affiliate",

      align: "left",
    },
    // {
    //   title: 'Affiliate Renovation Date',
    //   dataIndex: 'affilliate_renovation_date',
    //   key: 'affilliate_renovation_date',
    //
    //   align: 'left',
    // },
    // {
    //   title: 'Key Account Manager ID',
    //   dataIndex: 'key_account_manager_id',
    //   key: 'key_account_manager_id',
    //
    //   align: 'left',
    // },
    {
      title: "Actions",
      key: "operation",
      fixed: "right",

      render: (_: any, item: any) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            flexDirection: "row",
            gap: 4,
          }}
        >
          <Button
            type="primary"
            shape="circle"
            onClick={() => navigate(`/companies/${item.id}`)}
            icon={<EditOutlined />}
            size="large"
          />
          <Button
            type="primary"
            shape="circle"
            onClick={() => navigate(`/company/${item.id}/users`)}
            icon={<EyeOutlined />}
            size="large"
          />
        </Col>
      ),
    },
  ];

  return (
    <TableCustom columns={columns} service={CompaniesService} ref={tableRef} />
  );
}
