import { Spin } from "antd";
import { WrapperTabNavProjects } from "../styled";
import { useProjects, useProjectsById } from "../../hooks";
import { FormProvider } from 'react-hook-form';
import { ProjectsForm } from "./ProjectsForm";
// import { ProjectAuthorsFrom } from "../projects-authors";
import { ProjectsBenefits } from "../projects-benefits";
// import { ProjectsIncludes } from "../projects-includes";
import { ProjectsRequirements } from "../projects-requirements";
import { ProjectsComponents } from "../projects-components";
import { ProjectsPartners } from "../projects-partners";
import { ProjectsObjectives } from "../projects-objectives";
import { ProjectsManagers } from "../projects-managers";
import Clusters from "./Clusters";
import { useParams } from "react-router-dom";

export function ProjectsById() {
  const { isUpdateProjects, formMethodsCreate, formMethodsUpdate } =
    useProjects();
  const { isValidate } = useProjectsById();

  const { id: projectId } = useParams();

  return (
    <div style={{ width: "100%" }}>
      {!isValidate && <Spin size="large" />}
      {isValidate &&(
        <>
          {!isUpdateProjects && (
            <FormProvider {...formMethodsCreate}>
              <ProjectsForm />
            </FormProvider>
          )}
          {isUpdateProjects && (
            <WrapperTabNavProjects
              items={[
                {
                  label: `DATOS DEL PROYECTO`,
                  key: "1",
                  children: (
                    <FormProvider {...formMethodsUpdate}>
                      <ProjectsForm />
                    </FormProvider>
                  ),
                },
                {
                  label: `CLUSTERS DEL PROYECTO`,
                  key: "2",
                  children: (
                    <Clusters projectId={projectId ? +projectId : undefined} />
                  ),
                },
                {
                  label: `BENEFICIOS DEL PROYECTO`,
                  key: "3",
                  children: (
                    <ProjectsBenefits />
                  ),
                },
                {
                  label: `COMPONENTES DEL PROYECTO`,
                  key: "4",
                  children: (
                    <ProjectsComponents />
                  ),
                },
                {
                  label: `DIRECTORES DE PROYECTO`,
                  key: "5",
                  children: (
                    <ProjectsManagers />
                  ),
                },
                {
                  label: `REQUERIMIENTOS DEL PROYECTO`,
                  key: "6",
                  children: (
                    <ProjectsRequirements />
                  ),
                },
                {
                  label: `OBJETIVOS DEL PROYECTO`,
                  key: "7",
                  children: (
                    <ProjectsObjectives />
                  ),
                },
                {
                  label: `COLABORADORES DEL PROYECTO`,
                  key: "8",
                  children: (
                    <ProjectsPartners />
                  ),
                }
              ]}
            />
          )}
      </>
    )}
  </div>
  )
}
