import { AdminLayout } from "../shared";
import { Infographies } from "../modules/virtual-library";

export const InfographiesManagersPage = () => {
  return (
    <AdminLayout>
      <Infographies />
    </AdminLayout>
  );
};
