import { useSelector } from "react-redux";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useEffect, useState } from "react";
import { message } from "antd";
import { changeModalStateProjectsRequirements, createProjectRequirements, refreshTableProjectsRequirements, selectIsUpdateProjectsRequirements, selectRefreshTableProjectsRequirements, selectSingleProjects, selectSingleProjectsRequirements, setIsProjectsRequirementsForUpdate, updateProjectRequirements } from "../../../../redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { IModelProjectsRequirements } from "../../@types";
import { useForm } from "react-hook-form";
import { validateCreateProjectsRequirements, validateUpdateProjectsRequirements } from "../../../../shared/utils/yupValidation";

export function useProjectsRequirements() {
  const dispatch = useAppDispatch();

  const isUpdateProjectsRequirements = useSelector(
      selectIsUpdateProjectsRequirements
  );

  const dataUpdateProjectsRequirements = useSelector(
      selectSingleProjectsRequirements
  );

  const isRefreshTableProjectsRequirements = useSelector(
      selectRefreshTableProjectsRequirements
  );

  const dataUpdateProjects = useSelector( selectSingleProjects );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalProjectsRequirementsStateForCreate = () => {
    dispatch(setIsProjectsRequirementsForUpdate(false))
    dispatch(changeModalStateProjectsRequirements())
  }

  const formMethodsCreate = useForm<IModelProjectsRequirements>({
    resolver: yupResolver(validateCreateProjectsRequirements),
  });

  const formMethodsUpdate = useForm<IModelProjectsRequirements>({
    resolver: yupResolver(validateUpdateProjectsRequirements),
  });

  useEffect(() => {
  if (isUpdateProjectsRequirements) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateProjectsRequirements });
  }

  return () => {
      formMethodsUpdate.reset();
  };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateProjectsRequirements, dataUpdateProjectsRequirements]);

  const onSubmitCreateOrUpdate = async (data: IModelProjectsRequirements) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateProjectsRequirements) {
      await updateProjectRequirements({
        id: data.id,
        title: data.title,
        item: data.item,
        linkACtion: data.linkACtion,
        textButtom: data.textButtom,
        position: data.position,
        project_id: dataUpdateProjects?.id,
      })
        .then((res: Partial<IModelProjectsRequirements>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito los requerimientos con id:${res.id}`
            );
            dispatch(refreshTableProjectsRequirements(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createProjectRequirements({...data, project_id: dataUpdateProjects?.id})
        .then((res: IModelProjectsRequirements) => {
          if (res.id) {
            dispatch(changeModalStateProjectsRequirements());
            message.success(
              `Se ha creado con éxito los requerimientos con id:${res.id}`
            );
            dispatch(refreshTableProjectsRequirements(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableProjectsRequirements) {
      dispatch(refreshTableProjectsRequirements(false));
    }
  }, [isRefreshTableProjectsRequirements]);

  return{
      //state
      isLoading,
      isUpdateProjectsRequirements,
      dataUpdateProjectsRequirements,
      dataUpdateProjects,
      isRefreshTableProjectsRequirements,
      //methods
      formMethodsCreate,
      formMethodsUpdate,
      //functions
      changeModalProjectsRequirementsStateForCreate,
      onSubmitCreateOrUpdate,
  }
}
