import { ResourceById } from "../modules/resources-admin";
import { AdminLayout } from "../shared";

export function ResourceByIdPage() {
  return (
    <AdminLayout>
      <ResourceById />
    </AdminLayout>
  )
}
