 
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { TableCustom, projectsService } from "../../../../shared"
import { IModelProjectsPartners } from "../../@types"
import { useTableProjectsPartners, useProjectsPartners } from "../../hooks"
import { Button, Col } from "antd"
import { useSelector } from "react-redux";
import { selectSingleProjects } from "../../../../redux";
import { NullableId } from "@feathersjs/feathers";
import { AnyAction } from "redux";
import { TableColumns, TableProperties } from "../../../../shared/components/ui/table/types";
import React from "react";


export function TableProjectsPartners() {
  const dataUpdateProjects = useSelector( selectSingleProjects );
  // console.log(33, dataUpdateProjects);
  const dataUpdateProjectsPartners = JSON.parse(dataUpdateProjects?.meta_partners || '{}');
  
  // console.log(44, dataUpdateProjects);

  const handleFieldChange = async (value: any , projectId: any , fieldName: any) => {
    // Update the state or make an API call to save the changes
    const updatedProjectsPartners = {
        ...dataUpdateProjectsPartners,
        [projectId]: {
            ...dataUpdateProjectsPartners[projectId],
            [fieldName]: value,
        },
    };

    // Save the updated data back to the database using the projectsService or another API call
    await projectsService.patch(projectId, { meta_partners: JSON.stringify(updatedProjectsPartners[projectId]) });

    // Trigger a refresh of the table or update the local state if necessary
};


  const {
    //state
    //methods
    //functions
    changeProjectsPartnersForUpdate,
    showDeleteConfirm,
  } = useTableProjectsPartners()

  const {
    //state
    isRefreshTableProjectsPartners,
    //methods
    //functions
  }= useProjectsPartners()

  const tableRef = React.useRef<TableProperties>()

    React.useEffect(() => {
      tableRef.current?.refresh()
    }, [isRefreshTableProjectsPartners])

  const columns: TableColumns<IModelProjectsPartners> = [
    {
        title: 'ID',
        dataIndex: 'id',
        align: 'left',
        sorter:true
    },
    {
      title: 'Titulo',
      dataIndex: 'meta_partners',
      align: 'left',
      render: (text: any, record: any) => (
        
          <span>{JSON.parse(text)?.title}</span>
      ),
      },  
    {
        title: 'Posición',
        dataIndex: 'meta_partners',
        align: 'left',
        render: (text: any, record: any) => (
        
          <span>{JSON.parse(text)?.position}</span>
      ),
    },
    {
      title: 'items',
      dataIndex: 'meta_partners',
      align: 'left',
      render: (text: any, record: any) => (
        
        <span>{JSON.parse(text)?.items}</span>
    ),
    },
    {
      title: 'tipo',
      dataIndex: 'meta_partners',
      align: 'left',
      render: (text: any, record: any) => (
        
        <span>{JSON.parse(text)?.type}</span>
    ),
    },
    {
      title: 'path',
      dataIndex: 'meta_partners',
      align: 'left',
      render: (text: any, record: any) => (
        
        <span>{JSON.parse(text)?.path}</span>
    ),
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 140,
      render: (_:any, item:any) => {
        // console.log(55, item);
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeProjectsPartnersForUpdate(item.id)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.title}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
]

  return (
    <TableCustom fetchQueryProps={{id: dataUpdateProjects?.id,}} columns={columns}  service={projectsService} ref={tableRef}/>
  )
}
