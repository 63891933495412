import { Row } from "antd";
import { TableCompanies } from "./TableCompanies";
import { Outlet } from "react-router-dom";

export function CompaniesUsers() {

  return (
    <Row
      gutter={[8, 8]}
      style={{
        padding: "10px",
        width: "100%",
      }}
    >
      
      <TableCompanies />
      <Outlet />
    </Row>
  );
}
