import { useNavigate } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import { Button, Col, Row } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import useEditPressNewsletter from "../lib/useEditPressNewsletter";
import PressNewsletterForm from "../components/press-newsletter-form";

export default function EditPressNewsletter({
  recordId,
}: {
  recordId: number | null;
}) {
  const navigate = useNavigate();

  const { formMethods, loading, handleSubmit } = useEditPressNewsletter(
    recordId,
    {
      onSuccess() {
        navigate("/press/newsletter");
      },
    }
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit}>
        <Row gutter={[8, 16]}>
          <Col xs={24}>
            <PressNewsletterForm />
          </Col>
          <Col xs={24}>
            <Button
              icon={<SaveOutlined />}
              type="primary"
              htmlType="submit"
              loading={loading}
              block
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
}
