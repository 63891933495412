import { message } from "antd";
import { newsActions } from "../action-types";
import { IModelNews } from "../../modules";
import { newsService } from "../../shared";


export const changeModalStateNews = (): newsActions.INewsModalState =>({
    type: newsActions.NEWS_MODAL_STATE
})

export const getSingleNews = (val:IModelNews ): newsActions.IGetNewsForUpdate=> ({
    type: newsActions.GET_NEWS_FOR_UPDATE,
    payload: val
})

export const setIsNewsForUpdate =(val: boolean): newsActions.IIsNewsForUpdate =>({
    type: newsActions.IS_NEWS_FOR_UPDATE,
    payload: val,
})

export const fetchSingleNews = async(id: number) =>{
    return await newsService.find({ query: {id} })
      .then((res) => {
        return res;
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createNew = async(data:Partial<IModelNews>) =>{
    return await newsService.create(data)
    .then((res: IModelNews)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateNew = async(data:Partial<IModelNews>) =>{
    return await newsService.patch(data?.id!, data)
    .then((res: IModelNews)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteNew = async(id: number) =>{
    return await newsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableNews = (val: boolean): newsActions.ISwitchNewsRefreshTable =>({
    type: newsActions.SWITCH_NEWS_REFRESH_TABLE,
    payload: val
})

