import { AdminLayout } from "../shared";
import { Button, Flex } from "antd";
import { Link, Outlet } from "react-router-dom";
import MarketingMaterialLayout from "../modules/Corresponsales/ui/components/MarketingMaterialLayout";
import MarketingMaterialCategories from "../modules/Corresponsales/ui/MarketingMaterialCategories";

export default function CorresponsalesMarketingMaterialCategoriesPage() {
  return (
    <AdminLayout>
      <MarketingMaterialLayout>
        <Flex justify="flex-end">
          <Link to="/corresponsales/material-de-marketing-categorias/agregar">
            <Button type="primary">+ Categoría</Button>
          </Link>
        </Flex>

        <MarketingMaterialCategories />
      </MarketingMaterialLayout>
      <Outlet />
    </AdminLayout>
  );
}
