import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { TableNewsCategories } from "./TableNewsCategories";
import { FormProvider } from "react-hook-form";
import { useNewsCategories } from "../hooks";
import { ModalNewsCategories } from "./ModalNewsCategories";
import { selectModalNewsCategories } from "../../../redux";
import { useSelector } from "react-redux";

export function NewsCategories() {
  const modalNewsCategories = useSelector(
    selectModalNewsCategories
  );
  const {
    //state
    isUpdateNewsCategories,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalNewsCategoriesStateForCreate,
  } = useNewsCategories()
  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
        <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalNewsCategoriesStateForCreate}
            >
              Agregar nueva categoría de noticias
            </Button>
        </Col>
        <TableNewsCategories />
        {
            modalNewsCategories && isUpdateNewsCategories && (
                <FormProvider {...formMethodsUpdate}>
                    <ModalNewsCategories />
                </FormProvider>
            )
        }
        {
            modalNewsCategories && !isUpdateNewsCategories && (
                <FormProvider {...formMethodsCreate}>
                    <ModalNewsCategories />
                </FormProvider>
            )
        }
    </Row>
  )
}
