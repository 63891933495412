import { Button, Col, Typography } from "antd"
import { useSelector } from "react-redux";
 
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { selectSingleCourses } from "../../../../redux";
import { IModelCoursesSections } from '../../@types/IModelCoursesSections';
import { TableCustom, coursesSectionsService } from "../../../../shared";
import { useCoursesSections, useTableCoursesSections } from "../../hooks";
import { TableColumns, TableProperties } from "../../../../shared/components/ui/table/types";
import React from "react";

const { Title } = Typography;

export function TableCoursesSections() {
  const dataUpdateCourses = useSelector( selectSingleCourses );

  const {
    //state
    //methods
    //functions
    changeCoursesSectionsForUpdate,
    showDeleteConfirm,
  } = useTableCoursesSections()

  const {
    //state
    isRefreshTableCoursesSections,
    //methods
    //functions
  }= useCoursesSections()

  const tableRef = React.useRef<TableProperties>()

    React.useEffect(() => {
      tableRef.current?.refresh()
    }, [isRefreshTableCoursesSections])

  const columns: TableColumns<IModelCoursesSections> = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'left',
      sorter:true

    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      align: 'left',
      
    },
    {
      title: 'Posición',
      dataIndex: 'position',
      align: 'left',

    },
    {
      title: 'Curso',
      dataIndex: 'course_id',
      align: 'left',

    },
    {
      title: 'Tipo',
      width: 26,
      dataIndex: 'type',
      align: 'left',
      render: (_:any, item:any) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            {
              item.type &&
              <Title
              style={{
                  marginBottom: "0",
                }}
                level={5}
                >
                  {item.type === 'free' ? `Gratis` : `Pago`}
              </Title>
            }
          </Col>
        );
      },
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 12,
      render: (_:any, item:any) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeCoursesSectionsForUpdate(item.id)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ]

  return (
    <TableCustom fetchQueryProps={{course_id: dataUpdateCourses?.id!,}} columns={columns}  service={coursesSectionsService} ref={tableRef} />
  )
}

