import { TRootState } from '../@types/redux';

export const selectModalkeyAccountManagers= ( state: TRootState) =>state.keyAccountManagers.keyAccountManagersModalIsOpen

export const selectIsUpdatekeyAccountManagers= ( state: TRootState) =>state.keyAccountManagers.isUpdatekeyAccountManagers

export const selectkeyAccountManagersForUpdate= ( state: TRootState) =>state.keyAccountManagers.keyAccountManagersForUpdate

export const selectkeyAccountManagersRefreshTable= ( state: TRootState) =>state.keyAccountManagers.refreshTablekeyAccountManagers

export const selectkeyAccountManagersProductsRefreshTable= ( state: TRootState) =>state.keyAccountManagers.refreshTablekeyAccountManagersProducts

export const selectRefreshTablekeyAccountManagers = (state: TRootState) => state.keyAccountManagers.refreshTablekeyAccountManagers

export const selectSinglekeyAccountManagers = (state: TRootState) => state.keyAccountManagers.keyAccountManagersForUpdate
