import { Button, Col, Typography } from "antd";
import { useFormContext } from 'react-hook-form';
import { WrapperModalWebinars } from "../styled";
import { useSelector } from "react-redux";
import { selectModalWebinarsAuthors } from "../../../../redux";
import { useWebinarsAuthors } from "../../hooks";
import { IModelWebinarsAuthors } from "../../@types/IModelWebinarsAuthors";
import { InputSelect, InputSelectDynamic, authorsService } from "../../../../shared";
import { useEffect, useState } from "react";

const { Title } = Typography;

export function ModalWebinarsAuthors() {
  const modalWebinarsAuthors = useSelector(
    selectModalWebinarsAuthors
  );

  const {
    //state
    isLoading,
    isUpdateWebinarsAuthors,
    dataUpdateWebinarsAuthors,
    //methods
    //functions
    changeModalWebinarsAuthorsStateForCreate,
    onSubmitCreateOrUpdate
  } = useWebinarsAuthors()

  const { control, handleSubmit: onSubmit } = useFormContext<IModelWebinarsAuthors>();

  const [authors, setAuthors] = useState([])

  // const getAuthors = async () => {
  //   await authorsService.find(() => {

  //   }).then((res) => {
  //     setAuthors(res?.data)
  //   })
  // }

  // useEffect(() => {
  //   getAuthors()
  // }, [])
  

  return (
    <WrapperModalWebinars
      title={ isUpdateWebinarsAuthors
          ? `Actualizando categoría de webinar: '${dataUpdateWebinarsAuthors?.id!}'`
          : `Creando categoría de webinar` }
      open={modalWebinarsAuthors}
      width={"320px"}
      destroyOnClose
      onClose={changeModalWebinarsAuthorsStateForCreate}>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}>
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Autor/a asignado/a:
          </Title>
          <InputSelectDynamic name="author_id" control={control} serviceName={authorsService} labelName="first_name" labelName2="last_name" valName="id"/>
          {/* <InputSelect
              name="author_id"
              control={control}
              dataOptions={authors.map((author: any) => ({
                value: author.id,
                title: author?.meta_author_first_name
              }))}
            /> */}
        </Col>
        <Button
            style={{
            width: "100%",
            marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
        >
            {isUpdateWebinarsAuthors
            ? "Actualizar información"
            : "Crear autor/a"}
        </Button>
    </WrapperModalWebinars>
  )
}
