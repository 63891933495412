import { IModelAuthors } from "../../modules"

export const AUTHORS_MODAL_STATE = '[ AUTHORS ] Authors Modal State'

export interface IAuthorsModalState {
    type: typeof AUTHORS_MODAL_STATE
}

export const GET_AUTHORS_FOR_UPDATE = '[ AUTHORS ] Get Authors For Update'

export interface IGetAuthorsForUpdate {
    type: typeof GET_AUTHORS_FOR_UPDATE
    payload: IModelAuthors
}

export const IS_AUTHORS_FOR_UPDATE = '[ AUTHORS ] Is Authors For Update'

export interface IIsAuthorsForUpdate {
    type: typeof IS_AUTHORS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_AUTHORS_REFRESH_TABLE = '[ AUTHORS ] Switch Authors Refresh Table'

export interface ISwitchAuthorsRefreshTable {
    type: typeof SWITCH_AUTHORS_REFRESH_TABLE
    payload: boolean
}