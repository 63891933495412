import { Button, Col, Row } from "antd"
import { PlusOutlined } from "@ant-design/icons";
import { useUsers } from "../../hooks";
import { TableUsers } from "./TableUser";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux";


export function Users() {
    const { changeNavigateForCreateEvent  } = useUsers()
    const user = useSelector(selectUser);

    return (
    <Row
    gutter={[8, 8]}
    style={{
    padding: "10px",
    width: "100%",
    }}
    >
        {user?.role === 'super_admin' && (
            <Button type="primary" onClick={changeNavigateForCreateEvent}>Añadir usuario</Button>
        )}
        <TableUsers />
    </Row>
    )
}
