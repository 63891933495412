import type { TableProperties } from "../../../shared/components/ui/table/types";

import { Button, Space } from "antd";
import { corresponsalesFormsAndModelsCategoriesService } from "../../../shared";
import Table from "../../../shared/components/ui/table/TableCustom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ICorresponsalesFormsAndModelsCategories } from "../../../@types/dto/corresponsales-forms-and-models";
import useDeleteFormAndModelCategories from "../lib/useDeleteFormAndModelCategories";

export default function FormsAndModelsCategories() {
  const tableRef = React.useRef<TableProperties>();

  const location = useLocation();

  const { handleDelete } = useDeleteFormAndModelCategories({
    onSuccess() {
      tableRef.current?.refresh();
    },
  });

  React.useEffect(() => {
    tableRef.current?.refresh();
  }, [location.pathname]);

  return (
    <Table
      ref={tableRef}
      service={corresponsalesFormsAndModelsCategoriesService}
      columns={[
        { title: "ID", dataIndex: "id", sorter: true, filterType: "text" },
        { title: "Nombre", dataIndex: "name", filterType: "text" },

        {
          title: "Acciones",
          render: (record: ICorresponsalesFormsAndModelsCategories) => (
            <Space>
              <Link
                to={`/corresponsales/formatos-y-modelos-categorias/${record.id}`}
              >
                <Button type="text" icon={<EditOutlined />} />
              </Link>
              <Button
                onClick={() => handleDelete(record.id)}
                type="text"
                danger
                icon={<DeleteOutlined />}
              />
            </Space>
          ),
        },
      ]}
    />
  );
}
