import { Button, Space } from "antd";
import { IKeywordSearch } from "../../../@types/dto/keyword-search";
import { keywordSearchService } from "../../../shared";
import Table from "../../../shared/components/ui/table/TableCustom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import useDeleteKeywordSearch from "../lib/useDeleteKeywordSearch";
import React from "react";
import { TableProperties } from "../../../shared/components/ui/table/types";

export default function KeywordSearch() {
  const tableRef = React.useRef<TableProperties>();
  const location = useLocation();

  const { handleDelete } = useDeleteKeywordSearch({
    onSuccess() {
      tableRef.current?.refresh();
    },
  });

  React.useEffect(() => {
    tableRef.current?.refresh();
  }, [location.pathname]);

  return (
    <div>
      <Table
        ref={tableRef}
        service={keywordSearchService}
        columns={[
          { title: "id", dataIndex: "id", sorter: true, filterType: "text" },
          { title: "Palabra clave", dataIndex: "keywords", filterType: "text" },
          { title: "Url", dataIndex: "url" },
          { title: "Description", dataIndex: "description" },
          {
            title: "Acciones",
            render: (record: IKeywordSearch) => (
              <Space>
                <Link to={`/buscador/palabras-claves/${record.id}`}>
                  <Button icon={<EditOutlined />} type="link" />
                </Link>
                <Button
                  onClick={() => handleDelete(record.id)}
                  type="text"
                  danger
                  icon={<DeleteOutlined />}
                />
              </Space>
            ),
          },
        ]}
      />
    </div>
  );
}
