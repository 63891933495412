import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { selectModalEventsSchedules } from "../../../../redux";
import { useEventsSchedules } from "../../hooks";
import { TableEventsSchedules } from "./TableEventsSchedules";
import { ModalEventsSchedules } from "./ModalEventsSchedules";

export function EventsSchedules() {
  const modalEventsSchedules = useSelector(
    selectModalEventsSchedules
  );

  const {
    //state
    isUpdateEventsSchedules,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalEventsSchedulesStateForCreate,
  } = useEventsSchedules();

  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalEventsSchedulesStateForCreate}
            >
              Agregar nuevo horario del evento
            </Button>
        </Col>
        <TableEventsSchedules />
        {
            modalEventsSchedules && (
                <FormProvider { ...isUpdateEventsSchedules ?{...formMethodsUpdate} : {...formMethodsCreate}}>
                    <ModalEventsSchedules />
                </FormProvider>
            )
        }
    </Row>
  )
}
