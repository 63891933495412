import * as yup from 'yup'

export const validateCreateWebinarsRequirements = yup.object().shape({
    requirement_text:yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El TEXTO REQUERIDO debe tener mínimo de 2 caracteres.'),
    position:yup
    .number()
    .required('Campo requerido.'),
    // webinar_id:yup
    // .number()
    // .required('Campo requerido.'),
})