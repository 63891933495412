import type { TableProperties } from "../../../shared/components/ui/table/types";

import { Button, Space } from "antd";
import { corresponsalesMarketingMaterialService } from "../../../shared";
import Table from "../../../shared/components/ui/table/TableCustom";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { PATH_S3_BUCKET } from "../../../shared/utils/constants";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ICorresponsalesFormsAndModels } from "../../../@types/dto/corresponsales-forms-and-models";
import useDeleteMarketingMaterial from "../lib/useDeleteMarketingMaterial";

export default function MarketingMaterial() {
  const tableRef = React.useRef<TableProperties>();

  const location = useLocation();

  const { handleDelete } = useDeleteMarketingMaterial({
    onSuccess() {
      tableRef.current?.refresh();
    },
  });

  React.useEffect(() => {
    tableRef.current?.refresh();
  }, [location.pathname]);

  return (
    <Table
      ref={tableRef}
      service={corresponsalesMarketingMaterialService}
      columns={[
        { title: "ID", dataIndex: "id", sorter: true, filterType: "text" },
        { title: "Título", dataIndex: "title", filterType: "text" },
        { title: "Categoría", dataIndex: ["category", "name"] },
        {
          title: "Archivo",
          dataIndex: "resource_path",
          render: (resourcePath: string) => (
            <Button
              type="link"
              icon={<DownloadOutlined />}
              href={`${PATH_S3_BUCKET}/${resourcePath}`}
              target="_blank"
            >
              Descargar
            </Button>
          ),
        },
        {
          title: "Acciones",
          render: (record: ICorresponsalesFormsAndModels) => (
            <Space>
              <Link to={`/corresponsales/material-de-marketing/${record.id}`}>
                <Button type="text" icon={<EditOutlined />} />
              </Link>
              <Button
                onClick={() => handleDelete(record.id)}
                type="text"
                danger
                icon={<DeleteOutlined />}
              />
            </Space>
          ),
        },
      ]}
    />
  );
}
