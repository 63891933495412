import { useSelector } from "react-redux";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useEffect, useState } from "react";
import { message } from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IModelAuthors } from "../@types";
import { selectIsUpdateAuthors, selectRefreshTableAuthors, selectSingleAuthors } from "../../../redux/selectors/authors.selectors";
import { changeModalStateAuthors, createAuthors, refreshTableAuthors, setIsAuthorsForUpdate, updateAuthors,  } from '../../../redux/actions/authors.actions';
import { validateCreateAuthors, validateUpdateAuthors } from "../../../shared/utils/yupValidation";

export function useAuthors() {
  const dispatch = useAppDispatch();

  const isUpdateAuthors = useSelector(
    selectIsUpdateAuthors
  );
  const dataUpdateAuthors = useSelector(
    selectSingleAuthors
  );
  const isRefreshTableAuthors = useSelector(
    selectRefreshTableAuthors
  );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalAuthorsStateForCreate = () => {
    dispatch(setIsAuthorsForUpdate(false))
    dispatch(changeModalStateAuthors())
  }

  const formMethodsCreate = useForm<IModelAuthors>({
    resolver: yupResolver(validateCreateAuthors),
  });

  const formMethodsUpdate = useForm<IModelAuthors>({
    resolver: yupResolver(validateUpdateAuthors),
  });

  useEffect(() => {
    if (isUpdateAuthors) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateAuthors });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateAuthors, dataUpdateAuthors]);

  const onSubmitCreateOrUpdate = async (data: IModelAuthors) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateAuthors) {
      await updateAuthors({
        id: data.id,
        avatar_path: data.avatar_path,
        description: data.description,
        first_name: data.first_name,
        last_name: data.last_name,
        profession: data.profession,
        user_id: data.user_id,
      })
        .then((res: Partial<IModelAuthors>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito el autor con id:${res.id}`
            );
            dispatch(refreshTableAuthors(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createAuthors({...data})
        .then((res: IModelAuthors) => {
          if (res.id) {
            dispatch(changeModalStateAuthors());
            message.success(
              `Se ha creado con éxito el autor con id:${res.id}`
            );
            dispatch(refreshTableAuthors(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };


  useEffect(() => {
    if (isRefreshTableAuthors) {
      dispatch(refreshTableAuthors(false));
    }
  }, [isRefreshTableAuthors]);

  return{
    //state
    isLoading,
    isUpdateAuthors,
    dataUpdateAuthors,
    isRefreshTableAuthors,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalAuthorsStateForCreate,
    onSubmitCreateOrUpdate,
  }
}
