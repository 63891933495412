import { EventsTags } from "../modules";
import { AdminLayout } from "../shared";

export function EventsTagsPage() {
  return (
    <AdminLayout>
      <EventsTags />
    </AdminLayout>
  )
}
