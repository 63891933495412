import { Button, Col, Row } from "antd"
import { PlusOutlined } from "@ant-design/icons";
import { useProjects } from "../../hooks";
import { TableProjects } from "./TableProjects";


export function Projects() {
    const { changeNavigateForCreateProject } = useProjects()

    return (
      <Row
      gutter={[8, 8]}
      style={{
      padding: "10px",
      width: "100%",
      }}
      >
          <Col
              span={24}
              style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              }}
          >
              <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeNavigateForCreateProject}
              >
              Agregar nuevo Proyecto
              </Button>
          </Col>
          <TableProjects />
      </Row>
    )
}
