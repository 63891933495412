import { IModelProjectsObjectives } from "../../modules"


export const PROJECTS_OBJECTIVES_MODAL_STATE = '[ ProjectS OBJECTIVES ] PROJECTs OBJECTIVES Modal State'

export interface IProjectsObjectivesModalState {
    type: typeof PROJECTS_OBJECTIVES_MODAL_STATE
}

export const GET_PROJECTS_OBJECTIVES_FOR_UPDATE = '[ PROJECTS OBJECTIVES ] Get PROJECTs OBJECTIVES For Update'

export interface IGetProjectsObjectivesForUpdate {
    type: typeof GET_PROJECTS_OBJECTIVES_FOR_UPDATE
    payload: IModelProjectsObjectives
}

export const IS_PROJECTS_OBJECTIVES_FOR_UPDATE = '[ PROJECTS OBJECTIVES ] Is Projects OBJECTIVES For Update'

export interface IIsProjectsObjectivesForUpdate {
    type: typeof IS_PROJECTS_OBJECTIVES_FOR_UPDATE
    payload: boolean
}

export const SWITCH_PROJECTS_OBJECTIVES_REFRESH_TABLE = '[ PROJECTS OBJECTIVES ] Switch Projects OBJECTIVES Refresh Table'

export interface ISwitchProjectsObjectivesRefreshTable {
    type: typeof SWITCH_PROJECTS_OBJECTIVES_REFRESH_TABLE
    payload: boolean
}