import { CompaniesUsers } from "../modules/companies-user";
import { AdminLayout } from "../shared";

export const CompaniesUserPage = () => {
  return (
    <AdminLayout>
      <CompaniesUsers />
    </AdminLayout>
  );
};
