import { FormProvider } from "react-hook-form";
import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import useCreateFormsAndModelsCategories from "../lib/useCreateFormsAndModelsCategories";
import FormsAndModelsCategoriesForm from "./components/FormAndModelsFormCategories";
import { useNavigate } from "react-router-dom";

export default function CreateFormsAndModelsCategories() {
  const navigate = useNavigate();

  const { formMethods, loading, handleSubmit } =
    useCreateFormsAndModelsCategories({
      onSuccess() {
        navigate("/corresponsales/formatos-y-modelos-categorias");
      },
    });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit}>
        <Row gutter={[8, 16]} justify={"space-between"}>
          <Col xs={24}>
            <FormsAndModelsCategoriesForm />
          </Col>
          <Col xs={24}>
            &nbsp;
            <Button
              icon={<PlusOutlined />}
              block
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              Agregar
            </Button>
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
}
