import feathersClient,{ feathersStorage } from '../../clients/feathers.clients'

export const login = async (email: string, password: string) => {
    return await feathersClient
        .authenticate({
            strategy: 'local',
            email,
            password,
        })
        
        .then((res) => {return res})
        .catch((err) => {
            throw err
        })
}

export const retryLogin = async () => {
    return await feathersClient
        .authenticate({
            strategy: 'jwt',
            accessToken: feathersStorage.getItem('jwt'),
        })
}


export const Logout = async () => {
        return await feathersClient
          .logout()
          .then(async (res: any) => {
            window.location.reload();
            return res
          })
          .catch((err: any) => {
            throw err
          })
    
}
