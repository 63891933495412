import React from "react";
import { IStudyCertificateTemplateField } from "../../../../@types/dto/study-certificate-template-field";
import { studyCertificateTemplateFieldsService } from "../../../../shared";
import { message } from "antd";

export default function useStudyCertificateTemplateFields(templateId?: number) {
  const [studyCertificateTemplateFields, setStudyCertificateTemplateFields] =
    React.useState<IStudyCertificateTemplateField[]>([]);
  const [loading, setLoading] = React.useState(false);

  const loadingRef = React.useRef(false);

  const updateLoading = (value: boolean) => {
    setLoading(value);
    loadingRef.current = value;
  };

  const getTemplateFields = async () => {
    if (loadingRef.current || !templateId) return;

    updateLoading(true);

    await studyCertificateTemplateFieldsService
      .find({
        query: {
          study_certificate_template_id: templateId,
          $limit: 100,
        },
      })
      .then(({ data }: any) => {
        setStudyCertificateTemplateFields(
          data as IStudyCertificateTemplateField[]
        );
      })
      .catch((err) => {
        message.error(err?.message);
      })
      .finally(() => {
        updateLoading(false);
      });
  };

  React.useEffect(() => {
    getTemplateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId]);

  return {
    studyCertificateTemplateFields,
    loading,
    refresh: getTemplateFields,
  };
}
