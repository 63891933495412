import { message } from "antd";

import { coursesSectionsService } from "../../shared";
import { coursesSectionsActions } from "../action-types";
import { IModelCoursesSections } from "../../modules";

export const changeModalStateCoursesSections = (): coursesSectionsActions.ICoursesSectionsModalState =>({
    type: coursesSectionsActions.COURSES_SECTIONS_MODAL_STATE
})

export const getSingleCoursesSections = (val:IModelCoursesSections ): coursesSectionsActions.IGetCoursesSectionsForUpdate=> ({
    type: coursesSectionsActions.GET_COURSES_SECTIONS_FOR_UPDATE,
    payload: val
})

export const setIsCoursesSectionsForUpdate =(val: boolean): coursesSectionsActions.IIsCoursesSectionsForUpdate =>({
    type: coursesSectionsActions.IS_COURSES_SECTIONS_FOR_UPDATE,
    payload: val,
})

export const fetchSingleCoursesSections = async(id: number) =>{
    return await coursesSectionsService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createCourseSections = async(data:Partial<IModelCoursesSections>) =>{
    return await coursesSectionsService.create(data)
    .then((res: IModelCoursesSections)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateCourseSections = async(data:Partial<IModelCoursesSections>) =>{
    return await coursesSectionsService.patch(data?.id!, data)
    .then((res: IModelCoursesSections)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteCourseSections = async(id: number) =>{
    return await coursesSectionsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableCoursesSections = (val: boolean): coursesSectionsActions.ISwitchCoursesSectionsRefreshTable =>({
    type: coursesSectionsActions.SWITCH_COURSES_SECTIONS_REFRESH_TABLE,
    payload: val
})

