import { useEffect, useState } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useForm } from "react-hook-form";
import { changeModalStateCoursesAuthors, createCourseAuthors, refreshTableCoursesAuthors, selectIsUpdateCoursesAuthors, selectRefreshTableCoursesAuthors, selectSingleCourses, selectSingleCoursesAuthors, setIsCoursesAuthorsForUpdate, updateCourseAuthors } from "../../../../redux";
import { IModelCoursesAuthors } from "../../@types";
import { validateCreateCoursesAuthors } from '../../../../shared/utils/yupValidation/validateCreateCoursesAuthors';
import { validateUpdateCoursesAuthors } from "../../../../shared/utils/yupValidation";


export function useCoursesAuthors() {
  const dispatch = useAppDispatch();

  const isUpdateCoursesAuthors = useSelector(
      selectIsUpdateCoursesAuthors
  );

  const dataUpdateCoursesAuthors = useSelector(
      selectSingleCoursesAuthors
  );

  const isRefreshTableCoursesAuthors = useSelector(
      selectRefreshTableCoursesAuthors
  );

  const dataUpdateCourses = useSelector( selectSingleCourses );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalCoursesAuthorsStateForCreate = () => {
    dispatch(setIsCoursesAuthorsForUpdate(false))
    dispatch(changeModalStateCoursesAuthors())
  }

  const formMethodsCreate = useForm<IModelCoursesAuthors>({
    resolver: yupResolver(validateCreateCoursesAuthors),
  });

  const formMethodsUpdate = useForm<IModelCoursesAuthors>({
    resolver: yupResolver(validateUpdateCoursesAuthors),
  });

  useEffect(() => {
    if (isUpdateCoursesAuthors) {
        formMethodsCreate.reset();
        formMethodsUpdate.reset({ ...dataUpdateCoursesAuthors });
    }

    return () => {
        formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateCoursesAuthors, dataUpdateCoursesAuthors]);

  const onSubmitCreateOrUpdate = async (data: IModelCoursesAuthors) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateCoursesAuthors) {
      await updateCourseAuthors({
        id: data.id,
        author_id: data.author_id,
        course_id: dataUpdateCourses?.id,
      })
        .then((res: Partial<IModelCoursesAuthors>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito el/la autor/a del curso con id:${res.id}`
            );
            dispatch(refreshTableCoursesAuthors(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createCourseAuthors({...data, course_id: dataUpdateCourses?.id})
        .then((res: IModelCoursesAuthors) => {
          if (res.id) {
            dispatch(changeModalStateCoursesAuthors());
            message.success(
              `Se ha creado con éxito el/la autor/a del curso con id:${res.id}`
            );
            dispatch(refreshTableCoursesAuthors(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableCoursesAuthors) {
      dispatch(refreshTableCoursesAuthors(false));
    }
  }, [isRefreshTableCoursesAuthors]);

  return{
      //state
      isLoading,
      isUpdateCoursesAuthors,
      dataUpdateCoursesAuthors,
      isRefreshTableCoursesAuthors,
      //methods
      formMethodsCreate,
      formMethodsUpdate,
      //functions
      changeModalCoursesAuthorsStateForCreate,
      onSubmitCreateOrUpdate,
  }
}
