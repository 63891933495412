import styled, { css, keyframes } from "styled-components";
import { Button as AntButton } from "antd";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

type TWidgetDivProps = {
  selected?: boolean;
};

export const CloseWidget = styled.div<TWidgetDivProps>`
  top: -26px !important;
  position: absolute;
  left: -28px !important;
  text-shadow: 0 0;
  cursor: pointer;
  border: solid 0px black;
  border-radius: 0.5em;
  font-size: smaller;
  opacity: 0;
  transition: all 0.3s;
  width: 26px;
  height: 26px;
  text-align: center;
  vertical-align: middle;
  line-height: 26px;
  color: white;
  background: lightgrey;

  ${({ selected }) => {
    if (selected)
      return css`
        opacity: 1 !important;
        transform: scale(1) !important;
      `;
  }}
`;

export const StyleOptions = styled<any>(AntButton)`
  bottom: 0px !important;
  position: absolute;
  left: -28px !important;
  border-radius: 0.5em;
  font-size: smaller;
  opacity: 0;
  transition: all 0.3s;
  width: 26px;
  height: 26px;
  vertical-align: middle;
  background: lightgrey;

  ${({ selected }) => {
    if (selected)
      return css`
        opacity: 1 !important;
        transform: scale(1) !important;
      `;
  }}
`;

type TResizeHandleProps = {
  selected?: boolean;
};

export const MoveHandle = styled.div<TResizeHandleProps>`
  position: absolute;
  right: -28px;
  bottom: 26px;
  height: 26px;
  border-radius: 9px;
  vertical-align: middle;
  line-height: 26px;
  font-size: small;
  opacity: 0;
  cursor: move;
  transform: scale(0.5);
  transition: all 0.3s;
  height: 26px;
  width: 26px;
  text-align: center;

  &.grey {
    background-color: lightgrey;
  }

  ${({ selected }) => {
    if (selected)
      return css`
        transform: scale(1) !important;
        opacity: 1 !important;
      `;
  }}

  &.pop-up {
    top: 0;
    padding-left: 10px;
    width: 100% !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    text-align: left !important;
    left: 0;
    overflow: hidden;
    white-space: nowrap;
    background-color: #1890ff;

    ${({ selected }) => {
      if (selected)
        return css`
          transition: opacity 0.3s, background 0s !important;
          top: -27px;
          height: 27px;
        `;
    }}
  }
`;

type TResizeWidgetProps = {
  selected?: boolean;
};

export const ResizeWidget = styled.div<TResizeWidgetProps>`
  width: 26px;
  height: 26px;
  right: -28px;
  cursor: se-resize;
  bottom: -2px;
  line-height: 26px;
  transform: scale(0);
  position: absolute;
  transition: all 0.3s;
  opacity: 0;
  z-index: inherit !important;
  border-radius: 0.5em;
  background: lightgrey;
  text-align: center;
  vertical-align: middle;

  & i {
    transform: rotateY(180deg);
  }

  ${({ selected }) => {
    if (selected)
      return css`
        transform: scale(1) !important;
        opacity: 1 !important;
      `;
  }}
`;

export const WidgetName = styled.p`
  background: none;
  border: none;
  color: white;
  width: 100%;
  font-size: small;
  margin: 0 !important;
`;

type TWidgetProps = {
  selected?: boolean;
  fontSize?: number;
};

export const WidgetDiv = styled.div<TWidgetProps>`
  border: 1px solid #d9d9d9;
  border-radius: 9px;
  outline: none;
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 0% !important;
  color: black;
  font-weight: 500;
  line-height: 1;
  ${({ selected }) => {
    if (selected)
      return css`
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border: 1px solid #1890ff;
        transition: border 0.3s;
      `;
  }}
`;

interface IWidgetWrapperProps {
  selected?: boolean;
}

export const WidgetWrapper = styled.div<IWidgetWrapperProps>`
  position: absolute;
  color: #ffff;
  padding: 0;
  opacity: 1;
  z-index: 1;
  transition: all 0.3s, top 0s, left 0s, width 0s, height 0s;
  border-radius: 0px 0px 4px 4px;
  animation-name: ${fadeIn};
  animation-fill-mode: initial;
  animation-duration: 0.3s;
`;
