import { Button, Col, Typography } from "antd";
import { useFormContext } from 'react-hook-form';
import { useSelector } from "react-redux";
import { selectModalEventsAuthors } from "../../../../redux";
import { useEventsAuthors } from "../../hooks";
import { WrapperModalEvents } from "../styled";
import { IModelEventsAuthors } from "../../@types";
import { InputSelectDynamic, authorsService } from "../../../../shared";

const { Title } = Typography;

export function ModalEventsAuthors() {
  const modalEventsAuthors = useSelector(
    selectModalEventsAuthors
  );

  const {
    //state
    isLoading,
    isUpdateEventsAuthors,
    dataUpdateEventsAuthors,
    //methods
    //functions
    changeModalEventsAuthorsStateForCreate,
    onSubmitCreateOrUpdate
  } = useEventsAuthors()

  const { control, handleSubmit: onSubmit } = useFormContext<IModelEventsAuthors>();

  return (
    <WrapperModalEvents
      title={ isUpdateEventsAuthors
          ? `Actualizando autor de evento: '${dataUpdateEventsAuthors?.id!}'`
          : `Creando autor de evento` }
      open={modalEventsAuthors}
      width={"320px"}
      destroyOnClose
      onClose={changeModalEventsAuthorsStateForCreate}>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}>
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Autor/a asignado/a:
          </Title>
          <InputSelectDynamic name="author_id" control={control} serviceName={authorsService} labelName="first_name" labelName2="last_name" valName="id"/>
        </Col>
        <Button
            style={{
            width: "100%",
            marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
        >
            {isUpdateEventsAuthors
            ? "Actualizar información"
            : "Crear autor/a"}
        </Button>
    </WrapperModalEvents>
  )
}
