import * as yup from 'yup'

export const validateUpdateCourses = yup.object().shape({
    name:yup
    .string()
    .min(2, 'El nombre debe tener mínimo de 2 caracteres.'),
    status: yup
    .string()
    .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones.'),
    product_id: yup.string(),
    category_id:yup
    .number(),
    small_description: yup
    .string()
    .min(2, 'La descripción corta debe tener mínimo de 2 caracteres.'),
    description: yup
    .string()
    .min(2, 'La descripción debe tener mínimo de 2 caracteres.'),
    assistance_type: yup
    .string()
    .oneOf(['remote', 'hybrid', 'on_place'], 'Debes elegir entre estas opciones.'),
    start_date: yup
    .string()
    .min(1, 'Debes de seleccionar una fecha de inicio.'),
    duration: yup
    .string()
    .min(2, 'La duración debe ser en un formato valido.'),
    // path_video:yup
    // .string()
    // .min(1, 'Debes de incluir mínimo un video.'),
    // path_image:yup
    // .string()
    // .min(1, 'Debes de incluir mínimo una imagen.'),
    position:yup
    .number(),
    place_location:yup
    .string()
    .min(2, 'El lugar o locación debe tener mas de 1 carácter.'),
})