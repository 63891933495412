import { Drawer, Tabs } from "antd";
import styled from "styled-components";

//modal Events

export const WrapperModalUsers = styled(Drawer)``;


export const WrapperTabNavUsers = styled(Tabs)`
  width: 100%;
`;