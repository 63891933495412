import * as yup from 'yup'

export const validateUpdateNews = yup.object().shape({
    title:yup
    .string()
    .typeError('Campo requerido.')
    .min(2, 'El titulo debe tener mínimo de 2 caracteres.'),
    // image_path_cover:yup
    // .string()
    // .typeError('Campo requerido.')
    // .min(1, 'Debes de incluir mínimo una imagen.'),
    body: yup
    .string()
    .typeError('Campo requerido.')
    .min(2, 'El cuerpo de la noticia debe tener mínimo de 2 caracteres.'),
    short_description: yup
    .string()
    .typeError('Campo requerido.')
    .min(2, 'La descripción corta debe tener mínimo de 2 caracteres.'),
    published_date: yup
    .string()
    .typeError('Campo requerido.')
    .min(1, 'Debes de seleccionar una fecha de publicación.'),
    category_id:yup
    .number()
    .typeError('Campo requerido.'),
    position:yup
    .number()
    .typeError('Campo requerido.'),
    status: yup
    .string()
    .typeError('Campo requerido.')
    .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones.'),
})