import { Button, Col, Row, Spin } from "antd";
import { FormProvider } from 'react-hook-form';
import { FormCategiesInfograpgies } from "./keyAccountManagersForm";
import { SaveOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import UsekeyAccountManagersNew from "../../hooks/resource/usekeyAccountManagerNew";


export function InfographiesNew() {
  const navigate = useNavigate();
  const { formMethods, handleSubmit, loading } = UsekeyAccountManagersNew(
    {
      onSuccess() {
        navigate("/virtual-library");
      },
    }
  );

  return (
    <>
      <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit}>
              <Row gutter={[8, 16]} justify={"space-between"}>
                <Col xs={24}>
                  <FormCategiesInfograpgies />
                </Col>
                <Col xs={24}>
                  <Button
                    icon={<SaveOutlined />}
                    block
                    loading={loading}
                    htmlType="submit"
                    type="primary"
                  >
                    Guardar
                  </Button>
                </Col>
              </Row>
            </form>
          </FormProvider>
  </>
  )
}
