import { NewsById } from "../modules";
import { AdminLayout } from "../shared";

export function NewsByIdPage() {
  return (
    <AdminLayout>
        <NewsById />
    </AdminLayout>
  )
}
