import { Button, Col, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectModalAuthors } from "../../../redux/selectors/authors.selectors";
import { useAuthors } from "../hooks";
import { IModelAuthors } from "../@types";
import { WrapperModalAuthors } from "./styled";
import { InputSelectDynamic, InputText, usersService } from "../../../shared";

const { Title } = Typography;
export function ModalAuthors() {
  const modalAuthors = useSelector(
    selectModalAuthors
  );

  const {
    //state
    isLoading,
    isUpdateAuthors,
    dataUpdateAuthors,
    //methods
    //functions
    changeModalAuthorsStateForCreate,
    onSubmitCreateOrUpdate
  } = useAuthors()

  const { control, handleSubmit: onSubmit } =
  useFormContext<IModelAuthors>();

  return (
    <WrapperModalAuthors
    title={
      isUpdateAuthors
          ? `Actualizando autor/a: '${dataUpdateAuthors?.id!}'`
          : `Creando autor/a`
      }
      open={modalAuthors}
      width={"320px"}
      destroyOnClose
      onClose={changeModalAuthorsStateForCreate}>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
            Nombre/s:
            </Title>
            <InputText placeHolder="John" name="first_name" control={control} />
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
            Apellido/s
            </Title>
            <InputText placeHolder="Doe" name="last_name" control={control} />
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}>
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Usuario asignado:
          </Title>
          <InputSelectDynamic name="user_id" control={control} serviceName={usersService} labelName="first_name" valName="id" labelName2="last_name"/>
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Profesión
            </Title>
            <InputText placeHolder="Conferencista" name="profession" control={control} />
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Descripción
            </Title>
            <InputText placeHolder="Lorem ipsum dolor sit amet consectetur adipiscing..." rows name="description" control={control} />
        </Col>
        <Button
            style={{
            width: "100%",
            marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
        >
            {isUpdateAuthors
            ? "Actualizar información"
            : "Crear autor/a"}
        </Button>
      </WrapperModalAuthors>
  )
}
