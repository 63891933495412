import { message } from "antd";

import { CompaniesService } from "../../shared";
import { CompaniesActions } from "../action-types";
import { IModelCompanies } from "../../modules";

export const changeModalStateCompanies = (): CompaniesActions.ICompaniesChangeModalState =>({
    type: CompaniesActions.COMPANIES_CHANGE_MODAL_STATE
})

export const getSingleCompanies = (val:IModelCompanies ): CompaniesActions.IGetCompaniesForUpdate=> ({
    type: CompaniesActions.GET_COMPANIES_FOR_UPDATE,
    payload: val
})

export const setIsCompaniesForUpdate =(val: boolean): CompaniesActions.IIsCompaniesForUpdate =>({
    type: CompaniesActions.IS_COMPANIES_FOR_UPDATE,
    payload: val,
})

export const fetchSingleCompanies = async(id: number) =>{
    return await CompaniesService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createCompanies = async(data:Partial<IModelCompanies>) =>{
    return await CompaniesService.create(data)
    .then((res: any)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateCompanies = async(data:Partial<IModelCompanies>) =>{
    return await CompaniesService.patch(data?.id!, data)
    .then((res: any)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteCompanies = async(id: number) =>{
    return await CompaniesService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableCompanies = (val: boolean): CompaniesActions.ISwitchCompaniesRefreshTable =>({
    type: CompaniesActions.SWITCH_COMPANIES_REFRESH_TABLE,
    payload: val
})

