import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectModalWebinarsAuthors } from "../../../../redux";
import { useWebinarsAuthors } from "../../hooks";
import { TableWebinarsAuthors } from "./TableWebinarsAuthors";
import { ModalWebinarsAuthors } from "./ModalWebinarsAuthors";
import { FormProvider } from "react-hook-form";

export function WebinarAuthorsFrom() {
    const modalWebinarsAuthors = useSelector(
        selectModalWebinarsAuthors
    );

    const {
        //state
        isUpdateWebinarsAuthors,
        //methods
        formMethodsCreate,
        formMethodsUpdate,
        //functions
        changeModalWebinarsAuthorsStateForCreate,
    } = useWebinarsAuthors();

    return (
        <Row
            gutter={[8, 8]}
            style={{
            padding: "10px",
            width: "100%",
            }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}>
                <Button
                  type="primary"
                  shape="round"
                  icon={<PlusOutlined />}
                  size="large"
                  onClick={changeModalWebinarsAuthorsStateForCreate}
                >
                  Agregar nuevo/a autor/a a un webinar
                </Button>
            </Col>
            <TableWebinarsAuthors />
            {
                modalWebinarsAuthors && (
                    <FormProvider { ...isUpdateWebinarsAuthors ?{...formMethodsUpdate} : {...formMethodsCreate}}>
                        <ModalWebinarsAuthors />
                    </FormProvider>
                )
            }
        </Row>
    )
}
