import type { TableProperties } from "../../../shared/components/ui/table/types";
import type { ICorresponsalesVideosAndTutorials } from "../../../@types/dto/corresponsales-videos-and-tutorials";

import { Button, Space } from "antd";
import { corresponsalesVideosAndTutorialsService } from "../../../shared";
import Table from "../../../shared/components/ui/table/TableCustom";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { PATH_S3_BUCKET } from "../../../shared/utils/constants";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import useDeleteVideoAndTutorial from "../lib/useDeleteVideoAndTutotial";

export default function VideosAndTutorials() {
  const tableRef = React.useRef<TableProperties>();

  const location = useLocation();

  const { handleDelete } = useDeleteVideoAndTutorial({
    onSuccess() {
      tableRef.current?.refresh();
    },
  });

  React.useEffect(() => {
    tableRef.current?.refresh();
  }, [location.pathname]);

  return (
    <Table
      ref={tableRef}
      service={corresponsalesVideosAndTutorialsService}
      columns={[
        { title: "ID", dataIndex: "id", sorter: true, filterType: "text" },
        { title: "Título", dataIndex: "title", filterType: "text" },
        { title: "Categoría", dataIndex: ["category", "name"] },
        {
          title: "Video",
          dataIndex: "video_path",
          render: (videoPath: string) => (
            <Button
              type="link"
              icon={<DownloadOutlined />}
              href={`${PATH_S3_BUCKET}/${videoPath}`}
              target="_blank"
            >
              Descargar
            </Button>
          ),
        },
        {
          title: "Acciones",
          render: (record: ICorresponsalesVideosAndTutorials) => (
            <Space>
              <Link to={`/corresponsales/videos-y-tutoriales/${record.id}`}>
                <Button type="text" icon={<EditOutlined />} />
              </Link>
              <Button
                onClick={() => handleDelete(record.id)}
                type="text"
                danger
                icon={<DeleteOutlined />}
              />
            </Space>
          ),
        },
      ]}
    />
  );
}
