import { IModelProjectsComponents } from "../../modules"


export const PROJECTS_COMPONENTS_MODAL_STATE = '[ PROJECTS COMPONENTS ] Webinars Requirements Modal State'

export interface IProjectsComponentsModalState {
    type: typeof PROJECTS_COMPONENTS_MODAL_STATE
}

export const GET_PROJECTS_COMPONENTS_FOR_UPDATE = '[ PROJECTS COMPONENTS ] Get Projects Requirements For Update'

export interface IGetProjectsComponentsForUpdate {
    type: typeof GET_PROJECTS_COMPONENTS_FOR_UPDATE
    payload: IModelProjectsComponents
}

export const IS_PROJECTS_COMPONENTS_FOR_UPDATE = '[ PROJECTS COMPONENTS ] Is Webinars Requirements For Update'

export interface IIsProjectsComponentsForUpdate {
    type: typeof IS_PROJECTS_COMPONENTS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_PROJECTS_COMPONENTS_REFRESH_TABLE = '[ PROJECTS COMPONENTS ] Switch Webinars Requirements Refresh Table'

export interface ISwitchProjectsComponentsRefreshTable {
    type: typeof SWITCH_PROJECTS_COMPONENTS_REFRESH_TABLE
    payload: boolean
}