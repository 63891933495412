import { type ReactNode } from 'react'
import { Avatar, Col, Dropdown, Layout, Menu, Row } from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons'

import { WrapperActionsRight, WrapperCard, WrapperHeader, WrapperLayout, WrapperSider } from './styled'
import { LogoIcon } from '../LogoIcon'
import { useAdminLayout, useNavBar } from './hooks'
import moment from 'moment'
import { Logout } from '../../services/authentication.service'
import { useNavigate } from "react-router-dom";

const { Content } = Layout

interface Props {
  children: ReactNode
}

export function AdminLayout ({ children }: Props): JSX.Element {
  const { user } = useNavBar();
  const { collapsed,items, pathname, changeCollapsed } = useAdminLayout();
  const navigate = useNavigate();

//   const logoutNavitagion =() => {
//     const timeoutId = setTimeout(() => {
//       window.location.reload();
//     }, 800);

//     return () => {
//       clearTimeout(timeoutId);
//     };
// }

  const menu = (
    <Menu
      items={[
        {
          key: "logout",
          label: 'Cerrar Sesión',
          onClick: () => {
            // logoutNavitagion();
            Logout();
          },
        },
        {
          key: "user",
          label: `${user?.first_name} ${user?.last_name}`,
        },
        {
          key: "build",
          label: `Creado: ${moment(`${user?.createdAt}`).add("h", 5).format(
            "DD/MM/YYYY HH:mm:ss"
          )}`,
        },
      ]}
    />
  );

  return (
    <WrapperLayout>
      <WrapperHeader>
        <Row align="middle" style={{ width: '100%', paddingRight: 10 }}>
          <Col onClick={changeCollapsed} style={{ display: 'flex', justifyContent: 'flex-start',cursor:"pointer" }}>
            {
              collapsed ?(
                <MenuUnfoldOutlined style={{ fontSize: "25px", color:"var(--primary)", marginRight: "8px" }}/>
                ):(
                <MenuFoldOutlined style={{ fontSize: "25px", color:"var(--primary)", marginRight: "8px" }}/>
              )
            }
            <LogoIcon h={"60"} />
          </Col>
          <Col
            style={{
              display: 'flex',
              flexGrow: 1
            }}
          />
          <WrapperActionsRight>
            <Dropdown overlay={menu} placement="bottomRight" arrow>
              <Avatar size={32} icon={<UserOutlined />} />
            </Dropdown>
          </WrapperActionsRight>
        </Row>
      </WrapperHeader>
      <Layout hasSider>
        <WrapperSider
          theme="light"
          collapsedWidth="0"
          collapsible
          collapsed={collapsed}
        >
          <Menu selectedKeys={[pathname]} mode="inline" items={items} />
        </WrapperSider>
        <Content>
          <div
            // className="site-layout-background"
            style={{
              padding: 24,
              height: "calc(100vh - 64px)",
              overflow: "auto",
            }}
          >
            <WrapperCard>
              {children}
            </WrapperCard>
          </div>
        </Content>
      </Layout>
    </WrapperLayout>
  )
}
