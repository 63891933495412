 
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { Button, Col, Typography } from "antd";
import { useUsers, useTableUsers } from "../../hooks";
import { TableCustom, usersService } from "../../../../shared";
import { IModelUsers } from "../../@types";
import { TableColumns, TableProperties } from "../../../../shared/components/ui/table/types";
import React from "react";

const { Title } = Typography;

export function TableUsers() {
  const {
    //state
    //methods
    //functions
    changeUsersForUpdate,
    showDeleteConfirm,
  
} =  useTableUsers()

const {
    //state
    isRefreshTableUsers,
    isLoading,
    updateStatus,
}= useUsers()

const tableRef = React.useRef<TableProperties>()

    React.useEffect(() => {
      tableRef.current?.refresh()
    }, [isRefreshTableUsers])

const columns: TableColumns<any> = [
    {
        title: 'ID',
        dataIndex: 'id',
        align: 'left',
        sorter:true,
      },
      {
        title: 'Nombre',
        dataIndex: 'first_name',
        align: 'left',
        filterType: 'text',
      },
      {
        title: 'Apellido',
        dataIndex: 'last_name',
        align: 'left',
        filterType: 'text',
      },
      {
        title: 'Correo',
        
        dataIndex: 'email',
        align: 'left',
        filterType: 'text',
  },
    {
        title: 'DNI',
        dataIndex: 'dni',
        align: 'left',
        filterType: 'text',
    },
    {
        title: 'Rol',
        
        dataIndex: 'role',
        align: 'left',
        filters: [
          {text: 'Super Admin', value: 'super_admin'},
          {text: 'Admin', value: 'admin'},
          {text: 'Usuario', value: 'user'},
      ]
    },
    {
        title: 'Celular',
        dataIndex: 'phone',
        align: 'left',
        filterType: 'text',
    },
    {
        title: 'direccion',
        width: 100,
        dataIndex: 'address',
        align: 'center',
    },
    // {
    //     title: 'genero',
    //     width: 100,
    //     dataIndex: 'gender',
    //     align: 'center',
    // },
    {
      title: 'estado',
      width: 100,
      dataIndex: 'status',
      align: 'left',
  },
    // {
    //   title: 'Fecha de Creación',
    //   width: 250,
    //   dataIndex: 'createdAt',
    //   align: 'left',
    //   render: (_:any, item:any) => (
    //     <Col
    //       style={{
    //         display: "flex",
    //         width: "100%",
    //         height: "50%",
    //         justifyContent: "start",
    //         flexDirection: "row",
    //       }}
    //     >
    //       <Title level={5}>
    //         {moment(item.createdAt).utcOffset(0).format("DD/MM/YYYY HH:mm:ss")}
    //       </Title>
    //     </Col>
    //   ),
    // },
  //   {
  //     title: "Estado",
  //     
  //     dataIndex: "status",
  //     key: "status",
  //     render: (_:any, item:any) => {
  //       return (
  //         <Col
  //         style={{
  //           display: "flex",
  //           width: "100%",
  //           justifyContent: "start",
  //           flexDirection: "row",
  //           // transform: "translateY(-50%)",
  //           }}
  //         >
  //           <button
  //           disabled={isLoading}
  //             onClick={()=>{
  //               if(item.status === "active"){
  //                 updateStatus("inactive", item.id)
  //               }else if( item.status === "inactive"){
  //                 updateStatus("active", item.id)
  //               }
  //             }}
  //             style={{
  //               display: "flex",
  //               padding: "5px 27px",
  //               borderWidth: 0,
  //               cursor: "pointer",
  //               borderRadius: "20px",
  //               backgroundColor:
  //                 item.status === "active" ? "#4bd542" : "#ff4d4f",
  //             }}
  //           >
  //             <Title
  //               style={{
  //                 marginBottom: "1.5px",
  //                 color: "white",
  //               }}
  //               level={5}
  //             >
  //               {item.status}
  //             </Title>
  //           </button>
  //         </Col>
  //       );
  //     },
  // },
    {
        title: "Acciones",
        key: "operation",
        fixed: "right",
        
        render: (_: any, item: any) => {
          return (
            <Col
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "start",
                flexDirection: "row",
              }}
            >
              <Button
                type="primary"
                shape="circle"
                onClick={() => changeUsersForUpdate(item.id)}
                icon={<EditOutlined />}
                size="large"
              />
              <Button
                type="primary"
                danger
                onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
                shape="circle"
                icon={<DeleteOutlined />}
                size="large"
              />
            </Col>
          );
        },
    },
]

  return (
    <TableCustom fetchQueryProps={{$limit: 10}} columns={columns}  service={usersService} ref={tableRef}  />
  )
}
