import { useForm } from "react-hook-form"
import { IModelUsers } from "../@types"
import React from "react"
import { usersService } from "../../../shared"
import { message } from "antd"

export default function useCreateUser(props?: { onSuccess?: (res: IModelUsers) => void }) {
    const [loading, setLoading] = React.useState(false)

    const formMethods = useForm<IModelUsers>()

    const loadingRef = React.useRef(false)

    const updateLoading = (status: boolean) => {
        loadingRef.current = status
        setLoading(status)
    }

    const handleSubmit = async (data: IModelUsers) => {
        if (loadingRef.current) return

        updateLoading(true)

        await usersService.create({...data, status: 'active'})
            .then(res => {
                message.success('Usuario creado')
                props?.onSuccess?.(res)
            })
            .catch(err => {
                message.error(err?.message)
            })
            .finally(() => {
                updateLoading(false)
            })
    }


    return {
        formMethods,
        loading,
        handleSubmit: formMethods.handleSubmit(handleSubmit)
    }
}