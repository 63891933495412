import { message } from "antd";
import { IModelNewsCategories } from "../../modules/news-categories/@types";
import { newsCategoriesService } from "../../shared";
import { newsCategoriesActions } from "../action-types";

export const changeModalStateNewsCategories = (): newsCategoriesActions.INewsCategoriesModalState =>({
    type: newsCategoriesActions.NEWS_CATEGORIES_MODAL_STATE
})

export const getSingleNewsCategories = (val:IModelNewsCategories ): newsCategoriesActions.IGetNewsCategoriesForUpdate=> ({
    type: newsCategoriesActions.GET_NEWS_CATEGORIES_FOR_UPDATE,
    payload: val
})

export const setIsNewsCategoriesForUpdate =(val: boolean): newsCategoriesActions.IIsNewsCategoriesForUpdate =>({
    type: newsCategoriesActions.IS_NEWS_CATEGORIES_FOR_UPDATE,
    payload: val,
})

export const fetchSingleNewsCategories = async(id: number) =>{
    return await newsCategoriesService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createNewCategories = async(data:Partial<IModelNewsCategories>) =>{
    return await newsCategoriesService.create(data)
    .then((res: IModelNewsCategories)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateNewCategories = async(data:Partial<IModelNewsCategories>) =>{
    return await newsCategoriesService.patch(data?.id!, data)
    .then((res: IModelNewsCategories)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteNewCategories = async(id: number) =>{
    return await newsCategoriesService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableNewsCategories = (val: boolean): newsCategoriesActions.ISwitchNewsCategoriesRefreshTable =>({
    type: newsCategoriesActions.SWITCH_NEWS_CATEGORIES_REFRESH_TABLE,
    payload: val
})

