import { useFormContext } from 'react-hook-form';
import { Button, Col, Typography } from "antd";
import { useSelector } from "react-redux";
import { InputNumber, InputSelect, InputText } from '../../../../shared';
import { selectModalCoursesCategories } from '../../../../redux';
import { useCoursesCategories } from '../../hooks';
import { IModelCoursesCategories } from '../../@types';
import { WrapperModalCourses } from '../styled';

const { Title } = Typography;

export function ModalCoursesCategories() {
  const modalCoursesCategories = useSelector(
    selectModalCoursesCategories
);

const {
    //state
    isLoading,
    isUpdateCoursesCategories,
    dataUpdateCoursesCategories,
    //methods
    //functions
    changeModalCoursesCategoriesStateForCreate,
    onSubmitCreateOrUpdate
} = useCoursesCategories()

const { control, handleSubmit: onSubmit } =
useFormContext<IModelCoursesCategories>();
return (
<WrapperModalCourses
title={ isUpdateCoursesCategories ? `Actualizando categoría de curso: '${dataUpdateCoursesCategories?.id!}'` : `Creando categoría de curso`}
  open={modalCoursesCategories}
  width={"320px"}
  destroyOnClose
  onClose={changeModalCoursesCategoriesStateForCreate}>
    <Col
        span={24}
        style={{
        display: "flex",
        marginTop: "10px",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        }}
    >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
            Nombre:
        </Title>
        <InputText placeHolder="Categoría de curso" name="name" control={control} />
    </Col>
    <Col
        span={24}
        style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
        }}
        >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
            Estado:
        </Title>
        <InputSelect
            name="status"
            control={control}
            dataOptions={[
                { title: "Activo", value: "active" },
                { title: "Inactivo", value: "inactive" },
            ]}
            label="Estado"
        />
    </Col>
    <Col
        span={24}
        style={{
        display: "flex",
        marginTop: "10px",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        }}
    >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
            Posición:
        </Title>
        <InputNumber
            placeHolder="123"
            name="position"
            control={control}
            />
    </Col>
    <Button
        style={{
        width: "100%",
        marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
    >
        {isUpdateCoursesCategories
        ? "Actualizar información"
        : "Crear categoría de curso"}
    </Button>
</WrapperModalCourses>
)
}
