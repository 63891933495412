import { IModelWebinarsIncludes } from "../../modules"


export const WEBINARS_INCLUDES_MODAL_STATE = '[ WEBINARS INCLUDES ] Webinars Includes Modal State'

export interface IWebinarsIncludesModalState {
    type: typeof WEBINARS_INCLUDES_MODAL_STATE
}

export const GET_WEBINARS_INCLUDES_FOR_UPDATE = '[ WEBINARS INCLUDES ] Get Webinars Includes For Update'

export interface IGetWebinarsIncludesForUpdate {
    type: typeof GET_WEBINARS_INCLUDES_FOR_UPDATE
    payload: IModelWebinarsIncludes
}

export const IS_WEBINARS_INCLUDES_FOR_UPDATE = '[ WEBINARS INCLUDES ] Is Webinars Includes For Update'

export interface IIsWebinarsIncludesForUpdate {
    type: typeof IS_WEBINARS_INCLUDES_FOR_UPDATE
    payload: boolean
}

export const SWITCH_WEBINARS_INCLUDES_REFRESH_TABLE = '[ WEBINARS INCLUDES ] Switch Webinars Includes Refresh Table'

export interface ISwitchWebinarsIncludesRefreshTable {
    type: typeof SWITCH_WEBINARS_INCLUDES_REFRESH_TABLE
    payload: boolean
}