import { Button, Col, Typography } from "antd";
import { useFormContext } from 'react-hook-form';
import { WrapperModalProjects } from "../styled";
import { InputNumber, InputText } from "../../../../shared";
import { useSelector } from "react-redux";
import { selectModalProjectsManagers } from "../../../../redux";
import { useProjectsManagers } from "../../hooks";
import { IModelProjectsManagers } from "../../@types";

const { Title } = Typography;

export function ModalProjectsManagers() {
  const modalProjectsManagers = useSelector(
    selectModalProjectsManagers
  );

  const {
    //state
    isLoading,
    isUpdateProjectsManagers,
    dataUpdateProjectsManagers,
    dataUpdateProjects,
    //methods
    //functions
    changeModalProjectsManagersStateForCreate,
    onSubmitCreateOrUpdate
  } = useProjectsManagers()

  const { control, handleSubmit: onSubmit } = useFormContext<IModelProjectsManagers>();

  return (
    <WrapperModalProjects
      title={ isUpdateProjectsManagers
          ? `Actualizando beneficios del Proyecto : '${dataUpdateProjects?.id! || ''}'`
          : `Creando beneficio del Proyecto` }
      open={modalProjectsManagers}
      width={"320px"}
      destroyOnClose
      onClose={changeModalProjectsManagersStateForCreate}>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Titulo:
          </Title>
          <InputText placeHolder={dataUpdateProjectsManagers?.title ?? ""} name="title" control={control} />
        </Col>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Items:
          </Title>
          <InputText placeHolder={dataUpdateProjectsManagers?.title ?? ""} name="item" control={control} />
        </Col>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Tipo:
          </Title>
          <InputText placeHolder={dataUpdateProjectsManagers?.type ?? ""} name="type" control={control} />
        </Col>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Path:
          </Title>
          <InputText placeHolder={dataUpdateProjectsManagers?.path ?? ""} name="path" control={control} />
        </Col>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Posición:
          </Title>
          <InputNumber
            placeHolder="123"
            name="position"
            control={control}
          />
        </Col>
        <Button
            style={{
            width: "100%",
            marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
        >
            {isUpdateProjectsManagers
            ? "Actualizar información"
            : "Crear beneficio del Project"}
        </Button>
    </WrapperModalProjects>
  )
}
