import { useForm } from "react-hook-form"
import { IModelBanners } from "../@types"
import React from "react"
import { bannersService } from "../../../shared"
import { message } from "antd"

export default function useCreateBanner(props?: { onSuccess?: (res: IModelBanners) => void }) {
    const [loading, setLoading] = React.useState(false)

    const formMethods = useForm<IModelBanners>()

    const loadingRef = React.useRef(false)

    const updateLoading = (status: boolean) => {
        loadingRef.current = status
        setLoading(status)
    }

    const handleSubmit = async (data: IModelBanners) => {
        if (loadingRef.current) return

        updateLoading(true)

        await bannersService.create(data)
            .then(res => {
                message.success('Banner creado')
                // props?.onSuccess?.(res)
            })
            .catch(err => {
                message.error(err?.message)
            })
            .finally(() => {
                updateLoading(false)
            })
    }


    return {
        formMethods,
        loading,
        handleSubmit: formMethods.handleSubmit(handleSubmit)
    }
}