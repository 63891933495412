import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { Link } from "react-router-dom";
import EditFormsAndModelsCategories from "../modules/Corresponsales/ui/EditFormsAndModelsCategories";

export default function EditCorresponsalesFormsAndModelsCategoriesPage() {
  return (
    <Drawer
      title="Editar categoría"
      open
      width={350}
      closeIcon={
        <Link to="/corresponsales/formatos-y-modelos-categorias">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <EditFormsAndModelsCategories />
    </Drawer>
  );
}
