import { Drawer, Tabs } from "antd";
import styled from "styled-components";

//modal Events

export const WrapperModalCompanies = styled(Drawer)``;


export const WrapperTabNavCompanies = styled(Tabs)`
  width: 100%;
`;