import { Tabs } from "../modules/formation-ratings/ui/components/tabs";
import { AdminLayout } from "../shared";
import WebinarsRatings from "../modules/formation-ratings/ui/WebinarsRatings";
import Title from "antd/es/typography/Title";

export default function WebinarsRatingsPage() {
  return (
    <AdminLayout>
      <Title>Calificaciones</Title>
      <Tabs>
        <WebinarsRatings />
      </Tabs>
    </AdminLayout>
  );
}
