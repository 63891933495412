import React from "react";

import { useForm } from "react-hook-form";
import { infographicCategoriesService } from "../../../shared";
import { message } from "antd";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IInfographicCategory } from "../@types";

export default function useCreateInfographicCategory(props?: {
  onSuccess?: (record: IInfographicCategory) => void;
}) {
  const [loading, setLoading] = React.useState(false);

  const formMethods = useForm<IInfographicCategory>({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required("Campo requerido"),
      })
    ),
  });

  const loadingRef = React.useRef(false);

  const updateLoading = (status: boolean) => {
    setLoading(status);
    loadingRef.current = status;
  };

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    if (loadingRef.current) return;

    updateLoading(true);

    await infographicCategoriesService
      .create(data)
      .then((record) => {
        props?.onSuccess?.(record);
        message.success("Categoría creada correctamente");
      })
      .catch((err) => {
        message.error(err?.message);
      })
      .finally(() => {
        updateLoading(false);
      });
  });

  return {
    formMethods,
    loading,
    handleSubmit,
  };
}
