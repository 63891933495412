import moment from "moment";
import { formExperienceRegistrationalServices} from "../../../shared";
import Table from "../../../shared/components/ui/table/TableCustom";


export default function Forms() {
  const columns = [
    { title: "ID", dataIndex: "id", sorter: true, filterType: "text" },
    { title: "Nombre", dataIndex: "meta_user_first_name", filterType: "text" },
    { title: "Apellidos", dataIndex: "meta_user_last_name", filterType: "text" },
    { title: "Email", dataIndex: "meta_user_email", filterType: "text" },
    { title: "Marca", dataIndex: "brand", filterType: "text" },
    { title: "N° documento", dataIndex: "meta_user_dni", filterType: "text" },
    { title: "Teléfono", dataIndex: "meta_user_phone", filterType: "text" },
    {
      title: "Fecha",
      dataIndex: "createdAt",
      sorter: true,
      filterType: "date_range",
      render: (date: string) => moment(date).format("DD-MM-YYYY"),
    },
  ]
  return (
    <Table
      service={formExperienceRegistrationalServices}
      download
      columns={columns}
    />
  );
}
