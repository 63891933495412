import { IModelEvents } from "../../modules/events/@types/IModelEvents"

export const GET_EVENTS_FOR_UPDATE = '[ EVENTS ] Get Events  For Update'

export interface IGetEventsForUpdate {
    type: typeof GET_EVENTS_FOR_UPDATE
    payload: IModelEvents
}

export const IS_EVENTS_FOR_UPDATE = '[ EVENTS ] Is Events  For Update'

export interface IIsEventsForUpdate {
    type: typeof IS_EVENTS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_EVENTS_REFRESH_TABLE = '[ EVENTS ] Switch Events  Refresh Table'

export interface ISwitchEventsRefreshTable {
    type: typeof SWITCH_EVENTS_REFRESH_TABLE
    payload: boolean
}