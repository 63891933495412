import { Modal, message } from "antd";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { changeModalStateCoursesAuthors, deleteCourseAuthors, fetchSingleCoursesAuthors, getSingleCoursesAuthors, refreshTableCoursesAuthors, setIsCoursesAuthorsForUpdate } from "../../../../redux";


const { confirm } = Modal;

export function useTableCoursesAuthors() {
  const dispatch = useAppDispatch();

  const changeCoursesAuthorsForUpdate = (valId: number) => {
      fetchSingleCoursesAuthors(valId)
      .then((res)=>{
      dispatch(getSingleCoursesAuthors(res.data[0]));
      dispatch(setIsCoursesAuthorsForUpdate(true));
      dispatch(changeModalStateCoursesAuthors());
      })
  }

  const showDeleteConfirm = (valId: number, val: string) => {
      confirm({
        title: `Estás seguro de borrar el/la autor/a del curso con el id: ${valId}`,
        icon: <ExclamationCircleOutlined />,
        content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar autor/a" en caso tal solo oprime en "Cancelar".`,
        okText: "Borrar autor/a",
        okType: "danger",
        cancelText: "Cancelar",
        keyboard: true,
        maskClosable: true,
        async onOk() {
          return await deleteCourseAuthors(valId)
            .then((res) => {
              dispatch(refreshTableCoursesAuthors(true));
              if(res.name){
                return message.success(`Se ha borrado con éxito el/la autor/a con id:${valId}`);
              }else if(res){
                console.log({res})
                return message.error(`${res}`);
              }
            })
            .catch((err: any) => {
              console.log({ err });
              return message.error(err.message);
            });
        },
        onCancel() {},
      });
    };
    return{
      //state
      //methods
      //functions
      changeCoursesAuthorsForUpdate,
      showDeleteConfirm,
    }
}
