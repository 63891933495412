import { Spin } from "antd";
import { WrapperTabNavCourses } from "../styled";
import { FormProvider } from 'react-hook-form';

import { useCourses, useCoursesById } from "../../hooks";
import { CoursesForm } from "./CoursesForm";
import { CoursesAuthors } from "../courses-authors";
import { CoursesBenefits } from "../courses-benefits";
import { CoursesIncludes } from "../courses-includes";
import { CoursesRequirements } from "../courses-requirements";
import { CoursesChapters } from "../courses-chapters";
import { CoursesSections } from "../courses-sections";
import { useParams } from "react-router-dom";
import Clusters from "./Clusters";



export function CoursesById() {
  const { isUpdateCourses, formMethodsCreate, formMethodsUpdate } =
    useCourses();
  const { isValidate } = useCoursesById();

  const {id: courseId} = useParams();

  return (
    <div style={{ width: "100%" }}>
      {!isValidate && <Spin size="large" />}
      {isValidate &&(
        <>
          {!isUpdateCourses && (
            <FormProvider {...formMethodsCreate}>
              <CoursesForm />
            </FormProvider>
          )}
          {isUpdateCourses && (
            <WrapperTabNavCourses
              items={[
                {
                  label: `DATOS DEL CURSO`,
                  key: "1",
                  children: (
                    <FormProvider {...formMethodsUpdate}>
                      <CoursesForm />
                    </FormProvider>
                  ),
                },
                {
                  label: 'CLUSTERS DEL CURSO',
                  key: "2",
                  children: <Clusters courseId={courseId ? +courseId : undefined} />
                },
                {
                  label: `AUTORES DEL CURSO`,
                  key: "3",
                  children: (
                    <CoursesAuthors />
                  ),
                },
                {
                  label: `BENEFICIOS DEL CURSO`,
                  key: "4",
                  children: (
                    <CoursesBenefits />
                  ),
                },
                {
                  label: `CAPÍTULOS DEL CURSO`,
                  key: "5",
                  children: (
                    <CoursesChapters />
                  ),
                },
                {
                  label: `INCLUSIONES DEL CURSO`,
                  key: "6",
                  children: (
                    <CoursesIncludes />
                  ),
                },
                {
                  label: `REQUERIMIENTOS DEL CURSO`,
                  key: "7",
                  children: (
                    <CoursesRequirements />
                  ),
                },
                {
                  label: `SECCIONES DEL CURSO`,
                  key: "8",
                  children: (
                    <CoursesSections />
                  ),
                },
              ]}
            />
          )}
      </>
    )}
  </div>
  )
}
