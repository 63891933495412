import { useSelector } from "react-redux";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useEffect, useState } from "react";
import { message } from "antd";
import { changeModalStateProjectsObjectives, createProjectObjectives, refreshTableProjectsObjectives, selectIsUpdateProjectsObjectives, selectRefreshTableProjectsObjectives, selectSingleProjects, selectSingleProjectsObjectives, setIsProjectsObjectivesForUpdate, updateProjectObjectives } from "../../../../redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { IModelProjectsObjectives } from "../../@types";
import { useForm } from "react-hook-form";
import { validateCreateProjectsObjectives, validateUpdateProjectsObjectives } from "../../../../shared/utils/yupValidation";

export function useProjectsObjectives() {
  const dispatch = useAppDispatch();

  const isUpdateProjectsObjectives = useSelector(
      selectIsUpdateProjectsObjectives
  );

  const dataUpdateProjectsObjectives = useSelector(
      selectSingleProjectsObjectives
  );

  const isRefreshTableProjectsObjectives = useSelector(
      selectRefreshTableProjectsObjectives
  );

  const dataUpdateProjects = useSelector( selectSingleProjects );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalProjectsObjectivesStateForCreate = () => {
    dispatch(setIsProjectsObjectivesForUpdate(false))
    dispatch(changeModalStateProjectsObjectives())
  }

  const formMethodsCreate = useForm<IModelProjectsObjectives>({
    resolver: yupResolver(validateCreateProjectsObjectives),
  });

  const formMethodsUpdate = useForm<IModelProjectsObjectives>({
    resolver: yupResolver(validateUpdateProjectsObjectives),
  });

  useEffect(() => {
  if (isUpdateProjectsObjectives) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateProjectsObjectives });
  }

  return () => {
      formMethodsUpdate.reset();
  };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateProjectsObjectives, dataUpdateProjectsObjectives]);

  const onSubmitCreateOrUpdate = async (data: IModelProjectsObjectives) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateProjectsObjectives) {
      await updateProjectObjectives({
        id: data.id,
        title: data.title,
        items: data.items,
        linkACtion: data.linkACtion,
        textButtom: data.textButtom,
        position: data.position,
        project_id: dataUpdateProjects?.id,
      })
        .then((res: Partial<IModelProjectsObjectives>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito los requerimientos con id:${res.id}`
            );
            dispatch(refreshTableProjectsObjectives(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createProjectObjectives({...data, project_id: dataUpdateProjects?.id})
        .then((res: IModelProjectsObjectives) => {
          if (res.id) {
            dispatch(changeModalStateProjectsObjectives());
            message.success(
              `Se ha creado con éxito los requerimientos con id:${res.id}`
            );
            dispatch(refreshTableProjectsObjectives(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableProjectsObjectives) {
      dispatch(refreshTableProjectsObjectives(false));
    }
  }, [isRefreshTableProjectsObjectives]);

  return{
      //state
      isLoading,
      isUpdateProjectsObjectives,
      dataUpdateProjectsObjectives,
      dataUpdateProjects,
      isRefreshTableProjectsObjectives,
      //methods
      formMethodsCreate,
      formMethodsUpdate,
      //functions
      changeModalProjectsObjectivesStateForCreate,
      onSubmitCreateOrUpdate,
  }
}
