import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { newsService } from "../../../shared";
import { message } from "antd";
import { getSingleNews, setIsNewsForUpdate } from "../../../redux";

export  function useNewsById() {
    const [isValidate, setIsValidate ] = useState(false)

    const { pathname } = useLocation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const getSingleNewsFetch = async ( id: number) =>{
        return await newsService
        .get(id)
        .then((res) => {
            return res;
        })
        .catch((err: any) => {
            console.log(err);
            navigate(`/news`);
            message.error(err.message);
        });
    }

    useEffect(() => {
        if( pathname !== "/news/new" ){
          if (isNaN(Number(pathname.split("/")[2]))) return navigate(`/news`);
            dispatch(setIsNewsForUpdate(true));
            getSingleNewsFetch(Number(pathname.split("/")[2])).then((res) =>
                dispatch(getSingleNews(res as any))
            );
            setIsValidate(true)
        }else if(pathname === "/news/new" ){
            dispatch(setIsNewsForUpdate(false));
            setIsValidate(true)
        }
    }, [])

  return {
    //state
    isValidate,
    //methods
    //functions
  }
}
