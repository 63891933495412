import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { Link } from "react-router-dom";
import CreateCmsLawTransparency from "../modules/cms-law-transparency-page/ui/create-cms-law-transparency";

export default function CreateCmsLawTransparencyPage() {
  return (
    <Drawer
      title="Agregar ley de transparencia"
      open
      width={350}
      destroyOnClose
      closeIcon={
        <Link to="/ley-de-transparencia">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <CreateCmsLawTransparency />
    </Drawer>
  );
}
