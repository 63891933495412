import { message } from "antd";

import { eventsSchedulesActions } from "../action-types";
import { IModelEventsSchedules } from "../../modules";
import { eventsSchedulesService } from "../../shared";

export const changeModalStateEventsSchedules = (): eventsSchedulesActions.IEventsSchedulesModalState =>({
    type: eventsSchedulesActions.EVENTS_SCHEDULES_MODAL_STATE
})

export const getSingleEventsSchedules = (val:IModelEventsSchedules ): eventsSchedulesActions.IGetEventsSchedulesForUpdate=> ({
    type: eventsSchedulesActions.GET_EVENTS_SCHEDULES_FOR_UPDATE,
    payload: val
})

export const setIsEventsSchedulesForUpdate =(val: boolean): eventsSchedulesActions.IIsEventsSchedulesForUpdate =>({
    type: eventsSchedulesActions.IS_EVENTS_SCHEDULES_FOR_UPDATE,
    payload: val,
})

export const fetchSingleEventsSchedules = async(id: number) =>{
    return await eventsSchedulesService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createEventSchedules = async(data:Partial<IModelEventsSchedules>) =>{
    return await eventsSchedulesService.create(data)
    .then((res: IModelEventsSchedules)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateEventSchedules = async(data:Partial<IModelEventsSchedules>) =>{
    return await eventsSchedulesService.patch(data?.id!, data)
    .then((res: IModelEventsSchedules)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteEventSchedules = async(id: number) =>{
    return await eventsSchedulesService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableEventsSchedules = (val: boolean): eventsSchedulesActions.ISwitchEventsSchedulesRefreshTable =>({
    type: eventsSchedulesActions.SWITCH_EVENTS_SCHEDULES_REFRESH_TABLE,
    payload: val
})

