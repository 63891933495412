import { Typography } from "antd";
import FormsBrandRegistration from "../modules/form-brand-registration/ui/Forms";
import { AdminLayout } from "../shared";
import { Outlet } from "react-router-dom";

export default function FormsBrandRegistrationPage() {
  return (
    <AdminLayout>
      <div style={{ width: "100%" }}>
        <Typography.Title>Formulario Registro de Marca</Typography.Title>
        <FormsBrandRegistration />
      </div>
    </AdminLayout>
  );
}
