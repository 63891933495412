import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";

import { selectModalWebinarsIncludes } from "../../../../redux";
import { useWebinarsIncludes } from "../../hooks";
import { ModalWebinarsIncludes } from "./ModalWebinarsIncludes";
import { TableWebinarsIncludes } from "./TableWebinarsIncludes";

export function WebinarsIncludes() {
  const modalWebinarsIncludes = useSelector(
    selectModalWebinarsIncludes
  );

  const {
    //state
    isUpdateWebinarsIncludes,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalWebinarsIncludesStateForCreate,
  } = useWebinarsIncludes();

  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
            onClick={changeModalWebinarsIncludesStateForCreate}
          >
            Agregar inclusion a webinar
          </Button>
        </Col>
        <TableWebinarsIncludes />
        {
          modalWebinarsIncludes && (
            <FormProvider { ...isUpdateWebinarsIncludes ? {...formMethodsUpdate} : {...formMethodsCreate}}>
              <ModalWebinarsIncludes />
            </FormProvider>
          )
        }
    </Row>
  )
}
