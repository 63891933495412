import { useSelector } from "react-redux";
import { Button, Col, Typography } from "antd";
import { useFormContext } from 'react-hook-form';
import { selectModalEventsSchedules } from "../../../../redux";
import { useEventsSchedules } from "../../hooks";
import { IModelEventsSchedules } from "../../@types";
import { WrapperModalEvents } from "../styled";
import { InputSelect, InputText, InputTimePicker } from "../../../../shared";

const { Title } = Typography;

export function ModalEventsSchedules() {
  const modalEventsSchedules = useSelector(
    selectModalEventsSchedules
  );

  const {
    //state
    isLoading,
    isUpdateEventsSchedules,
    dataUpdateEventsSchedules,
    //methods
    //functions
    changeModalEventsSchedulesStateForCreate,
    onSubmitCreateOrUpdate
  } = useEventsSchedules()

  const { control, handleSubmit: onSubmit } = useFormContext<IModelEventsSchedules>();
  return (
  <WrapperModalEvents
    title={ isUpdateEventsSchedules ? `Actualizando categoría de evento: '${dataUpdateEventsSchedules?.id!}'` : `Creando categoría de evento` }
    open={modalEventsSchedules}
    width={"320px"}
    destroyOnClose
    onClose={changeModalEventsSchedulesStateForCreate}>
      <Col
        span={24}
        style={{
        display: "flex",
        marginTop: "10px",
        padding: 5,
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre:
        </Title>
        <InputText placeHolder="Categoría de evento" name="name" control={control} />
      </Col>
      <Col
        span={24}
        style={{
        display: "flex",
        marginTop: "10px",
        padding: 5,
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        }}>
          <Title style={{ marginBottom: "1.5px" }} level={5}>
          Descripción:
          </Title>
          <InputText
            placeHolder="Lorem Ipsum..."
            name="description"
            control={control}
            rows
          />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Dia:
        </Title>
        <InputSelect
          name="day"
          control={control}
          dataOptions={[
            { title: "1", value: "1" },
            { title: "2", value: "2" },
            { title: "3", value: "3" },
            { title: "4", value: "4" },
            { title: "5", value: "5" },
            { title: "6", value: "6" },
            { title: "7", value: "7" },
            { title: "8", value: "8" },
            { title: "9", value: "9" },
            { title: "10", value: "10" },
            { title: "11", value: "11" },
            { title: "12", value: "12" },
            { title: "13", value: "13" },
            { title: "14", value: "14" },
            { title: "15", value: "15" },
            { title: "16", value: "16" },
            { title: "17", value: "17" },
            { title: "18", value: "18" },
            { title: "19", value: "19" },
            { title: "20", value: "20" },
            { title: "21", value: "21" },
            { title: "22", value: "22" },
            { title: "23", value: "23" },
            { title: "24", value: "24" },
            { title: "25", value: "25" },
            { title: "26", value: "26" },
            { title: "27", value: "27" },
            { title: "28", value: "28" },
            { title: "29", value: "29" },
            { title: "30", value: "30" },
            { title: "31", value: "31" },
          ]}
          label="Estado"
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Hora de inicio:
        </Title>
        <InputTimePicker
          control={control}
          name="start_time"
          placeHolder="Hora de inicio"
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Hora de finalización:
        </Title>
        <InputTimePicker
          control={control}
          name="end_time"
          placeHolder="Hora de finalización"
        />
      </Col>
      <Button
        style={{
        width: "100%",
        marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}>
            {isUpdateEventsSchedules
            ? "Actualizar información"
            : "Crear horario del evento"}
        </Button>
    </WrapperModalEvents>
  )
}
