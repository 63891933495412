import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { useProjectsManagers } from "../../hooks/projects-managers/useProjectsManagers";
import { selectModalProjectsManagers } from "../../../../redux";
import { ModalProjectsManagers } from "./ModalProjectsManagers";
import { TableProjectsManagers } from "./TableProjectsManagers";

export function ProjectsManagers() {
  const modalProjectsManagers = useSelector(
    selectModalProjectsManagers
  );

  const {
    //state
    isUpdateProjectsManagers,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalProjectsManagersStateForCreate,
  } = useProjectsManagers();

  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalProjectsManagersStateForCreate}
            >
              Agregar nuevo director a Proyectos
            </Button>
        </Col>
        <TableProjectsManagers />
        {
            modalProjectsManagers && (
              <FormProvider { ...isUpdateProjectsManagers ?{...formMethodsUpdate} : {...formMethodsCreate}}>
                <ModalProjectsManagers />
              </FormProvider>
            )
        }
    </Row>
  )
}
