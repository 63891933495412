import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { Link } from "react-router-dom";
import EditMarketingMaterial from "../modules/Corresponsales/ui/EditMarketingMaterial";

export default function EditCorresponsalesMarketingMaterialPage() {
  return (
    <Drawer
      title="Editar material de marketing"
      open
      width={350}
      destroyOnClose
      closeIcon={
        <Link to="/corresponsales/material-de-marketing">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <EditMarketingMaterial />
    </Drawer>
  );
}
