import { AdminLayout } from "../shared";
import FormsAndModels from "../modules/Corresponsales/ui/FormsAndModels";
import { Button, Flex } from "antd";
import { Link, Outlet } from "react-router-dom";
import React from "react";
import FormsAndModelsLayout from "../modules/Corresponsales/ui/components/FormsAndModelsLayout";

export default function CorresponsalesFormsAndModelsPage() {
  return (
    <AdminLayout>
      <FormsAndModelsLayout>
        <Flex justify="flex-end">
          <Link to="/corresponsales/formatos-y-modelos/agregar">
            <Button type="primary">+ Formato/Modelo</Button>
          </Link>
        </Flex>

        <FormsAndModels />
        <Outlet />
      </FormsAndModelsLayout>
    </AdminLayout>
  );
}
