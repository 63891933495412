import React from "react";
import { useForm } from "react-hook-form";
import { ICorresponsalesFormsAndModels } from "../../../@types/dto/corresponsales-forms-and-models";
import { corresponsalesFormsAndModelsService } from "../../../shared";
import { message } from "antd";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export default function useEditFormAndModel(
  recordId?: number | null,
  props?: { onSuccess?: (record: ICorresponsalesFormsAndModels) => void }
) {
  const [loading, setLoading] = React.useState(false);

  const formMethods = useForm<ICorresponsalesFormsAndModels>({
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().required("Campo requerido"),
        category_id: yup.number().integer().required("Campo requerido"),
        resource_path: yup.string().required("Campo requerido"),
      })
    ),
  });

  const loadingRef = React.useRef(false);

  const updateLoading = (status: boolean) => {
    setLoading(status);
    loadingRef.current = status;
  };

  const getRecord = async () => {
    if (loadingRef.current || !recordId) return;

    updateLoading(true);

    await corresponsalesFormsAndModelsService
      .get(recordId)
      .then((record) => {
        formMethods.reset({
          title: record.title,
          resource_path: record.resource_path,
          category_id: record.category_id,
        });
      })
      .finally(() => {
        updateLoading(false);
      });
  };

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    if (loadingRef.current || !recordId) return;

    updateLoading(true);

    await corresponsalesFormsAndModelsService
      .patch(recordId, data)
      .then((record) => {
        message.success("Registro actualizado correctamente");
        props?.onSuccess?.(record as ICorresponsalesFormsAndModels);
      })
      .catch((err) => {
        alert(err?.message);
        message.error(err?.message);
      })
      .finally(() => {
        updateLoading(false);
      });
  });

  React.useEffect(() => {
    if (recordId) {
      getRecord();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId]);

  return {
    formMethods,
    loading,
    handleSubmit,
  };
}
