// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import "moment/min/locales";
import { DatePicker, DatePickerProps, Typography } from "antd";
import moment2 from "moment";
import { Controller, FieldErrorsImpl } from "react-hook-form";

import { InputError } from "./styled";

interface Props {
  width?: string;
  placeHolder?: string;
  name: string;
  dateFormat?: string;
  control: any;
  defaultValue?: boolean;
  labelText?: string;
}

interface PropsContent {
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  name: string;
  width: string;
  onChange: (...event: any[]) => void;
  value: any;
  placeHolder: string;
  dateFormat?: string;
  defaultValue?: boolean;
  labelText?: string;
}

// const dateFormat = "DD-MM-YYYY";

const DataContent = ({
  width,
  dateFormat,
  errors,
  placeHolder,
  onChange,
  value,
  name,
  defaultValue,
  labelText,
}: PropsContent) => {
  const onChange2: DatePickerProps["onChange"] = (date, dateString) => {
    onChange(dateString);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width,
        height: "80%",
        flexDirection: "column",
      }}
    >
      {labelText && (
        <Typography.Text style={{ fontWeight: "600" }}>
          {labelText}
        </Typography.Text>
      )}
      <DatePicker
        format={dateFormat ? dateFormat : "YYYY-MM-DD"}
        showTime={dateFormat ? true : false}
        style={{ width: "100%" }}
        disabledDate={(value: any) =>
          value ? value.isBefore(moment2().subtract(1, "day")) : false
        }
        status={errors[name] ? "error" : ""}
        placeholder={placeHolder}
        onChange={onChange2}
        value={value && moment2(value)}
      />
      {!!errors[name] && <InputError>{errors?.[name]?.message}</InputError>}
    </div>
  );
};

export const InputData = ({
  width = "100%",
  placeHolder,
  dateFormat,
  name,
  control,
  defaultValue = false,
  labelText,
}: Props) => {
  return (
    <Controller
      shouldUnregister
      control={control}
      name={name}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        return (
          <DataContent
            labelText={labelText}
            placeHolder={placeHolder}
            errors={errors}
            name={name}
            onChange={onChange}
            value={value}
            // value={moment2(value).utcOffset(0)}
            width={width}
            dateFormat={dateFormat}
            defaultValue={defaultValue}
          />
        );
      }}
    />
  );
};
