
import CreateBanner from "../modules/banners/ui/CreateBanners";
import { AdminLayout } from "../shared";

export const CreateBannerPage = () => {
  return (
    <AdminLayout>
      <CreateBanner />
    </AdminLayout>
  )
};
