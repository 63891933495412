
import { IModelCoursesCategories } from '../../modules';
import { ICoursesCategoriesState } from '../@types/coursesCategories';

import { coursesCategoriesActions } from '../action-types'

type coursesCategoriesActionType =
| { type: typeof coursesCategoriesActions.COURSES_CATEGORIES_MODAL_STATE; }
| { type: typeof coursesCategoriesActions.GET_COURSES_CATEGORIES_FOR_UPDATE; payload: IModelCoursesCategories }
| { type: typeof coursesCategoriesActions.IS_COURSES_CATEGORIES_FOR_UPDATE; payload: boolean }
| { type: typeof coursesCategoriesActions.SWITCH_COURSES_CATEGORIES_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: ICoursesCategoriesState = {
    coursesCategoriesModalIsOpen: false,
    isUpdateCoursesCategories: false,
    coursesCategoriesForUpdate: null,
    refreshTableCoursesCategories: false,
}

export default function coursesCategoriesReducer(state: ICoursesCategoriesState = INITIAL_STATE, action: coursesCategoriesActionType): ICoursesCategoriesState {
  switch (action.type) {
    case coursesCategoriesActions.COURSES_CATEGORIES_MODAL_STATE:
        return {
            ...state,
            coursesCategoriesModalIsOpen: !state.coursesCategoriesModalIsOpen,
        }
    case coursesCategoriesActions.GET_COURSES_CATEGORIES_FOR_UPDATE:
        return {
            ...state,
            coursesCategoriesForUpdate: action.payload,
        }
    case coursesCategoriesActions.IS_COURSES_CATEGORIES_FOR_UPDATE:
        return {
            ...state,
            isUpdateCoursesCategories: action.payload,
        }
    case coursesCategoriesActions.SWITCH_COURSES_CATEGORIES_REFRESH_TABLE:
        return {
            ...state,
            refreshTableCoursesCategories: action.payload,
        }
    default:
        return state
  }
}
