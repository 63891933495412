import { Typography } from "antd";
import { AdminLayout } from "../shared";
import Comments from "../modules/comments/ui/Comments";

export default function FeedbackGeneralSurveyPage() {
  return (
    <AdminLayout>
      <div style={{ width: "100%" }}>
        <Typography.Title>Comentarios</Typography.Title>
        <Comments />
      </div>
    </AdminLayout>
  );
}
