
import { IModelRegistryServicesConsultNews } from '../../modules/registry-services-consult-news';
import { IRegistryServicesConsultNewState } from '../@types';

import { RegistryServicesConsultNewsActions } from '../action-types'

type eventsActionType =
| { type: typeof RegistryServicesConsultNewsActions.GET_REGISTRY_SERVICES_CONSULT_NEWS_FOR_UPDATE; payload: IModelRegistryServicesConsultNews }
| { type: typeof RegistryServicesConsultNewsActions.IS_REGISTRY_SERVICES_CONSULT_NEWS_FOR_UPDATE; payload: boolean }
| { type: typeof RegistryServicesConsultNewsActions.SWITCH_REGISTRY_SERVICES_CONSULT_NEWS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IRegistryServicesConsultNewState = {
    isUpdateRegistryServicesConsultNews: false,
    RegistryServicesConsultNewForUpdate:null,
    refreshTableRegistryServicesConsultNews: false
}

export default function eventsReducer(state: IRegistryServicesConsultNewState = INITIAL_STATE, action: eventsActionType): IRegistryServicesConsultNewState {
  switch (action.type) {
    case RegistryServicesConsultNewsActions.GET_REGISTRY_SERVICES_CONSULT_NEWS_FOR_UPDATE:
        return {
            ...state,
            RegistryServicesConsultNewForUpdate: action.payload,
        }
    case RegistryServicesConsultNewsActions.IS_REGISTRY_SERVICES_CONSULT_NEWS_FOR_UPDATE:
        return {
            ...state,
            isUpdateRegistryServicesConsultNews: action.payload,
        }
    case RegistryServicesConsultNewsActions.SWITCH_REGISTRY_SERVICES_CONSULT_NEWS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableRegistryServicesConsultNews: action.payload,
        }
    default:
        return state
  }
}
