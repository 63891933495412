import { Resources } from "../modules/resources-admin";
import { AdminLayout } from "../shared";

export function ResourcesAdminPage() {
  return (
    <AdminLayout>
      <Resources />
    </AdminLayout>
  )
}
