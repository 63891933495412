import * as yup from 'yup'

export const validateCreateAuthors = yup.object().shape({
    first_name:yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El o los nombre/s debe tener mínimo de 2 caracteres.'),
    last_name:yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El o los apellido/s debe tener mínimo de 2 caracteres.'),
    user_id:yup.number(),
    // .required('Campo requerido.')
    // avatar_path:yup
    // .string()
    // .min(1, 'Debes de incluir mínimo una imagen.'),
    profession:yup
    .string()
    .min(2, 'La profesión debe tener mínimo de 2 caracteres.'),
    description:yup
    .string()
    .min(2, 'La descripción debe tener mínimo de 2 caracteres.'),
})