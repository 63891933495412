import { AdminLayout } from "../shared";
import { KeyAccountManagers } from "../modules";

export const KeyAccountManagersPage = () => {
  return (
    <AdminLayout>
      <KeyAccountManagers />
    </AdminLayout>
  );
};
