import React from "react";
import { WidgetItemWrapper } from "./styled";
import { DragOutlined } from "@ant-design/icons";

export interface IWidget {
  name: string;
  description: string;
  placeholder: string;
  key_name: string;
  ratio: number;
  offsetWidth: number;
  offsetHeight: number;
}

export default function Widget({ field }: { field: IWidget }) {
  const [isDragging, setIsDragging] = React.useState(false);

  const handleDragStart = (e: React.DragEvent<HTMLLIElement>) => {
    setIsDragging(true);

    e.dataTransfer.setData(
      "widget",
      JSON.stringify({
        ...field,
        offsetWidth: e.currentTarget.offsetWidth,
        offsetHeight: e.currentTarget.offsetHeight,
      })
    );
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  return (
    <WidgetItemWrapper
      isDragging={isDragging}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      draggable
    >
      <span
        style={{
          fontWeight: "bold",
          fontSize: "1.1em",
        }}
      >
        {field.name}
      </span>
      <p
        style={{
          margin: 0,
          color: "#666",
        }}
      >
        {field.description}
      </p>
      <span
        className="drag-icon"
        style={{
          position: "absolute",
          right: 8,
          top: "50%",
          transform: "translateY(-50%)",
          color: "#666",
        }}
      >
        <DragOutlined />
      </span>
    </WidgetItemWrapper>
  );
}
