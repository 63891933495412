import { IForm } from "../../@types/dto/forms";
import { formsService } from "../../shared";

const api = {
  forms: {
    get: async (formId: number): Promise<IForm> => {
      return await formsService.get(formId);
    },
  },
};

export default api;
