import React from "react";
import { Resizable } from "react-resizable";
import {
  CloseWidget,
  MoveHandle,
  ResizeWidget,
  StyleOptions,
  WidgetDiv,
  WidgetName,
  WidgetWrapper,
} from "./styled";
import {
  ExpandOutlined,
  CloseOutlined,
  DragOutlined,
  SettingOutlined,
  BoldOutlined,
  ItalicOutlined,
  AlignCenterOutlined,
} from "@ant-design/icons";
import useResizable from "./lib/field/useResizable";
import useDraggable from "./lib/field/useDraggable";
import { Button, Input } from "antd";
import { Popover } from "antd/lib";

export type ID = string | number;

export interface IField {
  id: ID;
  name: string;
  description: string;
  placeholder: string;
  key_name: string;
  left: number;
  top: number;
  width: number;
  height: number;
  styles: React.CSSProperties;
}

export const fieldDefaultStyles: React.CSSProperties = {
  fontWeight: "normal",
  fontStyle: "normal",
  fontSize: 16,
  textAlign: "start",
};

export default function Field({
  field,
  selected,
  onChange,
  onRemove,
  onClick,
}: {
  selected?: boolean;
  field: IField;
  onClick?: () => void;
  onRemove?: () => void;
  onChange: (field: IField) => void;
}) {
  const { sizes, resizableContainerRef, onResize, onResizeStop } = useResizable(
    {
      onChange(size) {
        onChange({
          ...field,
          ...size,
          left: field.left,
          top: field.top,
        });
      },
    }
  );
  const { listeners } = useDraggable({
    draggableNodeRef: resizableContainerRef,
    onChange(position) {
      onChange({
        ...field,
        ...position,
        width: field.width,
        height: field.height,
      });
    },
  });

  return (
    <Resizable
      width={sizes.width}
      onResize={onResize}
      onResizeStop={onResizeStop}
      height={sizes.height}
      handle={
        <ResizeWidget selected={selected}>
          <ExpandOutlined />
        </ResizeWidget>
      }
    >
      <WidgetWrapper
        ref={resizableContainerRef}
        style={{
          top: `${field.top}%`,
          left: `${field.left}%`,
          width: `${field.width}%`,
          height: `${field.height}%`,
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick?.();
        }}
      >
        <div style={{ pointerEvents: selected ? "initial" : "none" }}>
          <MoveHandle className="pop-up" selected={selected} {...listeners}>
            <WidgetName>{field.name}</WidgetName>
          </MoveHandle>
          <MoveHandle className="grey" selected={selected} {...listeners}>
            <DragOutlined />
          </MoveHandle>
          <CloseWidget
            selected={selected}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRemove && onRemove();
            }}
          >
            <CloseOutlined />
          </CloseWidget>

          <Popover
            content={
              <div>
                <div style={{ display: "flex", gap: 3 }}>
                  <Button
                    icon={<BoldOutlined />}
                    size="small"
                    onClick={() => {
                      const { styles } = field;
                      onChange({
                        ...field,
                        styles: {
                          ...styles,
                          fontWeight:
                            styles.fontWeight === "bold" ? "normal" : "bold",
                        },
                      });
                    }}
                    type={
                      field.styles.fontWeight === "bold" ? "primary" : "default"
                    }
                  />
                  <Button
                    icon={<ItalicOutlined />}
                    size="small"
                    onClick={() => {
                      const { styles } = field;
                      onChange({
                        ...field,
                        styles: {
                          ...styles,
                          fontStyle:
                            styles.fontStyle === "italic" ? "normal" : "italic",
                        },
                      });
                    }}
                    type={
                      field.styles.fontStyle === "italic"
                        ? "primary"
                        : "default"
                    }
                  />
                  <Button
                    icon={<AlignCenterOutlined />}
                    size="small"
                    onClick={() => {
                      const { styles } = field;
                      onChange({
                        ...field,
                        styles: {
                          ...styles,
                          textAlign:
                            styles.textAlign === "center" ? "start" : "center",
                        },
                      });
                    }}
                    type={
                      field.styles.textAlign === "center"
                        ? "primary"
                        : "default"
                    }
                  />
                  <Input
                    prefix={<strong>T</strong>}
                    style={{ width: 90 }}
                    type="number"
                    step={2}
                    value={field.styles.fontSize}
                    onChange={(e) => {
                      const { styles } = field;
                      onChange({
                        ...field,
                        styles: {
                          ...styles,
                          fontSize: +e.target.value,
                        },
                      });
                    }}
                    min={10}
                    max={32}
                    size="small"
                    suffix="px"
                  />
                </div>
              </div>
            }
            placement="bottom"
            trigger={["click"]}
          >
            <StyleOptions
              type="primary"
              size="small"
              selected={selected}
              icon={<SettingOutlined />}
            />
          </Popover>

          <WidgetDiv style={field.styles} selected={selected}>
            {field.description}
          </WidgetDiv>
        </div>
      </WidgetWrapper>
    </Resizable>
  );
}
