import { Button, Col, Row, Typography } from "antd";
import { WrapperCardLogin, WrapperColLogin } from "./styledLogin";
import { FormData, useLogin } from "../hooks";
import { useFormContext } from "react-hook-form";
import { InputPassword, InputText } from "../../../shared";

const { Title } = Typography;

export default function Login() {

  const { isLoading, onLoginUser } = useLogin();
  const { control, handleSubmit: onSubmit } = useFormContext<FormData>();

  return (
    <Row justify="center" align="middle">
        <WrapperColLogin span={24}>
            <WrapperCardLogin>
              <img
              src={"/logo-ccbq.svg"}
              alt="logoCCBQ"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
              <Col
              span={24}
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                Email:
              </Title>
              <InputText
                placeHolder="example@example.com"
                name="email"
                control={control}
              />
            </Col>
            <Col
              span={24}
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                Contraseña:
              </Title>
              <InputPassword name="password" control={control} />
            </Col>
            <Col
              span={24}
              style={{
                display: "flex",
                marginTop: "18px",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Button
                style={{ width: "100%" }}
                type="primary"
                htmlType="submit"
                shape="round"
                size="large"
                onClick={onSubmit(onLoginUser)}
                loading={isLoading}
              >
                Ingresar
              </Button>
            </Col>
            </WrapperCardLogin>
        </WrapperColLogin>
    </Row>
  )
}
