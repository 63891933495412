import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from 'react-hook-form';
import { selectModalCoursesCategories } from "../../../../redux";
import { TableCoursesCategories } from "./TableCoursesCategories";
import { ModalCoursesCategories } from "./ModalCoursesCategories";
import { useCoursesCategories } from "../../hooks";



export function CoursesCategories() {
  const modalCoursesCategories = useSelector(
    selectModalCoursesCategories
  );

  const {
    //state
    isUpdateCoursesCategories,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalCoursesCategoriesStateForCreate,
  } = useCoursesCategories();

  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalCoursesCategoriesStateForCreate}
            >
              Agregar nueva categoría de curso
            </Button>
        </Col>
        <TableCoursesCategories />
        {
            modalCoursesCategories && (
                <FormProvider { ...isUpdateCoursesCategories ?{...formMethodsUpdate} : {...formMethodsCreate}}>
                    <ModalCoursesCategories />
                </FormProvider>
            )
        }
    </Row>
  )
}
