import { IModelCoursesRequirements } from "../../modules"


export const COURSES_REQUIREMENTS_MODAL_STATE = '[ COURSES REQUIREMENTS ] Courses Requirements Modal State'

export interface ICoursesRequirementsModalState {
    type: typeof COURSES_REQUIREMENTS_MODAL_STATE
}

export const GET_COURSES_REQUIREMENTS_FOR_UPDATE = '[ COURSES REQUIREMENTS ] Get Courses Requirements For Update'

export interface IGetCoursesRequirementsForUpdate {
    type: typeof GET_COURSES_REQUIREMENTS_FOR_UPDATE
    payload: IModelCoursesRequirements
}

export const IS_COURSES_REQUIREMENTS_FOR_UPDATE = '[ COURSES REQUIREMENTS ] Is Courses Requirements For Update'

export interface IIsCoursesRequirementsForUpdate {
    type: typeof IS_COURSES_REQUIREMENTS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_COURSES_REQUIREMENTS_REFRESH_TABLE = '[ COURSES REQUIREMENTS ] Switch Courses Requirements Refresh Table'

export interface ISwitchCoursesRequirementsRefreshTable {
    type: typeof SWITCH_COURSES_REQUIREMENTS_REFRESH_TABLE
    payload: boolean
}