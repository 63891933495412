import React from "react";
import { useForm } from "react-hook-form";
import { message } from "antd";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CompaniesService } from "../../../../shared";
import { IModelCompanies } from "../../@types";

export default function useEditCompanies(
  recordId?: number | null,
  props?: { onSuccess?: (record: IModelCompanies) => void }
) {
  const [loading, setLoading] = React.useState(false);

  const formMethods = useForm<IModelCompanies>({
    resolver: yupResolver(
      yup.object().shape({
        cluster_id: yup.number().required("Campo requerido"),
      })
    ),
  });

  const loadingRef = React.useRef(false);

  const updateLoading = (status: boolean) => {
    setLoading(status);
    loadingRef.current = status;
  };

  const getRecord = async () => {
    if (loadingRef.current || !recordId) return;

    updateLoading(true);

    await CompaniesService
      .get(recordId)
      .then((record) => {
        formMethods.reset({
           ...record,
        });
        console.log(record)
      })
      .finally(() => {
        updateLoading(false);
      });
  };

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    if (loadingRef.current || !recordId) return;

    updateLoading(true);

    await CompaniesService
      .patch(recordId, {cluster_id: data?.cluster_id})
      .then((record) => {
        message.success("Registro actualizado correctamente");
        props?.onSuccess?.(record as IModelCompanies);
      })
      .catch((err) => {
        message.error(err?.message);
      })
      .finally(() => {
        updateLoading(false);
      });
  });

  React.useEffect(() => {
    if (recordId) {
      getRecord();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId]);

  return {
    formMethods,
    loading,
    handleSubmit,
  };
}
