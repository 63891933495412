import { Modal, message } from "antd";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { changeModalStateWebinarsBenefits, deleteWebinarBenefits, fetchSingleWebinarsBenefits, getSingleWebinarsBenefits, refreshTableWebinarsBenefits, setIsWebinarsBenefitsForUpdate } from "../../../../redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

export function useTableWebinarsBenefits() {
  const dispatch = useAppDispatch();

  const changeWebinarsBenefitsForUpdate = (valId: number) => {
      fetchSingleWebinarsBenefits(valId)
      .then((res)=>{
      dispatch(getSingleWebinarsBenefits(res.data[0]));
      dispatch(setIsWebinarsBenefitsForUpdate(true));
      dispatch(changeModalStateWebinarsBenefits());
      })
  }

  const showDeleteConfirm = (valId: number, val: string) => {
      confirm({
        title: `Estás seguro de borrar el beneficio de webinar con el id: ${valId}`,
        icon: <ExclamationCircleOutlined />,
        content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar beneficio" en caso tal solo oprime en "Cancelar".`,
        okText: "Borrar beneficio",
        okType: "danger",
        cancelText: "Cancelar",
        keyboard: true,
        maskClosable: true,
        async onOk() {
          return await deleteWebinarBenefits(valId)
            .then((res) => {
              dispatch(refreshTableWebinarsBenefits(true));
              if(res.name){
                return message.success(`Se ha borrado con éxito el beneficio con id:${valId}`);
              }else if(res){
                console.log({res})
                return message.error(`${res}`);
              }
            })
            .catch((err: any) => {
              console.log({ err });
              return message.error(err.message);
            });
        },
        onCancel() {},
      });
    };
    return{
      //state
      //methods
      //functions
      changeWebinarsBenefitsForUpdate,
      showDeleteConfirm,
    }
}
