import { IModelCoursesChapters } from "../../modules"


export const COURSES_CHAPTERS_MODAL_STATE = '[ COURSES CHAPTERS ] Courses Chapters Modal State'

export interface ICoursesChaptersModalState {
    type: typeof COURSES_CHAPTERS_MODAL_STATE
}

export const GET_COURSES_CHAPTERS_FOR_UPDATE = '[ COURSES CHAPTERS ] Get Courses Chapters For Update'

export interface IGetCoursesChaptersForUpdate {
    type: typeof GET_COURSES_CHAPTERS_FOR_UPDATE
    payload: IModelCoursesChapters
}

export const IS_COURSES_CHAPTERS_FOR_UPDATE = '[ COURSES CHAPTERS ] Is Courses Chapters For Update'

export interface IIsCoursesChaptersForUpdate {
    type: typeof IS_COURSES_CHAPTERS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_COURSES_CHAPTERS_REFRESH_TABLE = '[ COURSES CHAPTERS ] Switch Courses Chapters Refresh Table'

export interface ISwitchCoursesChaptersRefreshTable {
    type: typeof SWITCH_COURSES_CHAPTERS_REFRESH_TABLE
    payload: boolean
}