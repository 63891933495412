 
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col } from "antd";
import { useSelector } from "react-redux";
import { TableCustom, eventTagsService } from "../../../../shared";
import { IModelEventTags } from "../../@types";
import { selectSingleEvents } from "../../../../redux";
import { useEventTags, useTableEventTags } from "../../hooks";
import { TableColumns, TableProperties } from "../../../../shared/components/ui/table/types";
import React from "react";

export function TableEventTags() {
  const dataUpdateEvents = useSelector( selectSingleEvents );

  const {
    //state
    //methods
    //functions
    changeEventTagsForUpdate,
    showDeleteConfirm,
  } = useTableEventTags()

  const {
    //state
    isRefreshTableEventTags,
    //methods
    //functions
  }= useEventTags()

  const tableRef = React.useRef<TableProperties>()

    React.useEffect(() => {
      tableRef.current?.refresh()
    }, [isRefreshTableEventTags])

  const columns: TableColumns<IModelEventTags> = [
    {
        title: 'ID',
        dataIndex: 'id',
        align: 'left',
        sorter:true
    },
    {
      title: 'Tags evento',
      dataIndex: 'events_tags_id',
      align: 'left',
    },
    {
      title: 'Evento',
      dataIndex: 'event_id',
      align: 'left',
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 140,
      render: (_:any, item:any) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeEventTagsForUpdate(item.id)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.events_tags_id}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
]

  return (
    <TableCustom fetchQueryProps={{event_id: dataUpdateEvents?.id!,}} columns={columns}  service={eventTagsService} ref={tableRef}/>
  )
}
