import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { changeModalStateCoursesIncludes, createCourseIncludes, refreshTableCoursesIncludes, selectIsUpdateCoursesIncludes, selectRefreshTableCoursesIncludes, selectSingleCourses, selectSingleCoursesIncludes, setIsCoursesIncludesForUpdate, updateCourseIncludes } from "../../../../redux";
import { IModelCoursesIncludes } from "../../@types";
import { validateCreateCoursesIncludes, validateUpdateCoursesIncludes } from "../../../../shared/utils/yupValidation";



export function useCoursesIncludes() {
  const dispatch = useAppDispatch();

  const isUpdateCoursesIncludes = useSelector(
      selectIsUpdateCoursesIncludes
  );

  const dataUpdateCoursesIncludes = useSelector(
      selectSingleCoursesIncludes
  );

  const isRefreshTableCoursesIncludes = useSelector(
      selectRefreshTableCoursesIncludes
  );

  const dataUpdateCourses = useSelector( selectSingleCourses );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalCoursesIncludesStateForCreate = () => {
    dispatch(setIsCoursesIncludesForUpdate(false))
    dispatch(changeModalStateCoursesIncludes())
  }

  const formMethodsCreate = useForm<IModelCoursesIncludes>({
    resolver: yupResolver(validateCreateCoursesIncludes),
  });

  const formMethodsUpdate = useForm<IModelCoursesIncludes>({
    resolver: yupResolver(validateUpdateCoursesIncludes),
  });

  useEffect(() => {
  if (isUpdateCoursesIncludes) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateCoursesIncludes });
  }

  return () => {
      formMethodsUpdate.reset();
  };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateCoursesIncludes, dataUpdateCoursesIncludes]);

  const onSubmitCreateOrUpdate = async (data: IModelCoursesIncludes) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateCoursesIncludes) {
      await updateCourseIncludes({
        id: data.id,
        include_text: data.include_text,
        position: data.position,
        course_id: dataUpdateCourses?.id,
      })
        .then((res: Partial<IModelCoursesIncludes>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito las inclusiones con id:${res.id}`
            );
            dispatch(refreshTableCoursesIncludes(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createCourseIncludes({...data, course_id: dataUpdateCourses?.id,})
        .then((res: IModelCoursesIncludes) => {
          if (res.id) {
            dispatch(changeModalStateCoursesIncludes());
            message.success(
              `Se ha creado con éxito las inclusiones con id:${res.id}`
            );
            dispatch(refreshTableCoursesIncludes(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableCoursesIncludes) {
      dispatch(refreshTableCoursesIncludes(false));
    }
  }, [isRefreshTableCoursesIncludes]);

  return{
      //state
      isLoading,
      isUpdateCoursesIncludes,
      dataUpdateCoursesIncludes,
      isRefreshTableCoursesIncludes,
      //methods
      formMethodsCreate,
      formMethodsUpdate,
      //functions
      changeModalCoursesIncludesStateForCreate,
      onSubmitCreateOrUpdate,
  }
}
