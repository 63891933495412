import { Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { changeModalStateNewsCategories, deleteNewCategories, fetchSingleNewsCategories, getSingleNewsCategories, refreshTableNewsCategories, setIsNewsCategoriesForUpdate } from "../../../redux";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";

const { confirm } = Modal;

export default function useTableNewsCategories() {
  const dispatch = useAppDispatch();

  const changeNewsCategoriesForUpdate = (valId: number) => {
    fetchSingleNewsCategories(valId)
    .then((res)=>{
      dispatch(getSingleNewsCategories(res.data[0]));
      dispatch(setIsNewsCategoriesForUpdate(true));
      dispatch(changeModalStateNewsCategories());
    })
  }

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar la Categoría de noticias con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar categoría" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar categoría",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        return await deleteNewCategories(valId)
          .then((res) => {
            dispatch(refreshTableNewsCategories(true));
            if(res.name){
              return message.success(`Se ha borrado con éxito la Categoría con id:${valId}`);
            }else if(res){
              console.log({res})
              return message.error(`${res}`);
            }
          })
          .catch((err: any) => {
            console.log({ err });
            return message.error(err.message);
          });
      },
      onCancel() {},
    });
  };
  return{
    //state
    //methods
    //functions
    changeNewsCategoriesForUpdate,
    showDeleteConfirm,
  }
}
