/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from "lodash";
import {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
// import { useDebounce, useFetch } from '@/core/hooks'
import { Button, DatePicker, Input, Space, Table as AntTable } from "antd";
// import { FindMethod } from '@/core/@types/dto/hooks'
import {
  SorterResult,
  TablePaginationConfig,
  FilterValue,
  TableCurrentDataSource,
} from "antd/lib/table/interface";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";

// import { Div } from '../GlobalComponents'
// import { InputSearch } from '../input-search'

import { TableProps } from "./types";
import { FindMethod } from "../@types/dto/hooks";
import { useDebounce, useFetch } from "../../hooks";
import ButtonGroup from "antd/es/button/button-group";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Table = forwardRef(function <T extends object & { id: number } = any>(
  {
    service,
    columns,
    limit,
    pagination = true,
    fetch$ClientProps,
    fetchQueryProps,
    className,
    searchable = true,
    defaultSorter,
    searchActive,
    filtrableColumns,
    onChange,
    onFetch,
    fetchActive = true,
    download = false,
    onLoad,
    dataSource,
    ...restProps
  }: TableProps<any>,
  ref: any
) {
  // States
  const [isMounted, setIsMounted] = useState(false);
  const [inputValue, setInputValue] = useState<string | undefined>();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [savedFilters, setSavedFilters] = useState<any>({});
  const [savedSorter, setSavedSorter] = useState<any>({});
  const [paginationData, setPaginationData] = useState({
    total: 0,
    skip: 0,
    limit: limit || 10,
    currentPage: 1,
  });

  // Hooks
  const {
    fetch: fetchData,
    data: dataFetch,
    loading: loadingFetch,
  } = useFetch<FindMethod<T>>({ service: service.find });
  const { value: searchValue, loading: loadingSearch } = useDebounce(
    inputValue,
    1000
  );

  const getFilterComponent = (
    type: "text" | "date" | "date_range" | "range" | "number_range",
    { selectedKeys, setSelectedKeys, confirm }: any
  ) => {
    switch (type) {
      case "date_range":
        return (
          <div>
            <DatePicker.RangePicker
              autoFocus
              style={{ marginBottom: 8 }}
              value={
                (selectedKeys.length
                  ? [moment(selectedKeys[0]), moment(selectedKeys[1])]
                  : null) as any
              }
              onChange={(vMoment, vString) => {
                setSelectedKeys(vString.length ? vString : []);
              }}
            />
          </div>
        );
      case "number_range":
        return (
          <div>
            <Space
              style={{
                marginBottom: 8,
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Input
                placeholder="De"
                value={selectedKeys[0]}
                style={{ width: 100 }}
                onChange={(e) =>
                  setSelectedKeys(
                    e.target.value
                      ? [e.target.value, selectedKeys[1]]
                      : [undefined, selectedKeys[1]]
                  )
                }
                onPressEnter={() => confirm({ closeDropdown: true })}
              />
              a
              <Input
                placeholder="Hasta"
                value={selectedKeys[1]}
                style={{ width: 100 }}
                onChange={(e) =>
                  setSelectedKeys(
                    e.target.value
                      ? [selectedKeys[0], e.target.value]
                      : [selectedKeys[0], undefined]
                  )
                }
                onPressEnter={() => confirm({ closeDropdown: true })}
              />
            </Space>
          </div>
        );
      default:
        return (
          <Input
            autoFocus
            placeholder="Buscar..."
            style={{ marginBottom: 8, display: "block" }}
            type={type}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm({ closeDropdown: true })}
          />
        );
    }
  };

  const getColumnInputSearchProps = useCallback(
    (
      dataIndex: string,
      type: "text" | "date" | "date_range" | "range" | "number_range"
    ) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          {getFilterComponent(type, { selectedKeys, setSelectedKeys, confirm })}
          <Space>
            <Button
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
              type="primary"
              onClick={() => confirm({ closeDropdown: true })}
            >
              Buscar
            </Button>
            <Button
              size="small"
              style={{ width: 90 }}
              onClick={() => {
                clearFilters();
                confirm({ closeDropdown: false });
              }}
            >
              Reiniciar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      // onFilter: (value: any, record: any) => {
      //     return record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''
      // },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => /*  this.searchInput.select() */ undefined, 100);
        }
      },
    }),
    []
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _fetch = (
    skip?: number,
    params?: any,
    customFetcher?: (query: any) => Promise<any>
  ) => {
    const query = _.merge(
      {},
      {
        $limit: paginationData.limit,
        $skip: skip || 0,
        ...(fetchQueryProps || {}),
        ...(searchValue ? { q: searchValue } : {}),
        $client: {
          ...(filtrableColumns ? { filterBy: filtrableColumns } : {}),
          ...(fetch$ClientProps || {}),
        },
      },
      params || {}
    );
    if (customFetcher) {
      customFetcher(query);
    } else {
      fetchData({ query }).then((res) => {
        onFetch && onFetch(res);
      });
    }
  };

  const getSorData = useCallback(
    (sorter: SorterResult<T>[] | SorterResult<T>) => {
      return (!Array.isArray(sorter) ? [sorter] : sorter)
        .map((it) =>
          it.field
            ? {
                [it.field as string]:
                  it.order === "ascend" ? 1 : it.order === "descend" ? -1 : 0,
              }
            : {}
        )
        .filter((it) => Object.values(it)[0] !== 0)
        .reduce((acc, item) => {
          const key = Object.keys(item)[0];

          if (key) acc[key] = item[key];

          return acc;
        }, {});
    },
    []
  );

  const getFilterData = useCallback(
    (filters: any) => {
      const $clientFiltersKeys: string[] = [];
      const queryFilters = Object.keys(filters)
        .map((key) => {
          const colConfig = columns?.find((it: any) => it.dataIndex === key);
          let val: any;

          if (filters[key] !== null) {
            if (colConfig?.filterWay === "$client") {
              val = { [key]: filters[key] };
              $clientFiltersKeys.push(key);
            } else if (colConfig?.filterType === "date") {
              val = {
                [key]: {
                  $gte: `${filters[key]} 00:00:00`,
                  $lte: `${filters[key]} 23:59:59`,
                },
              };
            } else if (colConfig?.filterType === "date_range") {
              val = {
                [key]: {
                  $gte: `${filters[key][0]} 00:00:00`,
                  $lte: `${filters[key][1]} 23:59:59`,
                },
              };
            } else if (colConfig?.filterType === "number_range") {
              val = {
                [key]: {
                  $gte: filters[key][0],
                  $lte: filters[key][1],
                },
              };
            } else if (colConfig?.filterType === "text")
              val = { [key]: filters[key] };
            else val = { [key]: { $in: filters[key] } };
          } else val = {};

          return val;
        })
        .reduce((acc, item) => {
          const key = Object.keys(item)[0];

          if (key) acc[key] = item[key];

          return acc;
        }, {});

      return {
        queryFilters: Object.keys(queryFilters).reduce((acc: any, item) => {
          if (!$clientFiltersKeys.includes(item))
            acc[item] = queryFilters[item];

          return acc;
        }, {}),
        $clientFilters: Object.keys(queryFilters).reduce((acc: any, item) => {
          if ($clientFiltersKeys.includes(item)) acc[item] = queryFilters[item];

          return acc;
        }, {}),
      };
    },
    [columns]
  );

  const handleDownload = () => {
    if (downloadLoading) {
      return;
    }
    setDownloadLoading(true);
    const { queryFilters, $clientFilters } = getFilterData(savedFilters);

    _fetch(
      0,
      {
        ...queryFilters,
        $client: {
          filterBy: $clientFilters,
          export: "true",
        },
        $limit: paginationData.total,
        $sort: getSorData(savedSorter),
      },
      async (query) => {
        await service
          .find({ query })
          .then(({ objectUrl }: any) => {
            window.open(objectUrl, "_blank");
          })
          .finally(() => {
            setDownloadLoading(false);
          });
      }
    );
  };

  const handleChange = (
    pag: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T>[] | SorterResult<T>,
    extra: TableCurrentDataSource<T>
  ) => {
    setPaginationData((prev) => ({ ...prev, currentPage: pag.current || 1 }));
    setSavedFilters(filters);
    setSavedSorter(sorter);

    const { queryFilters, $clientFilters } = getFilterData(filters);

    _fetch(
      ((pag?.current || 1) - 1) * (pag?.pageSize || paginationData.limit),
      {
        ...queryFilters,
        $client: {
          filterBy: $clientFilters,
        },
        $sort: getSorData(sorter),
      }
    );

    if (onChange) onChange(pag, filters, sorter, extra);
  };

  const getInitialSort = () => {
    return (columns || [])
      .filter((col: any) => !!col?.defaultSortOrder)
      .map((col: any) => ({
        [col.dataIndex]: col?.defaultSortOrder === "descend" ? -1 : 1,
      }))
      .reduce((acc: any, it: any) => {
        return {
          ...acc,
          ...it,
        };
      }, {});
  };

  const getInitialFilters = () => {
    return (columns || [])
      .filter((col: any) => !!col?.defaultFilteredValue)
      .map((col: any) => ({
        [col.dataIndex]: { $in: col.defaultFilteredValue },
      }))
      .reduce((acc: any, it: any) => {
        return {
          ...acc,
          ...it,
        };
      }, {});
  };

  // Update pagination
  useEffect(() => {
    if (dataFetch) {
      setPaginationData((prev) => ({
        ...prev,
        total: dataFetch.total,
        skip: dataFetch.skip,
        limit: dataFetch.limit,
      }));
    }
  }, [dataFetch]);

  // Fetch data
  useEffect(() => {
    if (isMounted && fetchActive) {
      setPaginationData((prev) => ({ ...prev, currentPage: 1 }));
      if (!loadingFetch && !loadingSearch) {
        _fetch(0, {
          $sort: getInitialSort(),
          ...getInitialFilters(),
        });
      }
    }
    setIsMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchValue,
    service,
    fetch$ClientProps,
    fetchQueryProps,
    fetchActive,
    isMounted,
  ]);

  useImperativeHandle(ref, () => ({
    refresh() {
      _fetch(0, { $sort: getInitialSort(), ...getInitialFilters() });
    },
  }));

  useEffect(() => {
    if (onLoad) {
      onLoad({
        refresh: () =>
          _fetch(0, { $sort: getInitialSort(), ...getInitialFilters() }),
      });
    }
  }, []);

  return (
    <div style={{ minWidth: "100%" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 14,
          marginBottom: 14,
        }}
      >
        {download && (
          <ButtonGroup size="large">
            <Button
              onClick={handleDownload}
              loading={downloadLoading}
              size="large"
              icon={<DownloadOutlined />}
            />
          </ButtonGroup>
        )}
        {searchActive && (
          <div style={{ width: "20%" }}>
            <Input
              placeholder="Search..."
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
        )}
      </div>

      <div>
        <AntTable<T>
          className={`${className || ""} tableStyleOne`}
          columns={columns?.map((col: any) => ({
            ...col,
            ...(col?.render
              ? {
                  render: (value: any, record: T, index: number) => {
                    return col?.render(
                      value,
                      record,
                      index,
                      dataSource
                        ? dataSource(dataFetch?.data || [])
                        : dataFetch?.data
                    );
                  },
                }
              : {}),
            ...(col.filterType && col.filterType !== "base"
              ? { ...getColumnInputSearchProps(col.dataIndex, col.filterType) }
              : {}),
            ...(defaultSorter && col.dataIndex === defaultSorter[0]
              ? { defaultSortOrder: defaultSorter[1] }
              : {}),
          }))}
          dataSource={
            dataSource ? dataSource(dataFetch?.data || []) : dataFetch?.data
          }
          loading={loadingFetch || loadingSearch}
          pagination={
            pagination && {
              defaultCurrent: 1,
              current: paginationData.currentPage,
              showSizeChanger: false,
              pageSize: paginationData.limit,
              total: dataSource
                ? dataSource(dataFetch?.data || [])?.length
                : dataFetch?.total,
            }
          }
          rowKey={
            ((record: any) =>
              `row_table_key_${
                record.id || Math.random() * (paginationData.limit - 0) + 0
              }`) as unknown as any
          }
          onChange={handleChange}
          {...restProps}
        />
      </div>
    </div>
  );
});

export default Table;
