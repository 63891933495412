import { Button, Drawer } from "antd";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { InfographiesNew } from "../modules/virtual-library/ui/resource/keyAccountManagerNew";

export function InfographiesNewPage() {
  return (
    <Drawer
      title="Crear biblioteca virtual"
      open
      width={350}
      destroyOnClose
      closeIcon={
        <Link to="/virtual-library">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <InfographiesNew />
    </Drawer>
  )
}
