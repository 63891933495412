import { useSelector } from "react-redux";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { changeModalStateNewsCategories, createNewCategories, refreshTableNewsCategories, selectIsUpdateNewsCategories, selectRefreshTableNewsCategories, selectSingleNewsCategories, setIsNewsCategoriesForUpdate, updateNewCategories } from "../../../redux";
import { useEffect, useState } from "react";
import { IModelNewsCategories } from "../@types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validateCreateNewsCategories, validateUpdateNewsCategories } from "../../../shared/utils/yupValidation";
import { message } from "antd";

export function useNewsCategories() {
  const dispatch = useAppDispatch();

  const isUpdateNewsCategories = useSelector(
    selectIsUpdateNewsCategories
  );
  const dataUpdateNewsCategories = useSelector(
    selectSingleNewsCategories
  );
  const isRefreshTableNewsCategories = useSelector(
    selectRefreshTableNewsCategories
  );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalNewsCategoriesStateForCreate = () => {
    dispatch(setIsNewsCategoriesForUpdate(false))
    dispatch(changeModalStateNewsCategories())
  }

  const formMethodsCreate = useForm<IModelNewsCategories>({
    resolver: yupResolver(validateCreateNewsCategories),
  });

  const formMethodsUpdate = useForm<IModelNewsCategories>({
    resolver: yupResolver(validateUpdateNewsCategories),
  });

  useEffect(() => {
    if (isUpdateNewsCategories) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateNewsCategories });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateNewsCategories, dataUpdateNewsCategories]);

  const onSubmitCreateOrUpdate = async (data: IModelNewsCategories) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateNewsCategories) {
      await updateNewCategories({
        id: data.id,
        name: data.name,
        parent_id: data.parent_id,
      })
        .then((res: Partial<IModelNewsCategories>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito la categoría de noticia con id:${res.id}`
            );
            dispatch(refreshTableNewsCategories(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createNewCategories({...data})
        .then((res: IModelNewsCategories) => {
          if (res.id) {
            dispatch(changeModalStateNewsCategories());
            message.success(
              `Se ha creado con éxito la categoría de noticia con id:${res.id}`
            );
            dispatch(refreshTableNewsCategories(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };


  useEffect(() => {
    if (isRefreshTableNewsCategories) {
      dispatch(refreshTableNewsCategories(false));
    }
  }, [isRefreshTableNewsCategories]);

  return{
    //state
    isLoading,
    isUpdateNewsCategories,
    dataUpdateNewsCategories,
    isRefreshTableNewsCategories,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalNewsCategoriesStateForCreate,
    onSubmitCreateOrUpdate,
  }
}
