import { IModelWebinarsAuthors } from "../../modules/webinars/@types/IModelWebinarsAuthors"


export const WEBINARS_AUTHORS_MODAL_STATE = '[ WEBINARS AUTHORS ] Webinars Authors Modal State'

export interface IWebinarsAuthorsModalState {
    type: typeof WEBINARS_AUTHORS_MODAL_STATE
}

export const GET_WEBINARS_AUTHORS_FOR_UPDATE = '[ WEBINARS AUTHORS ] Get Webinars Authors For Update'

export interface IGetWebinarsAuthorsForUpdate {
    type: typeof GET_WEBINARS_AUTHORS_FOR_UPDATE
    payload: IModelWebinarsAuthors
}

export const IS_WEBINARS_AUTHORS_FOR_UPDATE = '[ WEBINARS AUTHORS ] Is Webinars Authors For Update'

export interface IIsWebinarsAuthorsForUpdate {
    type: typeof IS_WEBINARS_AUTHORS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_WEBINARS_AUTHORS_REFRESH_TABLE = '[ WEBINARS AUTHORS ] Switch Webinars Authors Refresh Table'

export interface ISwitchWebinarsAuthorsRefreshTable {
    type: typeof SWITCH_WEBINARS_AUTHORS_REFRESH_TABLE
    payload: boolean
}