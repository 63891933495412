import { useSelector } from "react-redux";
import { Button, Col, Typography } from "antd";
import { useFormContext } from 'react-hook-form';
import { IModelEventTags } from "../../@types";
import { WrapperModalEvents } from "../styled";
import { useEventTags } from "../../hooks";
import { selectModalEventTags } from "../../../../redux";
import { InputSelectDynamic, eventsTagsService } from "../../../../shared";

const { Title } = Typography;

export function ModalEventTags() {
  const modalEventTags = useSelector(
    selectModalEventTags
  );

  const {
    //state
    isLoading,
    isUpdateEventTags,
    dataUpdateEventTags,
    //methods
    //functions
    changeModalEventTagsStateForCreate,
    onSubmitCreateOrUpdate
  } = useEventTags()

  const { control, handleSubmit: onSubmit } = useFormContext<IModelEventTags>();
  return (
    <WrapperModalEvents
      title={ isUpdateEventTags
          ? `Actualizando tags de evento:'${dataUpdateEventTags?.id!}'`
          : `Creando tags de evento` }
      open={modalEventTags}
      width={"320px"}
      destroyOnClose
      onClose={changeModalEventTagsStateForCreate}>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Tags de evento:
            </Title>
            <InputSelectDynamic placeHolder="Tags " name="events_tags_id" control={control} serviceName={eventsTagsService} labelName="name" valName="id" />
        </Col>
        <Button
          style={{
          width: "100%",
          marginTop: 10,
          }}
          type="primary"
          shape="round"
          size="large"
          onClick={onSubmit(onSubmitCreateOrUpdate)}
          loading={isLoading}>
            {isUpdateEventTags
            ? "Actualizar información"
            : "Crear tags de evento"}
        </Button>
      </WrapperModalEvents>
  )
}
