import { message } from "antd";

import { projectsRequirementsService, projectsService } from "../../shared";
import { projectsRequirementsActions } from "../action-types";
import { IModelProjectsRequirements } from "../../modules";

export const changeModalStateProjectsRequirements = (): projectsRequirementsActions.IProjectsRequirementsModalState =>({
    type: projectsRequirementsActions.PROJECTS_REQUIREMENTS_MODAL_STATE
})

export const getSingleProjectsRequirements = (val:IModelProjectsRequirements ): projectsRequirementsActions.IGetProjectsRequirementsForUpdate=> ({
    type: projectsRequirementsActions.GET_PROJECTS_REQUIREMENTS_FOR_UPDATE,
    payload: val
})

export const setIsProjectsRequirementsForUpdate =(val: boolean): projectsRequirementsActions.IIsProjectsRequirementsForUpdate =>({
    type: projectsRequirementsActions.IS_PROJECTS_REQUIREMENTS_FOR_UPDATE,
    payload: val,
})

export const fetchSingleProjectsRequirements = async(id: number) =>{
    return await projectsRequirementsService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createProjectRequirements = async(data:Partial<IModelProjectsRequirements>) =>{
    return await projectsService.patch(data?.project_id!, { meta_requirements: JSON.stringify(data) })
    .then((res: IModelProjectsRequirements)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateProjectRequirements = async(data:Partial<IModelProjectsRequirements>) =>{
    return await projectsService.patch(data?.project_id!, { meta_requirements: JSON.stringify(data) })
    .then((res: IModelProjectsRequirements)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteProjectRequirements = async(id: number) =>{
    return await projectsService.patch(id, {meta_requirements: 'null'})
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableProjectsRequirements = (val: boolean): projectsRequirementsActions.ISwitchProjectsRequirementsRefreshTable =>({
    type: projectsRequirementsActions.SWITCH_PROJECTS_REQUIREMENTS_REFRESH_TABLE,
    payload: val
})

