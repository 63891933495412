import { useNavigate } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import { Button, Col, Flex, Row } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import useCreatePressNewsletter from "../lib/useCreatePressNewsletter";
import PressNewsletterForm from "../components/press-newsletter-form";

export default function CreatePressNewsletter() {
  const navigate = useNavigate();

  const { formMethods, loading, handleSubmit } = useCreatePressNewsletter({
    onSuccess() {
      navigate("/press/newsletter");
    },
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit}>
        <Row gutter={[8, 16]}>
          <Col xs={24}>
            <PressNewsletterForm />
          </Col>
          <Col xs={24}>
            <Flex justify="flex-end">
              <Button
                icon={<SaveOutlined />}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Crear
              </Button>
            </Flex>
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
}
