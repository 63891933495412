
import { IModelResources } from '../../modules/resources-admin';
import { IResourceState } from '../@types';

import { resourcesAdminActions } from '../action-types'

type eventsActionType =
| { type: typeof resourcesAdminActions.GET_RESOURCE_FOR_UPDATE; payload: IModelResources }
| { type: typeof resourcesAdminActions.IS_RESOURCE_FOR_UPDATE; payload: boolean }
| { type: typeof resourcesAdminActions.SWITCH_RESOURCE_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IResourceState = {
    isUpdateResources: false,
    resourceForUpdate:null,
    refreshTableResources: false
}

export default function eventsReducer(state: IResourceState = INITIAL_STATE, action: eventsActionType): IResourceState {
  switch (action.type) {
    case resourcesAdminActions.GET_RESOURCE_FOR_UPDATE:
        return {
            ...state,
            resourceForUpdate: action.payload,
        }
    case resourcesAdminActions.IS_RESOURCE_FOR_UPDATE:
        return {
            ...state,
            isUpdateResources: action.payload,
        }
    case resourcesAdminActions.SWITCH_RESOURCE_REFRESH_TABLE:
        return {
            ...state,
            refreshTableResources: action.payload,
        }
    default:
        return state
  }
}
