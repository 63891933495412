import { message } from "antd";

import { authorsService } from "../../shared";
import { authorsActions } from "../action-types";
import { IModelAuthors } from "../../modules";

export const changeModalStateAuthors = (): authorsActions.IAuthorsModalState =>({
    type: authorsActions.AUTHORS_MODAL_STATE
})

export const getSingleAuthors = (val:IModelAuthors ): authorsActions.IGetAuthorsForUpdate=> ({
    type: authorsActions.GET_AUTHORS_FOR_UPDATE,
    payload: val
})

export const setIsAuthorsForUpdate =(val: boolean): authorsActions.IIsAuthorsForUpdate =>({
    type: authorsActions.IS_AUTHORS_FOR_UPDATE,
    payload: val,
})

export const fetchSingleAuthors = async(id: number) =>{
    return await authorsService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createAuthors = async(data:Partial<IModelAuthors>) =>{
    return await authorsService.create(data)
    .then((res: IModelAuthors)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateAuthors = async(data:Partial<IModelAuthors>) =>{
    return await authorsService.patch(data?.id!, data)
    .then((res: IModelAuthors)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteAuthors = async(id: number) =>{
    return await authorsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableAuthors = (val: boolean): authorsActions.ISwitchAuthorsRefreshTable =>({
    type: authorsActions.SWITCH_AUTHORS_REFRESH_TABLE,
    payload: val
})

