import { Modal, message } from "antd";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { changeModalStateEventTags, deleteEventTags, fetchSingleEventTags, getSingleEventTags, refreshTableEventTags, setIsEventTagsForUpdate } from "../../../../redux";

const { confirm } = Modal;

export function useTableEventTags() {
  const dispatch = useAppDispatch();

  const changeEventTagsForUpdate = (valId: number) => {
      fetchSingleEventTags(valId)
      .then((res)=>{
      dispatch(getSingleEventTags(res.data[0]));
      dispatch(setIsEventTagsForUpdate(true));
      dispatch(changeModalStateEventTags());
      })
  }

  const showDeleteConfirm = (valId: number, val: string) => {
      confirm({
        title: `Estás seguro de borrar los tags de evento con el id: ${valId}`,
        icon: <ExclamationCircleOutlined />,
        content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar tag" en caso tal solo oprime en "Cancelar".`,
        okText: "Borrar tag",
        okType: "danger",
        cancelText: "Cancelar",
        keyboard: true,
        maskClosable: true,
        async onOk() {
          return await deleteEventTags(valId)
            .then((res) => {
              dispatch(refreshTableEventTags(true));
              if(res.name){
                return message.success(`Se ha borrado con éxito el tag con id:${valId}`);
              }else if(res){
                console.log({res})
                return message.error(`${res}`);
              }
            })
            .catch((err: any) => {
              console.log({ err });
              return message.error(err.message);
            });
        },
        onCancel() {},
      });
    };
    return{
      //state
      //methods
      //functions
      changeEventTagsForUpdate,
      showDeleteConfirm,
    }
}
