
import { IModelCoursesBenefits } from '../../modules';
import { ICoursesBenefitsState } from '../@types/coursesBenefits';

import { coursesBenefitsActions } from '../action-types'

type coursesBenefitsActionType =
| { type: typeof coursesBenefitsActions.COURSES_BENEFITS_MODAL_STATE; }
| { type: typeof coursesBenefitsActions.GET_COURSES_BENEFITS_FOR_UPDATE; payload: IModelCoursesBenefits }
| { type: typeof coursesBenefitsActions.IS_COURSES_BENEFITS_FOR_UPDATE; payload: boolean }
| { type: typeof coursesBenefitsActions.SWITCH_COURSES_BENEFITS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: ICoursesBenefitsState = {
    coursesBenefitsModalIsOpen: false,
    isUpdateCoursesBenefits: false,
    coursesBenefitsForUpdate: null,
    refreshTableCoursesBenefits: false,
}

export default function coursesBenefitsReducer(state: ICoursesBenefitsState = INITIAL_STATE, action: coursesBenefitsActionType): ICoursesBenefitsState {
  switch (action.type) {
    case coursesBenefitsActions.COURSES_BENEFITS_MODAL_STATE:
        return {
            ...state,
            coursesBenefitsModalIsOpen: !state.coursesBenefitsModalIsOpen,
        }
    case coursesBenefitsActions.GET_COURSES_BENEFITS_FOR_UPDATE:
        return {
            ...state,
            coursesBenefitsForUpdate: action.payload,
        }
    case coursesBenefitsActions.IS_COURSES_BENEFITS_FOR_UPDATE:
        return {
            ...state,
            isUpdateCoursesBenefits: action.payload,
        }
    case coursesBenefitsActions.SWITCH_COURSES_BENEFITS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableCoursesBenefits: action.payload,
        }
    default:
        return state
  }
}
