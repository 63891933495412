import * as yup from 'yup'

export const validateUpdateWebinarsIncludes = yup.object().shape({
    // webinar_id:yup
    // .number(),
    // path_icon: yup
    // .string()
    // .min(2, 'El ICONO es necesario.'),
    include_text:yup
    .string()
    .min(2, 'El TEXTO REQUERIDO debe tener mínimo de 2 caracteres.'),
    position:yup
    .number(),
})