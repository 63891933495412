import { AdminLayout } from "../shared";
import { VirtualLibraryCategories } from "../modules/virtual-library-categories";

export const VirtualLibraryCategoriesManagerPage = () => {
  return (
    <AdminLayout>
      <VirtualLibraryCategories />
    </AdminLayout>
  );
};
