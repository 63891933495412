import { Layout } from "antd";
import { ReactNode } from "react";

import { WrapperLayout } from "./styled";

const { Content } = Layout;

interface Props {
    children: ReactNode;
}

export function LoginLayout({children}:Props):JSX.Element {
  return (
    <WrapperLayout>
      <Content>{children}</Content>
    </WrapperLayout>
  )
}
