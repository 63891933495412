
import { IModelEventsTags } from '../../modules';
import { IEventsTagsState } from '../@types/eventsTags';

import { eventsTagsActions } from '../action-types'

type eventsTagsActionType =
| { type: typeof eventsTagsActions.EVENTS_TAGS_MODAL_STATE; }
| { type: typeof eventsTagsActions.GET_EVENTS_TAGS_FOR_UPDATE; payload: IModelEventsTags }
| { type: typeof eventsTagsActions.IS_EVENTS_TAGS_FOR_UPDATE; payload: boolean }
| { type: typeof eventsTagsActions.SWITCH_EVENTS_TAGS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IEventsTagsState = {
    eventsTagsModalIsOpen: false,
    isUpdateEventsTags: false,
    eventsTagsForUpdate: null,
    refreshTableEventsTags: false,
}

export default function eventsTagsReducer(state: IEventsTagsState = INITIAL_STATE, action: eventsTagsActionType): IEventsTagsState {
  switch (action.type) {
    case eventsTagsActions.EVENTS_TAGS_MODAL_STATE:
        return {
            ...state,
            eventsTagsModalIsOpen: !state.eventsTagsModalIsOpen,
        }
    case eventsTagsActions.GET_EVENTS_TAGS_FOR_UPDATE:
        return {
            ...state,
            eventsTagsForUpdate: action.payload,
        }
    case eventsTagsActions.IS_EVENTS_TAGS_FOR_UPDATE:
        return {
            ...state,
            isUpdateEventsTags: action.payload,
        }
    case eventsTagsActions.SWITCH_EVENTS_TAGS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableEventsTags: action.payload,
        }
    default:
        return state
  }
}
