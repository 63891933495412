import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { Link, Outlet } from "react-router-dom";
import CreateFormsAndModels from "../modules/Corresponsales/ui/CreateFormsAndModels";

export default function CreateCorresponsalesFormsAndModelsPage() {
  return (
    <>
      <Drawer
        title="Agregar formato/modelo"
        open
        width={350}
        destroyOnClose
        closeIcon={
          <Link to="/corresponsales/formatos-y-modelos">
            <Button icon={<CloseOutlined />} type="text" />
          </Link>
        }
      >
        <CreateFormsAndModels />
      </Drawer>
      <Outlet />
    </>
  );
}
