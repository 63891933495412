import { combineReducers } from '@reduxjs/toolkit'
import uiReducer from './ui.reducer'
import authReducer from './auth.reducer'
import newsCategoriesReducer from './news-categories'
import newsReducer from './news.reducer'
import projectsReducer from './projects.reducer'
import usersReducer from './users.reducer'
import authorsReducer from './authors.reducer'
import webinarsCategoriesReducer from './webinarsCategories.reducer'
import webinarsBenefitsReducer from './webinarsBenefits.reducer'
import projectsBenefitsReducer from './projectsBenefits.reducer'
import projectsPartnersReducer from './projectsPartners.reducer'
import projectsObjectivesReducer from './projectsObjectives.reducer'
import projectsManagersReducer from './projectsManagers.reducer'
import webinarsAuthorsReducer from './webinarsAuthors.reducer'
import webinarsRequirementsReducer from './webinarsRequirements.reducer'
import projectsRequirementsReducer from './projectsRequirements.reducer'
import webinarsIncludesReducer from './webinarsIncludes.reducer'
import webinarsReducer from './webinars.reducer'
import coursesReducer from './courses.reducer'
import coursesAuthorsReducer from './coursesAuthors.reducer'
import coursesBenefitsReducer from './coursesBenefits.reducer'
import coursesCategoriesReducer from './coursesCategories.reducer'
import coursesChaptersReducer from './coursesChapters.reducer'
import coursesRequirementsReducer from './coursesRequirements.reducer'
import projectsComponentsReducer from './projectsComponents.reducer'
import coursesSectionsReducer from './coursesSections.reducer'
import coursesIncludesReducer from './coursesIncludes.reducer'
import eventsReducer from './events.reducer'
import eventsAuthorsReducer from './eventsAuthors.reducer'
import eventsCategoriesReducer from './eventsCategories.reducer'
import eventsSchedulesReducer from './eventsSchedules.reducer'
import eventsTagsReducer from './eventsTags.reducer'
import eventTagsReducer from './eventTags.reducer'
import resourcesReducer from './resources.reducer'
import CompaniesReducer from './companies.reducer'
import keyAccountManagersReducer from './keyAccountManagers.reducer'
import RegistryServicesConsultNewsReducer from './registryServicesConsultNews.reducer'
import bannersReducer from './banners.reducer'



export default combineReducers({
    ui: uiReducer,
    auth: authReducer,
    newCategories: newsCategoriesReducer,
    new:newsReducer,
    project:projectsReducer,
    user:usersReducer,
    authors: authorsReducer,
    companies: CompaniesReducer,
    keyAccountManagers: keyAccountManagersReducer,
    webinarsCategories: webinarsCategoriesReducer,
    webinarsBenefits: webinarsBenefitsReducer,
    projectsBenefits: projectsBenefitsReducer,
    projectsPartners: projectsPartnersReducer,
    projectsObjectives: projectsObjectivesReducer,
    projectsManagers: projectsManagersReducer,
    webinarsAuthors: webinarsAuthorsReducer,
    webinarsRequirements: webinarsRequirementsReducer,
    projectsRequirements: projectsRequirementsReducer,
    webinarsIncludes: webinarsIncludesReducer,
    webinars: webinarsReducer,

    courses: coursesReducer,
    coursesAuthors: coursesAuthorsReducer,
    coursesBenefits:  coursesBenefitsReducer,
    coursesCategories: coursesCategoriesReducer,
    coursesChapters: coursesChaptersReducer,
    coursesIncludes: coursesIncludesReducer,
    coursesRequirements: coursesRequirementsReducer,
    projectsComponents: projectsComponentsReducer,
    coursesSections: coursesSectionsReducer,

    events: eventsReducer,
    eventsAuthors: eventsAuthorsReducer,
    eventsCategories: eventsCategoriesReducer,
    eventsSchedules: eventsSchedulesReducer,
    eventsTags: eventsTagsReducer,
    eventTags: eventTagsReducer,
    resourcesAdmin :resourcesReducer,
    RegistryServicesConsultNews :RegistryServicesConsultNewsReducer,
    banners : bannersReducer,
})