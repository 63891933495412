import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { changeModalStateCoursesRequirements, createCourseRequirements, refreshTableCoursesRequirements, selectIsUpdateCoursesRequirements, selectRefreshTableCoursesRequirements, selectSingleCourses, selectSingleCoursesRequirements, setIsCoursesRequirementsForUpdate, updateCourseRequirements } from "../../../../redux";
import { IModelCoursesRequirements } from "../../@types";
import { validateCreateCoursesRequirements, validateUpdateCoursesRequirements } from "../../../../shared/utils/yupValidation";

export function useCoursesRequirements() {
  const dispatch = useAppDispatch();

  const isUpdateCoursesRequirements = useSelector(
      selectIsUpdateCoursesRequirements
  );

  const dataUpdateCoursesRequirements = useSelector(
      selectSingleCoursesRequirements
  );

  const isRefreshTableCoursesRequirements = useSelector(
      selectRefreshTableCoursesRequirements
  );

  const dataUpdateCourses = useSelector( selectSingleCourses );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalCoursesRequirementsStateForCreate = () => {
    dispatch(setIsCoursesRequirementsForUpdate(false))
    dispatch(changeModalStateCoursesRequirements())
  }

  const formMethodsCreate = useForm<IModelCoursesRequirements>({
    resolver: yupResolver(validateCreateCoursesRequirements),
  });

  const formMethodsUpdate = useForm<IModelCoursesRequirements>({
    resolver: yupResolver(validateUpdateCoursesRequirements),
  });

  useEffect(() => {
  if (isUpdateCoursesRequirements) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateCoursesRequirements });
  }

  return () => {
      formMethodsUpdate.reset();
  };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateCoursesRequirements, dataUpdateCoursesRequirements]);

  const onSubmitCreateOrUpdate = async (data: IModelCoursesRequirements) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateCoursesRequirements) {
      await updateCourseRequirements({
        id: data.id,
        requirement_text: data.requirement_text,
        position: data.position,
        course_id: dataUpdateCourses?.id,
      })
        .then((res: Partial<IModelCoursesRequirements>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito los requerimientos con id:${res.id}`
            );
            dispatch(refreshTableCoursesRequirements(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createCourseRequirements({...data, course_id: dataUpdateCourses?.id})
        .then((res: IModelCoursesRequirements) => {
          if (res.id) {
            dispatch(changeModalStateCoursesRequirements());
            message.success(
              `Se ha creado con éxito los requerimientos con id:${res.id}`
            );
            dispatch(refreshTableCoursesRequirements(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableCoursesRequirements) {
      dispatch(refreshTableCoursesRequirements(false));
    }
  }, [isRefreshTableCoursesRequirements]);

  return{
      //state
      isLoading,
      isUpdateCoursesRequirements,
      dataUpdateCoursesRequirements,
      isRefreshTableCoursesRequirements,
      //methods
      formMethodsCreate,
      formMethodsUpdate,
      //functions
      changeModalCoursesRequirementsStateForCreate,
      onSubmitCreateOrUpdate,
  }
}
