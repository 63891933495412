
import { IModelCoursesIncludes } from '../../modules';
import { ICoursesIncludesState } from '../@types/coursesIncludes';

import { coursesIncludesActions } from '../action-types'

type coursesIncludesActionType =
| { type: typeof coursesIncludesActions.COURSES_INCLUDES_MODAL_STATE; }
| { type: typeof coursesIncludesActions.GET_COURSES_INCLUDES_FOR_UPDATE; payload: IModelCoursesIncludes }
| { type: typeof coursesIncludesActions.IS_COURSES_INCLUDES_FOR_UPDATE; payload: boolean }
| { type: typeof coursesIncludesActions.SWITCH_COURSES_INCLUDES_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: ICoursesIncludesState = {
    coursesIncludesModalIsOpen: false,
    isUpdateCoursesIncludes: false,
    coursesIncludesForUpdate: null,
    refreshTableCoursesIncludes: false,
}

export default function coursesIncludesReducer(state: ICoursesIncludesState = INITIAL_STATE, action: coursesIncludesActionType): ICoursesIncludesState {
  switch (action.type) {
    case coursesIncludesActions.COURSES_INCLUDES_MODAL_STATE:
        return {
            ...state,
            coursesIncludesModalIsOpen: !state.coursesIncludesModalIsOpen,
        }
    case coursesIncludesActions.GET_COURSES_INCLUDES_FOR_UPDATE:
        return {
            ...state,
            coursesIncludesForUpdate: action.payload,
        }
    case coursesIncludesActions.IS_COURSES_INCLUDES_FOR_UPDATE:
        return {
            ...state,
            isUpdateCoursesIncludes: action.payload,
        }
    case coursesIncludesActions.SWITCH_COURSES_INCLUDES_REFRESH_TABLE:
        return {
            ...state,
            refreshTableCoursesIncludes: action.payload,
        }
    default:
        return state
  }
}
