import { message } from "antd";
import { useEffect, useState } from "react";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import { useSelector } from "react-redux";
import { changeModalStateCoursesChapters, createCourseChapters, refreshTableCoursesChapters, selectIsUpdateCoursesChapters, selectRefreshTableCoursesChapters, selectSingleCourses, selectSingleCoursesChapters, setIsCoursesChaptersForUpdate, updateCourseChapters } from "../../../../redux";
import { IModelCoursesChapters } from "../../@types";
import { validateCreateCoursesChapters, validateUpdateCoursesChapters, } from "../../../../shared/utils/yupValidation";

export function useCoursesChapters() {
  const dispatch = useAppDispatch();

  const isUpdateCoursesChapters = useSelector(
      selectIsUpdateCoursesChapters
  );

  const dataUpdateCoursesChapters = useSelector(
      selectSingleCoursesChapters
  );

  const isRefreshTableCoursesChapters = useSelector(
      selectRefreshTableCoursesChapters
  );

  const dataUpdateCourses = useSelector( selectSingleCourses );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalCoursesChaptersStateForCreate = () => {
    dispatch(setIsCoursesChaptersForUpdate(false))
    dispatch(changeModalStateCoursesChapters())
  }

  const formMethodsCreate = useForm<IModelCoursesChapters>({
    resolver: yupResolver(validateCreateCoursesChapters),
  });

  const formMethodsUpdate = useForm<IModelCoursesChapters>({
    resolver: yupResolver(validateUpdateCoursesChapters),
  });

  useEffect(() => {
  if (isUpdateCoursesChapters) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateCoursesChapters });
  }

  return () => {
      formMethodsUpdate.reset();
  };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateCoursesChapters, dataUpdateCoursesChapters]);

  const onSubmitCreateOrUpdate = async (data: IModelCoursesChapters) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateCoursesChapters) {
      await updateCourseChapters({
        id: data.id,
        name: data.name,
        position: data.position,
        course_section_id: data.course_section_id,
        duration: data.duration,
        type: data.type,
        course_id: dataUpdateCourses?.id,
      })
        .then((res: Partial<IModelCoursesChapters>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito el capitulo del curso con id:${res.id}`
            );
            dispatch(refreshTableCoursesChapters(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createCourseChapters({...data, course_id: dataUpdateCourses?.id})
        .then((res: IModelCoursesChapters) => {
          if (res.id) {
            dispatch(changeModalStateCoursesChapters());
            message.success(
              `Se ha creado con éxito el capitulo del curso con id:${res.id}`
            );
            dispatch(refreshTableCoursesChapters(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableCoursesChapters) {
      dispatch(refreshTableCoursesChapters(false));
    }
  }, [isRefreshTableCoursesChapters]);

  return{
      //state
      isLoading,
      isUpdateCoursesChapters,
      dataUpdateCoursesChapters,
      isRefreshTableCoursesChapters,
      //methods
      formMethodsCreate,
      formMethodsUpdate,
      //functions
      changeModalCoursesChaptersStateForCreate,
      onSubmitCreateOrUpdate,
  }
}

