 
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useNews, useTableNews } from "../hooks"
import { TableCustom, newsService } from "../../../shared"
import { Avatar, Button, Col, Typography } from "antd";
import { IModelNews } from "../@types";
import { PATH_S3_BUCKET } from "../../../shared/utils/constants";
import moment from "moment";
import { TableColumns, TableProperties } from "../../../shared/components/ui/table/types";
import React from "react";

const { Title } = Typography;

export function TableNews() {
    const {
        //state
        //methods
        //functions
        changeNewsForUpdate,
        showDeleteConfirm,
    } =  useTableNews()
    const {
        //state
        isRefreshTableNews,
        isLoading,
        //methods
        //functions
        updateStatus,
    }= useNews()

    const tableRef = React.useRef<TableProperties>()

    React.useEffect(() => {
      tableRef.current?.refresh()
    }, [isRefreshTableNews])

    const columns: TableColumns<IModelNews> = [
        {
            title: 'ID',
            dataIndex: 'id',
            align: 'left',
            sorter:true
        },
        {
            title: "Imagen",
            align: 'left',
            dataIndex: "image_path_cover",
            key: "image_path_cover",
            render: (_:any, item:any) => {
              return (
                <Col
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    flexDirection: "row",
                  }}
                >
                  <Avatar size={64} src={`${PATH_S3_BUCKET}/${item.image_path_cover}`} />
                </Col>
              );
            },
        },
        {
            title: 'Titulo',
            dataIndex: 'title',
            align: 'left',
        },
        // {
        //     title: 'Slug',
        //     
        //     dataIndex: 'slug',
        //     align: 'left',
        // },
        {
            title: 'Fecha de publicación',
            dataIndex: 'published_date',
            align: 'left',
            render: (_:any, item:any) => (
              <Col
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "start",
                  flexDirection: "row",
                }}
              >
                <Title level={5}>
                  {moment(item.published_date).utcOffset(0).format("DD/MM/YYYY HH:mm:ss")}
                </Title>
              </Col>
            ),
        },
        {
            title: 'Posición',
            dataIndex: 'position',
            align: 'left',
            sorter:true
        },
        // {
        //     title: 'Visitas hace 7 Días',
        //     
        //     dataIndex: 'views_7_Days',
        //     align: 'left',
        // },
        // {
        //     title: 'Visitas hace 30 Días',
        //     
        //     dataIndex: 'views_30_Days',
        //     align: 'left',
        // },
        // {
        //     title: 'Visitas hace 24 horas',
        //     
        //     dataIndex: 'views_24_hrs',
        //     align: 'left',
        // },
        // {
        //     title: 'Visitas totales',
        //     
        //     dataIndex: 'views_total',
        //     align: 'left',
        // },
        // {
        //     title: 'Total de comentarios',
        //     
        //     dataIndex: 'total_comments',
        //     align: 'left',
        // },
        // {
        //     title: 'Total de likes',
        //     
        //     dataIndex: 'total_likes',
        //     align: 'left',
        // },
        // {
        //     title: 'Total de dislike',
        //     
        //     dataIndex: 'total_unlikes',
        //     align: 'left',
        // },
        // {
        //     title: 'Likes y dilikes',
        //     
        //     dataIndex: 'likes_unlikes',
        //     align: 'left',
        // },
        {
            title: "Estado",
            dataIndex: "status",
            // width: 25,
            key: "status",
            render: (_:any, item:any) => {
              return (
                <Col
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    flexDirection: "row",
                  }}
                >
                  <button
                  disabled={isLoading}
                    onClick={()=>{
                      if(item.status === "active"){
                        updateStatus("inactive", item.id)
                      }else if( item.status === "inactive"){
                        updateStatus("active", item.id)
                      }
                    }}
                    style={{
                      display: "flex",
                      padding: "5px 27px",
                      borderWidth: 0,
                      cursor: "pointer",
                      borderRadius: "20px",
                      backgroundColor:
                        item.status === "active" ? "#4bd542" : "#ff4d4f",
                    }}
                  >
                    <Title
                      style={{
                        marginBottom: "1.5px",
                        color: "white",
                      }}
                      level={5}
                    >
                      {item.status}
                    </Title>
                  </button>
                </Col>
              );
            },
        },
        {
            title: "Acciones",
            key: "operation",
            fixed: "right",
            width: 25,
            render: (_:any, item:any) => {
              return (
                <Col
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    flexDirection: "row",
                  }}
                >
                  <Button
                    type="primary"
                    shape="circle"
                    onClick={() => changeNewsForUpdate(item.id)}
                    icon={<EditOutlined />}
                    size="large"
                  />
                  <Button
                    type="primary"
                    danger
                    onClick={() => showDeleteConfirm(item.id, `${item.title}`)}
                    shape="circle"
                    icon={<DeleteOutlined />}
                    size="large"
                  />
                </Col>
              );
            },
        },
    ]

  return (
    <TableCustom columns={columns}  service={newsService} ref={tableRef} />
  )
}
