import { message } from "antd";

import { webinarsIncludesService } from "../../shared";
import { webinarsIncludesActions } from "../action-types";
import { IModelWebinarsIncludes } from "../../modules";

export const changeModalStateWebinarsIncludes = (): webinarsIncludesActions.IWebinarsIncludesModalState =>({
    type: webinarsIncludesActions.WEBINARS_INCLUDES_MODAL_STATE
})

export const getSingleWebinarsIncludes = (val:IModelWebinarsIncludes ): webinarsIncludesActions.IGetWebinarsIncludesForUpdate=> ({
    type: webinarsIncludesActions.GET_WEBINARS_INCLUDES_FOR_UPDATE,
    payload: val
})

export const setIsWebinarsIncludesForUpdate =(val: boolean): webinarsIncludesActions.IIsWebinarsIncludesForUpdate =>({
    type: webinarsIncludesActions.IS_WEBINARS_INCLUDES_FOR_UPDATE,
    payload: val,
})

export const fetchSingleWebinarsIncludes = async(id: number) =>{
    return await webinarsIncludesService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createWebinarIncludes = async(data:Partial<IModelWebinarsIncludes>) =>{
    return await webinarsIncludesService.create(data)
    .then((res: IModelWebinarsIncludes)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateWebinarIncludes = async(data:Partial<IModelWebinarsIncludes>) =>{
    return await webinarsIncludesService.patch(data?.id!, data)
    .then((res: IModelWebinarsIncludes)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteWebinarIncludes = async(id: number) =>{
    return await webinarsIncludesService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableWebinarsIncludes = (val: boolean): webinarsIncludesActions.ISwitchWebinarsIncludesRefreshTable =>({
    type: webinarsIncludesActions.SWITCH_WEBINARS_INCLUDES_REFRESH_TABLE,
    payload: val
})

