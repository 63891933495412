import { Spin } from "antd";
import { FormProvider } from 'react-hook-form';
import { useEvents, useEventsById } from "../../hooks";
import { EventsForm } from "./EventsForm";
import { WrapperTabNavEvents } from "../styled";
import { EventsAuthors } from "../events-authors";
import { EventsSchedules } from "../events-schedules";
import { EventTags } from "../event-tags";
import { useParams } from "react-router-dom";
import Clusters from "./Clusters";


export function EventsById() {
  const { isUpdateEvents, formMethodsCreate, formMethodsUpdate } =
    useEvents();
  const { isValidate } = useEventsById();

  const {id: eventId} = useParams();

  return (
    <div style={{ width: "100%" }}>
      {!isValidate && <Spin size="large" />}
      {isValidate &&(
        <>
          {!isUpdateEvents && (
            <FormProvider {...formMethodsCreate}>
              <EventsForm />
            </FormProvider>
          )}
          {isUpdateEvents && (
            <WrapperTabNavEvents
              items={[
                {
                  label: `DATOS DEL EVENTO`,
                  key: "1",
                  children: (
                    <FormProvider {...formMethodsUpdate}>
                      <EventsForm />
                    </FormProvider>
                  ),
                },
                {
                  label: 'CLUSTERS DEL EVENTO',
                  key: "2",
                  children: (
                    <Clusters eventId={eventId ? +eventId : undefined} />
                  ),
                },
                {
                  label: `AUTORES DEL EVENTO`,
                  key: "3",
                  children: (
                    <EventsAuthors />
                  ),
                },
                {
                  label: `HORARIOS DEL EVENTO`,
                  key: "4",
                  children: (
                    <EventsSchedules />
                  ),
                },
                {
                  label: `TAGS DEL EVENTO`,
                  key: "5",
                  children: (
                    <EventTags />
                  ),
                },
              ]}
            />
          )}
      </>
    )}
  </div>
  )
}
