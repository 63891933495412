import { Avatar, Button, Col, Typography } from "antd";
 
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useCoursesCategories, useTableCoursesCategories } from "../../hooks";
import { IModelCoursesCategories } from "../../@types";
import { TableCustom, coursesCategoriesService } from "../../../../shared";
import { PATH_S3_BUCKET } from "../../../../shared/utils/constants";
import { TableColumns, TableProperties } from "../../../../shared/components/ui/table/types";
import React from "react";

const {Title} = Typography;

export function TableCoursesCategories() {
  const {
    //state
    //methods
    //functions
    changeCoursesCategoriesForUpdate,
    showDeleteConfirm,
} =  useTableCoursesCategories()
const {
    //state
    isRefreshTableCoursesCategories,
    isLoading,
    //methods
    //functions
    updateStatus,
}= useCoursesCategories()

const tableRef = React.useRef<TableProperties>()

    React.useEffect(() => {
      tableRef.current?.refresh()
    }, [isRefreshTableCoursesCategories])

const columns: TableColumns<IModelCoursesCategories> = [
    {
        title: 'ID',
        dataIndex: 'id',
        align: 'left',
        sorter:true
    },
    {
        title: 'Nombre',
        dataIndex: 'name',
        align: 'left',
    },
    {
        title: "Estado",
        // 
        dataIndex: "status",
        key: "status",
        render: (_:any, item:any) => {
          return (
            <Col
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "start",
                flexDirection: "row",
              }}
            >
              <button
              disabled={isLoading}
                onClick={()=>{
                  if(item.status === "active"){
                    updateStatus("inactive", item.id)
                  }else if( item.status === "inactive"){
                    updateStatus("active", item.id)
                  }
                }}
                style={{
                  display: "flex",
                  padding: "5px 27px",
                  borderWidth: 0,
                  cursor: "pointer",
                  borderRadius: "20px",
                  backgroundColor:
                    item.status === "active" ? "#4bd542" : "#ff4d4f",
                }}
              >
                <Title
                  style={{
                    marginBottom: "1.5px",
                    color: "white",
                  }}
                  level={5}
                >
                  {item.status}
                </Title>
              </button>
            </Col>
          );
        },
    },
    {
      title: 'Posición',
      dataIndex: 'position',
      align: 'left',
      sorter:true
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      render: (_:any, item:any)  => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeCoursesCategoriesForUpdate(item.id)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
]

  return (
    <TableCustom columns={columns}  service={coursesCategoriesService} ref={tableRef}/>
  )
}
