import Title from "antd/es/typography/Title";
import EventsRatings from "../modules/formation-ratings/ui/EventsRatings";
import { Tabs } from "../modules/formation-ratings/ui/components/tabs";
import { AdminLayout } from "../shared";

export default function EventsRatingsPage() {
  return (
    <AdminLayout>
      <Title>Calificaciones</Title>
      <Tabs>
        <EventsRatings />
      </Tabs>
    </AdminLayout>
  );
}
