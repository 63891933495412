import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { selectModalCoursesBenefits } from "../../../../redux";
import { ModalCoursesBenefits } from "./ModalCoursesBenefits";
import { TableCoursesBenefits } from "./TableCoursesBenefits";
import { useCoursesBenefits } from "../../hooks";


export function CoursesBenefits() {
  const modalCoursesBenefits = useSelector(
    selectModalCoursesBenefits
  );

  const {
    //state
    isUpdateCoursesBenefits,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalCoursesBenefitsStateForCreate,
  } = useCoursesBenefits();

  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalCoursesBenefitsStateForCreate}
            >
              Agregar nuevo beneficio a curso
            </Button>
        </Col>
        <TableCoursesBenefits />
        {
            modalCoursesBenefits && (
                <FormProvider { ...isUpdateCoursesBenefits ?{...formMethodsUpdate} : {...formMethodsCreate}}>
                    <ModalCoursesBenefits />
                </FormProvider>
            )
        }
    </Row>
  )
}
