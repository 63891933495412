import { IModelProjectsManagers } from "../../modules"


export const PROJECTS_MANAGERS_MODAL_STATE = '[ ProjectS MANAGERS ] PROJECTs MANAGERS Modal State'

export interface IProjectsManagersModalState {
    type: typeof PROJECTS_MANAGERS_MODAL_STATE
}

export const GET_PROJECTS_MANAGERS_FOR_UPDATE = '[ PROJECTS MANAGERS ] Get PROJECTs MANAGERS For Update'

export interface IGetProjectsManagersForUpdate {
    type: typeof GET_PROJECTS_MANAGERS_FOR_UPDATE
    payload: IModelProjectsManagers
}

export const IS_PROJECTS_MANAGERS_FOR_UPDATE = '[ PROJECTS MANAGERS ] Is Projects MANAGERS For Update'

export interface IIsProjectsManagersForUpdate {
    type: typeof IS_PROJECTS_MANAGERS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_PROJECTS_MANAGERS_REFRESH_TABLE = '[ PROJECTS MANAGERS ] Switch Projects MANAGERS Refresh Table'

export interface ISwitchProjectsManagersRefreshTable {
    type: typeof SWITCH_PROJECTS_MANAGERS_REFRESH_TABLE
    payload: boolean
}