import { FormProvider } from "react-hook-form";
import { Button, Col, Row } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import useEditCmsLawTransparency from "../lib/useEditCmsLawTransparency";
import CmsLawTransparencyForm from "../components/cms-law-transparency-form";

export default function EditCmsLawTransparency() {
  const params = useParams();
  const navigate = useNavigate();

  const { formMethods, loading, handleSubmit } =
    useEditCmsLawTransparency(
      params?.recordId ? Number(params.recordId) : null,
      {
        onSuccess() {
          navigate("/ley-de-transparencia");
        },
      }
    );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit}>
        <Row gutter={[8, 16]} justify={"space-between"}>
          <Col xs={24}>
            <CmsLawTransparencyForm />
          </Col>
          <Col xs={24}>
            <Button
              icon={<SaveOutlined />}
              block
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
}
