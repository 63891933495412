import React from "react";
import {
  InputSelectDynamic,
  TableCustom,
  clustersService,
  projectClusters,
} from "../../../../shared";
import { TableProperties } from "../../../../shared/components/ui/table/types";
import moment from "moment";
import { Button, Modal, Space, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export default function Clusters({ projectId }: { projectId?: number }) {
  const [loading, setLoading] = React.useState(false);

  const tableRef = React.useRef<TableProperties>();
  const loadingRef = React.useRef(false);

  const updateLoading = (status: boolean) => {
    setLoading(status);
    loadingRef.current = status;
  };

  const { control, handleSubmit: onSubmit } = useForm<{
    project_id: number;
    cluster_id: number;
  }>({
    defaultValues: {
      project_id: projectId,
    },
    resolver: yupResolver(
      yup.object().shape({
        cluster_id: yup.number().integer().required("Seleccione un cluster"),
      })
    ),
  });

  const handleSubmit = onSubmit(async (data) => {
    if (loadingRef.current) return;

    updateLoading(true);

    await projectClusters
      .create(data as any)
      .then(() => {
        message.success("Cluster asignado al proyecto");
        tableRef.current?.refresh();
      })
      .catch((err) => {
        message.error(err?.message);
      })
      .finally(() => {
        updateLoading(false);
      });
  });

  const handleDeleteCluster = (recordId: number) => {
    Modal.confirm({
      title: "Seguro que desea eliminar este cluster?",
      okButtonProps: {
        danger: true,
      },
      okText: "Eliminar",
      cancelText: "Cancelar",
      onOk: async () => {
        await projectClusters
          .remove(recordId)
          .then(() => {
            message.success("Cluster eliminado del proyecto!");
          })
          .catch((err) => {
            message.error(err?.message);
          })
          .finally(() => {
            tableRef.current?.refresh();
          });
      },
    });
  };

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <form onSubmit={handleSubmit}>
          <Space>
            <div style={{ width: 200 }}>
              <InputSelectDynamic
                name="cluster_id"
                control={control}
                serviceName={clustersService}
                labelName="name"
                valName="id"
              />
            </div>
            <Button type="primary" htmlType="submit" loading={loading}>
              Asignar
            </Button>
          </Space>
        </form>
      </div>
      <TableCustom
        fetchQueryProps={{
          project_id: projectId,
        }}
        columns={[
          {
            title: "Cluster",
            dataIndex: ["cluster", "name"],
          },
          {
            title: "Fecha de asignación",
            dataIndex: "createdAt",
            render: (createdAt: string) => moment(createdAt).format("LLL"),
          },
          {
            title: "Acciones",
            render: (record: {
              id: number;
              cluster_id: number;
              project_id: number;
            }) => (
              <Space>
                <Button
                  onClick={() => handleDeleteCluster(record.id)}
                  type="text"
                  danger
                >
                  <DeleteOutlined />
                </Button>
              </Space>
            ),
          },
        ]}
        service={projectClusters}
        ref={tableRef}
      />
    </div>
  );
}
