import { message } from "antd";

import {  projectsService } from "../../shared";
import { projectsComponentsActions } from "../action-types";
import { IModelProjectsComponents } from "../../modules";

export const changeModalStateProjectsComponents = (): projectsComponentsActions.IProjectsComponentsModalState =>({
    type: projectsComponentsActions.PROJECTS_COMPONENTS_MODAL_STATE
})

export const getSingleProjectsComponents = (val:IModelProjectsComponents ): projectsComponentsActions.IGetProjectsComponentsForUpdate=> ({
    type: projectsComponentsActions.GET_PROJECTS_COMPONENTS_FOR_UPDATE,
    payload: val
})

export const setIsProjectsComponentsForUpdate =(val: boolean): projectsComponentsActions.IIsProjectsComponentsForUpdate =>({
    type: projectsComponentsActions.IS_PROJECTS_COMPONENTS_FOR_UPDATE,
    payload: val,
})

export const fetchSingleProjectsComponents = async(id: number) =>{
    return await projectsService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createProjectComponents = async(data:Partial<IModelProjectsComponents>) =>{
    return await projectsService.patch(data?.project_id!, { meta_components: JSON.stringify(data) })
    .then((res: IModelProjectsComponents)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateProjectComponents = async(data:Partial<IModelProjectsComponents>) =>{
    return await projectsService.patch(data?.project_id!, { meta_components: JSON.stringify(data) })
    .then((res: IModelProjectsComponents)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteProjectComponents = async(id: number) =>{
    return await projectsService.patch(id, {meta_components: 'null'})
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableProjectsComponents = (val: boolean): projectsComponentsActions.ISwitchProjectsComponentsRefreshTable =>({
    type: projectsComponentsActions.SWITCH_PROJECTS_COMPONENTS_REFRESH_TABLE,
    payload: val
})

