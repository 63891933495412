import { Button, Drawer } from "antd";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { KeyAccountManagersNew } from "../modules/key-account-managers/ui/resource/keyAccountManagerNew";

export function KeyAccountManagersNewPage() {
  return (
    <Drawer
      title="Crear key account managers"
      open
      width={350}
      destroyOnClose
      closeIcon={
        <Link to="/key-account-managers">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <KeyAccountManagersNew />
    </Drawer>
  )
}
