import React from "react";
import { TableProperties } from "../../../shared/components/ui/table/types";
import { Link, useLocation } from "react-router-dom";
import Table from "../../../shared/components/ui/table/TableCustom";
import { infographicCategoriesService } from "../../../shared";
import { Button, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import useDeleteInfographicCategory from "../lib/useDeleteInfographicCategory";
import { IInfographicCategory } from "../@types";

export default function InfographicCategories() {
  const location = useLocation();

  const tableRef = React.useRef<TableProperties>();

  const { handleDelete } = useDeleteInfographicCategory({
    onSuccess() {
      tableRef.current?.refresh();
    },
  });

  React.useEffect(() => {
    tableRef.current?.refresh();
  }, [location.pathname]);

  return (
    <Table
      ref={tableRef}
      service={infographicCategoriesService}
      columns={[
        { title: "ID", dataIndex: "id", sorter: true, filterType: "text" },
        {
          title: "Nombre",
          dataIndex: "name",
        },
        {
          title: "Acciones",
          render: (record: IInfographicCategory) => {
            return (
              <Space>
                <Link to={`/infographic-categories/${record.id}`}>
                  <Button type="link" icon={<EditOutlined />} />
                </Link>
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() => handleDelete(record.id)}
                />
              </Space>
            );
          },
        },
      ]}
    />
  );
}
