import { message } from "antd";

import { eventsTagsActions } from "../action-types";
import { IModelEventsTags } from "../../modules";
import { eventsTagsService } from "../../shared";

export const changeModalStateEventsTags = (): eventsTagsActions.IEventsTagsModalState =>({
    type: eventsTagsActions.EVENTS_TAGS_MODAL_STATE
})

export const getSingleEventsTags = (val:IModelEventsTags ): eventsTagsActions.IGetEventsTagsForUpdate=> ({
    type: eventsTagsActions.GET_EVENTS_TAGS_FOR_UPDATE,
    payload: val
})

export const setIsEventsTagsForUpdate =(val: boolean): eventsTagsActions.IIsEventsTagsForUpdate =>({
    type: eventsTagsActions.IS_EVENTS_TAGS_FOR_UPDATE,
    payload: val,
})

export const fetchSingleEventsTags = async(id: number) =>{
    return await eventsTagsService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createEventsTags = async(data:Partial<IModelEventsTags>) =>{
    return await eventsTagsService.create(data)
    .then((res: IModelEventsTags)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateEventsTags = async(data:Partial<IModelEventsTags>) =>{
    return await eventsTagsService.patch(data?.id!, data)
    .then((res: IModelEventsTags)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteEventsTags = async(id: number) =>{
    return await eventsTagsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableEventsTags = (val: boolean): eventsTagsActions.ISwitchEventsTagsRefreshTable =>({
    type: eventsTagsActions.SWITCH_EVENTS_TAGS_REFRESH_TABLE,
    payload: val
})

