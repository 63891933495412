import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { Link, useParams } from "react-router-dom";
import EditPopup from "../modules/popups/ui/EditPopup";

export default function EditPopupPage() {
  const { recordId } = useParams();

  return (
    <Drawer
      open
      width={350}
      destroyOnClose
      title="Crear popup"
      closeIcon={
        <Link to="/popups">
          <Button type="link" icon={<CloseOutlined />} />
        </Link>
      }
    >
      <EditPopup recordId={recordId ? Number(recordId) : null} />
    </Drawer>
  );
}
