import { message } from "antd";

import { projectsBenefitsService, projectsService } from "../../shared";
import { projectsBenefitsActions } from "../action-types";
import { IModelProjectsBenefits } from "../../modules";

export const changeModalStateProjectsBenefits = (): projectsBenefitsActions.IProjectsBenefitsModalState =>({
    type: projectsBenefitsActions.PROJECTS_BENEFITS_MODAL_STATE
})

export const getSingleProjectsBenefits = (val:IModelProjectsBenefits ): projectsBenefitsActions.IGetProjectsBenefitsForUpdate=> ({
    type: projectsBenefitsActions.GET_PROJECTS_BENEFITS_FOR_UPDATE,
    payload: val
})

export const setIsProjectsBenefitsForUpdate =(val: boolean): projectsBenefitsActions.IIsProjectsBenefitsForUpdate =>({
    type: projectsBenefitsActions.IS_PROJECTS_BENEFITS_FOR_UPDATE,
    payload: val,
})

export const fetchSingleProjectsBenefits = async(id: number) =>{
    try {
        const result = await projectsService.find({ query: { id } });

        // console.log(66, result.data && result.data.length > 0);
        // let selectedColumn = {}
        
        if (result.data && result.data.length > 0) {
            const selectedColumn = JSON.parse(result.data[0]['meta_benefits']);

            return (selectedColumn);
        } else {
            throw new Error('No se encontró ningún proyecto con ese ID.');
        }
    } catch (err) {
        console.error(err);
        // message.error(err );
        return err;
    }
}

export const createProjectBenefits = async(data:Partial<IModelProjectsBenefits>) =>{
    return await projectsService.patch(data?.project_id!, { meta_benefits: JSON.stringify(data) })
    .then((res: IModelProjectsBenefits)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateProjectBenefits = async(data:Partial<IModelProjectsBenefits>) =>{
    return await projectsService.patch(data?.project_id!, { meta_benefits: JSON.stringify(data) })
    .then((res: IModelProjectsBenefits)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteProjectBenefits = async(id: number) =>{
    return await projectsService.patch(id, {meta_benefits: 'null'})
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableProjectsBenefits = (val: boolean): projectsBenefitsActions.ISwitchProjectsBenefitsRefreshTable =>({
    type: projectsBenefitsActions.SWITCH_PROJECTS_BENEFITS_REFRESH_TABLE,
    payload: val
})

