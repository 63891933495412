import { message } from "antd";

import { webinarsAuthorsService } from "../../shared";
import { webinarsAuthorsActions } from "../action-types";
import { IModelWebinarsAuthors } from "../../modules/webinars/@types/IModelWebinarsAuthors";

export const changeModalStateWebinarsAuthors = (): webinarsAuthorsActions.IWebinarsAuthorsModalState =>({
    type: webinarsAuthorsActions.WEBINARS_AUTHORS_MODAL_STATE
})

export const getSingleWebinarsAuthors = (val:IModelWebinarsAuthors ): webinarsAuthorsActions.IGetWebinarsAuthorsForUpdate=> ({
    type: webinarsAuthorsActions.GET_WEBINARS_AUTHORS_FOR_UPDATE,
    payload: val
})

export const setIsWebinarsAuthorsForUpdate =(val: boolean): webinarsAuthorsActions.IIsWebinarsAuthorsForUpdate =>({
    type: webinarsAuthorsActions.IS_WEBINARS_AUTHORS_FOR_UPDATE,
    payload: val,
})

export const fetchSingleWebinarsAuthors = async(id: number) =>{
    return await webinarsAuthorsService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createWebinarAuthors = async(data:Partial<IModelWebinarsAuthors>) =>{
    return await webinarsAuthorsService.create(data)
    .then((res: IModelWebinarsAuthors)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateWebinarAuthors = async(data:Partial<IModelWebinarsAuthors>) =>{
    return await webinarsAuthorsService.patch(data?.id!, data)
    .then((res: IModelWebinarsAuthors)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteWebinarAuthors = async(id: number) =>{
    return await webinarsAuthorsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableWebinarsAuthors = (val: boolean): webinarsAuthorsActions.ISwitchWebinarsAuthorsRefreshTable =>({
    type: webinarsAuthorsActions.SWITCH_WEBINARS_AUTHORS_REFRESH_TABLE,
    payload: val
})

