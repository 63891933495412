import { Button, Col, Typography } from "antd";
import { useFormContext } from 'react-hook-form';
import { InputNumber, InputText } from "../../../../shared";
import { useSelector } from "react-redux";
import { selectModalCoursesBenefits } from "../../../../redux";
import { useCoursesBenefits } from "../../hooks";
import { WrapperModalCourses } from "../styled";
import { IModelCoursesBenefits } from "../../@types";

const { Title } = Typography;

export function ModalCoursesBenefits() {
  const modalCoursesBenefits = useSelector(
    selectModalCoursesBenefits
  );

  const {
    //state
    isLoading,
    isUpdateCoursesBenefits,
    dataUpdateCoursesBenefits,
    //methods
    //functions
    changeModalCoursesBenefitsStateForCreate,
    onSubmitCreateOrUpdate
  } = useCoursesBenefits()

  const { control, handleSubmit: onSubmit } = useFormContext<IModelCoursesBenefits>();

  return (
    <WrapperModalCourses
      title={ isUpdateCoursesBenefits
          ? `Actualizando beneficio del curso: '${dataUpdateCoursesBenefits?.id!}'`
          : `Creando beneficio del curso` }
      open={modalCoursesBenefits}
      width={"320px"}
      destroyOnClose
      onClose={changeModalCoursesBenefitsStateForCreate}>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Nombre:
          </Title>
          <InputText placeHolder="Categoría de webinar" name="name" control={control} />
        </Col>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Posición:
          </Title>
          <InputNumber
            placeHolder="123"
            name="position"
            control={control}
          />
        </Col>
        <Button
            style={{
            width: "100%",
            marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
        >
            {isUpdateCoursesBenefits
            ? "Actualizar información"
            : "Crear beneficio del curso"}
        </Button>
    </WrapperModalCourses>
  )
}
