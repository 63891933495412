import * as yup from 'yup'

export const validateUpdateWebinarsRequirements = yup.object().shape({
    requirement_text:yup
    .string()
    .min(2, 'El TEXTO REQUERIDO debe tener mínimo de 2 caracteres.'),
    position:yup
    .number(),
    // webinar_id:yup
    // .number(),
})