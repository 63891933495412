import { useSelector } from "react-redux";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useEffect, useState } from "react";
import { message } from "antd";
import { changeModalStateProjectsPartners, createProjectPartners, refreshTableProjectsPartners, selectIsUpdateProjectsPartners, selectRefreshTableProjectsPartners, selectSingleProjects, selectSingleProjectsPartners, setIsProjectsPartnersForUpdate, updateProjectPartners } from "../../../../redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { IModelProjectsPartners } from "../../@types";
import { useForm } from "react-hook-form";
import { validateCreateProjectsPartners, validateUpdateProjectsPartners } from "../../../../shared/utils/yupValidation";

export function useProjectsPartners() {
  const dispatch = useAppDispatch();

  const isUpdateProjectsPartners = useSelector(
      selectIsUpdateProjectsPartners
  );

  const dataUpdateProjectsPartners = useSelector(
      selectSingleProjectsPartners
  );

  const isRefreshTableProjectsPartners = useSelector(
      selectRefreshTableProjectsPartners
  );

  const dataUpdateProjects = useSelector( selectSingleProjects );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalProjectsPartnersStateForCreate = () => {
    dispatch(setIsProjectsPartnersForUpdate(false))
    dispatch(changeModalStateProjectsPartners())
  }

  const formMethodsCreate = useForm<IModelProjectsPartners>({
    resolver: yupResolver(validateCreateProjectsPartners),
  });

  const formMethodsUpdate = useForm<IModelProjectsPartners>({
    resolver: yupResolver(validateUpdateProjectsPartners),
  });

  useEffect(() => {
  if (isUpdateProjectsPartners) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateProjectsPartners });
  }

  return () => {
      formMethodsUpdate.reset();
  };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateProjectsPartners, dataUpdateProjectsPartners]);

  const onSubmitCreateOrUpdate = async (data: IModelProjectsPartners) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateProjectsPartners) {
      await updateProjectPartners({
        id: data.id,
        title: data.title,
        items: data.items,
        linkACtion: data.linkACtion,
        textButtom: data.textButtom,
        position: data.position,
        project_id: dataUpdateProjects?.id,
      })
        .then((res: Partial<IModelProjectsPartners>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito los requerimientos con id:${res.id}`
            );
            dispatch(refreshTableProjectsPartners(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createProjectPartners({...data, project_id: dataUpdateProjects?.id})
        .then((res: IModelProjectsPartners) => {
          if (res.id) {
            dispatch(changeModalStateProjectsPartners());
            message.success(
              `Se ha creado con éxito los requerimientos con id:${res.id}`
            );
            dispatch(refreshTableProjectsPartners(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableProjectsPartners) {
      dispatch(refreshTableProjectsPartners(false));
    }
  }, [isRefreshTableProjectsPartners]);

  return{
      //state
      isLoading,
      isUpdateProjectsPartners,
      dataUpdateProjectsPartners,
      dataUpdateProjects,
      isRefreshTableProjectsPartners,
      //methods
      formMethodsCreate,
      formMethodsUpdate,
      //functions
      changeModalProjectsPartnersStateForCreate,
      onSubmitCreateOrUpdate,
  }
}
