import { message } from "antd";

import { webinarsService } from "../../shared";
import { webinarsActions } from "../action-types";
import { IModelWebinars } from "../../modules/webinars/@types/IModelWebinars";

export const getSingleWebinars = (
  val: IModelWebinars
): webinarsActions.IGetWebinarsForUpdate => ({
  type: webinarsActions.GET_WEBINARS_FOR_UPDATE,
  payload: val,
});

export const setIsWebinarsForUpdate = (
  val: boolean
): webinarsActions.IIsWebinarsForUpdate => ({
  type: webinarsActions.IS_WEBINARS_FOR_UPDATE,
  payload: val,
});

export const fetchSingleWebinars = async (id: number) => {
  return await webinarsService
    .find({ query: { id } })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      console.log(err);
      message.error(err.message);
      return err;
    });
};

export const createWebinar = async (data: Partial<IModelWebinars>) => {
  return await webinarsService
    .create(data)
    .then((res: IModelWebinars) => {
      return res;
    })
    .catch((err: any) => {
      return err.message;
    });
};

export const updateWebinar = async ({
  id,
  ...data
}: Partial<IModelWebinars>) => {
  return await webinarsService
    .patch(id!, data)
    .then((res: IModelWebinars) => {
      return res;
    })
    .catch((err: any) => {
      return err.message;
    });
};

export const deleteWebinar = async (id: number) => {
  return await webinarsService
    .remove(id.toString())
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      return err.message;
    });
};

export const refreshTableWebinars = (
  val: boolean
): webinarsActions.ISwitchWebinarsRefreshTable => ({
  type: webinarsActions.SWITCH_WEBINARS_REFRESH_TABLE,
  payload: val,
});
