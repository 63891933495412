import * as yup from 'yup'

export const validateCreateCoursesChapters = yup.object().shape({
    name:yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El NOMBRE debe tener mínimo de 2 caracteres.'),
    // path_video:yup
    // .string()
    // .required('Campo requerido.')
    // .min(1, 'Debes de incluir mínimo un video.'),
    position:yup
    .number()
    .required('Campo requerido.'),
    course_section_id:yup
    .number()
    .required('Campo requerido.'),
    type: yup
    .string()
    .required('Campo requerido.')
    .oneOf(['free', 'paid'], 'Debes elegir entre estas opciones.'),
    duration: yup
    .string()
    .min(2, 'La duración debe ser en un formato valido.'),
})