import { Button, Col, Row } from "antd";
import { FormProvider } from "react-hook-form";
import { BannersForm } from "./BannerForm";
import Title from "antd/es/typography/Title";
import useCreateBanner from "../hooks/useCreateBanners";
import { useNavigate } from "react-router-dom";

export default function CreateBanner() {
    const navigate = useNavigate()

    const {formMethods, loading, handleSubmit} = useCreateBanner({
        onSuccess(res:any) {
            navigate(`/banner/${res.id}`)
        },
    })

    return (
        <Row>
            <Col>
                <Title style={{ marginBottom: "1.5px" }} level={2}>
                  Crear banner
                </Title>
            </Col>
            <Col style={{minWidth: '100%'}}>
                <FormProvider {...formMethods}>
                    <form onSubmit={handleSubmit}>
                        <BannersForm />
                        <div>
                            <Button
                                style={{
                                width: "100%",
                                marginTop: 10,
                                }}
                                type="primary"
                                shape="round"
                                size="large"
                                htmlType="submit"
                                loading={loading}
                            >
                                Crear
                            </Button>
                        </div>
                    </form>
                </FormProvider>
            </Col>
        </Row>
    )
}