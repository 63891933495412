import { IModelEventsAuthors } from "../../modules"


export const EVENTS_AUTHORS_MODAL_STATE = '[ EVENTS AUTHORS ] Events Authors Modal State'

export interface IEventsAuthorsModalState {
    type: typeof EVENTS_AUTHORS_MODAL_STATE
}

export const GET_EVENTS_AUTHORS_FOR_UPDATE = '[ EVENTS AUTHORS ] Get Events Authors For Update'

export interface IGetEventsAuthorsForUpdate {
    type: typeof GET_EVENTS_AUTHORS_FOR_UPDATE
    payload: IModelEventsAuthors
}

export const IS_EVENTS_AUTHORS_FOR_UPDATE = '[ EVENTS AUTHORS ] Is Events Authors For Update'

export interface IIsEventsAuthorsForUpdate {
    type: typeof IS_EVENTS_AUTHORS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_EVENTS_AUTHORS_REFRESH_TABLE = '[ EVENTS AUTHORS ] Switch Events Authors Refresh Table'

export interface ISwitchEventsAuthorsRefreshTable {
    type: typeof SWITCH_EVENTS_AUTHORS_REFRESH_TABLE
    payload: boolean
}