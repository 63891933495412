import { Button, Flex } from "antd";
import PressResourcesLayout from "../modules/press/components/press-resources-layout";
import { AdminLayout } from "../shared";
import { Link, Outlet } from "react-router-dom";
import PressResources from "../modules/press/ui/press-resources";

export default function PressResourcesPage() {
  return (
    <AdminLayout>
      <PressResourcesLayout>
        <div style={{ width: "100%" }}>
          <Flex justify="flex-end">
            <Link to="/press/resources/add">
              <Button type="primary">+ Recurso</Button>
            </Link>
          </Flex>

          <PressResources />
        </div>
      </PressResourcesLayout>
      <Outlet />
    </AdminLayout>
  );
}