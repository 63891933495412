import { useSelector } from "react-redux";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import { IModelWebinarsAuthors } from "../../@types/IModelWebinarsAuthors";
import { changeModalStateWebinarsAuthors, createWebinarAuthors, refreshTableWebinarsAuthors, setIsWebinarsAuthorsForUpdate, updateWebinarAuthors } from "../../../../redux/actions/webinarsAuthors.actions";
import { selectIsUpdateWebinarsAuthors, selectRefreshTableWebinarsAuthors, selectSingleWebinars, selectSingleWebinarsAuthors } from "../../../../redux";
import { validateCreateWebinarsAuthors, validateUpdateWebinarsAuthors } from "../../../../shared/utils/yupValidation";

export function useWebinarsAuthors() {
  const dispatch = useAppDispatch();

  const isUpdateWebinarsAuthors = useSelector(
      selectIsUpdateWebinarsAuthors
  );

  const dataUpdateWebinarsAuthors = useSelector(
      selectSingleWebinarsAuthors
  );

  const isRefreshTableWebinarsAuthors = useSelector(
      selectRefreshTableWebinarsAuthors
  );

  const dataUpdateWebinars = useSelector( selectSingleWebinars );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalWebinarsAuthorsStateForCreate = () => {
    dispatch(setIsWebinarsAuthorsForUpdate(false))
    dispatch(changeModalStateWebinarsAuthors())
  }

  const formMethodsCreate = useForm<IModelWebinarsAuthors>({
    resolver: yupResolver(validateCreateWebinarsAuthors),
  });

  const formMethodsUpdate = useForm<IModelWebinarsAuthors>({
    resolver: yupResolver(validateUpdateWebinarsAuthors),
  });

  useEffect(() => {
    if (isUpdateWebinarsAuthors) {
        formMethodsCreate.reset();
        formMethodsUpdate.reset({ ...dataUpdateWebinarsAuthors });
    }

    return () => {
        formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateWebinarsAuthors, dataUpdateWebinarsAuthors]);

  const onSubmitCreateOrUpdate = async (data: IModelWebinarsAuthors) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateWebinarsAuthors) {
      await updateWebinarAuthors({
        id: data.id,
        author_id: data.author_id,
        webinar_id: dataUpdateWebinars?.id,
      })
        .then((res: Partial<IModelWebinarsAuthors>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito el/la autor/a de webinar con id:${res.id}`
            );
            dispatch(refreshTableWebinarsAuthors(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createWebinarAuthors({...data, webinar_id: dataUpdateWebinars?.id})
        .then((res: IModelWebinarsAuthors) => {
          if (res.id) {
            dispatch(changeModalStateWebinarsAuthors());
            message.success(
              `Se ha creado con éxito el/la autor/a de webinar con id:${res.id}`
            );
            dispatch(refreshTableWebinarsAuthors(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableWebinarsAuthors) {
      dispatch(refreshTableWebinarsAuthors(false));
    }
  }, [isRefreshTableWebinarsAuthors]);

  return{
      //state
      isLoading,
      isUpdateWebinarsAuthors,
      dataUpdateWebinarsAuthors,
      isRefreshTableWebinarsAuthors,
      //methods
      formMethodsCreate,
      formMethodsUpdate,
      //functions
      changeModalWebinarsAuthorsStateForCreate,
      onSubmitCreateOrUpdate,
  }
}
