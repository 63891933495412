import { eventsRatingsService } from "../../../shared";
import Table from "../../../shared/components/ui/table/TableCustom";

export default function EventsRatings() {
  return (
    <Table
      download
      service={eventsRatingsService}
      columns={[
        {
          title: "ID del evento",
          dataIndex: "event_id",
          sorter: true,
          filterType: "text",
        },
        {
          title: "Evento",
          dataIndex: ["event", "name"],
        },
        {
          title: "Usuario",
          render: (record: any) => {
            return `${record.meta_user_first_name} ${record.meta_user_last_name}`;
          },
        },
        {
          title: "Calificación",
          dataIndex: "rating",
          sorter: true,
          filterType: "number_range",
        },
        {
          title: "Mensaje",
          dataIndex: "text",
        },
        {
          title: "Satisfacción de temática",
          dataIndex: "topic_satisfaction",
          sorter: true,
          filterType: "number_range",
        },
        {
          title: "Calificación del expositor",
          dataIndex: "speaker_rating",
          sorter: true,
          filterType: "number_range",
        },
        {
          title: "Calificación del espacio",
          dataIndex: "connection_space_rating",
          sorter: true,
          filterType: "number_range",
        },
        {
          title: "Probabilidad de recomendación",
          dataIndex: "likelihood_to_recommend",
          sorter: true,
          filterType: "number_range",
        },
      ]}
      fetch$ClientProps={{ withEvent: "true" }}
    />
  );
}
