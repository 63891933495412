import { useSelector } from "react-redux";
 
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Button, Col } from "antd";
import { PATH_S3_BUCKET } from "../../../../shared/utils/constants";
import { selectSingleCourses } from "../../../../redux";
import { useCoursesIncludes, useTableCoursesIncludes } from "../../hooks";
import { IModelCoursesIncludes } from "../../@types";
import { TableCustom, coursesIncludesService } from "../../../../shared";
import { TableColumns, TableProperties } from "../../../../shared/components/ui/table/types";
import React from "react";

export function TableCoursesIncludes() {
  const dataUpdateCourses = useSelector( selectSingleCourses );

  const {
    //state
    //methods
    //functions
    changeCoursesIncludesForUpdate,
    showDeleteConfirm,
  } = useTableCoursesIncludes()

  const {
    //state
    isRefreshTableCoursesIncludes,
    //methods
    //functions
  }= useCoursesIncludes()

  const tableRef = React.useRef<TableProperties>()

    React.useEffect(() => {
      tableRef.current?.refresh()
    }, [isRefreshTableCoursesIncludes])

  const columns: TableColumns<IModelCoursesIncludes> = [
    {
        title: 'ID',
        dataIndex: 'id',
        align: 'left',
        sorter:true
    },
    {
        title: 'Texto requerido',
        dataIndex: 'include_text',
        align: 'left',
    },
    {
      title: "Icono",
      
      dataIndex: "path_icon",
      key: "path_icon",
      render: (_:any, item:any) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Avatar size={64} src={`${PATH_S3_BUCKET}/${item.path_icon}`} />
          </Col>
        );
      },
    },
    {
        title: 'Posición',
        dataIndex: 'position',
        align: 'left',
    },
    {
      title: 'Curso',
      dataIndex: 'course_id',
      align: 'left',
    },
    {
        title: "Acciones",
        key: "operation",
        fixed: "right",
        width: 140,
        render: (_:any, item:any) => {
          return (
            <Col
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "start",
                flexDirection: "row",
              }}
            >
              <Button
                type="primary"
                shape="circle"
                onClick={() => changeCoursesIncludesForUpdate(item.id)}
                icon={<EditOutlined />}
                size="large"
              />
              <Button
                type="primary"
                danger
                onClick={() => showDeleteConfirm(item.id, `${item.include_text.slice(0, 10) + "..."}`)}
                shape="circle"
                icon={<DeleteOutlined />}
                size="large"
              />
            </Col>
          );
        },
    },
  ]

  return (
    <TableCustom fetchQueryProps={{course_id: dataUpdateCourses?.id!,}} columns={columns}  service={coursesIncludesService} ref={tableRef}/>
  )
}
