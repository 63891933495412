import { message } from "antd";

import { eventTagsActions } from "../action-types";
import { IModelEventTags } from "../../modules";
import { eventTagsService } from "../../shared";

export const changeModalStateEventTags = (): eventTagsActions.IEventTagsModalState =>({
    type: eventTagsActions.EVENT_TAGS_MODAL_STATE
})

export const getSingleEventTags = (val:IModelEventTags ): eventTagsActions.IGetEventTagsForUpdate=> ({
    type: eventTagsActions.GET_EVENT_TAGS_FOR_UPDATE,
    payload: val
})

export const setIsEventTagsForUpdate =(val: boolean): eventTagsActions.IIsEventTagsForUpdate =>({
    type: eventTagsActions.IS_EVENT_TAGS_FOR_UPDATE,
    payload: val,
})

export const fetchSingleEventTags = async(id: number) =>{
    return await eventTagsService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createEventTags = async(data:Partial<IModelEventTags>) =>{
    return await eventTagsService.create(data)
    .then((res: IModelEventTags)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateEventTags = async(data:Partial<IModelEventTags>) =>{
    return await eventTagsService.patch(data?.id!, data)
    .then((res: IModelEventTags)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteEventTags = async(id: number) =>{
    return await eventTagsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableEventTags = (val: boolean): eventTagsActions.ISwitchEventTagsRefreshTable =>({
    type: eventTagsActions.SWITCH_EVENT_TAGS_REFRESH_TABLE,
    payload: val
})

