import styled, { css } from "styled-components";

type TWidgetItemWrapperProps = {
  isDragging: boolean;
};

export const WidgetItemWrapper = styled.li<TWidgetItemWrapperProps>`
  border: 1px solid #d9d9d9;
  border-radius: 0.5em;
  padding: 8px;
  cursor: move;
  user-select: none;
  position: relative;
  background-color: white;

  ${({ isDragging }) => {
    if (isDragging) {
      return css`
        opacity: 0.6;
        transition: opacity 0.3s;
      `;
    }
  }}

  & .drag-icon {
    display: none;
  }

  &:hover {
    background-color: #f5f5f5;
    transition: background-color 0.3s;

    .drag-icon {
      display: block;
    }
  }
`;
