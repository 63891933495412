import { EventsById } from "../modules";
import { AdminLayout } from "../shared";

export function EventsByIdPage() {
  return (
    <AdminLayout>
      <EventsById />
    </AdminLayout>
  )
}
