import { Card, Col } from "antd";
import styled from "styled-components";

export const WrapperColLogin = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: var(--primary);
`;

export const WrapperCardLogin = styled(Card)`
  height: 56%;
  width: 50%;
  border-radius: 11px;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  max-height: 360px;
`;