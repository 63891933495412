import { useSelector } from "react-redux";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import { changeModalStateEventsAuthors, createEventAuthors, refreshTableEventsAuthors, selectIsUpdateEventsAuthors, selectRefreshTableEventsAuthors, selectSingleEvents, selectSingleEventsAuthors, setIsEventsAuthorsForUpdate, updateEventAuthors } from "../../../../redux";
import { IModelEventsAuthors } from "../../@types";
import { validateCreateEventsAuthors, validateUpdateEventsAuthors } from "../../../../shared/utils/yupValidation";

export function useEventsAuthors() {
  const dispatch = useAppDispatch();

  const isUpdateEventsAuthors = useSelector(
      selectIsUpdateEventsAuthors
  );

  const dataUpdateEventsAuthors = useSelector(
      selectSingleEventsAuthors
  );

  const isRefreshTableEventsAuthors = useSelector(
      selectRefreshTableEventsAuthors
  );

  const dataUpdateEvents = useSelector( selectSingleEvents );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalEventsAuthorsStateForCreate = () => {
    dispatch(setIsEventsAuthorsForUpdate(false))
    dispatch(changeModalStateEventsAuthors())
  }

  const formMethodsCreate = useForm<IModelEventsAuthors>({
    resolver: yupResolver(validateCreateEventsAuthors),
  });

  const formMethodsUpdate = useForm<IModelEventsAuthors>({
    resolver: yupResolver(validateUpdateEventsAuthors),
  });

  useEffect(() => {
    if (isUpdateEventsAuthors) {
        formMethodsCreate.reset();
        formMethodsUpdate.reset({ ...dataUpdateEventsAuthors });
    }

    return () => {
        formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateEventsAuthors, dataUpdateEventsAuthors]);

  const onSubmitCreateOrUpdate = async (data: IModelEventsAuthors) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateEventsAuthors) {
      await updateEventAuthors({
        id: data.id,
        author_id: data.author_id,
        event_id: dataUpdateEvents?.id,
      })
        .then((res: Partial<IModelEventsAuthors>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito el/la autor/a del evento con id:${res.id}`
            );
            dispatch(refreshTableEventsAuthors(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createEventAuthors({...data, event_id: dataUpdateEvents?.id})
        .then((res: IModelEventsAuthors) => {
          if (res.id) {
            dispatch(changeModalStateEventsAuthors());
            message.success(
              `Se ha creado con éxito el/la autor/a del evento con id:${res.id}`
            );
            dispatch(refreshTableEventsAuthors(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableEventsAuthors) {
      dispatch(refreshTableEventsAuthors(false));
    }
  }, [isRefreshTableEventsAuthors]);

  return{
      //state
      isLoading,
      isUpdateEventsAuthors,
      dataUpdateEventsAuthors,
      isRefreshTableEventsAuthors,
      //methods
      formMethodsCreate,
      formMethodsUpdate,
      //functions
      changeModalEventsAuthorsStateForCreate,
      onSubmitCreateOrUpdate,
  }
}
