import { IModelWebinarsRequirements } from "../../modules"


export const WEBINARS_REQUIREMENTS_MODAL_STATE = '[ WEBINARS REQUIREMENTS ] Webinars Requirements Modal State'

export interface IWebinarsRequirementsModalState {
    type: typeof WEBINARS_REQUIREMENTS_MODAL_STATE
}

export const GET_WEBINARS_REQUIREMENTS_FOR_UPDATE = '[ WEBINARS REQUIREMENTS ] Get Webinars Requirements For Update'

export interface IGetWebinarsRequirementsForUpdate {
    type: typeof GET_WEBINARS_REQUIREMENTS_FOR_UPDATE
    payload: IModelWebinarsRequirements
}

export const IS_WEBINARS_REQUIREMENTS_FOR_UPDATE = '[ WEBINARS REQUIREMENTS ] Is Webinars Requirements For Update'

export interface IIsWebinarsRequirementsForUpdate {
    type: typeof IS_WEBINARS_REQUIREMENTS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_WEBINARS_REQUIREMENTS_REFRESH_TABLE = '[ WEBINARS REQUIREMENTS ] Switch Webinars Requirements Refresh Table'

export interface ISwitchWebinarsRequirementsRefreshTable {
    type: typeof SWITCH_WEBINARS_REQUIREMENTS_REFRESH_TABLE
    payload: boolean
}