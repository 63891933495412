import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { Button, Col, Row, Typography } from "antd";
import { useSelector } from "react-redux";
import { UsekeyAccountManagers } from "../../hooks";
import { IModelkeyAccountManagers } from "../../@types";
import { selectIsUpdateRegistryServicesConsultNew, selectSingleRegistryServicesConsultNew } from "../../../../redux";
import { InputData, InputNumber, InputSelect, InputSelectDynamic, InputText, eventsCategoriesService } from "../../../../shared";
import { useLocation } from "react-router-dom";

const { Title } = Typography;

export function KeyAccountManagersForm() {
  const [selectProps, setSelectProps] = useState<any>();

  const { control } = useFormContext<IModelkeyAccountManagers>();

  const location = useLocation();

  useEffect(() => {
    selectProps?.refresh?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return (
    <>
        <Row justify="start">
            <Col style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            }} span={24}>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Nombre:
            </Title>
            <InputText
              placeHolder=""
              name="first_name"
              control={control}
            />
            </Col>
            <Col style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            }} span={24}>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Apellido:
            </Title>
            <InputText
              placeHolder=""
              name="last_name"
              control={control}
            />
            </Col>
            <Col style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            }} span={24}>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Email:
            </Title>
            <InputText
              placeHolder=""
              name="email"
              control={control}
            />
            </Col>
            <Col style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            }} span={24}>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              telefono:
            </Title>
            <InputText
              placeHolder=""
              name="phone"
              control={control}
            />
            </Col>
            <Col
              span={24}
              style={{
                  display: "flex",
                  marginTop: "10px",
                  padding: 5,
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
              }}>
                <Title style={{ marginBottom: "1.5px" }} level={5}>
                  Estado:
              </Title>
              <InputSelect
                name="status"
                control={control}
                dataOptions={[
                  { title: "Activo", value: "active" },
                  { title: "Inactivo", value: "inactive" },
                ]}
                label="Estado"
              />
            </Col>
            <Col style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            }} span={24}>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              DNI:
            </Title>
            <InputText
              placeHolder=""
              name="dni"
              control={control}
            />
            </Col>
        </Row>
    </>
  )
}
