import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { Link, useParams } from "react-router-dom";
import EditPressResource from "../modules/press/ui/edit-press-resource";

export default function EditPressResourcePage() {
  const {recordId} = useParams()

  return (
    <Drawer
      title="Editar recurso"
      open
      width={350}
      destroyOnClose
      closeIcon={
        <Link to="/press/resources">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <EditPressResource recordId={recordId ? +recordId : null} />
    </Drawer>
  );
}
