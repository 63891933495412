import { Button, Col, Typography } from "antd";
import { useFormContext } from 'react-hook-form';
import { WrapperModalWebinars } from "../styled";
import { InputNumber, InputText } from "../../../../shared";
import { useSelector } from "react-redux";
import { selectModalWebinarsBenefits } from "../../../../redux";
import { useWebinarsBenefits } from "../../hooks";
import { IModelWebinarsBenefits } from "../../@types";

const { Title } = Typography;

export function ModalWebinarsBenefits() {
  const modalWebinarsBenefits = useSelector(
    selectModalWebinarsBenefits
  );

  const {
    //state
    isLoading,
    isUpdateWebinarsBenefits,
    dataUpdateWebinarsBenefits,
    //methods
    //functions
    changeModalWebinarsBenefitsStateForCreate,
    onSubmitCreateOrUpdate
  } = useWebinarsBenefits()

  const { control, handleSubmit: onSubmit } = useFormContext<IModelWebinarsBenefits>();

  return (
    <WrapperModalWebinars
      title={ isUpdateWebinarsBenefits
          ? `Actualizando categoría de webinar: '${dataUpdateWebinarsBenefits?.id!}'`
          : `Creando categoría de webinar` }
      open={modalWebinarsBenefits}
      width={"320px"}
      destroyOnClose
      onClose={changeModalWebinarsBenefitsStateForCreate}>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Nombre:
          </Title>
          <InputText placeHolder="Categoría de webinar" name="name" control={control} />
        </Col>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Posición:
          </Title>
          <InputNumber
            placeHolder="123"
            name="position"
            control={control}
          />
        </Col>
        <Button
            style={{
            width: "100%",
            marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
        >
            {isUpdateWebinarsBenefits
            ? "Actualizar información"
            : "Crear beneficio del webinar"}
        </Button>
    </WrapperModalWebinars>
  )
}
