
import { IModelEvents } from '../../modules';
import { IEventsState } from '../@types/events';

import { eventsActions } from '../action-types'

type eventsActionType =
| { type: typeof eventsActions.GET_EVENTS_FOR_UPDATE; payload: IModelEvents }
| { type: typeof eventsActions.IS_EVENTS_FOR_UPDATE; payload: boolean }
| { type: typeof eventsActions.SWITCH_EVENTS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IEventsState = {
    isUpdateEvents: false,
    eventsForUpdate: null,
    refreshTableEvents: false,
}

export default function eventsReducer(state: IEventsState = INITIAL_STATE, action: eventsActionType): IEventsState {
  switch (action.type) {
    case eventsActions.GET_EVENTS_FOR_UPDATE:
        return {
            ...state,
            eventsForUpdate: action.payload,
        }
    case eventsActions.IS_EVENTS_FOR_UPDATE:
        return {
            ...state,
            isUpdateEvents: action.payload,
        }
    case eventsActions.SWITCH_EVENTS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableEvents: action.payload,
        }
    default:
        return state
  }
}
