import { FormProvider } from "react-hook-form";
import { useLogin } from "../modules";
import { Login } from "../modules/login/ui";
import { LoginLayout } from "../shared";

export function LoginPage() {
  const { formsMethods } = useLogin();
  return (
    <LoginLayout>
      <FormProvider {...formsMethods}>
        <Login />
      </FormProvider>
    </LoginLayout>
  )
}
