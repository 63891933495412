import React from "react";
import { Spin } from "antd";
import { getPicture } from "../../../../../shared/utils/imageHandler";
import Field, { IField } from "./field";
import useDroppable from "./lib/canvas/useDroppable";

export default function Canvas({
  fields,
  srcPath,
  onChange,
}: {
  fields: IField[];
  srcPath?: string;
  onChange?: (fields: IField[]) => void;
}) {
  const [selectedFieldIndex, setSelectedFieldIndex] = React.useState(-1);

  const { listeners } = useDroppable({
    onDrop(field) {
      const newFields = [...fields, field];

      onChange?.(newFields);
      setSelectedFieldIndex(fields.length);
    },
  });

  return (
    <div
      style={{
        border: "1px solid #d9d9d9",
        borderRadius: "6px",
        overflow: "hidden",
        backgroundColor: "#f5f5f5",
        padding: "4px",
        display: "flex",
        justifyContent: "center",
        position: "relative", // Agregamos posición relativa para que los campos arrastrables se coloquen correctamente
      }}
    >
      {!!srcPath ? (
        <div
          id="canvas"
          onClick={() => {
            setSelectedFieldIndex(-1);
          }}
          style={{
            width: 900,
            height: 600,
          }}
          {...listeners}
        >
          <img
            alt="template"
            src={getPicture(srcPath)}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              userSelect: "none",
            }}
          />
          {fields.map((field, index) => (
            <Field
              onRemove={() => {
                const newFields = [...fields];
                newFields.splice(index, 1);

                onChange?.(newFields);
              }}
              onChange={(field) => {
                onChange?.(
                  fields.map((f, i) =>
                    i === index
                      ? {
                          ...f,
                          ...field,
                        }
                      : f
                  )
                );
              }}
              key={`dragged-field-${field.key_name}${index}`}
              field={field}
              onClick={() => setSelectedFieldIndex(index)}
              selected={selectedFieldIndex === index}
            />
          ))}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "500px",
          }}
        >
          <Spin />
        </div>
      )}
    </div>
  );
}
