import { useFormContext } from "react-hook-form";
import { Col, Row, Typography } from "antd";
import { IModelStudy } from "../../@types";
import {
  InputData,
  InputSelect,
  InputSelectDynamic,
  InputText,
  studyCertificateTemplatesService,
  usersService,
} from "../../../../shared";

const { Title } = Typography;

export function FormCategiesInfograpgies() {
  const { control } = useFormContext<IModelStudy>();

  return (
    <>
      <Row justify="start">
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        ></Col>
        <Col
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
          span={24}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Nombre:
          </Title>
          <InputText placeHolder="" name="name" control={control} />
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Plantila:
          </Title>
          <InputSelectDynamic
            name="study_certificate_template_id"
            control={control}
            serviceName={studyCertificateTemplatesService}
            labelName="name"
            valName="id"
          />
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Usuario:
          </Title>
          <InputSelectDynamic
            name="user_id"
            control={control}
            serviceName={usersService}
            labelName="first_name"
            valName="id"
            labelName2="last_name"
          />
        </Col>
        <InputData
          control={control}
          name="emission_date"
          labelText="Fecha de emisión"
        />
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Encuesta necesaria:
          </Title>
          <InputSelect
            name="survey_needed"
            control={control}
            dataOptions={[
              { title: "Si", value: "true" },
              { title: "No", value: "false" },
            ]}
            label="Encuesta necesaria"
          />
        </Col>
      </Row>
    </>
  );
}
