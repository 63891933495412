import { Tabs } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function PressResourcesLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const activeKey = React.useMemo(() => {
    if (
      [
        "/press/resources",
        "/press/resources/add",
        "/press/resources/:recordId",
      ].includes(location.pathname)
    ) {
      return "/press/resources";
    }
    return "/press/resources/categories";
  }, [location.pathname]);

  return (
    <>
      <Title level={3}>Recursos sala de prensa</Title>
      <div style={{ width: "100%" }}>
        <Tabs
          size="large"
          type="card"
          activeKey={activeKey}
          onChange={(activeKey) => {
            navigate(activeKey);
          }}
          items={[
            {
              key: "/press/resources",
              label: "Recursos",
              children,
            },
            {
              key: "/press/resources/categories",
              label: "Categorías",
              children,
            },
          ]}
        />
      </div>
    </>
  );
}
