import React from 'react'
import { IStudyCertificateTemplate } from '../../../../@types/dto/study-certificate-template'
import { studyCertificateTemplatesService } from '../../../../shared'
import { message } from 'antd'

export default function useCreateStudyCertificateTemplate(props?: {
  onSuccess?: (templateId: IStudyCertificateTemplate) => void
}) {
  const [loading, setLoading] = React.useState(false)
  
  const loadingRef = React.useRef(false)

  const updateLoading = (value: boolean) => {
    setLoading(value)
    loadingRef.current = value
  }

  const handleCreateStudyCertificateTemplate = async (data: IStudyCertificateTemplate) => {
    if (loadingRef.current) {
      return
    }

    updateLoading(true)
    
    await studyCertificateTemplatesService.create(data)
      .then((response) => {
        props?.onSuccess?.(response as IStudyCertificateTemplate)
      })
      .catch((error) => {
        message.error(error?.message)
      })
      .finally(() => {
        updateLoading(false)
      })
  }

  return {
    loading,
    handleCreateStudyCertificateTemplate
  }
}