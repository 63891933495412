import { AdminLayout } from "../shared";
import { CiuuManagers } from "../modules/ciuu";

export const CiuuManagersPage = () => {
  return (
    <AdminLayout>
      <CiuuManagers />
    </AdminLayout>
  );
};
