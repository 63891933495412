import { message } from "antd";

import { projectsService } from "../../shared";
import { projectsObjectivesActions } from "../action-types";
import { IModelProjectsObjectives } from "../../modules";

export const changeModalStateProjectsObjectives = (): projectsObjectivesActions.IProjectsObjectivesModalState =>({
    type: projectsObjectivesActions.PROJECTS_OBJECTIVES_MODAL_STATE
})

export const getSingleProjectsObjectives = (val:IModelProjectsObjectives ): projectsObjectivesActions.IGetProjectsObjectivesForUpdate=> ({
    type: projectsObjectivesActions.GET_PROJECTS_OBJECTIVES_FOR_UPDATE,
    payload: val
})

export const setIsProjectsObjectivesForUpdate =(val: boolean): projectsObjectivesActions.IIsProjectsObjectivesForUpdate =>({
    type: projectsObjectivesActions.IS_PROJECTS_OBJECTIVES_FOR_UPDATE,
    payload: val,
})

export const fetchSingleProjectsObjectives = async(id: number) =>{
    return await projectsService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createProjectObjectives = async(data:Partial<IModelProjectsObjectives>) =>{
    return await projectsService.patch(data?.project_id!, { meta_objectives: JSON.stringify(data) })
    .then((res: IModelProjectsObjectives)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateProjectObjectives = async(data:Partial<IModelProjectsObjectives>) =>{
    return await projectsService.patch(data?.project_id!, { meta_objectives: JSON.stringify(data) })
    .then((res: IModelProjectsObjectives)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteProjectObjectives = async(id: number) =>{
    return await projectsService.patch(id, {meta_objectives: 'null'})
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableProjectsObjectives = (val: boolean): projectsObjectivesActions.ISwitchProjectsObjectivesRefreshTable =>({
    type: projectsObjectivesActions.SWITCH_PROJECTS_OBJECTIVES_REFRESH_TABLE,
    payload: val
})

