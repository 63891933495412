
import { IModelEventsSchedules } from '../../modules';
import { IEventsSchedulesState } from '../@types/eventsSchedules';

import { eventsSchedulesActions } from '../action-types'

type eventsSchedulesActionType =
| { type: typeof eventsSchedulesActions.EVENTS_SCHEDULES_MODAL_STATE; }
| { type: typeof eventsSchedulesActions.GET_EVENTS_SCHEDULES_FOR_UPDATE; payload: IModelEventsSchedules }
| { type: typeof eventsSchedulesActions.IS_EVENTS_SCHEDULES_FOR_UPDATE; payload: boolean }
| { type: typeof eventsSchedulesActions.SWITCH_EVENTS_SCHEDULES_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IEventsSchedulesState = {
    eventsSchedulesModalIsOpen: false,
    isUpdateEventsSchedules: false,
    eventsSchedulesForUpdate: null,
    refreshTableEventsSchedules: false,
}

export default function eventsSchedulesReducer(state: IEventsSchedulesState = INITIAL_STATE, action: eventsSchedulesActionType): IEventsSchedulesState {
  switch (action.type) {
    case eventsSchedulesActions.EVENTS_SCHEDULES_MODAL_STATE:
        return {
            ...state,
            eventsSchedulesModalIsOpen: !state.eventsSchedulesModalIsOpen,
        }
    case eventsSchedulesActions.GET_EVENTS_SCHEDULES_FOR_UPDATE:
        return {
            ...state,
            eventsSchedulesForUpdate: action.payload,
        }
    case eventsSchedulesActions.IS_EVENTS_SCHEDULES_FOR_UPDATE:
        return {
            ...state,
            isUpdateEventsSchedules: action.payload,
        }
    case eventsSchedulesActions.SWITCH_EVENTS_SCHEDULES_REFRESH_TABLE:
        return {
            ...state,
            refreshTableEventsSchedules: action.payload,
        }
    default:
        return state
  }
}
