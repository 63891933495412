import { Button, Col, Typography } from "antd";
import { useFormContext } from 'react-hook-form';
import { useSelector } from "react-redux";
import { InputNumber, InputText } from "../../../../shared";
import { selectModalCoursesRequirements } from "../../../../redux";
import { useCoursesRequirements } from "../../hooks";
import { WrapperModalCourses } from "../styled";
import { IModelCoursesRequirements } from "../../@types";

const { Title } = Typography;

export function ModalCoursesRequirements() {
  const modalCoursesRequirements = useSelector(
    selectModalCoursesRequirements
  );

  const {
    //state
    isLoading,
    isUpdateCoursesRequirements,
    dataUpdateCoursesRequirements,
    //methods
    //functions
    changeModalCoursesRequirementsStateForCreate,
    onSubmitCreateOrUpdate
  } = useCoursesRequirements()

  const { control, handleSubmit: onSubmit } = useFormContext<IModelCoursesRequirements>();

  return (
    <WrapperModalCourses
      title={ isUpdateCoursesRequirements
          ? `Actualizando requerimientos de curso: '${dataUpdateCoursesRequirements?.id!}'`
          : `Creando requerimientos de curso` }
      open={modalCoursesRequirements}
      width={"320px"}
      destroyOnClose
      onClose={changeModalCoursesRequirementsStateForCreate}>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Text requerido:
          </Title>
          <InputText placeHolder="Requerimiento de curso...." name="requirement_text" rows control={control} />
        </Col>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Posición:
          </Title>
          <InputNumber
            placeHolder="123"
            name="position"
            control={control}
          />
        </Col>
        {/* <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}>
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Course asignado:
          </Title>
          <InputSelectDynamic name="webinar_id" control={control} serviceName={webinarsService} labelName="name" valName="id"/>
        </Col> */}
        <Button
            style={{
            width: "100%",
            marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
        >
            {isUpdateCoursesRequirements
            ? "Actualizar información"
            : "Crear requerimiento de curso"}
        </Button>
    </WrapperModalCourses>
  )
}
