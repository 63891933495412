import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import { Button, Col, Typography, Avatar } from "antd";
import { TableCustom, webinarsService } from "../../../../shared";
import { IModelWebinars } from "../../@types";
import { useTableWebinars, useWebinars } from "../../hooks";
import { PATH_S3_BUCKET } from "../../../../shared/utils/constants";
import {
  TableColumns,
  TableProperties,
} from "../../../../shared/components/ui/table/types";
import React from "react";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

export function TableWebinars() {
  const {
    //state
    //methods
    //functions
    changeWebinarsForUpdate,
    showDeleteConfirm,
  } = useTableWebinars();

  const {
    //state
    isRefreshTableWebinars,
    isLoading,
    //methods
    //functions
    updateStatus,
  } = useWebinars();

  const tableRef = React.useRef<TableProperties>();

  React.useEffect(() => {
    tableRef.current?.refresh();
  }, [isRefreshTableWebinars]);

  const navigate = useNavigate();

  const columns: TableColumns<IModelWebinars> = [
    {
      title: "ID",
      dataIndex: "id",
      align: "left",
      sorter: true,
    },
    {
      title: "Imagen",

      dataIndex: "path_image",
      key: "path_image",
      render: (_: any, item: any) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Avatar size={64} src={`${PATH_S3_BUCKET}/${item.path_image}`} />
          </Col>
        );
      },
    },
    {
      title: "Nombre",

      dataIndex: "name",
      align: "left",
    },
    {
      title: "Estado",

      dataIndex: "status",
      key: "status",
      render: (_: any, item: any) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <button
              disabled={isLoading}
              onClick={() => {
                if (item.status === "active") {
                  updateStatus("inactive", item.id);
                } else if (item.status === "inactive") {
                  updateStatus("active", item.id);
                }
              }}
              style={{
                display: "flex",
                padding: "5px 27px",
                borderWidth: 0,
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor:
                  item.status === "active" ? "#4bd542" : "#ff4d4f",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "white",
                }}
                level={5}
              >
                {item.status}
              </Title>
            </button>
          </Col>
        );
      },
    },
    {
      title: "Posición",
      dataIndex: "position",
      align: "left",
      sorter: true,
    },
    {
      title: "Tipo",
      dataIndex: "type",
      align: "left",
    },
    {
      title: "Fecha de inicio",
      dataIndex: "start_date",
      align: "left",
      render: (_: any, item: any) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
            flexDirection: "row",
          }}
        >
          <Title level={5}>
            {moment(item.start_date).utcOffset(0).format("DD/MM/YYYY HH:mm:ss")}
          </Title>
        </Col>
      ),
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 25,
      render: (_: any, item: any) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
              gap: 2,
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => navigate(`/user-webinar/${item.id}`)}
              icon={<EyeOutlined />}
              size="large"
            />
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeWebinarsForUpdate(item.id)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  return (
    <TableCustom columns={columns} service={webinarsService} ref={tableRef} />
  );
}
