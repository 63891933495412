import { useForm } from "react-hook-form"
import { IUser } from "../../../redux/@types"
import { IModelUsers } from "../@types"
import React from "react"
import { usersService } from "../../../shared"
import { message } from "antd"

export default function useUpdateUser(userId?: number | null, props?: { onSuccess?: (res: IModelUsers) => void }) {
    const [loading, setLoading] = React.useState(false)

    const formMethods = useForm<IModelUsers>()

    const loadingRef = React.useRef(false)

    const updateLoading = (status: boolean) => {
        loadingRef.current = status
        setLoading(status)
    }

    const getUserData = async () => {
        if (loadingRef.current || !userId) return

        updateLoading(true)

        await usersService.get(userId)
            .then(res => res as IModelUsers)
            .then(res => {
                formMethods.reset(res)
            })
            .finally(() => {
                updateLoading(false)
            })
    }

    const handleSubmit = async (data: IModelUsers) => {
        if (loadingRef.current || !userId) return

        updateLoading(true)

        await usersService.patch(userId, {...data, phone_country_code: data.phone_country_code ? data.phone_country_code.toString() : ''})
            .then(res => {
                message.success('Usuario actualizado')
                props?.onSuccess?.(res)
            })
            .catch(err => {
                message.error(err?.message)
            })
            .finally(() => {
                updateLoading(false)
            })
    }

    React.useEffect(() => {
        if (userId) {
            getUserData()
        }
    }, [userId])

    return {
        formMethods,
        loading,
        handleSubmit: formMethods.handleSubmit(handleSubmit)
    }
}