import UpdateBanner from "../modules/banners/ui/UpdateBanner";
import { UsersById } from "../modules/users";
import UpdateUser from "../modules/users/ui/users/UpdateUser";
import { AdminLayout } from "../shared";

export function BannnerByIdPage() {
  return (
    <AdminLayout>
      <UpdateBanner />
    </AdminLayout>
  )
}
