import React from "react";
import { TableProperties } from "../../../shared/components/ui/table/types";
import { Link, useLocation } from "react-router-dom";
import Table from "../../../shared/components/ui/table/TableCustom";
import { Button, Col, Space, Tag } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {  pressNewsletterService } from "../../../shared";
import moment from "moment";
import Title from "antd/es/typography/Title";
import useUpdatePressNewsletterStatus from "../lib/useUpdatePressNewsletterStatus";
import useDeletePressNewsletter from "../lib/useDeletePressNewsletter";
import { IPressNewsletter } from "../@types/press-newsletter";
import { getPicture } from "../../../shared/utils/imageHandler";

export default function PressNewsletter() {
  const location = useLocation();

  const tableRef = React.useRef<TableProperties>();

  const { updateStatus } = useUpdatePressNewsletterStatus({
    onSuccess() {
      tableRef.current?.refresh();
    },
  });


  const { handleDelete } = useDeletePressNewsletter({
    onSuccess() {
      tableRef.current?.refresh();
    },
  });

  const columns = React.useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        align: "left",
        sorter: true,
      },
      {
        title: "Imagen de Portada",
        align: "left",
        dataIndex: "image_path_cover",
        key: "image_path_cover",
        render: (_: any, item: any) => {
          return (
            <Col
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "start",
                flexDirection: "row",
              }}
            >
              <img
                alt="cover_image"
                style={{ maxWidth: 40, objectFit: "cover" }}
                src={getPicture(item.image_path_cover, 80, 80)}
              />
            </Col>
          );
        },
      },
      {
        title: "Titulo",
        dataIndex: "title",
        align: "left",
      },
      {
        title: "Fecha de Publicación",
        dataIndex: "published_date",
        align: "left",
        render: (_: any, item: any) => (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Title level={5}>
              {moment(item.published_date)
                .utcOffset(0)
                .format("DD/MM/YYYY HH:mm:ss")}
            </Title>
          </Col>
        ),
      },
      {
        title: "Estado",
        dataIndex: "status",
        render: (status: IPressNewsletter["status"], record: IPressNewsletter) => {
          return (
            <Button
              type="text"
              onClick={() =>
                updateStatus(
                  record.id,
                  status === "active" ? "inactive" : "active"
                )
              }
            >
              <Tag color={status === "active" ? "success" : "error"}>
                {status === "active" ? "Activo" : "Inactivo"}
              </Tag>
            </Button>
          );
        },
      },
      {
        title: "Fecha de Creación",
        width: 250,
        dataIndex: "createdAt",
        align: "left",
        render: (_: any, item: any) => (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Title level={5}>
              {moment(item.createdAt)
                .utcOffset(0)
                .format("DD/MM/YYYY HH:mm:ss")}
            </Title>
          </Col>
        ),
      },
      {
        title: "Acciones",
        render: (record: IPressNewsletter) => {
          return (
            <Space>
              <Link to={`/press/newsletter/${record.id}`}>
                <Button type="link" icon={<EditOutlined />} />
              </Link>
              <Button
                type="text"
                icon={<DeleteOutlined />}
                danger
                onClick={() => handleDelete(record.id)}
              />
            </Space>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  React.useEffect(() => {
    tableRef.current?.refresh();
  }, [location.pathname]);

  return (
    <Table
      ref={tableRef}
      service={pressNewsletterService}
      columns={columns}
    />
  );
}
