import { CoursesById } from "../modules";
import { AdminLayout } from "../shared";

export function CoursesByIdPage() {
  return (
    <AdminLayout>
        <CoursesById />
    </AdminLayout>
  )
}
