import * as yup from 'yup'

export const validateUpdateUsers = yup.object().shape({
    firts_name:yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El NOMBRE debe tener mínimo de 2 caracteres.'),
    last_name:yup
    .number()
    .required('Campo requerido.'),
})