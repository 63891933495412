
import { IModelProjectsRequirements } from '../../modules';
import { IProjectsRequirementsState } from '../@types/projectsRequirements';

import { projectsRequirementsActions } from '../action-types'

type projectsRequirementsActionType =
| { type: typeof projectsRequirementsActions.PROJECTS_REQUIREMENTS_MODAL_STATE; }
| { type: typeof projectsRequirementsActions.GET_PROJECTS_REQUIREMENTS_FOR_UPDATE; payload: IModelProjectsRequirements }
| { type: typeof projectsRequirementsActions.IS_PROJECTS_REQUIREMENTS_FOR_UPDATE; payload: boolean }
| { type: typeof projectsRequirementsActions.SWITCH_PROJECTS_REQUIREMENTS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IProjectsRequirementsState = {
    projectsRequirementsModalIsOpen: false,
    isUpdateProjectsRequirements: false,
    projectsRequirementsForUpdate: null,
    refreshTableProjectsRequirements: false,
}

export default function projectsRequirementsReducer(state: IProjectsRequirementsState = INITIAL_STATE, action: projectsRequirementsActionType): IProjectsRequirementsState {
  switch (action.type) {
    case projectsRequirementsActions.PROJECTS_REQUIREMENTS_MODAL_STATE:
        return {
            ...state,
            projectsRequirementsModalIsOpen: !state.projectsRequirementsModalIsOpen,
        }
    case projectsRequirementsActions.GET_PROJECTS_REQUIREMENTS_FOR_UPDATE:
        return {
            ...state,
            projectsRequirementsForUpdate: action.payload,
        }
    case projectsRequirementsActions.IS_PROJECTS_REQUIREMENTS_FOR_UPDATE:
        return {
            ...state,
            isUpdateProjectsRequirements: action.payload,
        }
    case projectsRequirementsActions.SWITCH_PROJECTS_REQUIREMENTS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableProjectsRequirements: action.payload,
        }
    default:
        return state
  }
}
