import { Button, Flex, Typography } from "antd";
import { AdminLayout } from "../shared";
import { Link } from "react-router-dom";
import PressNewsletter from "../modules/press/ui/press-newsletter";

export default function PressNewsletterPage() {
  return (
    <AdminLayout>
        <div style={{ width: "100%" }}>
          <Typography.Title level={3}>Boletines sala de prensa</Typography.Title>
          <Flex justify="flex-end">
            <Link to="/press/newsletter/add">
              <Button type="primary">+ Boletín</Button>
            </Link>
          </Flex>

          <PressNewsletter />
        </div>
    </AdminLayout>
  );
}