import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { createkeyAccountManagers, getSinglekeyAccountManagers, refreshTablekeyAccountManagers, selectIsUpdatekeyAccountManagers , selectRefreshTablekeyAccountManagers, selectSinglekeyAccountManagers, setIskeyAccountManagersForUpdate , updatekeyAccountManagers } from "../../../../redux";
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { IModelStudy } from "../../@types";
import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import moment from "moment";
import { virtualLibrariesService } from "../../../../shared";

export function UsekeyAccountManagers() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isUpdatekeyAccountManagers = useSelector(
    selectIsUpdatekeyAccountManagers
  );

  const dataUpdateEvents = useSelector(
    selectSinglekeyAccountManagers
  );

  const isRefreshTablekeyAccountManagers = useSelector(
    selectRefreshTablekeyAccountManagers
  );

  const [isLoading, setIsLoading] = useState(false);

  const changeNavigateForCreatekeyAccountManagers = () => {
      dispatch(setIskeyAccountManagersForUpdate(false))
      navigate(`/key-account-manager/new`);
  }

  const formMethodsCreate = useForm<IModelStudy>({
      // resolver: yupResolver(validateCreateEvents),
  });

  const formMethodsUpdate = useForm<IModelStudy>({
        // resolver: yupResolver(validateUpdateEvents),
  });

  useEffect(() => {
    console.log(1111, isUpdatekeyAccountManagers, dataUpdateEvents)
      if (dataUpdateEvents) {
        formMethodsUpdate.reset({});
          // formMethodsUpdate.reset({ ...dataUpdateEvents, date_recived: moment(dataUpdateEvents?.date_recived).utcOffset(0), inscription_date: moment(dataUpdateEvents?.inscription_date).utcOffset(0)});
      }

      return () => {
        formMethodsCreate.reset();
          
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdatekeyAccountManagers, dataUpdateEvents]);

  const updateStatus = async(value: "active" | "inactive", id: any) =>{
    if (isLoading) return;
    setIsLoading(true);
    await virtualLibrariesService.patch(id, {status: value})
    .then((res: any) => {
      setIsLoading(false);
      if (res.id) {
        message.success(
          `Se ha actualizado con éxito el estado del Project con id:${res.id}`
        );
        dispatch(refreshTablekeyAccountManagers(true));
      } else {
        message.error(res as unknown as string);
      }
    })
    .catch((err: any) => {
      setIsLoading(false);
      console.log(err);
      message.error(err.message);
    });
  }  


  const onSubmitCreateOrUpdate = async (data: IModelStudy) => {
        const filteredData:any = Object.fromEntries(
          Object.entries(data).filter(
              ([key, value]) =>
                  value !== undefined && value !== '' && value !== null 
          ),
      )
      if (Object.keys(filteredData).length === 0) {
          return
      }
      const dataEve = {id: dataUpdateEvents?.id, ...filteredData}
      // console.log(dataUpdateEvents);
      if (isLoading) return;
      setIsLoading(true);
      if (isUpdatekeyAccountManagers) {
        await updatekeyAccountManagers(dataEve)
          .then((res: Partial<IModelStudy>) => {
            if (res.id) {
              message.success(
                `Se ha actualizado con éxito el registro con id:${res.id}`
              );
              dispatch(getSinglekeyAccountManagers(res as any))
              dispatch(refreshTablekeyAccountManagers(true));
            } else {
              message.error(res as unknown as string);
            }
          })
          .catch((err: any) => {
            console.log(err);
            message.error(err.message);
          })
          .finally(()=>{
              setIsLoading(false);
          });
      } else {
        await createkeyAccountManagers({...filteredData})
          .then((res: IModelStudy) => {
            if (res.id) {
              //navegar a la tabla
              // dispatch(changeModalStateEvents());
              message.success(`Se ha creado con éxito el registro con id:${res.id}`);
              dispatch(refreshTablekeyAccountManagers(true));
              navigate(`/key-account-managers`)
              window.scrollTo(0, 0)
            } else {
              message.error(res as any);
            }
          })
          .catch((err: any) => {
            console.log(err);
            message.error(err.message);
          })
          .finally(()=>{
              setIsLoading(false);
          });
      }
  };

  useEffect(() => {
      if (isRefreshTablekeyAccountManagers) {
        dispatch(refreshTablekeyAccountManagers(false));
      }
    }, [isRefreshTablekeyAccountManagers]);

return {
  //state
  isLoading,
  isUpdatekeyAccountManagers,
  dataUpdateEvents,
  updateStatus,
  isRefreshTablekeyAccountManagers,
  //methods
  formMethodsCreate,
  formMethodsUpdate,
  //functions
  changeNavigateForCreatekeyAccountManagers,
  onSubmitCreateOrUpdate,
}
}
