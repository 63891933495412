import { message } from "antd";

import { bannersService } from "../../shared";
import { bannersActions } from "../action-types";
import { IModelBanners } from "../../modules";

export const changeModalStateBanners = (): bannersActions.IBannersChangeModalState =>({
    type: bannersActions.BANNERS_CHANGE_MODAL_STATE
})

export const getSingleBanners = (val:IModelBanners ): bannersActions.IGetBannersForUpdate=> ({
    type: bannersActions.GET_BANNER_FOR_UPDATE,
    payload: val
})

export const setIsBannersForUpdate =(val: boolean): bannersActions.IIsBannersForUpdate =>({
    type: bannersActions.IS_BANNER_FOR_UPDATE,
    payload: val,
})

export const fetchSingleBanners = async(id: number) =>{
    return await bannersService.find({ query: {id} })
    .then((res) => {
        console.log(res);
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createBanners = async(data:Partial<IModelBanners>) =>{
    return await bannersService.create(data)
    .then((res: any)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateBanners = async(data:Partial<IModelBanners>) =>{
    return await bannersService.patch(data?.id!, data)
    .then((res: any)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteBanner = async(id: number) =>{
    return await bannersService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableBanners = (val: boolean): bannersActions.ISwitchBannersRefreshTable =>({
    type: bannersActions.SWITCH_BANNERS_REFRESH_TABLE,
    payload: val
})

