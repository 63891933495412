import { IModelCoursesBenefits } from "../../modules"


export const COURSES_BENEFITS_MODAL_STATE = '[ COURSES BENEFITS ] Courses Benefits Modal State'

export interface ICoursesBenefitsModalState {
    type: typeof COURSES_BENEFITS_MODAL_STATE
}

export const GET_COURSES_BENEFITS_FOR_UPDATE = '[ COURSES BENEFITS ] Get Courses Benefits For Update'

export interface IGetCoursesBenefitsForUpdate {
    type: typeof GET_COURSES_BENEFITS_FOR_UPDATE
    payload: IModelCoursesBenefits
}

export const IS_COURSES_BENEFITS_FOR_UPDATE = '[ COURSES BENEFITS ] Is Courses Benefits For Update'

export interface IIsCoursesBenefitsForUpdate {
    type: typeof IS_COURSES_BENEFITS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_COURSES_BENEFITS_REFRESH_TABLE = '[ COURSES BENEFITS ] Switch Courses Benefits Refresh Table'

export interface ISwitchCoursesBenefitsRefreshTable {
    type: typeof SWITCH_COURSES_BENEFITS_REFRESH_TABLE
    payload: boolean
}