import { Link, Outlet, useLocation } from "react-router-dom";
import StudyCertificatesLayout from "../modules/studyCertificates/components/study-certificates-layout";
import { AdminLayout, studyCertificateTemplatesService } from "../shared";
import Table from "../shared/components/ui/table/TableCustom";
import moment from "moment";
import { Button, Space } from "antd";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import React from "react";
import { TableProperties } from "../shared/components/ui/table/types";

moment.locale("es");

export default function StudyCertificateTemplatesPage() {
  const tableRef = React.useRef<TableProperties>(null);

  const location = useLocation();

  React.useEffect(() => {
    tableRef.current?.refresh(); // :-)
  }, [location.pathname]);

  return (
    <AdminLayout>
      <StudyCertificatesLayout>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 20,
          }}
        >
          <Button type="primary">
            <Link to="/certificates/templates/new">Nueva plantilla</Link>
          </Button>
        </div>
        <Table
          ref={tableRef}
          service={studyCertificateTemplatesService}
          columns={[
            { title: "ID", dataIndex: "id" },
            { title: "Nombre", dataIndex: "name" },
            {
              title: "Fecha de creación",
              dataIndex: "createdAt",
              render: (createdAt: string) => moment(createdAt).format("LLL"),
            },
            {
              title: "Acciones",
              render: (record: any) => (
                <Space size="small">
                  <Link type="link" to={`/certificates/templates/${record.id}`}>
                    <EditOutlined />
                  </Link>
                  <Link
                    type="link"
                    to={`/certificates/templates/${record.id}/fields`}
                  >
                    <EyeOutlined />
                  </Link>
                </Space>
              ),
            },
          ]}
        />
      </StudyCertificatesLayout>
      <Outlet />
    </AdminLayout>
  );
}
