import { Spin } from "antd";
import { WrapperTabNav } from "../styled";
import { useWebinars, useWebinarsById } from "../../hooks";
import { FormProvider } from 'react-hook-form';
import { WebinarsForm } from "./WebinarsForm";
import { WebinarAuthorsFrom } from "../webinars-authors";
import { WebinarsBenefits } from "../webinars-benefits";
import { WebinarsIncludes } from "../webinars-includes";
import { WebinarsRequirements } from "../webinars-requirements";
import { useParams } from "react-router-dom";
import Clusters from "./Clusters";

export function WebinarsById() {
  const { isUpdateWebinars, formMethodsCreate, formMethodsUpdate } =
    useWebinars();
  const { isValidate } = useWebinarsById();

  const {id: webinarId} = useParams();

  return (
    <div style={{ width: "100%" }}>
      {!isValidate && <Spin size="large" />}
      {isValidate &&(
        <>
          {!isUpdateWebinars && (
            <FormProvider {...formMethodsCreate}>
              <WebinarsForm />
            </FormProvider>
          )}
          {isUpdateWebinars && (
            <WrapperTabNav
              items={[
                {
                  label: `DATOS DEL WEBINAR`,
                  key: "1",
                  children: (
                    <FormProvider {...formMethodsUpdate}>
                      <WebinarsForm />
                    </FormProvider>
                  ),
                },
                {
                  label: 'CLUSTERS DEL WEBINAR',
                  key: "2",
                  children: <Clusters webinarId={webinarId ? +webinarId : undefined} />
                },
                {
                  label: `AUTORES DEL WEBINAR`,
                  key: "3",
                  children: (
                    <WebinarAuthorsFrom />
                  ),
                },
                {
                  label: `BENEFICIOS DEL WEBINAR`,
                  key: "4",
                  children: (
                    <WebinarsBenefits />
                  ),
                },
                {
                  label: `INCLUSIONES DEL WEBINAR`,
                  key: "5",
                  children: (
                    <WebinarsIncludes />
                  ),
                },
                {
                  label: `REQUERIMIENTOS DEL WEBINAR`,
                  key: "6",
                  children: (
                    <WebinarsRequirements />
                  ),
                }
              ]}
            />
          )}
      </>
    )}
  </div>
  )
}
