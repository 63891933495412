import { AdminLayout } from "../shared";
import { Button, Flex } from "antd";
import { Link, Outlet } from "react-router-dom";
import CmsLawTransparencyLayout from "../modules/cms-law-transparency-page/components/cms-law-transparency-layout";
import CmsLawTransparencyCategories from "../modules/cms-law-transparency-page/ui/cms-law-transparency-categories";

export default function CmsLawTransparencyCategoriesPage() {
  return (
    <AdminLayout>
      <CmsLawTransparencyLayout>
        <div style={{width: '100%'}}>
          <Flex justify="flex-end">
              <Link to="/ley-de-transparencia-categorias/agregar">
                <Button type="primary">+ Categoría</Button>
              </Link>
            </Flex>

            <CmsLawTransparencyCategories />
        </div>
      </CmsLawTransparencyLayout>
      <Outlet />
    </AdminLayout>
  );
}
