import { Navigate, Route, Routes } from "react-router-dom";
import {
  AuthorsPage,
  CoursesByIdPage,
  CoursesCategoriesPage,
  CoursesPage,
  DashboardPage,
  EventsByIdPage,
  EventsCategoriesPage,
  EventsPage,
  EventsTagsPage,
  LoginPage,
  NewsByIdPage,
  NewsCategoriesPage,
  NewsPage,
  WebinarsByIdPage,
  WebinarsCategoriesPage,
  WebinarsPage,
  ProjectsPage,
  ProjectsByIdPage,
  UsersPage,
  UserByIdPage,
  CreateBannerPage,
  CiuuManagersPage,
  CiuuByIdPage,
  CiuuNewPage,
  VirtualLibraryCategoryNewPage,
  VirtualLibraryCategoriesManagerPage,
  InfographiesManagersPage,
  InfographiesNewPage,
  InfographiesIdPage,
  StudyCerticatesPage,
  StudyCerticatesIdPage,
  StudyCerticatesNewPage,
  VirtualLibraryCategoryByIdPage,
} from "../pages";
import { useAppRouter } from "./hooks";
import { InscriptionsPage } from "../pages/inscriptions";
import { ResourcesAdminPage } from "../pages/ResourcesPage";
import { ResourceByIdPage } from "../pages/ResourceByIdPage";
import { RegistryServicesConsultNewPage } from "../pages/RegistryServicesConsultNewPage";
import { RegistryServicesConsultNewByIdPage } from "../pages/RegistryServicesConsultNewByIdPage";
import { ShedulingPage } from "../pages/sheduling";
import { BannersPage } from "../pages/BannersPage";
import { CompaniesPage } from "../pages/companiesPage";
import { KeyAccountManagersPage } from "../pages/keyAccountManagersPage";
import { KeyAccountManagersByIdPage } from "../pages/keyAccountManagersByIdPage";
import { CreateUserPage } from "../pages/CreateUserPage";
import CoursesRatingsPage from "../pages/CoursesRatingsPage";
import WebinarsRatingsPage from "../pages/WebinarsRatingsPage";
import EventsRatingsPage from "../pages/EventsRatingsPage";
import { BannnerByIdPage } from "../pages/BannerByIdPage";
import { RegistrationEventPage } from "../pages/RegistrationEventsPage";
import { UserEventsPage } from "../pages/UserEventsPage";
import { UserCoursesPage } from "../pages/UserCoursesPage";
import { UserWebinarPage } from "../pages/UserWebinarPage";
import CreateCorresponsalesFormsAndModelsPage from "../pages/CreateCorresponsalesFormsAndModelsPage";
import EditCorresponsalesFormsAndModelsPage from "../pages/EditCorresponsalesFormsAndModelsPage";
import { CompaniesByidPage } from "../pages/companiesByidPage";
import CorresponsalesVideosAndTutorialsPage from "../pages/CorresponsalesVideosAndTutorialsPage";
import CreateCorresponsalesVideosAndTutorialsPage from "../pages/CreateCorresponsalesVideosAndTutorialsPage";
import EditCorresponsalesVideosAndTutorialsPage from "../pages/EditCorresponsalesVideosAndTutorialsPage";
import CorresponsalesMarketingMaterial from "../pages/CorresponsalesMarketingMaterialPage";
import CreateCorresponsalesMarketingMaterialPage from "../pages/CreateCorresponsalesMarketingMaterialPage";
import EditCorresponsalesMarketingMaterialPage from "../pages/EditCorresponsalesMarketingMaterialPage";
import CorresponsalesFormsAndModelsPage from "../pages/CorresponsalesFormsAndModelsPage";
import CorresponsalesFormsAndModelsCategoriesPage from "../pages/CorresponsalesFormsAndModelsCategoriesPage";
import CreateCorresponsalesFormsAndModelsCategoriesPage from "../pages/CreateCorresponsalesFormsAndModelsCategpriesPage ";
import EditCorresponsalesFormsAndModelsCategoriesPage from "../pages/EditCorresponsalesFormsAndModelsCategoriesPage";
import CorresponsalesVideosAndTutorialsCategoriesPage from "../pages/CorresponsalesVideosAndTutorialsCategoriesPage";
import CreateCorresponsalesVideosAndTutorialsCategoriesPage from "../pages/CreateCorresponsalesVideosAndTutorialsCategoriesPage";
import EditCorresponsalesVideosAndTutorialsCategoriesPage from "../pages/EditCorresponsalesVideosAndTutorialsCategoriesPage";
import CorresponsalesMarketingMaterialCategoriesPage from "../pages/CorresponsalesMarketingMaterialCategoriesPage";
import CreateCorresponsalesMarketingMaterialCategoriesPage from "../pages/CreateCorresponsalesMarketingMaterialCategoriesPage";
import EditCorresponsalesMarketingMaterialCategoriesPage from "../pages/EditCorresponsalesMarketingMaterialCategoriesPage";
import KeywordSearchPage from "../pages/KeywordSearchPage";
import CreateKeywordSearchPage from "../pages/CreateKeywordSearchPage";
import EditKeywordSearchPage from "../pages/EditKeywordSearchPage";
import PopupsPage from "../pages/PopupsPage";
import CreatePopupPage from "../pages/CreatePopupPage";
import EditPopupPage from "../pages/EditPopupPage";
import FeedbackGeneralSurveyPage from "../pages/FeedbackGeneralSurveyPage";
import FormsPage from "../pages/FormsPage";
import FormsBrandRegistrationPage from "../pages/FormsBrandRegistrationPage";
import FormDetailPage from "../pages/FormsDetailPage";
import { KeyAccountManagersNewPage } from "../pages/keyAccountManagersNewPage";
import CmsLawTransparencyPage from "../pages/CmsLawTransperencyPage";
import EditLawTransparencyPage from "../pages/EditCmsLawTransparencyPage";
import CreateCmsLawTransparencyPage from "../pages/CreateCmsLawTransparencyPage";
import CmsLawTransparencyCategoriesPage from "../pages/CmsLawTransperencyCategoriesPage";
import CreateCmsLawTransparencyCategoryPage from "../pages/CreateCmsLawTransparencyCategoryPage";
import EditLawTransparencyCategoryPage from "../pages/EditCmsLawTransparencyCategoryPage";
import { CompaniesUserPage } from "../pages/companiesUserPage";
import { CompanyUserByidPage } from "../pages/companyUserByidPage";
import FormsExperienceRegistrationalServicesPage from "../pages/FormExperienceRegistrationalServicesPage";
import { InfographicsCategoriesPage } from "../pages/InfographicsCategoriesPage";
import CreateInfographicCategoryPage from "../pages/CreateInfographicCategoryPage";
import EditInfoGraphicCategoryPage from "../pages/EditInfographicCategoryPage";
import { ComapniesUserNewPage } from "../pages/companiesUserNewPage";
import InfographicsPage from "../pages/InfographicsPage";
import EditInfographicPage from "../pages/EditInfographicPage";
import CreateInfographicPage from "../pages/CreateInfographicPage";
import PressResourcesPage from "../pages/PressResourcesPage";
import CreatePressResourcePage from "../pages/CreatePressResourcePage";
import EditPressResourcePage from "../pages/EditPressResourcePage";
import PressResourceCategoriesPage from "../pages/PressResourceCategoriesPage";
import CreatePressResourceCategoryPage from "../pages/CreatePressResourceCategoryPage";
import EditPressResourceCategoryPage from "../pages/EditPressResourceCategoryPage";
import PressNewsletterPage from "../pages/PressNewsletterPage";
import CreatePressNewsletterPage from "../pages/CreatePressNewsletterPage";
import EditPressNewsletterPage from "../pages/EditPressNewsletterPage";
import StudyCertificateTemplatesPage from "../pages/studyCertificateTemplatesPage";
import EditStudyCertificateTemplatePage from "../pages/EditStudyCertificateTemplatePage";
import CreateStudyCertificateTemplatePage from "../pages/CreateStudyCertificateTemplatePage";
import StudyCertificateTemplateFieldsPage from "../pages/StudyCertificateTemplateFieldsPage";
import FormServicesOnlineInformationPage from "../pages/FormServicesOnlineInformation";
// import CreateBanner from "../modules/banners/ui/CreateBanners";

export function AppRouter() {
  const { auth } = useAppRouter();
  return (
    <Routes>
      {/* <Route path="/" element={auth === true ? <Navigate to="/news-categories" replace/> : <Navigate to="/login" replace/>} /> */}
      {auth && (
        <>
          <Route path="/" element={<DashboardPage />} />
          {/* news */}
          <Route path="/news-categories" element={<NewsCategoriesPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/news/:id" element={<NewsByIdPage />} />
          {/* projects */}
          {/* <Route path="/projects-categories" element={<ProjectsCategoriesPage />}/> */}
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/project/:id" element={<ProjectsByIdPage />} />
          {/* authors */}
          <Route path="/authors" element={<AuthorsPage />} />
          {/* webinars */}
          <Route path="/webinar" element={<WebinarsPage />} />
          <Route path="/webinar/:id" element={<WebinarsByIdPage />} />
          <Route
            path="/webinar-categories"
            element={<WebinarsCategoriesPage />}
          />
          {/* courses */}
          <Route path="/course" element={<CoursesPage />} />
          <Route path="/course/:id" element={<CoursesByIdPage />} />
          <Route
            path="/course-categories"
            element={<CoursesCategoriesPage />}
          />
          {/* events */}
          <Route path="/event" element={<EventsPage />} />
          <Route path="/event/:id" element={<EventsByIdPage />} />
          <Route path="/event-categories" element={<EventsCategoriesPage />} />
          <Route path="/events-tags" element={<EventsTagsPage />} />
          {/* Inscripciones */}
          <Route path="/inscripciones" element={<InscriptionsPage />} />
          {/* Agendamiento de citas */}
          <Route path="/appointment-scheduling" element={<ShedulingPage />} />
          {/* Recursos */}
          <Route
            path="/recursos-administrativos"
            element={<ResourcesAdminPage />}
          />
          <Route
            path="/recursos-administrativos/:id"
            element={<ResourceByIdPage />}
          />
          {/* servicio de registro de consultas */}
          <Route
            path="/registry-services-consult-news"
            element={<RegistryServicesConsultNewPage />}
          />
          <Route
            path="/registry-services-consult-news/:id"
            element={<RegistryServicesConsultNewByIdPage />}
          />
          <Route path="/banners" element={<BannersPage />} />
          <Route path="/banner/:id" element={<BannnerByIdPage />} />
          <Route path="/banner/new" element={<CreateBannerPage />} />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/user/new" element={<CreateUserPage />} />
          <Route path="/user/:id" element={<UserByIdPage />} />
          <Route path="/companies" element={<CompaniesPage />}>
            <Route path=":recordId" element={<CompaniesByidPage />} />
          </Route>
          <Route
            path="/company/:companyId/users"
            element={<CompaniesUserPage />}
          >
            <Route path=":id" element={<CompanyUserByidPage />} />
            <Route path="new" element={<ComapniesUserNewPage />} />
            {/* <Route path="new" element={<KeyAccountManagersNewPage />} /> */}
          </Route>

          <Route
            path="/key-account-managers"
            element={<KeyAccountManagersPage />}
          >
            <Route path=":recordId" element={<KeyAccountManagersByIdPage />} />
            <Route path="new" element={<KeyAccountManagersNewPage />} />
          </Route>
          <Route path="/ciuu" element={<CiuuManagersPage />}>
            <Route path=":recordId" element={<CiuuByIdPage />} />
            <Route path="new" element={<CiuuNewPage />} />
          </Route>

          <Route
            path="/virtual-library-categories"
            element={<VirtualLibraryCategoriesManagerPage />}
          >
            <Route
              path=":recordId"
              element={<VirtualLibraryCategoryByIdPage />}
            />
            <Route path="new" element={<VirtualLibraryCategoryNewPage />} />
          </Route>

          <Route path="/virtual-library" element={<InfographiesManagersPage />}>
            <Route path=":recordId" element={<InfographiesIdPage />} />
            <Route path="new" element={<InfographiesNewPage />} />
          </Route>

          <Route
            path="/infographic-categories"
            element={<InfographicsCategoriesPage />}
          >
            <Route path=":recordId" element={<EditInfoGraphicCategoryPage />} />
            <Route path="new" element={<CreateInfographicCategoryPage />} />
          </Route>
          <Route path="/infographics" element={<InfographicsPage />}>
            <Route path=":recordId" element={<EditInfographicPage />} />
            <Route path="new" element={<CreateInfographicPage />} />
          </Route>

          <Route path="certificates" element={<StudyCerticatesPage />}>
            <Route path=":recordId" element={<StudyCerticatesIdPage />} />
            <Route path="new" element={<StudyCerticatesNewPage />} />
          </Route>
          <Route
            path="certificates/templates"
            element={<StudyCertificateTemplatesPage />}
          >
            <Route
              path=":recordId"
              element={<EditStudyCertificateTemplatePage />}
            />
            <Route
              path="new"
              element={<CreateStudyCertificateTemplatePage />}
            />
          </Route>
          <Route
            path="/certificates/templates/:recordId/fields"
            element={<StudyCertificateTemplateFieldsPage />}
          />
          <Route
            path="/calificaciones/cursos"
            element={<CoursesRatingsPage />}
          />
          <Route
            path="/calificaciones/webinars"
            element={<WebinarsRatingsPage />}
          />
          <Route
            path="/calificaciones/eventos"
            element={<EventsRatingsPage />}
          />
          <Route
            path="/registration-events"
            element={<RegistrationEventPage />}
          />
          <Route path="/userevent/:id" element={<UserEventsPage />} />
          <Route path="/user-course/:id" element={<UserCoursesPage />} />

          <Route path="/user-webinar/:id" element={<UserWebinarPage />} />

          <Route path="corresponsales">
            {/* Forms and Models */}
            <Route
              path="formatos-y-modelos"
              element={<CorresponsalesFormsAndModelsPage />}
            >
              <Route
                path=":recordId"
                element={<EditCorresponsalesFormsAndModelsPage />}
              />
              <Route
                path="agregar"
                element={<CreateCorresponsalesFormsAndModelsPage />}
              />
            </Route>
            <Route
              path="formatos-y-modelos-categorias"
              element={<CorresponsalesFormsAndModelsCategoriesPage />}
            >
              <Route
                path="agregar"
                element={<CreateCorresponsalesFormsAndModelsCategoriesPage />}
              />
              <Route
                path=":recordId"
                element={<EditCorresponsalesFormsAndModelsCategoriesPage />}
              />
            </Route>

            {/* Videos and Tutorials */}
            <Route
              path="videos-y-tutoriales"
              element={<CorresponsalesVideosAndTutorialsPage />}
            >
              <Route
                path="agregar"
                element={<CreateCorresponsalesVideosAndTutorialsPage />}
              />
              <Route
                path=":recordId"
                element={<EditCorresponsalesVideosAndTutorialsPage />}
              />
            </Route>
            <Route
              path="videos-y-tutoriales-categorias"
              element={<CorresponsalesVideosAndTutorialsCategoriesPage />}
            >
              <Route
                path="agregar"
                element={
                  <CreateCorresponsalesVideosAndTutorialsCategoriesPage />
                }
              />
              <Route
                path=":recordId"
                element={<EditCorresponsalesVideosAndTutorialsCategoriesPage />}
              />
            </Route>

            {/* Marketing material */}
            <Route
              path="material-de-marketing"
              element={<CorresponsalesMarketingMaterial />}
            >
              <Route
                path="agregar"
                element={<CreateCorresponsalesMarketingMaterialPage />}
              />
              <Route
                path=":recordId"
                element={<EditCorresponsalesMarketingMaterialPage />}
              />
            </Route>
            <Route
              path="material-de-marketing-categorias"
              element={<CorresponsalesMarketingMaterialCategoriesPage />}
            >
              <Route
                path="agregar"
                element={
                  <CreateCorresponsalesMarketingMaterialCategoriesPage />
                }
              />
              <Route
                path=":recordId"
                element={<EditCorresponsalesMarketingMaterialCategoriesPage />}
              />
            </Route>
          </Route>

          <Route
            path="ley-de-transparencia"
            element={<CmsLawTransparencyPage />}
          >
            <Route path="agregar" element={<CreateCmsLawTransparencyPage />} />
            <Route path=":recordId" element={<EditLawTransparencyPage />} />
          </Route>

          <Route
            path="ley-de-transparencia-categorias"
            element={<CmsLawTransparencyCategoriesPage />}
          >
            <Route
              path="agregar"
              element={<CreateCmsLawTransparencyCategoryPage />}
            />
            <Route
              path=":recordId"
              element={<EditLawTransparencyCategoryPage />}
            />
          </Route>

          <Route path="buscador">
            <Route path="palabras-claves" element={<KeywordSearchPage />}>
              <Route path="agregar" element={<CreateKeywordSearchPage />} />
              <Route path=":recordId" element={<EditKeywordSearchPage />} />
            </Route>
          </Route>

          <Route path="popups" element={<PopupsPage />}>
            <Route path="agregar" element={<CreatePopupPage />} />
            <Route path=":recordId" element={<EditPopupPage />} />
          </Route>

          <Route path="/comentarios" element={<FeedbackGeneralSurveyPage />} />

          <Route path="formularios" element={<FormsPage />}>
            <Route path=":recordId" element={<FormDetailPage />} />
          </Route>

          <Route
            path="/form-brand-registration"
            element={<FormsBrandRegistrationPage />}
          />
          <Route
            path="/form-services-online-information"
            element={<FormServicesOnlineInformationPage />}
          />
          <Route
            path="/form-experience-registrational-services"
            element={<FormsExperienceRegistrationalServicesPage />}
          />

          <Route path="press">
            <Route path="resources" element={<PressResourcesPage />}>
              <Route path="add" element={<CreatePressResourcePage />} />
              <Route path=":recordId" element={<EditPressResourcePage />} />
            </Route>
            <Route
              path="resources/categories"
              element={<PressResourceCategoriesPage />}
            >
              <Route path="add" element={<CreatePressResourceCategoryPage />} />
              <Route
                path=":recordId"
                element={<EditPressResourceCategoryPage />}
              />
            </Route>

            <Route path="newsletter" element={<PressNewsletterPage />} />
            <Route
              path="newsletter/add"
              element={<CreatePressNewsletterPage />}
            />
            <Route
              path="newsletter/:recordId"
              element={<EditPressNewsletterPage />}
            />
          </Route>

          <Route path="/*" element={<Navigate to="/" replace />} />
        </>
      )}
      {!auth && (
        <>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/*" element={<Navigate to="/login" replace />} />
        </>
      )}
    </Routes>
  );
}
