import { IModelUsers } from "../../modules"

export const USERS_MODAL_STATE = '[ USERS ] USERS Modal State'

export interface IUsersModalState {
    type: typeof USERS_MODAL_STATE
}

export const GET_USERS_FOR_UPDATE = '[ USERS ] Get USERS For Update'

export interface IGetUsersForUpdate {
    type: typeof GET_USERS_FOR_UPDATE
    payload: IModelUsers
}

export const IS_USERS_FOR_UPDATE = '[ USERS ] Is USERS For Update'

export interface IIsUsersForUpdate {
    type: typeof IS_USERS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_USERS_REFRESH_TABLE = '[ USERS ] Switch USERS Refresh Table'

export interface ISwitchUsersRefreshTable {
    type: typeof SWITCH_USERS_REFRESH_TABLE
    payload: boolean
}