import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";


import { WrapperCard } from "./styled";
import { TableBanners } from "./TableBanners";
import { useBanners } from "../hooks";
import { useSelector } from "react-redux";
import { selectModalBanners } from "../../../redux";
import { FormProvider } from "react-hook-form";
import { BannersForm } from "./BannerForm";
// import { ModalBanners } from "./ModalBanners";

export const Banners = () => {
  const modalBanners = useSelector(selectModalBanners);
  const {
    isUpdateBanner,
    formMethodsCreate,
    formMethodsUpdate,
    changeModalBannersState,
    changeBannersForCreate,
  } = useBanners();

  return (
    <>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeBannersForCreate}
            >
              Agregar nuevo banner
            </Button>
          </Col>
        </Row>
        <TableBanners />

        {modalBanners && isUpdateBanner && (
          <FormProvider {...formMethodsUpdate}>
            <BannersForm />
          </FormProvider>
        )}
        {modalBanners && !isUpdateBanner && (
          <FormProvider {...formMethodsCreate}>
            <BannersForm />
          </FormProvider>
        )}
      </>
  );
};
