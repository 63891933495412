import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { Col, Row, Typography } from "antd";
import { IModelCiuu } from "../../@types";
import { InputSelect, InputSelectDynamic, InputText, clustersService } from "../../../../shared";
import { useLocation } from "react-router-dom";

const { Title } = Typography;

export function CiuuForm() {
  const [selectProps, setSelectProps] = useState<any>();

  const { control } = useFormContext<IModelCiuu>();

  const location = useLocation();

  useEffect(() => {
    selectProps?.refresh?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return (
    <>
        <Row justify="start">
            <Col style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            }} span={24}>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              CIUU:
            </Title>
            <InputText
              placeHolder=""
              name="ciuu"
              control={control}
            />
            </Col>
            <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Cluster:
          </Title>
          <InputSelectDynamic
            name="cluster_id"
            control={control}
            serviceName={clustersService}
            labelName="name"
            valName="id"
          />
        </Col>
            <Col
              span={24}
              style={{
                  display: "flex",
                  marginTop: "10px",
                  padding: 5,
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
              }}>
                <Title style={{ marginBottom: "1.5px" }} level={5}>
                Rnt Required:
              </Title>
              <InputSelect
                name="rnt_required"
                control={control}
                dataOptions={[
                  { title: "Si", value: "true" },
                  { title: "No", value: "false" },
                ]}
                label="Rnt Required"
              />
            </Col>
        </Row>
    </>
  )
}
