import { useSelector } from "react-redux";
import { changeModalStateWebinarsIncludes, createWebinarIncludes, refreshTableWebinarsIncludes, selectIsUpdateWebinarsIncludes, selectRefreshTableWebinarsIncludes, selectSingleWebinars, selectSingleWebinarsIncludes, setIsWebinarsIncludesForUpdate, updateWebinarIncludes } from "../../../../redux";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IModelWebinarsIncludes } from "../../@types";
import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import { validateCreateWebinarsIncludes } from "../../../../shared/utils/yupValidation/validateCreateWebinarsIncludes";
import { validateUpdateWebinarsIncludes } from "../../../../shared/utils/yupValidation/validateUpdateWebinarsIncludes";

export function useWebinarsIncludes() {
  const dispatch = useAppDispatch();

  const isUpdateWebinarsIncludes = useSelector(
      selectIsUpdateWebinarsIncludes
  );

  const dataUpdateWebinarsIncludes = useSelector(
      selectSingleWebinarsIncludes
  );

  const isRefreshTableWebinarsIncludes = useSelector(
      selectRefreshTableWebinarsIncludes
  );

  const dataUpdateWebinars = useSelector( selectSingleWebinars );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalWebinarsIncludesStateForCreate = () => {
    dispatch(setIsWebinarsIncludesForUpdate(false))
    dispatch(changeModalStateWebinarsIncludes())
  }

  const formMethodsCreate = useForm<IModelWebinarsIncludes>({
    resolver: yupResolver(validateCreateWebinarsIncludes),
  });

  const formMethodsUpdate = useForm<IModelWebinarsIncludes>({
    resolver: yupResolver(validateUpdateWebinarsIncludes),
  });

  useEffect(() => {
  if (isUpdateWebinarsIncludes) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateWebinarsIncludes });
  }

  return () => {
      formMethodsUpdate.reset();
  };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateWebinarsIncludes, dataUpdateWebinarsIncludes]);

  const onSubmitCreateOrUpdate = async (data: IModelWebinarsIncludes) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateWebinarsIncludes) {
      await updateWebinarIncludes({
        id: data.id,
        include_text: data.include_text,
        position: data.position,
        webinar_id: dataUpdateWebinars?.id,
      })
        .then((res: Partial<IModelWebinarsIncludes>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito las inclusiones con id:${res.id}`
            );
            dispatch(refreshTableWebinarsIncludes(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createWebinarIncludes({...data, webinar_id: dataUpdateWebinars?.id,})
        .then((res: IModelWebinarsIncludes) => {
          if (res.id) {
            dispatch(changeModalStateWebinarsIncludes());
            message.success(
              `Se ha creado con éxito las inclusiones con id:${res.id}`
            );
            dispatch(refreshTableWebinarsIncludes(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableWebinarsIncludes) {
      dispatch(refreshTableWebinarsIncludes(false));
    }
  }, [isRefreshTableWebinarsIncludes]);

  return{
      //state
      isLoading,
      isUpdateWebinarsIncludes,
      dataUpdateWebinarsIncludes,
      isRefreshTableWebinarsIncludes,
      //methods
      formMethodsCreate,
      formMethodsUpdate,
      //functions
      changeModalWebinarsIncludesStateForCreate,
      onSubmitCreateOrUpdate,
  }
}
