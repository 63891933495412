import { message } from "antd";

import { coursesIncludesService } from "../../shared";
import { coursesIncludesActions } from "../action-types";
import { IModelCoursesIncludes } from "../../modules";

export const changeModalStateCoursesIncludes = (): coursesIncludesActions.ICoursesIncludesModalState =>({
    type: coursesIncludesActions.COURSES_INCLUDES_MODAL_STATE
})

export const getSingleCoursesIncludes = (val:IModelCoursesIncludes ): coursesIncludesActions.IGetCoursesIncludesForUpdate=> ({
    type: coursesIncludesActions.GET_COURSES_INCLUDES_FOR_UPDATE,
    payload: val
})

export const setIsCoursesIncludesForUpdate =(val: boolean): coursesIncludesActions.IIsCoursesIncludesForUpdate =>({
    type: coursesIncludesActions.IS_COURSES_INCLUDES_FOR_UPDATE,
    payload: val,
})

export const fetchSingleCoursesIncludes = async(id: number) =>{
    return await coursesIncludesService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createCourseIncludes = async(data:Partial<IModelCoursesIncludes>) =>{
    return await coursesIncludesService.create(data)
    .then((res: IModelCoursesIncludes)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateCourseIncludes = async(data:Partial<IModelCoursesIncludes>) =>{
    return await coursesIncludesService.patch(data?.id!, data)
    .then((res: IModelCoursesIncludes)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteCourseIncludes = async(id: number) =>{
    return await coursesIncludesService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableCoursesIncludes = (val: boolean): coursesIncludesActions.ISwitchCoursesIncludesRefreshTable =>({
    type: coursesIncludesActions.SWITCH_COURSES_INCLUDES_REFRESH_TABLE,
    payload: val
})

