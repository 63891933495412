import { useFormContext } from 'react-hook-form';
import { Button, Col, Typography } from "antd";
import { useSelector } from "react-redux";
import { InputNumber, InputText } from '../../../../shared';
import { WrapperModalEvents } from '../styled';
import { IModelEventsCategories } from '../../@types';
import { selectModalEventsCategories } from '../../../../redux';
import { useEventsCategories } from '../../hooks';

const { Title } = Typography;

export function ModalEventsCategories() {
  const modalEventsCategories = useSelector(
    selectModalEventsCategories
  );

  const {
    //state
    isLoading,
    isUpdateEventsCategories,
    dataUpdateEventsCategories,
    //methods
    //functions
    changeModalEventsCategoriesStateForCreate,
    onSubmitCreateOrUpdate
  } = useEventsCategories()

const { control, handleSubmit: onSubmit } =
useFormContext<IModelEventsCategories>();
return (
  <WrapperModalEvents
    title={ isUpdateEventsCategories ? `Actualizando categoría de evento: '${dataUpdateEventsCategories?.id!}'` : `Creando categoría de evento`}
    open={modalEventsCategories}
    width={"320px"}
    destroyOnClose
    onClose={changeModalEventsCategoriesStateForCreate}>
      <Col
        span={24}
        style={{
        display: "flex",
        marginTop: "10px",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        }}>
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Nombre:
          </Title>
          <InputText placeHolder="Categoría de evento" name="name" control={control} />
      </Col>
      <Col
        span={24}
        style={{
        display: "flex",
        marginTop: "10px",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Posición:
        </Title>
        <InputNumber
          placeHolder="123"
          name="position"
          control={control}
        />
      </Col>
      <Button
          style={{
          width: "100%",
          marginTop: 10,
          }}
          type="primary"
          shape="round"
          size="large"
          onClick={onSubmit(onSubmitCreateOrUpdate)}
          loading={isLoading}
      >
          {isUpdateEventsCategories
          ? "Actualizar información"
          : "Crear categoría de trabajo"}
      </Button>
  </WrapperModalEvents>
)
}
