 
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col } from "antd";
import { TableCustom, eventsTagsService } from "../../../../shared";
import { IModelEventsTags } from "../../@types";
import { useEventsTags, useTableEventsTags } from "../../hooks";
import { TableColumns, TableProperties } from "../../../../shared/components/ui/table/types";
import React from "react";


export function TableEventsTags() {
  const {
    //state
    //methods
    //functions
    changeEventsTagsForUpdate,
    showDeleteConfirm,
  } =  useTableEventsTags()
  const {
    //state
    isRefreshTableEventsTags,
    // isLoading,
    //methods
    //functions
    // updateStatus,
  }= useEventsTags()

  const tableRef = React.useRef<TableProperties>()

    React.useEffect(() => {
      tableRef.current?.refresh()
    }, [isRefreshTableEventsTags])

  const columns: TableColumns<IModelEventsTags> = [
      {
          title: 'ID',
          dataIndex: 'id',
          align: 'left',
      },
      {
          title: 'Nombre',
          dataIndex: 'name',
          align: 'left',
      },
      {
          title: "Acciones",
          key: "operation",
          fixed: "right",
          width: 50,
          render: (_:any, item:any)  => {
            return (
              <Col
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "start",
                  flexDirection: "row",
                }}
              >
                <Button
                  type="primary"
                  shape="circle"
                  onClick={() => changeEventsTagsForUpdate(item.id)}
                  icon={<EditOutlined />}
                  size="large"
                />
                <Button
                  type="primary"
                  danger
                  onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
                  shape="circle"
                  icon={<DeleteOutlined />}
                  size="large"
                />
              </Col>
            );
          },
        },
  ]

  return (
    <TableCustom columns={columns}  service={eventsTagsService} ref={tableRef}/>
  )
}
