import { message } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { coursesService } from "../../../../shared";
import { getSingleCourse, setIsCourseForUpdate } from "../../../../redux";


export function useCoursesById() {
  const [isValidate, setIsValidate ] = useState(false)

  const { pathname } = useLocation()
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getSingleCoursesFetch = async ( id: number) =>{
      return await coursesService
      .get(id)
      .then((res) => {
          return res;
      })
      .catch((err: any) => {
          console.log(err);
          navigate(`/course`);
          message.error(err.message);
      });
  }

  useEffect(() => {
      if( pathname !== "/course/new" ){
        if (isNaN(Number(pathname.split("/")[2]))) return navigate(`/course`);
          dispatch(setIsCourseForUpdate(true));
          getSingleCoursesFetch(Number(pathname.split("/")[2])).then((res) =>
              dispatch(getSingleCourse(res as any))
          );
          setIsValidate(true)
      }else if(pathname === "/course/new" ){
          dispatch(setIsCourseForUpdate(false));
          setIsValidate(true)
      }
  }, [])

return {
  //state
  isValidate,
  //methods
  //functions
}
}
