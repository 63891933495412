import { message } from "antd";

import { eventsAuthorsService } from "../../shared";
import { eventsAuthorsActions } from "../action-types";
import { IModelEventsAuthors } from "../../modules/events/@types/IModelEventsAuthors";

export const changeModalStateEventsAuthors = (): eventsAuthorsActions.IEventsAuthorsModalState =>({
    type: eventsAuthorsActions.EVENTS_AUTHORS_MODAL_STATE
})

export const getSingleEventsAuthors = (val:IModelEventsAuthors ): eventsAuthorsActions.IGetEventsAuthorsForUpdate=> ({
    type: eventsAuthorsActions.GET_EVENTS_AUTHORS_FOR_UPDATE,
    payload: val
})

export const setIsEventsAuthorsForUpdate =(val: boolean): eventsAuthorsActions.IIsEventsAuthorsForUpdate =>({
    type: eventsAuthorsActions.IS_EVENTS_AUTHORS_FOR_UPDATE,
    payload: val,
})

export const fetchSingleEventsAuthors = async(id: number) =>{
    return await eventsAuthorsService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createEventAuthors = async(data:Partial<IModelEventsAuthors>) =>{
    return await eventsAuthorsService.create(data)
    .then((res: IModelEventsAuthors)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateEventAuthors = async(data:Partial<IModelEventsAuthors>) =>{
    return await eventsAuthorsService.patch(data?.id!, data)
    .then((res: IModelEventsAuthors)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteEventAuthors = async(id: number) =>{
    return await eventsAuthorsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableEventsAuthors = (val: boolean): eventsAuthorsActions.ISwitchEventsAuthorsRefreshTable =>({
    type: eventsAuthorsActions.SWITCH_EVENTS_AUTHORS_REFRESH_TABLE,
    payload: val
})

