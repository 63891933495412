import { message } from "antd";

import { coursesChaptersService } from "../../shared";
import { coursesChaptersActions } from "../action-types";
import { IModelCoursesChapters } from "../../modules";

export const changeModalStateCoursesChapters = (): coursesChaptersActions.ICoursesChaptersModalState =>({
    type: coursesChaptersActions.COURSES_CHAPTERS_MODAL_STATE
})

export const getSingleCoursesChapters = (val:IModelCoursesChapters ): coursesChaptersActions.IGetCoursesChaptersForUpdate=> ({
    type: coursesChaptersActions.GET_COURSES_CHAPTERS_FOR_UPDATE,
    payload: val
})

export const setIsCoursesChaptersForUpdate =(val: boolean): coursesChaptersActions.IIsCoursesChaptersForUpdate =>({
    type: coursesChaptersActions.IS_COURSES_CHAPTERS_FOR_UPDATE,
    payload: val,
})

export const fetchSingleCoursesChapters = async(id: number) =>{
    return await coursesChaptersService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createCourseChapters = async(data:Partial<IModelCoursesChapters>) =>{
    return await coursesChaptersService.create(data)
    .then((res: IModelCoursesChapters)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateCourseChapters = async(data:Partial<IModelCoursesChapters>) =>{
    return await coursesChaptersService.patch(data?.id!, data)
    .then((res: IModelCoursesChapters)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteCourseChapters = async(id: number) =>{
    return await coursesChaptersService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableCoursesChapters = (val: boolean): coursesChaptersActions.ISwitchCoursesChaptersRefreshTable =>({
    type: coursesChaptersActions.SWITCH_COURSES_CHAPTERS_REFRESH_TABLE,
    payload: val
})

