import { useFormContext } from "react-hook-form";
import { Col, Row } from "antd";
import {
  InputText,
} from "../../../../shared";
import { IInfographicCategory } from "../../@types";

export default function InfographicCategoryForm() {
  const { control } = useFormContext<IInfographicCategory>();


  return (
    <Row gutter={[8, 16]}>
      <Col xs={24}>
        <InputText control={control} name="name" labelText="Nombre" />
      </Col>
    </Row>
  );
}
