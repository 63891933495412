import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { message } from "antd";
import { getSingleWebinars, setIsWebinarsForUpdate } from "../../../../redux";
import { webinarsService } from "../../../../shared";

export function useWebinarsById() {
    const [isValidate, setIsValidate ] = useState(false)

    const { pathname } = useLocation()
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const getSingleWebinarsFetch = async ( id: number) =>{
        return await webinarsService
        .get(id)
        .then((res) => {
            return res;
        })
        .catch((err: any) => {
            console.log(err);
            navigate(`/webinar`);
            message.error(err.message);
        });
    }

    useEffect(() => {
        if( pathname !== "/webinar/new" ){
          if (isNaN(Number(pathname.split("/")[2]))) return navigate(`/webinar`);
            dispatch(setIsWebinarsForUpdate(true));
            getSingleWebinarsFetch(Number(pathname.split("/")[2])).then((res) =>
                dispatch(getSingleWebinars(res as any))
            );
            setIsValidate(true)
        }else if(pathname === "/webinar/new" ){
            dispatch(setIsWebinarsForUpdate(false));
            setIsValidate(true)
        }
    }, [])

  return {
    //state
    isValidate,
    //methods
    //functions
  }
}
