import { IModelCourses } from "../../modules"

export const GET_COURSES_FOR_UPDATE = '[ COURSES ] Get Courses  For Update'

export interface IGetCoursesForUpdate {
    type: typeof GET_COURSES_FOR_UPDATE
    payload: IModelCourses
}

export const IS_COURSES_FOR_UPDATE = '[ COURSES ] Is Courses  For Update'

export interface IIsCoursesForUpdate {
    type: typeof IS_COURSES_FOR_UPDATE
    payload: boolean
}

export const SWITCH_COURSES_REFRESH_TABLE = '[ COURSES ] Switch Courses  Refresh Table'

export interface ISwitchCoursesRefreshTable {
    type: typeof SWITCH_COURSES_REFRESH_TABLE
    payload: boolean
}