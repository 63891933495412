import { Button, Col, Typography } from "antd";
import { useFormContext } from 'react-hook-form';
import { useSelector } from "react-redux";
import { selectModalCoursesIncludes } from "../../../../redux";
import { useCoursesIncludes } from "../../hooks";
import { IModelCoursesIncludes } from "../../@types";
import { WrapperModalCourses } from "../styled";
import { InputNumber, InputText } from "../../../../shared";

const { Title } = Typography;

export function ModalCoursesIncludes() {
  const modalCoursesIncludes = useSelector(
    selectModalCoursesIncludes
  );

  const {
    //state
    isLoading,
    isUpdateCoursesIncludes,
    dataUpdateCoursesIncludes,
    //methods
    //functions
    changeModalCoursesIncludesStateForCreate,
    onSubmitCreateOrUpdate
  } = useCoursesIncludes()

  const { control, handleSubmit: onSubmit } = useFormContext<IModelCoursesIncludes>();

  return (
    <WrapperModalCourses
      title={ isUpdateCoursesIncludes
        ? `Actualizando inclusion de curso: '${dataUpdateCoursesIncludes?.id!}'`
        : `Creando inclusion de curso` }
      open={modalCoursesIncludes}
      width={"320px"}
      destroyOnClose
      onClose={changeModalCoursesIncludesStateForCreate}>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Text requerido:
          </Title>
          <InputText placeHolder="Inclusion del webinar...." name="include_text" rows control={control} />
        </Col>
        <Col
          span={24}
          style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Posición:
          </Title>
          <InputNumber
            placeHolder="123"
            name="position"
            control={control}
          />
        </Col>
        {/* <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}>
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Course asignado:
          </Title>
          <InputSelectDynamic name="webinar_id" control={control} serviceName={webinarsService} labelName="name" valName="id"/>
        </Col> */}
        <Button
          style={{
          width: "100%",
          marginTop: 10,
          }}
          type="primary"
          shape="round"
          size="large"
          onClick={onSubmit(onSubmitCreateOrUpdate)}
          loading={isLoading}>
            {isUpdateCoursesIncludes
            ? "Actualizar información"
            : "Crear inclusion"}
        </Button>
    </WrapperModalCourses>
  )
}
