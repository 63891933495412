
import "moment/min/locales"
import { DatePicker } from "antd";
import moment from "moment-timezone";
import moment2, { Moment } from "moment";
import momentGenerateConfig from 'rc-picker/lib/generate/moment';

interface Props {
  width?: string;
  isDisabledDate?: boolean;
  placeHolder: string;
  name: string;
  dateFormat?: string;
  value: Moment | string | null | undefined;
  onChangeDate: any;
}
const MyDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig as any);

export const SimpleInputData = ({
  width = "100%",
  placeHolder,
  dateFormat,
  value,
  onChangeDate,
  isDisabledDate = false,
}: Props) => {
  return (
    <div
            style={{
              display: "flex",
              justifyContent: "center",
              width,
              height: "80%",
              flexDirection: "column",
            }}
          >
            
            <MyDatePicker
              format={dateFormat ? dateFormat : "YYYY-MM-DD"}
              showTime={dateFormat ? true : false}
              style={{ width: "100%" }}
              disabledDate={(value) => isDisabledDate && value.isBefore(moment().add(-1, "day"))}
              placeholder={placeHolder}
              onChange={(val:any)=>{
                return onChangeDate(val)
                }
              }
              value={value ? moment2(value) : null}
            />
          </div>
  );
};