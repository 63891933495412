import { IModelEventsAuthors } from "../../modules";
import { IEventsAuthorsState } from "../@types";
import { eventsAuthorsActions } from "../action-types";



type eventsAuthorsActionType =
| { type: typeof eventsAuthorsActions.EVENTS_AUTHORS_MODAL_STATE; }
| { type: typeof eventsAuthorsActions.GET_EVENTS_AUTHORS_FOR_UPDATE; payload: IModelEventsAuthors }
| { type: typeof eventsAuthorsActions.IS_EVENTS_AUTHORS_FOR_UPDATE; payload: boolean }
| { type: typeof eventsAuthorsActions.SWITCH_EVENTS_AUTHORS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IEventsAuthorsState = {
    eventsAuthorsModalIsOpen: false,
    isUpdateEventsAuthors: false,
    eventsAuthorsForUpdate: null,
    refreshTableEventsAuthors: false,
}

export default function eventsAuthorsReducer(state: IEventsAuthorsState = INITIAL_STATE, action: eventsAuthorsActionType): IEventsAuthorsState {
  switch (action.type) {
    case eventsAuthorsActions.EVENTS_AUTHORS_MODAL_STATE:
        return {
            ...state,
            eventsAuthorsModalIsOpen: !state.eventsAuthorsModalIsOpen,
        }
    case eventsAuthorsActions.GET_EVENTS_AUTHORS_FOR_UPDATE:
        return {
            ...state,
            eventsAuthorsForUpdate: action.payload,
        }
    case eventsAuthorsActions.IS_EVENTS_AUTHORS_FOR_UPDATE:
        return {
            ...state,
            isUpdateEventsAuthors: action.payload,
        }
    case eventsAuthorsActions.SWITCH_EVENTS_AUTHORS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableEventsAuthors: action.payload,
        }
    default:
        return state
  }
}
