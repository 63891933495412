import { RegistryServicesConsultNewById } from "../modules/registry-services-consult-news";
import { AdminLayout } from "../shared";

export function RegistryServicesConsultNewByIdPage() {
  return (
    <AdminLayout>
      <RegistryServicesConsultNewById />
    </AdminLayout>
  )
}
