import React from "react";
import { Tabs as AntTabs } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

export function Tabs({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <AntTabs
      style={{ width: "100%" }}
      size="large"
      type="card"
      activeKey={location.pathname}
      onChange={(activeKey) => {
        navigate(activeKey);
      }}
      items={[
        {
          key: "/calificaciones/cursos",
          label: "Cursos",
          children,
        },
        {
          key: "/calificaciones/webinars",
          label: "Webinars",
          children,
        },
        {
          key: "/calificaciones/eventos",
          label: "Eventos",
          children,
        },
      ]}
    />
  );
}
