import * as yup from 'yup'

export const validateCreateCoursesCategories = yup.object().shape({
    name:yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El NOMBRE debe tener mínimo de 2 caracteres.'),
    status: yup
    .string()
    .required('Campo requerido.')
    .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones.'),
    position:yup
    .number()
    .required('Campo requerido.'),
    // path_image:yup
    // .string()
    // .required('Campo requerido.')
    // .min(1, 'Debes de incluir mínimo una imagen.'),
})