import { Button, Drawer } from "antd";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { StudyCerticatesById } from "../modules/studyCertificates";

export function StudyCerticatesIdPage() {
  return (
    <Drawer
      title="Editar Certificado"
      open
      width={350}
      destroyOnClose
      closeIcon={
        <Link to="/certificates">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <StudyCerticatesById />
    </Drawer>
  )
}
