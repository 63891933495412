import { TableUserCourse } from "../modules/userCourses";
import { AdminLayout } from "../shared";

export function UserCoursesPage() {
  return (
    <AdminLayout>
      <TableUserCourse />
    </AdminLayout>
  );
}
