import { IModelWebinarsBenefits } from "../../modules"


export const WEBINARS_BENEFITS_MODAL_STATE = '[ WEBINARS BENEFITS ] Webinars Benefits Modal State'

export interface IWebinarsBenefitsModalState {
    type: typeof WEBINARS_BENEFITS_MODAL_STATE
}

export const GET_WEBINARS_BENEFITS_FOR_UPDATE = '[ WEBINARS BENEFITS ] Get Webinars Benefits For Update'

export interface IGetWebinarsBenefitsForUpdate {
    type: typeof GET_WEBINARS_BENEFITS_FOR_UPDATE
    payload: IModelWebinarsBenefits
}

export const IS_WEBINARS_BENEFITS_FOR_UPDATE = '[ WEBINARS BENEFITS ] Is Webinars Benefits For Update'

export interface IIsWebinarsBenefitsForUpdate {
    type: typeof IS_WEBINARS_BENEFITS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_WEBINARS_BENEFITS_REFRESH_TABLE = '[ WEBINARS BENEFITS ] Switch Webinars Benefits Refresh Table'

export interface ISwitchWebinarsBenefitsRefreshTable {
    type: typeof SWITCH_WEBINARS_BENEFITS_REFRESH_TABLE
    payload: boolean
}