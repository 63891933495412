import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { Col, Row, Typography } from "antd";
import { IModelVirtualLibraryCategory } from "../../@types";
import { InputSelect, InputSelectDynamic, InputText, clustersService } from "../../../../shared";
import { useLocation } from "react-router-dom";

const { Title } = Typography;

export function FormCategiesInfograpgies() {
  const [selectProps, setSelectProps] = useState<any>();

  const { control } = useFormContext<IModelVirtualLibraryCategory>();

  const location = useLocation();

  useEffect(() => {
    selectProps?.refresh?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return (
    <>
        <Row justify="start">
            <Col style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            }} span={24}>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Name:
            </Title>
            <InputText
              placeHolder=""
              name="name"
              control={control}
            />
            </Col>
                   </Row>
    </>
  )
}
