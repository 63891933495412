import { IModelNewsCategories } from '../../modules/news-categories/@types';
import { INewsCategoriesState } from '../@types/newsCategories';

import { newsCategoriesActions } from '../action-types'

type newsCategoriesActionType =
| { type: typeof newsCategoriesActions.NEWS_CATEGORIES_MODAL_STATE; }
| { type: typeof newsCategoriesActions.GET_NEWS_CATEGORIES_FOR_UPDATE; payload: IModelNewsCategories }
| { type: typeof newsCategoriesActions.IS_NEWS_CATEGORIES_FOR_UPDATE; payload: boolean }
| { type: typeof newsCategoriesActions.SWITCH_NEWS_CATEGORIES_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: INewsCategoriesState = {
    newsCategoriesModalIsOpen: false,
    isUpdateNewsCategories: false,
    newsCategoriesForUpdate: null,
    refreshTableNewsCategories: false,
}

export default function newsCategoriesReducer(state: INewsCategoriesState = INITIAL_STATE, action: newsCategoriesActionType): INewsCategoriesState {
  switch (action.type) {
    case newsCategoriesActions.NEWS_CATEGORIES_MODAL_STATE:
        return {
            ...state,
            newsCategoriesModalIsOpen: !state.newsCategoriesModalIsOpen,
        }
    case newsCategoriesActions.GET_NEWS_CATEGORIES_FOR_UPDATE:
        return {
            ...state,
            newsCategoriesForUpdate: action.payload,
        }
    case newsCategoriesActions.IS_NEWS_CATEGORIES_FOR_UPDATE:
        return {
            ...state,
            isUpdateNewsCategories: action.payload,
        }
    case newsCategoriesActions.SWITCH_NEWS_CATEGORIES_REFRESH_TABLE:
        return {
            ...state,
            refreshTableNewsCategories: action.payload,
        }
    default:
        return state
  }
}
