import { Drawer, Tabs } from "antd";
import styled from "styled-components";

//modal Projects

export const WrapperModalProjects = styled(Drawer)``;


export const WrapperTabNavProjects = styled(Tabs)`
  width: 100%;
`;