import React from "react";
import { TableProperties } from "../../../shared/components/ui/table/types";
import { Link, useLocation } from "react-router-dom";
import Table from "../../../shared/components/ui/table/TableCustom";
import { Button, Col, Space, Tag } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { IInfographic } from "../@types";
import useDeleteInfographic from "../lib/useDeleteInfographic";
import { infographicsService } from "../../../shared";
import { PATH_S3_BUCKET } from "../../../shared/utils/constants";
import useUpdateInfographicStatus from "../lib/useUpdateInfographicStatus";
import moment from "moment";
import Title from "antd/es/typography/Title";

export default function Infographics() {
  const location = useLocation();

  const tableRef = React.useRef<TableProperties>();

  const { updateStatus } = useUpdateInfographicStatus({
    onSuccess() {
      tableRef.current?.refresh();
    },
  });


  const { handleDelete } = useDeleteInfographic({
    onSuccess() {
      tableRef.current?.refresh();
    },
  });

  const columns = React.useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        align: "left",
        sorter: true,
      },
      {
        title: "Imagen miniatura",
        align: "left",
        dataIndex: "path_thumbnail",
        key: "path_thumbnail",
        render: (_: any, item: any) => {
          return (
            <Col
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "start",
                flexDirection: "row",
              }}
            >
              <img
                alt="thumbnail"
                style={{ maxHeight: 40 }}
                src={`${PATH_S3_BUCKET}/${item.path_thumbnail}`}
              />
            </Col>
          );
        },
      },
      {
        title: "Name",
        dataIndex: "name",
        align: "left",
      },
      {
        title: "Categoría",
        dataIndex: ["category", "name"],
        align: "left",
      },
      {
        title: "Estado",
        dataIndex: "status",
        render: (status: IInfographic["status"], record: IInfographic) => {
          return (
            <Button
              type="text"
              onClick={() =>
                updateStatus(
                  record.id,
                  status === "active" ? "inactive" : "active"
                )
              }
            >
              <Tag color={status === "active" ? "success" : "error"}>
                {status === "active" ? "Activo" : "Inactivo"}
              </Tag>
            </Button>
          );
        },
      },
      {
        title: "Fecha de Creación",
        width: 250,
        dataIndex: "createdAt",
        align: "left",
        render: (_: any, item: any) => (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Title level={5}>
              {moment(item.createdAt)
                .utcOffset(0)
                .format("DD/MM/YYYY HH:mm:ss")}
            </Title>
          </Col>
        ),
      },
      {
        title: "Acciones",
        render: (record: IInfographic) => {
          return (
            <Space>
              <Link to={`/infographics/${record.id}`}>
                <Button type="link" icon={<EditOutlined />} />
              </Link>
              <Button
                type="text"
                icon={<DeleteOutlined />}
                danger
                onClick={() => handleDelete(record.id)}
              />
            </Space>
          );
        },
      },
    ],
    []
  );

  React.useEffect(() => {
    tableRef.current?.refresh();
  }, [location.pathname]);

  return (
    <Table
      ref={tableRef}
      service={infographicsService}
      columns={columns}
    />
  );
}
