import { IModelEventsTags } from "../../modules"


export const EVENTS_TAGS_MODAL_STATE = '[ EVENTS TAGS ] Events Tags Modal State'

export interface IEventsTagsModalState {
    type: typeof EVENTS_TAGS_MODAL_STATE
}

export const GET_EVENTS_TAGS_FOR_UPDATE = '[ EVENTS TAGS ] Get Events Tags For Update'

export interface IGetEventsTagsForUpdate {
    type: typeof GET_EVENTS_TAGS_FOR_UPDATE
    payload: IModelEventsTags
}

export const IS_EVENTS_TAGS_FOR_UPDATE = '[ EVENTS TAGS ] Is Events Tags For Update'

export interface IIsEventsTagsForUpdate {
    type: typeof IS_EVENTS_TAGS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_EVENTS_TAGS_REFRESH_TABLE = '[ EVENTS TAGS ] Switch Events Tags Refresh Table'

export interface ISwitchEventsTagsRefreshTable {
    type: typeof SWITCH_EVENTS_TAGS_REFRESH_TABLE
    payload: boolean
}