/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

import { Button, message, Spin } from "antd";
import lodash from "lodash";

import { onUploadFile } from "../../../utils/uploadS3";

interface LoadProps {
  progress: number;
}

function Load(props: LoadProps): JSX.Element {
  return (
    <div
      style={{
        width: "100%",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Spin size="default" />
      <></>
    </div>
  );
}

interface UploadProps {
  fileName?: string;
  filePath?: string;
  fileMatch?: false | string | RegExp;
  fileMaxSize?: Number;
  fileUploadProgress?: (progress: number) => void;
  fileMultiple?: boolean;
  onUploadedFile?: (file: any) => void;
  fullWidth?: boolean;
  render?: (props: {
    progress: number;
    onClick: () => void;
  }) => React.ReactNode;
  value?: string;
  placeHolder?: string;
}

function Upload(props: UploadProps) {
  const [progress, setProgress] = useState<number>(0);
  const ref = useRef<HTMLInputElement>(null);
  //console.log({ props });
  const fileName =
    (props.value &&
      props.value !== "" &&
      props.value.split("/").pop()!.substring(37)) ||
    "";

  function onProgress(value: number) {
    setProgress(value);
  }

  useEffect(() => {
    if (props.fileUploadProgress) props.fileUploadProgress(progress);
  }, [progress]);

  return (
    <div style={{ position: "relative", display: "block" }}>
      {/* <input
        style={{ display: "none" }}
        ref={ref}
        type="file"
        multiple={props.fileMultiple}
        onChange={(e) => {
          console.log({ e });
          setProgress(0);
          onUploadFile(
            e,
            lodash.merge(
              {},
              props.fileName ? { name: props.fileName } : {},
              props.filePath ? { path: props.filePath } : {},
              props.fileMatch ? { validate: { match: props.fileMatch } } : {},
              props.fileMaxSize
                ? { validate: { maxSize: props.fileMaxSize } }
                : {},
              { onProgress: onProgress }
            )
          ).subscribe({
            next: (file: any) => {
              setProgress(0);
              if (props.onUploadedFile) props.onUploadedFile(file);
            },
            error: (err: any) => {
              message.error(
                err.message || "Algo a salido mal por favor intente de nuevo"
              );
            },
          });
        }}
      /> */}
      {props.render ? (
        props.render({
          progress,
          onClick: () => {
            ref.current!.click();
          },
        })
      ) : (
        <React.Fragment>
          {!(progress > 0 && progress <= 100) && (
            <Button
              htmlType="button"
              size="large"
              onClick={() => {
                ref.current!.click();
              }}
            >
              {props.placeHolder || "Cargar Imagen"}
            </Button>
          )}
          {fileName !== "" && fileName}
          {progress > 0 && progress <= 100 && <Load progress={progress} />}
        </React.Fragment>
      )}
    </div>
  );
}

export default Upload;
