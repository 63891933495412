import React from "react";
import { useForm } from "react-hook-form";
import { message } from "antd";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { studyCertificatesService, ciuuService } from "../../../../shared";
import { IModelStudy } from "../../@types";
import moment from "moment";

export default function UsekeyAccountManagersById(
  recordId?: number | null,
  props?: { onSuccess?: (record: IModelStudy) => void }
) {
  const [loading, setLoading] = React.useState(false);

  const formMethods = useForm<IModelStudy>({
    resolver: yupResolver(yup.object().shape({})),
  });

  const loadingRef = React.useRef(false);

  const updateLoading = (status: boolean) => {
    setLoading(status);
    loadingRef.current = status;
  };

  const getRecord = async () => {
    if (loadingRef.current || !recordId) return;

    updateLoading(true);

    await studyCertificatesService
      .get(recordId)
      .then((record) => {
        formMethods.reset({
          name: record.name,
          path_file: record.path_file,
          study_certificate_template_id: record.study_certificate_template_id,
          emission_date: moment.utc(record.emission_date),
          user_id: record.user_id,
          survey_done: record.survey_done,
          survey_needed: record.survey_needed,
        });
      })
      .finally(() => {
        updateLoading(false);
      });
  };

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    if (loadingRef.current || !recordId) return;

    updateLoading(true);

    await studyCertificatesService
      .patch(recordId, data)
      .then((record) => {
        message.success("Registro actualizado correctamente");
        props?.onSuccess?.(record as IModelStudy);
      })
      .catch((err) => {
        alert(err?.message);
        message.error(err?.message);
      })
      .finally(() => {
        updateLoading(false);
      });
  });

  React.useEffect(() => {
    if (recordId) {
      getRecord();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId]);

  return {
    formMethods,
    loading,
    handleSubmit,
  };
}
