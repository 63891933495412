import * as yup from "yup";

export const validateCreateEvents = yup.object().shape({
  name: yup
    .string()
    .required("Campo requerido.")
    .min(2, "El nombre debe tener mínimo de 2 caracteres."),
  date_start: yup
    .string()
    .required("Campo requerido.")
    .min(1, "La fecha de inicio debe tener más de 1 carácter"),
  date_end: yup
    .string()
    .required("Campo requerido.")
    .min(1, "La fecha de finalización debe tener más de 1 carácter"),
  description: yup
    .string()
    .required("Campo requerido.")
    .min(2, "La descripción debe tener mínimo de 2 caracteres."),
  status: yup
    .string()
    .required("Campo requerido.")
    .oneOf(["active", "inactive"], "Debes elegir entre estas opciones."),
  assistance_type: yup
    .string()
    .required("Campo requerido.")
    .oneOf(
      ["remote", "hybrid", "on_place"],
      "Debes elegir entre estas opciones."
    ),
  location_name: yup
    .string()
    .nullable()
    .min(1, "El nombre de la locación debe tener más de 1 caracteres")
    .when("assistance_type", {
      is: ["hybrid", "on_place"],
      then: yup
        .string()
        .required("Campo requerido.")
        .min(1, "El nombre de la locación debe tener más de 1 caracteres"),
    }),
  location_latitude: yup.number().when("assistance_type", {
    is: ["hybrid", "on_place"],
    then: yup.number().required("Campo requerido."),
  }),
  location_longitude: yup.number().when("assistance_type", {
    is: ["hybrid", "on_place"],
    then: yup.number().required("Campo requerido."),
  }),
  location_description: yup
    .string()
    .nullable()
    .min(1, "El nombre de la locación debe tener más de 1 caracteres")
    .when("assistance_type", {
      is: ["hybrid", "on_place"],
      then: yup
        .string()
        .required("Campo requerido.")
        .min(1, "El nombre de la locación debe tener más de 1 caracteres"),
    }),
  // path_cover_image:yup
  // .string()
  // .required('Campo requerido.')
  // .min(1, 'Debes de incluir mínimo un video.'),
  // path_cover_video:yup
  // .string()
  // .min(1, 'Debes de incluir mínimo una imagen.'),
  // path_banner_image:yup
  // .string()
  // .required('Campo requerido.')
  // .min(1, 'Debes de incluir mínimo un video.'),
  max_capacity: yup.number(),
});
