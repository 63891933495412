
import { IModelProjectsManagers } from '../../modules';
import { IProjectsManagersState } from '../@types/projectsManagers';

import { projectsManagersActions } from '../action-types'

type projectsManagersActionType =
| { type: typeof projectsManagersActions.PROJECTS_MANAGERS_MODAL_STATE; }
| { type: typeof projectsManagersActions.GET_PROJECTS_MANAGERS_FOR_UPDATE; payload: IModelProjectsManagers }
| { type: typeof projectsManagersActions.IS_PROJECTS_MANAGERS_FOR_UPDATE; payload: boolean }
| { type: typeof projectsManagersActions.SWITCH_PROJECTS_MANAGERS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IProjectsManagersState = {
    projectsManagersModalIsOpen: false,
    isUpdateProjectsManagers: false,
    projectsManagersForUpdate: null,
    refreshTableProjectsManagers: false,
}

export default function projectsManagersReducer(state: IProjectsManagersState = INITIAL_STATE, action: projectsManagersActionType): IProjectsManagersState {
  switch (action.type) {
    case projectsManagersActions.PROJECTS_MANAGERS_MODAL_STATE:
        return {
            ...state,
            projectsManagersModalIsOpen: !state.projectsManagersModalIsOpen,
        }
    case projectsManagersActions.GET_PROJECTS_MANAGERS_FOR_UPDATE:
        return {
            ...state,
            projectsManagersForUpdate: action.payload,
        }
    case projectsManagersActions.IS_PROJECTS_MANAGERS_FOR_UPDATE:
        return {
            ...state,
            isUpdateProjectsManagers: action.payload,
        }
    case projectsManagersActions.SWITCH_PROJECTS_MANAGERS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableProjectsManagers: action.payload,
        }
    default:
        return state
  }
}
