import { useSelector } from "react-redux";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { changeModalStateEventsCategories, createEventCategories, refreshTableEventsCategories, selectIsUpdateEventsCategories, selectRefreshTableEventsCategories, selectSingleEventsCategories, setIsEventsCategoriesForUpdate, updateEventCategories } from "../../../../redux";
import { IModelEventsCategories } from "../../@types";



export function useEventsCategories() {
  const dispatch = useAppDispatch();

  const isUpdateEventsCategories = useSelector(
      selectIsUpdateEventsCategories
  );
  const dataUpdateEventsCategories = useSelector(
      selectSingleEventsCategories
  );
  const isRefreshTableEventsCategories = useSelector(
      selectRefreshTableEventsCategories
  );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalEventsCategoriesStateForCreate = () => {
      dispatch(setIsEventsCategoriesForUpdate(false))
      dispatch(changeModalStateEventsCategories())
  }

  const formMethodsCreate = useForm<IModelEventsCategories>({
    //resolver: yupResolver(validateCreateEventsCategories),
  });

  const formMethodsUpdate = useForm<IModelEventsCategories>({
    //resolver: yupResolver(validateUpdateEventsCategories),
  });

  useEffect(() => {
  if (isUpdateEventsCategories) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateEventsCategories });
  }

  return () => {
      formMethodsUpdate.reset();
  };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateEventsCategories, dataUpdateEventsCategories]);

  // const updateStatus = async(value: "active" | "inactive", id: any) =>{
  //   if (isLoading) return;
  //   setIsLoading(true);
  //   await updateEventCategories({
  //     id,
  //     status: value,
  //   })
  //   .then((res: Partial<IModelEventsCategories>) => {
  //     setIsLoading(false);
  //     if (res.id) {
  //       message.success(
  //         `Se ha actualizado con éxito el estado del evento con id:${res.id}`
  //       );
  //       dispatch(refreshTableEventsCategories(true));
  //     } else {
  //       message.error(res as string);
  //     }
  //   })
  //   .catch((err: any) => {
  //     setIsLoading(false);
  //     console.log(err);
  //     message.error(err.message);
  //   });
  // }

  const onSubmitCreateOrUpdate = async (data: IModelEventsCategories) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateEventsCategories) {
      await updateEventCategories({
        id: data.id,
        name: data.name,
        position: data.position,
      })
        .then((res: Partial<IModelEventsCategories>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito la categoría de evento con id:${res.id}`
            );
            dispatch(refreshTableEventsCategories(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createEventCategories({...data})
        .then((res: IModelEventsCategories) => {
          if (res.id) {
            dispatch(changeModalStateEventsCategories());
            message.success(
              `Se ha creado con éxito la categoría de evento con id:${res.id}`
            );
            dispatch(refreshTableEventsCategories(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableEventsCategories) {
      dispatch(refreshTableEventsCategories(false));
    }
  }, [isRefreshTableEventsCategories]);

  return{
      //state
      isLoading,
      isUpdateEventsCategories,
      dataUpdateEventsCategories,
      isRefreshTableEventsCategories,
      //methods
      formMethodsCreate,
      formMethodsUpdate,
      //functions
      changeModalEventsCategoriesStateForCreate,
      onSubmitCreateOrUpdate,
      // updateStatus,
  }
}
