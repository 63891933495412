import { message } from "antd";
import { useEffect, useState } from "react";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { changeModalStateProjectsBenefits, createProjectBenefits, refreshTableProjectsBenefits, selectIsUpdateProjectsBenefits, selectRefreshTableProjectsBenefits, selectSingleProjects, selectSingleProjectsBenefits, setIsProjectsBenefitsForUpdate, updateProjectBenefits } from "../../../../redux";
import { IModelProjectsBenefits } from "../../@types";
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { validateCreateProjectsBenefits, validateUpdateProjectsBenefits } from "../../../../shared/utils/yupValidation";

export function useProjectsBenefits() {
  const dispatch = useAppDispatch();

  const isUpdateProjectsBenefits = useSelector(
      selectIsUpdateProjectsBenefits
  );

  
  const isRefreshTableProjectsBenefits = useSelector(
    selectRefreshTableProjectsBenefits
    );
    
    const dataUpdateProjects = useSelector( selectSingleProjects );
    const dataUpdateProjectsBenefits = JSON.parse(dataUpdateProjects?.meta_benefits || '')
  // console.log(33, dataUpdateProjects, 77, dataUpdateProjectsBenefits);

  const [isLoading, setIsLoading] = useState(false);

  const changeModalProjectsBenefitsStateForCreate = () => {
    dispatch(setIsProjectsBenefitsForUpdate(false))
    dispatch(changeModalStateProjectsBenefits())
  }

  const formMethodsCreate = useForm<IModelProjectsBenefits>({
    // resolver: yupResolver(validateCreateProjectsBenefits),
  });

  const formMethodsUpdate = useForm<IModelProjectsBenefits>({
    // resolver: yupResolver(validateUpdateProjectsBenefits),
  });

  const onSubmitCreateOrUpdate = async (data: IModelProjectsBenefits) => {
    // console.log(77, data, dataUpdateProjects);
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateProjectsBenefits) {
      await updateProjectBenefits({
        id: data.id,
        title: data.title,
        items: data.items,
        position: data.position,
        project_id: dataUpdateProjects?.id,
      })
        .then((res: Partial<IModelProjectsBenefits>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito la beneficios de Project con id:${res.id}`
            );
            dispatch(refreshTableProjectsBenefits(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createProjectBenefits({...data, project_id: dataUpdateProjects?.id})
        .then((res: IModelProjectsBenefits) => {
          if (res.id) {
            dispatch(changeModalStateProjectsBenefits());
            message.success(
              `Se ha creado con éxito la beneficios de Project con id:${res.id}`
            );
            dispatch(refreshTableProjectsBenefits(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isUpdateProjectsBenefits) {
        formMethodsCreate.reset();
        formMethodsUpdate.reset({ ...dataUpdateProjectsBenefits });
    }
  
    return () => {
        formMethodsUpdate.reset();
    };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdateProjectsBenefits]);

  useEffect(() => {
    if (isRefreshTableProjectsBenefits) {
      dispatch(refreshTableProjectsBenefits(false));
    }
  }, [isRefreshTableProjectsBenefits]);

  return{
    //state
    isLoading,
    isUpdateProjectsBenefits,
    dataUpdateProjectsBenefits,
    dataUpdateProjects,
    isRefreshTableProjectsBenefits,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalProjectsBenefitsStateForCreate,
    onSubmitCreateOrUpdate,
  }
}
