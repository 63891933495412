import React from "react";
import { Button, Typography } from "antd";
import useStudyCertificateTemplate from "../../hooks/templates/useStudyCertificateTemplate";
import Canvas from "./canvas";
import Widgets from "./widgets";
import { SaveOutlined } from "@ant-design/icons";
import { IField } from "./canvas/field";
import useStudyCertificateTemplateFields from "../../hooks/templates/useStudyCertificateTemplateFields";
import useUpdateStudyCertificateTemplateFields from "../../hooks/templates/useUpdateStudyCertificateTemplateFields";

export default function TemplateConfig({
  templateId,
}: {
  templateId?: number;
}) {
  const [fields, setFields] = React.useState<IField[]>([]);

  const { studyCertificateTemplate } = useStudyCertificateTemplate(templateId);

  const { studyCertificateTemplateFields, refresh } =
    useStudyCertificateTemplateFields(templateId);

  const fieldsToCreate = React.useMemo(() => {
    return fields.filter((field) => typeof field.id === "string");
  }, [fields]);

  const fieldsToDelete = React.useMemo(() => {
    const initialFieldsIds = studyCertificateTemplateFields.map(({ id }) => id);
    const currentFieldsId = fields.map(({ id }) => id);
    return initialFieldsIds.filter(
      (fieldId) => !currentFieldsId.includes(fieldId)
    );
  }, [fields, studyCertificateTemplateFields]);

  const fieldsToUpdate = React.useMemo(() => {
    const initialFieldsIds = studyCertificateTemplateFields.map(({ id }) => id);
    return fields.filter((field) =>
      initialFieldsIds.includes(field.id as number)
    );
  }, [fields, studyCertificateTemplateFields]);

  const { handleUpdateFields } = useUpdateStudyCertificateTemplateFields(
    templateId,
    {
      fieldsToCreate,
      fieldsToDelete,
      fieldsToUpdate,
    },
    {
      onSuccess() {
        refresh();
      },
    }
  );

  React.useEffect(() => {
    setFields(
      studyCertificateTemplateFields.map((field) => ({
        id: field.id,
        description: field.field_placeholder,
        height: field.height,
        key_name: field.key_name_to_fill,
        left: field.left,
        name: field.field_name,
        placeholder: field.field_placeholder,
        styles: JSON.parse(field.css_styles),
        top: field.top,
        width: field.width,
      }))
    );
  }, [studyCertificateTemplateFields]);

  return (
    <div
      style={{
        display: "flex",
        gap: 16,
      }}
    >
      <div style={{ flex: 1 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 14,
          }}
        >
          <Button
            onClick={handleUpdateFields}
            size="small"
            icon={<SaveOutlined />}
            type="primary"
          >
            Guardar
          </Button>
        </div>
        <Canvas
          fields={fields}
          onChange={setFields}
          srcPath={studyCertificateTemplate?.image_template_path}
        />
      </div>
      <div
        style={{
          width: 300,
        }}
      >
        <Typography.Title level={4}>
          Configuración de plantilla
        </Typography.Title>
        <Widgets />
      </div>
    </div>
  );
}
