import { IModelRegistryServicesConsultNews } from "../../modules/registry-services-consult-news"

export const GET_REGISTRY_SERVICES_CONSULT_NEWS_FOR_UPDATE = '[ REGISTRY_SERVICES_CONSULT_NEW ] Get REGISTRY_SERVICES_CONSULT_NEW  For Update'

export interface IGetRegistryServicesConsultNewForUpdate {
    type: typeof GET_REGISTRY_SERVICES_CONSULT_NEWS_FOR_UPDATE
    payload: IModelRegistryServicesConsultNews
}

export const IS_REGISTRY_SERVICES_CONSULT_NEWS_FOR_UPDATE = '[ REGISTRY_SERVICES_CONSULT_NEW ] Is REGISTRY_SERVICES_CONSULT_NEWs  For Update'

export interface IIsRegistryServicesConsultNewForUpdate {
    type: typeof IS_REGISTRY_SERVICES_CONSULT_NEWS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_REGISTRY_SERVICES_CONSULT_NEWS_REFRESH_TABLE = '[ REGISTRY_SERVICES_CONSULT_NEW ] Switch REGISTRY_SERVICES_CONSULT_NEWs  Refresh Table'

export interface ISwitchRegistryServicesConsultNewRefreshTable {
    type: typeof SWITCH_REGISTRY_SERVICES_CONSULT_NEWS_REFRESH_TABLE
    payload: boolean
}