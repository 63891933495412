import { message } from "antd";

import { coursesService } from "../../shared";
import { coursesActions } from "../action-types";
import { IModelCourses } from "../../modules";


export const getSingleCourse = (val:IModelCourses ): coursesActions.IGetCoursesForUpdate=> ({
    type: coursesActions.GET_COURSES_FOR_UPDATE,
    payload: val
})

export const setIsCourseForUpdate =(val: boolean): coursesActions.IIsCoursesForUpdate =>({
    type: coursesActions.IS_COURSES_FOR_UPDATE,
    payload: val,
})

export const fetchSingleCourse = async(id: number) =>{
    return await coursesService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createCourse = async(data:Partial<IModelCourses>) =>{
    return await coursesService.create(data)
    .then((res: IModelCourses)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateCourse = async(data:Partial<IModelCourses>) =>{
    return await coursesService.patch(data?.id!, data)
    .then((res: IModelCourses)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteCourse = async(id: number) =>{
    return await coursesService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableCourse = (val: boolean): coursesActions.ISwitchCoursesRefreshTable =>({
    type: coursesActions.SWITCH_COURSES_REFRESH_TABLE,
    payload: val
})

