import { Button, Flex } from "antd";
import { Link, Outlet } from "react-router-dom";
import FormsAndModelsCategories from "../modules/Corresponsales/ui/FormsAndModelsCategories";
import FormsAndModelsLayout from "../modules/Corresponsales/ui/components/FormsAndModelsLayout";
import { AdminLayout } from "../shared";

export default function CorresponsalesFormsAndModelsCategoriesPage() {
  return (
    <AdminLayout>
      <FormsAndModelsLayout>
        <Flex justify="flex-end">
          <Link to="/corresponsales/formatos-y-modelos-categorias/agregar">
            <Button type="primary">+ Categoría</Button>
          </Link>
        </Flex>

        <FormsAndModelsCategories />
        <Outlet />
      </FormsAndModelsLayout>
    </AdminLayout>
  );
}
