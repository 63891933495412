import { useForm } from 'react-hook-form';
import { useSelector } from "react-redux";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import { useEffect, useState } from "react";
import { changeModalStateEventsTags, createEventsTags, refreshTableEventsTags, selectIsUpdateEventsTags, selectRefreshTableEventsTags, selectSingleEventsTags, setIsEventsTagsForUpdate, updateEventsTags } from '../../../../redux';
import { IModelEventsTags } from '../../@types';


export function useEventsTags() {
  const dispatch = useAppDispatch();

  const isUpdateEventsTags = useSelector(
    selectIsUpdateEventsTags
  );
  const dataUpdateEventsTags = useSelector(
    selectSingleEventsTags
  );
  const isRefreshTableEventsTags = useSelector(
    selectRefreshTableEventsTags
  );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalEventsTagsStateForCreate = () => {
      dispatch(setIsEventsTagsForUpdate(false))
      dispatch(changeModalStateEventsTags())
  }

  const formMethodsCreate = useForm<IModelEventsTags>({
    //resolver: yupResolver(validateCreateEventsTags),
  });

  const formMethodsUpdate = useForm<IModelEventsTags>({
    //resolver: yupResolver(validateUpdateEventsTags),
  });

  useEffect(() => {
  if (isUpdateEventsTags) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateEventsTags });
  }

  return () => {
      formMethodsUpdate.reset();
  };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateEventsTags, dataUpdateEventsTags]);

  // const updateStatus = async(value: "active" | "inactive", id: any) =>{
  //   if (isLoading) return;
  //   setIsLoading(true);
  //   await updateEventsTags({
  //     id,
  //     status: value,
  //   })
  //   .then((res: Partial<IModelEventsTags>) => {
  //     setIsLoading(false);
  //     if (res.id) {
  //       message.success(
  //         `Se ha actualizado con éxito los tags de eventos con id:${res.id}`
  //       );
  //       dispatch(refreshTableEventsTags(true));
  //     } else {
  //       message.error(res as string);
  //     }
  //   })
  //   .catch((err: any) => {
  //     setIsLoading(false);
  //     console.log(err);
  //     message.error(err.message);
  //   });
  // }

  const onSubmitCreateOrUpdate = async (data: IModelEventsTags) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateEventsTags) {
      await updateEventsTags({
        id: data.id,
        name: data.name,
      })
        .then((res: Partial<IModelEventsTags>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito los tags de eventos con id:${res.id}`
            );
            dispatch(refreshTableEventsTags(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createEventsTags({...data})
        .then((res: IModelEventsTags) => {
          if (res.id) {
            dispatch(changeModalStateEventsTags());
            message.success(
              `Se ha creado con éxito los tags de eventos con id:${res.id}`
            );
            dispatch(refreshTableEventsTags(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableEventsTags) {
      dispatch(refreshTableEventsTags(false));
    }
  }, [isRefreshTableEventsTags]);

  return{
    //state
    isLoading,
    isUpdateEventsTags,
    dataUpdateEventsTags,
    isRefreshTableEventsTags,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalEventsTagsStateForCreate,
    onSubmitCreateOrUpdate,
    // updateStatus,
  }
}
