import { Button, Col, Row, Spin } from "antd";
import { FormProvider } from 'react-hook-form';
import { CiuuForm } from "./keyAccountManagersForm";
import { SaveOutlined } from "@ant-design/icons";
import UsekeyAccountManagersById from "../../hooks/resource/usekeyAccountManagerById";
import { useNavigate, useParams } from "react-router-dom";


export function CiuuById() {
  const navigate = useNavigate();

  const params = useParams();
  const { formMethods, handleSubmit, loading } = UsekeyAccountManagersById(
    params?.recordId ? Number(params.recordId) : null,
    {
      onSuccess() {
        navigate("/ciuu");
      },
    }
  );

  return (
    <>
      <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit}>
              <Row gutter={[8, 16]} justify={"space-between"}>
                <Col xs={24}>
                  <CiuuForm />
                </Col>
                <Col xs={24}>
                  <Button
                    icon={<SaveOutlined />}
                    block
                    loading={loading}
                    htmlType="submit"
                    type="primary"
                  >
                    Guardar
                  </Button>
                </Col>
              </Row>
            </form>
          </FormProvider>
  </>
  )
}
