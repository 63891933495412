
export const COMPANIES_CHANGE_MODAL_STATE = '[ COMPANIES ] COMPANIES Change Modal State'

export interface ICompaniesChangeModalState {
    type: typeof COMPANIES_CHANGE_MODAL_STATE
}

export const GET_COMPANIES_FOR_UPDATE = '[ COMPANIES ] Get COMPANIES For Update'

export interface IGetCompaniesForUpdate {
    type: typeof GET_COMPANIES_FOR_UPDATE
    payload: any
}

export const IS_COMPANIES_FOR_UPDATE = '[ COMPANIES ] Is COMPANIES For Update'
export const COMPANIES_MODAL_STATE = '[ COMPANIES ] Is COMPANIES For cahge state'

export interface IIsCompaniesForUpdate {
    type: typeof IS_COMPANIES_FOR_UPDATE
    payload: boolean
}

export const SWITCH_COMPANIES_REFRESH_TABLE = '[ COMPANIES ] Switch COMPANIES Refresh Table'

export interface ISwitchCompaniesRefreshTable {
    type: typeof SWITCH_COMPANIES_REFRESH_TABLE
    payload: boolean
}

export const SWITCH_COMPANIES_PRODUCTS_REFRESH_TABLE = '[ COMPANIES ] Switch COMPANIES Products Refresh Table'

export interface ISwitchCompaniesProductsRefreshTable {
    type: typeof SWITCH_COMPANIES_PRODUCTS_REFRESH_TABLE
    payload: boolean
}
