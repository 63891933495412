import { message } from "antd";
import { useEffect, useState } from "react";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { changeModalStateWebinarsBenefits, createWebinarBenefits, refreshTableWebinarsBenefits, selectIsUpdateWebinarsBenefits, selectRefreshTableWebinarsBenefits, selectSingleWebinars, selectSingleWebinarsBenefits, setIsWebinarsBenefitsForUpdate, updateWebinarBenefits } from "../../../../redux";
import { IModelWebinarsBenefits } from "../../@types";
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { validateCreateWebinarsBenefits, validateUpdateWebinarsBenefits } from "../../../../shared/utils/yupValidation";

export function useWebinarsBenefits() {
  const dispatch = useAppDispatch();

  const isUpdateWebinarsBenefits = useSelector(
      selectIsUpdateWebinarsBenefits
  );

  const dataUpdateWebinarsBenefits = useSelector(
      selectSingleWebinarsBenefits
  );

  const isRefreshTableWebinarsBenefits = useSelector(
      selectRefreshTableWebinarsBenefits
  );

  const dataUpdateWebinars = useSelector( selectSingleWebinars );

  const [isLoading, setIsLoading] = useState(false);

  const changeModalWebinarsBenefitsStateForCreate = () => {
    dispatch(setIsWebinarsBenefitsForUpdate(false))
    dispatch(changeModalStateWebinarsBenefits())
  }

  const formMethodsCreate = useForm<IModelWebinarsBenefits>({
    resolver: yupResolver(validateCreateWebinarsBenefits),
  });

  const formMethodsUpdate = useForm<IModelWebinarsBenefits>({
    resolver: yupResolver(validateUpdateWebinarsBenefits),
  });

  useEffect(() => {
  if (isUpdateWebinarsBenefits) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateWebinarsBenefits });
  }

  return () => {
      formMethodsUpdate.reset();
  };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateWebinarsBenefits, dataUpdateWebinarsBenefits]);

  const onSubmitCreateOrUpdate = async (data: IModelWebinarsBenefits) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateWebinarsBenefits) {
      await updateWebinarBenefits({
        id: data.id,
        name: data.name,
        position: data.position,
        webinar_id: dataUpdateWebinars?.id,
      })
        .then((res: Partial<IModelWebinarsBenefits>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito la beneficios de webinar con id:${res.id}`
            );
            dispatch(refreshTableWebinarsBenefits(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    } else {
      await createWebinarBenefits({...data, webinar_id: dataUpdateWebinars?.id})
        .then((res: IModelWebinarsBenefits) => {
          if (res.id) {
            dispatch(changeModalStateWebinarsBenefits());
            message.success(
              `Se ha creado con éxito la beneficios de webinar con id:${res.id}`
            );
            dispatch(refreshTableWebinarsBenefits(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableWebinarsBenefits) {
      dispatch(refreshTableWebinarsBenefits(false));
    }
  }, [isRefreshTableWebinarsBenefits]);

  return{
    //state
    isLoading,
    isUpdateWebinarsBenefits,
    dataUpdateWebinarsBenefits,
    isRefreshTableWebinarsBenefits,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalWebinarsBenefitsStateForCreate,
    onSubmitCreateOrUpdate,
  }
}
