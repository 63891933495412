import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { useProjectsObjectives } from "../../hooks/projects-objectives/useProjectsObjectives";
import { selectModalProjectsObjectives } from "../../../../redux";
import { ModalProjectsObjectives } from "./ModalProjectsObjectives";
import { TableProjectsObjectives } from "./TableProjectsObjectives";

export function ProjectsObjectives() {
  const modalProjectsObjectives = useSelector(
    selectModalProjectsObjectives
  );

  const {
    //state
    isUpdateProjectsObjectives,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalProjectsObjectivesStateForCreate,
  } = useProjectsObjectives();

  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalProjectsObjectivesStateForCreate}
            >
              Agregar nuevo objetivo a Proyectos
            </Button>
        </Col>
        <TableProjectsObjectives />
        {
            modalProjectsObjectives && (
              <FormProvider { ...isUpdateProjectsObjectives ?{...formMethodsUpdate} : {...formMethodsCreate}}>
                <ModalProjectsObjectives />
              </FormProvider>
            )
        }
    </Row>
  )
}
