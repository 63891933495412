import { IModelNews } from "../../modules";
import { INewsState } from "../@types/news";
import { newsActions } from "../action-types";


type newsActionType =
| { type: typeof newsActions.NEWS_MODAL_STATE; }
| { type: typeof newsActions.GET_NEWS_FOR_UPDATE; payload: IModelNews }
| { type: typeof newsActions.IS_NEWS_FOR_UPDATE; payload: boolean }
| { type: typeof newsActions.SWITCH_NEWS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: INewsState = {
    newsModalIsOpen: false,
    isUpdateNews: false,
    newsForUpdate: null,
    refreshTableNews: false,
}

export default function newsReducer(state: INewsState = INITIAL_STATE, action: newsActionType): INewsState {
  switch (action.type) {
    case newsActions.NEWS_MODAL_STATE:
        return {
            ...state,
            newsModalIsOpen: !state.newsModalIsOpen,
        }
    case newsActions.GET_NEWS_FOR_UPDATE:
        return {
            ...state,
            newsForUpdate: action.payload,
        }
    case newsActions.IS_NEWS_FOR_UPDATE:
        return {
            ...state,
            isUpdateNews: action.payload,
        }
    case newsActions.SWITCH_NEWS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableNews: action.payload,
        }
    default:
        return state
  }
}
