import moment from "moment";
import { formsBrandService, formsService } from "../../../shared";
import Table from "../../../shared/components/ui/table/TableCustom";
import { IForm } from "../../../@types/dto/forms";
import { Button, Space } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function Forms() {
  const columns = [
    { title: "ID", dataIndex: "id", sorter: true, filterType: "text" },
    { title: "Nombre", dataIndex: "meta_user_first_name", filterType: "text" },
    { title: "Apellidos", dataIndex: "meta_user_last_name", filterType: "text" },
    { title: "Email", dataIndex: "meta_user_email", filterType: "text" },
    { title: "Marca", dataIndex: "brand", filterType: "text" },
    { title: "N° documento", dataIndex: "meta_user_dni", filterType: "text" },
    { title: "Teléfono", dataIndex: "meta_user_phone", filterType: "text" },
    // {
    //   title: "Total campos",
    //   render: (record: IForm) =>
    //     Object.entries(JSON?.parse(record?.form_data)).length,
    // },
    {
      title: "Fecha",
      dataIndex: "createdAt",
      sorter: true,
      filterType: "date_range",
      render: (date: string) => moment(date).format("DD-MM-YYYY"),
    },
    // {
    //   title: "Acciones",
    //   render: (record: IForm) => (
    //     <Space>
    //       <Link to={`/formularios/${record.id}`}>
    //         <Button icon={<EyeOutlined />} size="small" type="link" />
    //       </Link>
    //     </Space>
    //   ),
    // },
  ]
  return (
    <Table
      service={formsBrandService}
      download
      columns={columns}
    />
  );
}
