import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import { Button, Col, Typography, Avatar } from "antd";
import { UseCompanies, useTableCompanies } from "../../hooks";
import { TableCustom, CompaniesService, CompanyUsersService } from "../../../../shared";
import { PATH_S3_BUCKET } from "../../../../shared/utils/constants";
import { IModelCompaniesUser } from "../../@types";
import {
  TableColumns,
  TableProperties,
} from "../../../../shared/components/ui/table/types";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const { Title } = Typography;

export function TableCompanies() {
  const {
    //state
    //methods
    //functions
    // changeCompaniesForUpdate,
    showDeleteConfirm,
  } = useTableCompanies();

  const {
    //state
    isRefreshTableCompanies,
    isLoading,
    updateStatus,
  } = UseCompanies();

  const tableRef = React.useRef<TableProperties>();

  React.useEffect(() => {
    tableRef.current?.refresh();
  }, [isRefreshTableCompanies]);

  const navigate = useNavigate();

  const columns: TableColumns<any> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: "left",
      sorter: true,
    },
    {
      title: "Compañía",
      dataIndex: ["company", "legal_name"],
      align: "left",
    },
    {
      title: "Nit",
      dataIndex: ["company", "dni"],
      align: "left",
    },
    {
      title: "Nombre",
      dataIndex: ['user', 'first_name'],
      align: "left",
    },
    {
      title: "Apellido",
      dataIndex: ['user', 'last_name'],
      align: "left",
    },
    {
      title: "Email",
      dataIndex: ['user', 'email'],
      align: "left",
    },
    {
      title: "Actions",
      key: "operation",
      fixed: "right",

      render: (_: any, item: any) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            flexDirection: "row",
            gap: 4,
          }}
        >
          <Button
            type="primary"
            shape="circle"
            onClick={() => navigate(`/company/${companyId}/users/${item.id}`)}
            icon={<EditOutlined />}
            size="large"
          />
          <Button
                type="primary"
                danger
                onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
                shape="circle"
                icon={<DeleteOutlined />}
                size="large"
              />
        </Col>
      ),
    },
  ];

  const {companyId} = useParams()

  return (
    <>
    <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
      <Button onClick={() => navigate(`/company/${companyId}/users/new`)} type="primary">Agregar nuevo</Button>
    </div>
    <TableCustom columns={columns} service={CompanyUsersService} ref={tableRef} fetchQueryProps={{company_id: companyId}}/>
    </>
  );
}
