import { Button, Drawer } from "antd";
import CreateKeywordSearch from "../modules/keyword-search/ui/CreateKeywordSearch";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";

export default function CreateKeywordSearchPage() {
  return (
    <Drawer
      title="Crear palabra clave"
      width={350}
      open
      destroyOnClose
      closeIcon={
        <Link to="/buscador/palabras-claves">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <CreateKeywordSearch />
    </Drawer>
  );
}
