import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { Link, useParams } from "react-router-dom";
import EditPressResourceCategory from "../modules/press/ui/edit-press-resource-category";

export default function EditPressResourceCategoryPage() {
  const {recordId} = useParams()

  return (
    <Drawer
      title="Editar recurso"
      open
      width={350}
      destroyOnClose
      closeIcon={
        <Link to="/press/resources/categories">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <EditPressResourceCategory recordId={recordId ? +recordId : null} />
    </Drawer>
  );
}
