import { Button, Drawer } from "antd";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { InfographiesById } from "../modules/virtual-library";

export function InfographiesIdPage() {
  return (
    <Drawer
      title="Editar biblioteca virtual"
      open
      width={350}
      destroyOnClose
      closeIcon={
        <Link to="/virtual-library">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <InfographiesById />
    </Drawer>
  )
}
