import { useFormContext } from "react-hook-form";
import {  Col, Row, Typography } from "antd";
import { IModelUsers } from "../../@types";
import {
  InputNumber,
  InputPassword,
  InputSelect,
  InputText,
} from "../../../../shared";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux";

const { Title } = Typography;

export function UsersFormCreate() {
  const {control, watch} = useFormContext<IModelUsers>()
  
  const dataUsers =  watch()

  const user = useSelector(selectUser);


  return (
    <>
       
        <Row justify="start" >

          <Col
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
            span={12}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Nombre:
            </Title>
            <InputText placeHolder={dataUsers?.first_name ?? ""} name="first_name" control={control} />
          </Col>
          <Col
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
            span={12}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Apellido:
            </Title>
            <InputText placeHolder={dataUsers?.last_name ?? ""} name="last_name" control={control} />
          </Col>
          <Col
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
            span={12}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Email:
            </Title>
            <InputText placeHolder="" name="email" control={control} />
          </Col>
            {user?.role === 'super_admin' && (
              <>
              <Col
                    span={12}
                    style={{
                        display: "flex",
                        marginTop: "10px",
                        padding: 5,
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                    }}
                >
                <Title style={{ marginBottom: "1.5px" }} level={5}>
                    Rol de Usuario:
                </Title>
                <InputSelect
                    name="role"
                    control={control}
                    dataOptions={[
                      // { title: "Super Admin", value: "super_admin" },
                      { title: "Admin", value: "admin" },
                      // { title: "User", value: "user" },
                    ]}
                    label="Rol"
                />
                </Col>
              <Col
                    span={12}
                    style={{
                        display: "flex",
                        marginTop: "10px",
                        padding: 5,
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                    }}
                >
                <Title style={{ marginBottom: "1.5px" }} level={5}>
                    Contraseña:
                </Title>
                  <InputPassword name="password" control={control} />
                </Col>
                </>
            )}
          
        </Row>
      
    </>
  );
}
