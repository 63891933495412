import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { Link } from "react-router-dom";
import CreateMarketingMaterial from "../modules/Corresponsales/ui/CreateMarketingMaterial";

export default function CreateCorresponsalesMarketingMaterialPage() {
  return (
    <Drawer
      title="Agregar material de marketing"
      open
      width={350}
      destroyOnClose
      closeIcon={
        <Link to="/corresponsales/material-de-marketing">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <CreateMarketingMaterial />
    </Drawer>
  );
}
