import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { UseCompanies } from "../../hooks";
import { TableCompanies } from "./TableCompanies";
import { Outlet } from "react-router-dom";

export function Companies() {
  const { changeNavigateForCreateCompanies } = UseCompanies();

  return (
    <Row
      gutter={[8, 8]}
      style={{
        padding: "10px",
        width: "100%",
      }}
    >
      <TableCompanies />
      <Outlet />
    </Row>
  );
}
