import { Typography } from "antd";
import CreatePressNewsletter from "../modules/press/ui/create-press-newsletter";
import { AdminLayout } from "../shared";

export default function CreatePressNewsletterPage() {
  return (
    <AdminLayout>
      <div style={{ width: "100%" }}>
        <Typography.Title level={3}>Crear boletín</Typography.Title>
        <CreatePressNewsletter />
      </div>
    </AdminLayout>
  );
}
