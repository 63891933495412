import React from 'react'
import { IStudyCertificateTemplate } from '../../../../@types/dto/study-certificate-template'
import { studyCertificateTemplatesService } from '../../../../shared'
import { message } from 'antd'

export default function useStudyCertificateTemplate(templateId?: number) {
  const [studyCertificateTemplate, setStudyCertificateTemplate] = React.useState<IStudyCertificateTemplate | null>(null)
  const [loading, setLoading] = React.useState(false)

  const loadingRef = React.useRef(false)

  const updateLoading = (value: boolean) => {
    setLoading(value)
    loadingRef.current = value
  }

  const getStudyCertificateTemplate = async () => {
    if (loadingRef.current || !templateId) {
      return
    }
    
    updateLoading(true)

    await studyCertificateTemplatesService.get(templateId)
      .then((response) => {
        setStudyCertificateTemplate(response)
      })
      .catch((error) => {
        message.error(error.message)
      })
      .finally(() => {
        updateLoading(false)
      })
  }

  React.useEffect(() => {
    getStudyCertificateTemplate()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId])

  return {
    studyCertificateTemplate,
    loading,
    refresh: getStudyCertificateTemplate,
  }
}