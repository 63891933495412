import { Button, Col, Row } from "antd"
import { PlusOutlined } from "@ant-design/icons";
import { useCourses } from "../../hooks";
import { TableCourses } from "./TableCourses";

export function Courses() {
  const { changeNavigateForCreateCourse } = useCourses()

  return (
    <Row
      gutter={[8, 8]}
      style={{
      padding: "10px",
      width: "100%",
      }}
      >
          <Col
              span={24}
              style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              }}
          >
              <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeNavigateForCreateCourse}
              >
              Agregar nuevo curso
              </Button>
          </Col>
          <TableCourses />
      </Row>
  )
}
