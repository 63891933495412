import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { TableNews } from "./TableNews";
import { useNews } from "../hooks";

export function News() {
  const { changeNavigateForCreateNew } = useNews()

  return (
    <Row
    gutter={[8, 8]}
    style={{
    padding: "10px",
    width: "100%",
    }}
    >
        <Col
            span={24}
            style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            }}
        >
            <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
            onClick={changeNavigateForCreateNew}
            >
            Agregar nueva noticias
            </Button>
        </Col>
        <TableNews />
    </Row>
  )
}
