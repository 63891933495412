import { useEffect, useState } from "react";
import { message } from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { createNew, getSingleNews, refreshTableNews, selectIsUpdateNews, selectRefreshTableNews, selectSingleNews, setIsNewsForUpdate, updateNew } from "../../../redux";
import { IModelNews } from "../@types";
import { validateCreateNews, validateUpdateNews } from "../../../shared/utils/yupValidation";
import { useNavigate } from "react-router-dom";
import moment from "moment";


export function useNews() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isUpdateNews = useSelector(
      selectIsUpdateNews
    );
    const dataUpdateNews = useSelector(
      selectSingleNews
    );
    const isRefreshTableNews = useSelector(
      selectRefreshTableNews
    );

    const [isLoading, setIsLoading] = useState(false);

    const changeNavigateForCreateNew = () => {
        dispatch(setIsNewsForUpdate(false))
        navigate(`/news/new`);
    }

    const formMethodsCreate = useForm<IModelNews>({
        resolver: yupResolver(validateCreateNews),
    });

    const formMethodsUpdate = useForm<IModelNews>({
        resolver: yupResolver(validateUpdateNews),
    });

    useEffect(() => {
        if (isUpdateNews) {
            formMethodsCreate.reset();
            formMethodsUpdate.reset({ ...dataUpdateNews, published_date: moment(dataUpdateNews?.published_date).utcOffset(0) });
        }

        return () => {
            formMethodsUpdate.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdateNews, dataUpdateNews]);

    const updateStatus = async(value: "active" | "inactive", id: any) =>{
      if (isLoading) return;
      setIsLoading(true);
      await updateNew({
        id,
        status: value,
      })
      .then((res: Partial<IModelNews>) => {
        setIsLoading(false);
        if (res.id) {
          message.success(
            `Se ha actualizado con éxito el estado de la noticia con id:${res.id}`
          );
          dispatch(refreshTableNews(true));
        } else {
          message.error(res as string);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
        message.error(err.message);
      });
    }

    const onSubmitCreateOrUpdate = async (data: IModelNews) => {
        // console.log({ data });
        if (isLoading) return;
        setIsLoading(true);
        if (isUpdateNews) {
          await updateNew({...data})
            .then((res: Partial<IModelNews>) => {
              if (res.id) {
                message.success(
                  `Se ha actualizado con éxito la noticia con id:${res.id}`
                );
                dispatch(getSingleNews(res as any))
                dispatch(refreshTableNews(true));
              } else {
                message.error(res as string);
              }
            })
            .catch((err: any) => {
              console.log(err);
              message.error(err.message);
            })
            .finally(()=>{
                setIsLoading(false);
            });
        } else {
          await createNew({...data})
            .then((res: IModelNews) => {
              if (res.id) {
                //navegar a la tabla
                // dispatch(changeModalStateNews());
                message.success(`Se ha creado con éxito la noticia con id:${res.id}`);
                dispatch(refreshTableNews(true));
                navigate(`/news`)
                window.scrollTo(0, 0)
              } else {
                message.error(res as any);
              }
            })
            .catch((err: any) => {
              console.log(err);
              message.error(err.message);
            })
            .finally(()=>{
                setIsLoading(false);
            });
        }
    };

    useEffect(() => {
        if (isRefreshTableNews) {
          dispatch(refreshTableNews(false));
        }
      }, [isRefreshTableNews]);

  return{
    //state
    isLoading,
    isUpdateNews,
    dataUpdateNews,
    isRefreshTableNews,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeNavigateForCreateNew,
    onSubmitCreateOrUpdate,
    updateStatus,
  }
}
