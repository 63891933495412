import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { Link } from "react-router-dom";
import CreatePressResourceCategory from "../modules/press/ui/create-press-resource-category";

export default function CreatePressResourceCategoryPage() {
  return (
    <Drawer
      title="Agregar categoría"
      open
      width={350}
      destroyOnClose
      closeIcon={
        <Link to="/press/resources/categories">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
      >
        <CreatePressResourceCategory />
      </Drawer>
  );
}