import type { ICorresponsalesFormsAndModels } from "../../../../../@types/dto/corresponsales-forms-and-models";

import { Button, Col, Row, Typography, theme } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import {
  InputSelectDynamic,
  InputText,
  corresponsalesFormsAndModelsCategoriesService,
} from "../../../../../shared";
import FileUPload from "../../../../../shared/components/ui/FileUpload";
import { PATH_S3_BUCKET } from "../../../../../shared/utils/constants";
import { DownloadOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import React from "react";

export default function FormsAndModelsForm() {
  const [selectProps, setSelectProps] = React.useState<any>();

  const { control } = useFormContext<ICorresponsalesFormsAndModels>();

  const location = useLocation();

  const themeToken = theme.useToken();

  React.useEffect(() => {
    selectProps?.refresh?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Row gutter={[8, 16]}>
      <Col span={24}>
        <InputText
          labelText="Titulo"
          control={control}
          name="title"
          placeHolder="Titulo"
        />
      </Col>
      <Col span={24}>
        <InputSelectDynamic
          onLoad={setSelectProps}
          labelText="Categoría"
          serviceName={corresponsalesFormsAndModelsCategoriesService}
          control={control}
          labelName="name"
          valName="id"
          name="category_id"
          placeHolder="Categoría"
        />
      </Col>
      <Col span={24}>
        <Typography.Text style={{ fontWeight: 600 }}>
          Subir archivo
        </Typography.Text>
        <Controller
          control={control}
          name="resource_path"
          shouldUnregister
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div>
              <FileUPload
                path={`corresponsales/forms-and-models`}
                onUploadFinish={({ fileKey }) => {
                  onChange(fileKey);
                }}
              />
              {!!value && (
                <Button
                  style={{ marginTop: 10 }}
                  block
                  type="primary"
                  href={`${PATH_S3_BUCKET}/${value}`}
                  target="_blank"
                  icon={<DownloadOutlined />}
                >
                  Descargar
                </Button>
              )}
              {!!error && (
                <Typography.Text style={{ color: themeToken.token.colorError }}>
                  {error.message}
                </Typography.Text>
              )}
            </div>
          )}
        />
      </Col>
    </Row>
  );
}
