import moment from "moment";
import { useEffect, useState } from "react";
import { message } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  createCourse,
  getSingleCourse,
  refreshTableCourse,
  selectIsUpdateCourses,
  selectRefreshTableCourses,
  selectSingleCourses,
  setIsCoursesAuthorsForUpdate,
  updateCourse,
} from "../../../../redux";
import { IModelCourses } from "../../@types";
import {
  validateCreateCourses,
  validateUpdateCourses,
} from "../../../../shared/utils/yupValidation";

export function useCourses() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isUpdateCourses = useSelector(selectIsUpdateCourses);

  const dataUpdateCourses = useSelector(selectSingleCourses);

  const isRefreshTableCourses = useSelector(selectRefreshTableCourses);

  const [isLoading, setIsLoading] = useState(false);

  const changeNavigateForCreateCourse = () => {
    dispatch(setIsCoursesAuthorsForUpdate(false));
    navigate(`/course/new`);
  };

  const formMethodsCreate = useForm<IModelCourses>({
    resolver: yupResolver(validateCreateCourses),
  });

  const formMethodsUpdate = useForm<IModelCourses>({
    resolver: yupResolver(validateUpdateCourses),
  });

  useEffect(() => {
    if (isUpdateCourses) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({
        ...dataUpdateCourses,
        start_date: moment(dataUpdateCourses?.start_date).utcOffset(0),
      });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateCourses, dataUpdateCourses]);

  const updateStatus = async (value: "active" | "inactive", id: any) => {
    if (isLoading) return;
    setIsLoading(true);
    await updateCourse({
      id,
      status: value,
    })
      .then((res: Partial<IModelCourses>) => {
        setIsLoading(false);
        if (res.id) {
          message.success(
            `Se ha actualizado con éxito el estado del curso con id:${res.id}`
          );
          dispatch(refreshTableCourse(true));
        } else {
          message.error(res as unknown as string);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
        message.error(err.message);
      });
  };

  const onSubmitCreateOrUpdate = async (data: IModelCourses) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateCourses) {
      await updateCourse({ ...data })
        .then((res: Partial<IModelCourses>) => {
          if (res.id) {
            message.success(
              `Se ha actualizado con éxito el curso con id:${res.id}`
            );
            dispatch(getSingleCourse(res as any));
            dispatch(refreshTableCourse(true));
          } else {
            message.error(res as unknown as string);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await createCourse({ ...data })
        .then((res: IModelCourses) => {
          if (res.id) {
            //navegar a la tabla
            // dispatch(changeModalStateCourses());
            message.success(`Se ha creado con éxito el curso con id:${res.id}`);
            dispatch(refreshTableCourse(true));
            navigate(`/course`);
            window.scrollTo(0, 0);
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableCourses) {
      dispatch(refreshTableCourse(false));
    }
  }, [isRefreshTableCourses]);

  return {
    //state
    isLoading,
    isUpdateCourses,
    dataUpdateCourses,
    isRefreshTableCourses,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeNavigateForCreateCourse,
    onSubmitCreateOrUpdate,
    updateStatus,
  };
}
