import { webinarsRatingsService } from "../../../shared";
import Table from "../../../shared/components/ui/table/TableCustom";

export default function WebinarsRatings() {
  return (
    <Table
      download
      service={webinarsRatingsService}
      columns={[
        {
          title: "ID del webinar",
          dataIndex: "webinar_id",
          sorter: true,
          filterType: "text",
        },
        {
          title: "Webinar",
          dataIndex: ["webinar", "name"],
        },
        {
          title: "Usuario",
          render: (record: any) => {
            return `${record.meta_user_first_name} ${record.meta_user_last_name}`;
          },
        },
        {
          title: "Calificación",
          dataIndex: "rating",
          sorter: true,
          filterType: "number_range",
        },
        {
          title: "Mensaje",
          dataIndex: "text",
          render: (message: string) => `${message.substring(0, 60)}...`,
        },
        {
          title: "Satisfacción de temática",
          dataIndex: "topic_satisfaction",
          sorter: true,
          filterType: "number_range",
        },
        {
          title: "Calificación del expositor",
          dataIndex: "speaker_rating",
          sorter: true,
          filterType: "number_range",
        },
        {
          title: "Calificación del espacio",
          dataIndex: "connection_space_rating",
          sorter: true,
          filterType: "number_range",
        },
        {
          title: "Probabilidad de recomendación",
          dataIndex: "likelihood_to_recommend",
          sorter: true,
          filterType: "number_range",
        },
      ]}
      fetch$ClientProps={{ withWebinar: "true" }}
    />
  );
}
