import { message } from "antd";

import { registryServicesConsultNewsService } from "../../shared";
import { RegistryServicesConsultNewsActions } from "../action-types";
import { IModelRegistryServicesConsultNews } from "../../modules/registry-services-consult-news";

export const getSingleRegistryServicesConsultNew = (val:IModelRegistryServicesConsultNews ): RegistryServicesConsultNewsActions.IGetRegistryServicesConsultNewForUpdate=> ({
    type: RegistryServicesConsultNewsActions.GET_REGISTRY_SERVICES_CONSULT_NEWS_FOR_UPDATE,
    payload: val
})

export const setIsRegistryServicesConsultNewForUpdate =(val: boolean): RegistryServicesConsultNewsActions.IIsRegistryServicesConsultNewForUpdate =>({
    type: RegistryServicesConsultNewsActions.IS_REGISTRY_SERVICES_CONSULT_NEWS_FOR_UPDATE,
    payload: val,
})

export const fetchSingleRegistryServicesConsultNew = async(id: number) =>{
    return await registryServicesConsultNewsService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createRegistryServicesConsultNew = async(data: IModelRegistryServicesConsultNews) =>{
    return await registryServicesConsultNewsService.create(data)
    .then((res: IModelRegistryServicesConsultNews)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateRegistryServicesConsultNew = async(data: IModelRegistryServicesConsultNews) =>{
    return await registryServicesConsultNewsService.patch(data?.id!, data)
    .then((res: any)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteRegistryServicesConsultNew = async(id: number) =>{
    return await registryServicesConsultNewsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableRegistryServicesConsultNew = (val: boolean): RegistryServicesConsultNewsActions.ISwitchRegistryServicesConsultNewRefreshTable =>({
    type: RegistryServicesConsultNewsActions.SWITCH_REGISTRY_SERVICES_CONSULT_NEWS_REFRESH_TABLE,
    payload: val
})

