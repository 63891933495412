import { Typography } from "antd";
import { useParams } from "react-router-dom";
import EditPressNewsletter from "../modules/press/ui/edit-press-newsletter";
import { AdminLayout } from "../shared";

export default function EditPressNewsletterPage() {
  const { recordId } = useParams();

  return (
    <AdminLayout>
      <div style={{ width: "100%" }}>
        <Typography.Title level={3}>Editar boletín</Typography.Title>
        <EditPressNewsletter recordId={recordId ? +recordId : null} />
      </div>
    </AdminLayout>
  );
}
