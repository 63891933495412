
import { IModelWebinarsRequirements } from '../../modules';
import { IWebinarsRequirementsState } from '../@types/webinarsRequirements';

import { webinarsRequirementsActions } from '../action-types'

type webinarsRequirementsActionType =
| { type: typeof webinarsRequirementsActions.WEBINARS_REQUIREMENTS_MODAL_STATE; }
| { type: typeof webinarsRequirementsActions.GET_WEBINARS_REQUIREMENTS_FOR_UPDATE; payload: IModelWebinarsRequirements }
| { type: typeof webinarsRequirementsActions.IS_WEBINARS_REQUIREMENTS_FOR_UPDATE; payload: boolean }
| { type: typeof webinarsRequirementsActions.SWITCH_WEBINARS_REQUIREMENTS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IWebinarsRequirementsState = {
    webinarsRequirementsModalIsOpen: false,
    isUpdateWebinarsRequirements: false,
    webinarsRequirementsForUpdate: null,
    refreshTableWebinarsRequirements: false,
}

export default function webinarsRequirementsReducer(state: IWebinarsRequirementsState = INITIAL_STATE, action: webinarsRequirementsActionType): IWebinarsRequirementsState {
  switch (action.type) {
    case webinarsRequirementsActions.WEBINARS_REQUIREMENTS_MODAL_STATE:
        return {
            ...state,
            webinarsRequirementsModalIsOpen: !state.webinarsRequirementsModalIsOpen,
        }
    case webinarsRequirementsActions.GET_WEBINARS_REQUIREMENTS_FOR_UPDATE:
        return {
            ...state,
            webinarsRequirementsForUpdate: action.payload,
        }
    case webinarsRequirementsActions.IS_WEBINARS_REQUIREMENTS_FOR_UPDATE:
        return {
            ...state,
            isUpdateWebinarsRequirements: action.payload,
        }
    case webinarsRequirementsActions.SWITCH_WEBINARS_REQUIREMENTS_REFRESH_TABLE:
        return {
            ...state,
            refreshTableWebinarsRequirements: action.payload,
        }
    default:
        return state
  }
}
