
export const KEY_ACCOUNT_MANAGERS_CHANGE_MODAL_STATE = '[ keyAccountManagers ] keyAccountManagers Change Modal State'

export interface IkeyAccountManagersChangeModalState {
    type: typeof KEY_ACCOUNT_MANAGERS_CHANGE_MODAL_STATE
}

export const GET_BANNER_FOR_UPDATE = '[ KEYACCOUNTMANAGERS ] Get Banner For Update'
export const GET_KEY_ACCOUNT_MANAGERS_FOR_UPDATE = '[ KEYACCOUNTMANAGERS ] Get Banner For Update'
export const IS_KEY_ACCOUNT_MANAGERS_FOR_UPDATE = '[ KEYACCOUNTMANAGERS ] Get Banner For Update'

export interface IGetkeyAccountManagersForUpdate {
    type: typeof GET_BANNER_FOR_UPDATE
    payload: any
}
export interface IGetkeyAccountManagersForUpdate {
    type: typeof GET_KEY_ACCOUNT_MANAGERS_FOR_UPDATE
    payload: any
}

export const IS_BANNER_FOR_UPDATE = '[ KEYACCOUNTMANAGERS ] Is Banner For Update'

export interface IIskeyAccountManagersForUpdate {
    type: typeof IS_KEY_ACCOUNT_MANAGERS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_KEY_ACCOUNT_MANAGERS_REFRESH_TABLE = '[ KEYACCOUNTMANAGERS ] Switch Banners Refresh Table'

export interface ISwitchkeyAccountManagersRefreshTable {
    type: typeof SWITCH_KEY_ACCOUNT_MANAGERS_REFRESH_TABLE
    payload: boolean
}

export const SWITCH_KEY_ACCOUNT_MANAGERS_PRODUCTS_REFRESH_TABLE = '[ KEYACCOUNTMANAGERS ] Switch Banners Products Refresh Table'

export interface ISwitchkeyAccountManagersProductsRefreshTable {
    type: typeof SWITCH_KEY_ACCOUNT_MANAGERS_PRODUCTS_REFRESH_TABLE
    payload: boolean
}
