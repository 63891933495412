import { useFormContext } from "react-hook-form";
import { Button, Col, Row, Typography } from "antd";
import { IModelCompaniesUser } from "../../@types";
import {
  CompaniesService,
  CompanyUsersJobAreaPosicionService,
  CompanyUsersJobAreaService,
  InputNumber,
  InputSelect,
  InputSelectDynamic,
  InputText,
  clustersService,
  keyAccountManagersService,
  usersService,
} from "../../../../shared";

const { Title } = Typography;

export function CompaniesForm() {
  const { control, watch } = useFormContext<any>();

  const area = watch('company_users_job_title_area_id')

  return (
    <>
      <Row justify="start">
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Compañía:
          </Title>
          <InputSelectDynamic
            name="company_id"
            control={control}
            serviceName={CompaniesService}
            labelName="legal_name"
            valName="id"
          />
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Usuario:
          </Title>
          <InputSelectDynamic
            name="user_id"
            control={control}
            serviceName={usersService}
            labelName="first_name" valName="id" labelName2="last_name"
          />
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Rol:
          </Title>
          <InputSelect
            name="role"
            control={control}
            dataOptions={[
              { title: "Super admin", value: "super_admin" },
              { title: "Admin", value: "admin" },
              { title: "Empleado", value: "employee" },
              { title: "Rol pendiente", value: "pending_role" },
            ]}
            label="Tipo"
          />
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Estado:
          </Title>
          <InputSelect
            name="access_status"
            control={control}
            dataOptions={[
              { title: "Pendiente de validación", value: "pending_validation" },
              { title: "Activo", value: "active" },
              { title: "Inactivo", value: "inactive" },
              { title: "Rechazado", value: "rejected" },
            ]}
            label="Estado"
          />
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Area de trabajo:
          </Title>
          <InputSelectDynamic
            name="company_users_job_title_area_id"
            control={control}
            serviceName={CompanyUsersJobAreaService}
            labelName="title" valName="id"
          />
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Posición Area de trabajo:
          </Title>
          <InputSelectDynamic
            name="company_users_job_title_area_position_id"
            control={control}
            queryFetch={{company_users_job_title_area_id: area}}
            disabled={!area}
            serviceName={CompanyUsersJobAreaPosicionService}
            labelName="title" valName="id"
          />
        </Col>
      </Row>
    </>
  );
}
