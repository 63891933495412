import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useResources } from "../../hooks";
import { TableEvents } from "./TableResource";

export function Resources() {
  const { changeNavigateForCreateEvent } = useResources();

  return (
    <Row
      gutter={[8, 8]}
      style={{
        padding: "10px",
        width: "100%",
      }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          gap: 10,
        }}
      >
        <Button type="primary" shape="round" size="large">
          <a
            href="https://camarabaq.org.co/servicios-registrales/recursos-administrativos-presentados"
            target="_blank"
          >
            Ver Pagina
          </a>
        </Button>
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          size="large"
          onClick={changeNavigateForCreateEvent}
        >
          Agregar nuevo recurso
        </Button>
      </Col>
      <TableEvents />
    </Row>
  );
}
