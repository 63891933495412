import { useSelector } from "react-redux";
import { WrapperModalNewsCategories } from "./styled";
import { selectModalNewsCategories } from "../../../redux";
import { useNewsCategories } from "../hooks";
import { Button, Col, Typography } from "antd";
import { InputNumber, InputText } from "../../../shared";
import { useFormContext } from "react-hook-form";
import { IModelNewsCategories } from "../@types";

const { Title } = Typography;

export function ModalNewsCategories() {
    const modalNewsCategories = useSelector(
        selectModalNewsCategories
    );

    const {
        //state
        isLoading,
        isUpdateNewsCategories,
        dataUpdateNewsCategories,
        //methods
        //functions
        changeModalNewsCategoriesStateForCreate,
        onSubmitCreateOrUpdate
    } = useNewsCategories()

    const { control, handleSubmit: onSubmit } =
    useFormContext<IModelNewsCategories>();
  return (
    <WrapperModalNewsCategories
    title={
        isUpdateNewsCategories
          ? `Actualizando categoría de noticia: '${dataUpdateNewsCategories?.id!}'`
          : `Creando categoría de noticia`
      }
      open={modalNewsCategories}
      width={"320px"}
      destroyOnClose
      onClose={changeModalNewsCategoriesStateForCreate}>
        <Col
            span={24}
            style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            }}
        >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
            Nombre:
            </Title>
            <InputText placeHolder="Clusters" name="name" control={control} />
        </Col>
        {/* <Col
            span={24}
            style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            }}
        >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
                Categoría padre:
            </Title>
            <InputNumber
                // stringMode={true}
                placeHolder="123"
                name="parent_id"
                control={control}
                />
        </Col> */}
        <Button
            style={{
            width: "100%",
            marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
        >
            {isUpdateNewsCategories
            ? "Actualizar información"
            : "Crear categoría de trabajo"}
        </Button>
    </WrapperModalNewsCategories>
  )
}
