import { AdminLayout } from "../shared";
import { Button, Flex } from "antd";
import { Link, Outlet } from "react-router-dom";
import CmsLawTransparency from "../modules/cms-law-transparency-page/ui/cms-law-transparency";
import CmsLawTransparencyLayout from "../modules/cms-law-transparency-page/components/cms-law-transparency-layout";

export default function CmsLawTransparencyPage() {
  return (
    <AdminLayout>
      <CmsLawTransparencyLayout>
        <div style={{width: '100%'}}>
          <Flex justify="flex-end">
              <Link to="/ley-de-transparencia/agregar">
                <Button type="primary">+ Ley de transparencia</Button>
              </Link>
            </Flex>

            <CmsLawTransparency />
        </div>
      </CmsLawTransparencyLayout>
      <Outlet />
    </AdminLayout>
  );
}
