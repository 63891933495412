import { Button, Col, Row } from "antd"
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { UsekeyAccountManagers } from "../../hooks";
import { TablekeyAccountManagers } from "./TablekeyAccountManager";
import { Outlet, useNavigate } from "react-router-dom";
import { keyAccountManagersService } from "../../../../shared";


export function KeyAccountManagers() {
    const navigate = useNavigate();

    const handleDonwload = async () => {
        await keyAccountManagersService.find({
          query: {
            $client: {exportData: 'true'}
          }
        }).then((res) => {
        //   console.log(44, res?.data?.[0]?.objectUrl);
          window.open(res?.data?.[0]?.objectUrl)
        })
      }

    return (
    <Row
    gutter={[8, 8]}
    style={{
    padding: "10px",
    width: "100%",
    }}
    >
        <Col
            span={24}
            style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            gap: 5,
            }}
        >
            <Button
            type="primary"
            shape="round"
            icon={<DownloadOutlined />}
            size="large"
            onClick={() => handleDonwload()}
            >
            Descargar asociaciones
            </Button>
            <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
            onClick={() => navigate(`/key-account-managers/new`)}
            >
            Agregar nuevo key account managers
            </Button>
        </Col>
        <TablekeyAccountManagers />
        <Outlet />
    </Row>
    )
}
