import { FormProvider } from 'react-hook-form';
import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectModalEventsTags } from '../../../../redux';
import { useEventsTags } from '../../hooks';
import { TableEventsTags } from './TableEventsTags';
import { ModalEventsTags } from './ModalEventsTags';

export function EventsTags() {
  const modalEventsTags = useSelector(
    selectModalEventsTags
  );

  const {
    //state
    isUpdateEventsTags,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalEventsTagsStateForCreate,
  } = useEventsTags();

  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
            onClick={changeModalEventsTagsStateForCreate}
          >
            Agregar nuevo tags de eventos
          </Button>
        </Col>
        <TableEventsTags />
        {
            modalEventsTags && (
                <FormProvider { ...isUpdateEventsTags ?{...formMethodsUpdate} : {...formMethodsCreate}}>
                    <ModalEventsTags />
                </FormProvider>
            )
        }
    </Row>
  )
}
