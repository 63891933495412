import { useParams } from "react-router-dom"
import useUpdateUser from "../../hooks/useUpdateUser"
import { FormProvider } from "react-hook-form"
import { UsersForm } from "./UserForm"
import { Button, Col, Row } from "antd"
import Title from "antd/es/typography/Title"

export default function UpdateUser() {

    const {id} = useParams()

    const {formMethods, loading, handleSubmit} = useUpdateUser(id ? Number(id) : null, {
        onSuccess(res) {
            // TODO
        }
    })

    return (
        <Row>
            <Col>
                <Title style={{ marginBottom: "1.5px" }} level={2}>
                  Actualizar usuario
                </Title>
            </Col>
            <Col style={{minWidth: '100%'}}>
                <FormProvider {...formMethods}>
                    <form onSubmit={handleSubmit}>
                        <UsersForm />
                        <div>
                            <Button
                                style={{
                                width: "100%",
                                marginTop: 10,
                                }}
                                type="primary"
                                shape="round"
                                size="large"
                                htmlType="submit"
                                loading={loading}
                            >
                                Actuailzar
                            </Button>
                        </div>
                    </form>
                </FormProvider>
            </Col>
        </Row>
    )
}