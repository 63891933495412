
import { Button, Drawer } from "antd";
import { Link, useParams } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { CompaniyUserById } from "../modules/companies-user";

export const CompanyUserByidPage = () => {

  const {companyId} = useParams()
  return (
    <Drawer
      title="Editar"
      open
      width={350}
      destroyOnClose
      closeIcon={
        <Link to={`/company/${companyId}/users`}>
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <CompaniyUserById />
    </Drawer>
  );
};
