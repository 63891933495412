import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import { Button, Space, theme } from "antd";
import { IModelEvents } from "../../@types";
import { useEvents, useTableEvents } from "../../hooks";
import { TableCustom, eventsService } from "../../../../shared";
import {
  TableColumns,
  TableProperties,
} from "../../../../shared/components/ui/table/types";
import React from "react";
import { useNavigate } from "react-router-dom";

const ASSISTANCE_TYPES = {
  on_place: "Presencial",
  hybrid: "Híbrido",
  virtual: "Virtual",
  remote: "Remoto",
};

export function TableEvents() {
  const {
    //state
    //methods
    //functions
    changeEventsForUpdate,
    showDeleteConfirm,
  } = useTableEvents();

  const {
    //state
    isRefreshTableEvents,
    //methods
    //functions
    updateStatus,
  } = useEvents();

  const tableRef = React.useRef<TableProperties>();

  const themeToken = theme.useToken();

  React.useEffect(() => {
    tableRef.current?.refresh();
  }, [isRefreshTableEvents]);

  const navigate = useNavigate();

  const columns: TableColumns<IModelEvents> = [
    {
      title: "ID",
      dataIndex: "id",
      align: "left",
      sorter: true,
    },
    {
      title: "Nombre",

      dataIndex: "name",
      align: "left",
    },

    {
      title: "Fecha de inicio",

      dataIndex: "date_start",
      align: "left",
      render: (date: string) =>
        date ? (
          <b>{moment(date).utcOffset(0).format("DD/MM/YYYY HH:mm:ss")}</b>
        ) : (
          ""
        ),
    },
    {
      title: "Fecha de finalización",

      dataIndex: "date_end",
      align: "left",
      render: (date: string) =>
        date ? (
          <b>{moment(date).utcOffset(0).format("DD/MM/YYYY HH:mm:ss")}</b>
        ) : (
          ""
        ),
    },
    {
      title: "Estado",

      dataIndex: "status",
      key: "status",
      render: (status: string, item: any) => {
        return (
          <Button
            type="primary"
            shape="round"
            danger={status === "inactive"}
            onClick={() => {
              if (status === "active") {
                updateStatus("inactive", item.id);
              } else if (status === "inactive") {
                updateStatus("active", item.id);
              }
            }}
            style={
              status === "active"
                ? { backgroundColor: themeToken.token.colorSuccess }
                : {}
            }
          >
            {status === "active" ? "Activo" : "Inactivo"}
          </Button>
        );
      },
    },
    {
      title: "Tipo de asistencia",
      dataIndex: "assistance_type",
      align: "left",
      render: (assistanceType: keyof typeof ASSISTANCE_TYPES) => {
        return <b>{ASSISTANCE_TYPES[assistanceType]}</b>;
      },
    },
    { title: "Capacidad", dataIndex: "max_capacity" },
    { title: "Nro de inscripciones", dataIndex: "total_inscriptions" },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      render: (_: any, item: any) => {
        return (
          <Space>
            <Button
              type="primary"
              shape="circle"
              onClick={() => navigate(`/userevent/${item.id}`)}
              icon={<EyeOutlined />}
              size="large"
            />
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeEventsForUpdate(item.id)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Space>
        );
      },
    },
  ];

  return (
    <TableCustom columns={columns} service={eventsService} ref={tableRef} />
  );
}
