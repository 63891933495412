import { Button, Col, Typography } from "antd";
import { useFormContext } from 'react-hook-form';
import { useSelector } from "react-redux";
import { InputSelectDynamic, authorsService } from "../../../../shared";
import { selectModalCoursesAuthors } from "../../../../redux";
import { IModelCoursesAuthors } from "../../@types";
import { WrapperModalCourses } from "../styled";
import { useCoursesAuthors } from "../../hooks";

const { Title } = Typography;

export function ModalCoursesAuthors() {
  const modalCoursesAuthors = useSelector(
    selectModalCoursesAuthors
  );

  const {
    //state
    isLoading,
    isUpdateCoursesAuthors,
    dataUpdateCoursesAuthors,
    //methods
    //functions
    changeModalCoursesAuthorsStateForCreate,
    onSubmitCreateOrUpdate
  } = useCoursesAuthors()

  const { control, handleSubmit: onSubmit } = useFormContext<IModelCoursesAuthors>();

  return (
    <WrapperModalCourses
      title={ isUpdateCoursesAuthors
          ? `Actualizando categoría del curso: '${dataUpdateCoursesAuthors?.id!}'`
          : `Creando categoría del curso` }
      open={modalCoursesAuthors}
      width={"320px"}
      destroyOnClose
      onClose={changeModalCoursesAuthorsStateForCreate}>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}>
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Autor/a asignado/a:
          </Title>
          <InputSelectDynamic name="author_id" control={control} serviceName={authorsService} labelName="first_name" labelName2="last_name" valName="id"/>
        </Col>
        <Button
            style={{
            width: "100%",
            marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
        >
            {isUpdateCoursesAuthors
            ? "Actualizar información"
            : "Crear autor/a"}
        </Button>
    </WrapperModalCourses>
  )
}
