import { Button, Flex, Typography } from "antd";
import { AdminLayout } from "../shared";
import { Link, Outlet } from "react-router-dom";
import Infographics from "../modules/infographics/ui/infographics";

export default function InfographicsPage() {
  return (
    <AdminLayout>
      <Typography.Title>Infografias</Typography.Title>
      <div style={{ width: "100%" }}>
        <Flex justify="flex-end">
          <Link to="/infographics/new">
            <Button type="primary">+ Infografia</Button>
          </Link>
        </Flex>
        <Infographics />
        <Outlet />
      </div>
    </AdminLayout>
  );
}
