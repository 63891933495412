 
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Typography } from "antd";
import { useEventsCategories, useTableEventsCategories } from "../../hooks";
import { IModelEventsCategories } from "../../@types";
import { TableCustom, eventsCategoriesService } from "../../../../shared";
import { TableColumns, TableProperties } from "../../../../shared/components/ui/table/types";
import React from "react";

const {Title} = Typography

export function TableEventsCategories() {
  const {
    //state
    //methods
    //functions
    changeEventsCategoriesForUpdate,
    showDeleteConfirm,
  } =  useTableEventsCategories()
  const {
      //state
      isRefreshTableEventsCategories,
      //methods
      //functions
      // updateStatus,
  }= useEventsCategories()

  const tableRef = React.useRef<TableProperties>()

    React.useEffect(() => {
      tableRef.current?.refresh()
    }, [isRefreshTableEventsCategories])

  const columns: TableColumns<IModelEventsCategories> = [
    {
        title: 'ID',
        dataIndex: 'id',
        align: 'left',
        sorter:true
    },
    {
        title: 'Nombre',
        dataIndex: 'name',
        align: 'left',
    },
    // {
    //     title: "Estado",
    //     // 
    //     dataIndex: "status",
    //     key: "status",
    //     render: (_:any, item:any) => {
    //       return (
    //         <Col
    //           style={{
    //             display: "flex",
    //             width: "100%",
    //             justifyContent: "start",
    //             flexDirection: "row",
    //           }}
    //         >
    //           <button
    //           disabled={isLoading}
    //             onClick={()=>{
    //               if(item.status === "active"){
    //                 updateStatus("inactive", item.id)
    //               }else if( item.status === "inactive"){
    //                 updateStatus("active", item.id)
    //               }
    //             }}
    //             style={{
    //               display: "flex",
    //               padding: "5px 27px",
    //               borderWidth: 0,
    //               cursor: "pointer",
    //               borderRadius: "20px",
    //               backgroundColor:
    //                 item.status === "active" ? "#4bd542" : "#ff4d4f",
    //             }}
    //           >
    //             <Title
    //               style={{
    //                 marginBottom: "1.5px",
    //                 color: "white",
    //               }}
    //               level={5}
    //             >
    //               {item.status}
    //             </Title>
    //           </button>
    //         </Col>
    //       );
    //     },
    // },
    {
        title: 'Posición',
        dataIndex: 'position',
        align: 'left',
    },
    {
        title: "Acciones",
        key: "operation",
        fixed: "right",
        width: 140,
        render: (_:any, item:any) => {
          return (
            <Col
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "start",
                flexDirection: "row",
              }}
            >
              <Button
                type="primary"
                shape="circle"
                onClick={() => changeEventsCategoriesForUpdate(item.id)}
                icon={<EditOutlined />}
                size="large"
              />
              <Button
                type="primary"
                danger
                onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
                shape="circle"
                icon={<DeleteOutlined />}
                size="large"
              />
            </Col>
          );
        },
      },
  ]

  return (
  <TableCustom columns={columns}  service={eventsCategoriesService} ref={tableRef}/>
  )
}
