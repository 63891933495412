import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { Link } from "react-router-dom";
import CreateFormsAndModelsCategories from "../modules/Corresponsales/ui/CreateFormsAndModelsCategories";

export default function CreateCorresponsalesFormsAndModelsCategoriesPage() {
  return (
    <Drawer
      title="Agregar categoría"
      open
      width={350}
      destroyOnClose
      closeIcon={
        <Link to="/corresponsales/formatos-y-modelos-categorias">
          <Button icon={<CloseOutlined />} type="text" />
        </Link>
      }
    >
      <CreateFormsAndModelsCategories />
    </Drawer>
  );
}
