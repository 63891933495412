import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { selectModalEventsAuthors } from "../../../../redux";
import { useEventsAuthors } from "../../hooks";
import { TableEventsAuthors } from "./TableEventsAuthors";
import { ModalEventsAuthors } from "./ModalEventsAuthors";

export function EventsAuthors() {
  const modalEventsAuthors = useSelector(
    selectModalEventsAuthors
);

const {
    //state
    isUpdateEventsAuthors,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalEventsAuthorsStateForCreate,
} = useEventsAuthors();

return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalEventsAuthorsStateForCreate}
            >
              Agregar nuevo/a autor/a a un evento
            </Button>
        </Col>
        <TableEventsAuthors />
        {
          modalEventsAuthors && (
            <FormProvider { ...isUpdateEventsAuthors ?{...formMethodsUpdate} : {...formMethodsCreate}}>
                <ModalEventsAuthors />
            </FormProvider>
          )
        }
    </Row>
)
}

