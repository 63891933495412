import { Modal, message } from "antd";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { changeModalStateEventsSchedules, deleteEventSchedules, fetchSingleEventsSchedules, getSingleEventsSchedules, refreshTableEventsSchedules, setIsEventsSchedulesForUpdate } from "../../../../redux";

const { confirm } = Modal;

export function useTableEventsSchedules() {
  const dispatch = useAppDispatch();

  const changeEventsSchedulesForUpdate = (valId: number) => {
      fetchSingleEventsSchedules(valId)
      .then((res)=>{
      dispatch(getSingleEventsSchedules(res.data[0]));
      dispatch(setIsEventsSchedulesForUpdate(true));
      dispatch(changeModalStateEventsSchedules());
      })
  }

  const showDeleteConfirm = (valId: number, val: string) => {
      confirm({
        title: `Estás seguro de borrar el horario del evento con el id: ${valId}`,
        icon: <ExclamationCircleOutlined />,
        content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar horario" en caso tal solo oprime en "Cancelar".`,
        okText: "Borrar horario",
        okType: "danger",
        cancelText: "Cancelar",
        keyboard: true,
        maskClosable: true,
        async onOk() {
          return await deleteEventSchedules(valId)
            .then((res) => {
              dispatch(refreshTableEventsSchedules(true));
              if(res.name){
                return message.success(`Se ha borrado con éxito el horario con id:${valId}`);
              }else if(res){
                console.log({res})
                return message.error(`${res}`);
              }
            })
            .catch((err: any) => {
              console.log({ err });
              return message.error(err.message);
            });
        },
        onCancel() {},
      });
    };
    return{
      //state
      //methods
      //functions
      changeEventsSchedulesForUpdate,
      showDeleteConfirm,
    }
}
