import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { useProjectsPartners } from "../../hooks/projects-partners/useProjectsPartners";
import { selectModalProjectsPartners } from "../../../../redux";
import { ModalProjectsPartners } from "./ModalProjectsPartners";
import { TableProjectsPartners } from "./TableProjectsPartners";

export function ProjectsPartners() {
  const modalProjectsPartners = useSelector(
    selectModalProjectsPartners
  );

  const {
    //state
    isUpdateProjectsPartners,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalProjectsPartnersStateForCreate,
  } = useProjectsPartners();

  return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalProjectsPartnersStateForCreate}
            >
              Agregar nuevo colaborador a Proyecto
            </Button>
        </Col>
        <TableProjectsPartners />
        {
            modalProjectsPartners && (
              <FormProvider { ...isUpdateProjectsPartners ?{...formMethodsUpdate} : {...formMethodsCreate}}>
                <ModalProjectsPartners />
              </FormProvider>
            )
        }
    </Row>
  )
}
