import { message } from "antd";

import { webinarsRequirementsService } from "../../shared";
import { webinarsRequirementsActions } from "../action-types";
import { IModelWebinarsRequirements } from "../../modules";

export const changeModalStateWebinarsRequirements = (): webinarsRequirementsActions.IWebinarsRequirementsModalState =>({
    type: webinarsRequirementsActions.WEBINARS_REQUIREMENTS_MODAL_STATE
})

export const getSingleWebinarsRequirements = (val:IModelWebinarsRequirements ): webinarsRequirementsActions.IGetWebinarsRequirementsForUpdate=> ({
    type: webinarsRequirementsActions.GET_WEBINARS_REQUIREMENTS_FOR_UPDATE,
    payload: val
})

export const setIsWebinarsRequirementsForUpdate =(val: boolean): webinarsRequirementsActions.IIsWebinarsRequirementsForUpdate =>({
    type: webinarsRequirementsActions.IS_WEBINARS_REQUIREMENTS_FOR_UPDATE,
    payload: val,
})

export const fetchSingleWebinarsRequirements = async(id: number) =>{
    return await webinarsRequirementsService.find({ query: {id} })
    .then((res) => {
        return res;
    })
    .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return err
    });
}

export const createWebinarRequirements = async(data:Partial<IModelWebinarsRequirements>) =>{
    return await webinarsRequirementsService.create(data)
    .then((res: IModelWebinarsRequirements)=>{return res})
    .catch((err: any) => { return err.message })
}

export const updateWebinarRequirements = async(data:Partial<IModelWebinarsRequirements>) =>{
    return await webinarsRequirementsService.patch(data?.id!, data)
    .then((res: IModelWebinarsRequirements)=>{return res})
    .catch((err: any) => { return err.message })
}

export const deleteWebinarRequirements = async(id: number) =>{
    return await webinarsRequirementsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableWebinarsRequirements = (val: boolean): webinarsRequirementsActions.ISwitchWebinarsRequirementsRefreshTable =>({
    type: webinarsRequirementsActions.SWITCH_WEBINARS_REQUIREMENTS_REFRESH_TABLE,
    payload: val
})

