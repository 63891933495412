import { IModelProjects } from "../../modules"

export const PROJECTS_MODAL_STATE = '[ PROJECTS ] Projects Modal State'

export interface IProjectsModalState {
    type: typeof PROJECTS_MODAL_STATE
}

export const GET_PROJECTS_FOR_UPDATE = '[ PROJECTS ] Get Projects For Update'

export interface IGetProjectsForUpdate {
    type: typeof GET_PROJECTS_FOR_UPDATE
    payload: IModelProjects
}

export const IS_PROJECTS_FOR_UPDATE = '[ PROJECTS ] Is Projects For Update'

export interface IIsProjectsForUpdate {
    type: typeof IS_PROJECTS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_PROJECTS_REFRESH_TABLE = '[ PROJECTS ] Switch Projects Refresh Table'

export interface ISwitchProjectsRefreshTable {
    type: typeof SWITCH_PROJECTS_REFRESH_TABLE
    payload: boolean
}