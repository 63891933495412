import { Button, Col } from "antd"
import { useSelector } from "react-redux";
 
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { selectSingleCourses } from "../../../../redux";
import { useCoursesBenefits, useTableCoursesBenefits } from "../../hooks";
import { IModelCoursesBenefits } from "../../@types";
import { TableCustom, coursesBenefitsService } from "../../../../shared";
import { TableColumns, TableProperties } from "../../../../shared/components/ui/table/types";
import React from "react";

export function TableCoursesBenefits() {
  const dataUpdateCourses = useSelector( selectSingleCourses );

  const {
    //state
    //methods
    //functions
    changeCoursesBenefitsForUpdate,
    showDeleteConfirm,
  } = useTableCoursesBenefits()

  const {
    //state
    isRefreshTableCoursesBenefits,
    //methods
    //functions
  }= useCoursesBenefits()

  const tableRef = React.useRef<TableProperties>()

    React.useEffect(() => {
      tableRef.current?.refresh()
    }, [isRefreshTableCoursesBenefits])

  const columns: TableColumns<IModelCoursesBenefits> = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'left',
      sorter:true
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      align: 'left',
    },
    {
      title: 'Posición',
      dataIndex: 'position',
      align: 'left',
      sorter:true
    },
    {
      title: 'Curso',
      dataIndex: 'course_id',
      align: 'left',
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 140,
      render: (_:any, item:any) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeCoursesBenefitsForUpdate(item.id)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ]

  return (
    <TableCustom fetchQueryProps={{course_id: dataUpdateCourses?.id!,}} columns={columns}  service={coursesBenefitsService} ref={tableRef}/>
  )
}
