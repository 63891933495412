import { coursesRatingsService } from "../../../shared";
import Table from "../../../shared/components/ui/table/TableCustom";

export default function CoursesRatings() {
  return (
    <Table
      download
      service={coursesRatingsService}
      columns={[
        {
          title: "ID del curso",
          dataIndex: "course_id",
          sorter: true,
          filterType: "text",
        },
        {
          title: "Curso",
          dataIndex: ["course", "name"],
        },
        {
          title: "Usuario",
          render: (record: any) => {
            return `${record.meta_user_first_name} ${record.meta_user_last_name}`;
          },
        },
        {
          title: "Calificación",
          dataIndex: "rating",
          sorter: true,
          filterType: "number_range",
        },
        {
          title: "Mensaje",
          dataIndex: "text",
        },
        {
          title: "Satisfacción de temática",
          dataIndex: "topic_satisfaction",
          sorter: true,
          filterType: "number_range",
        },
        {
          title: "Calificación del expositor",
          dataIndex: "speaker_rating",
          sorter: true,
          filterType: "number_range",
        },
        {
          title: "Calificación del espacio",
          dataIndex: "connection_space_rating",
          sorter: true,
          filterType: "number_range",
        },
        {
          title: "Probabilidad de recomendación",
          dataIndex: "likelihood_to_recommend",
          sorter: true,
          filterType: "number_range",
        },
      ]}
      fetch$ClientProps={{ withCourse: "true" }}
    />
  );
}
