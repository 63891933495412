import { Courses } from "../modules";
import { AdminLayout } from "../shared";

export function CoursesPage() {
  return (
    <AdminLayout>
      <Courses />
    </AdminLayout>
  )
}
