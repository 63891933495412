import { Link, Outlet } from "react-router-dom";
import KeywordSearch from "../modules/keyword-search/ui/KeywordSearch";
import { AdminLayout } from "../shared";
import { Button, Flex, Typography } from "antd";

export default function KeywordSearchPage() {
  return (
    <AdminLayout>
      <Typography.Title>Palabras claves</Typography.Title>
      <div style={{ width: "100%" }}>
        <Flex justify="flex-end">
          <Link to="/buscador/palabras-claves/agregar">
            <Button type="primary">+ Palabra clave</Button>
          </Link>
        </Flex>
        <KeywordSearch />
        <Outlet />
      </div>
    </AdminLayout>
  );
}
