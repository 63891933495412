import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { TableCoursesAuthors } from "./TableCoursesAuthors";
import { selectModalCoursesAuthors } from "../../../../redux";
import { useCoursesAuthors } from "../../hooks";
import { ModalCoursesAuthors } from "./ModalCoursesAuthors";


export function CoursesAuthors() {
  const modalCoursesAuthors = useSelector(
    selectModalCoursesAuthors
);

const {
    //state
    isUpdateCoursesAuthors,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    //functions
    changeModalCoursesAuthorsStateForCreate,
} = useCoursesAuthors();

return (
    <Row
        gutter={[8, 8]}
        style={{
        padding: "10px",
        width: "100%",
        }}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalCoursesAuthorsStateForCreate}
            >
              Agregar nuevo autor a un curso
            </Button>
        </Col>
        <TableCoursesAuthors />
        {
            modalCoursesAuthors && (
                <FormProvider { ...isUpdateCoursesAuthors ?{...formMethodsUpdate} : {...formMethodsCreate}}>
                    <ModalCoursesAuthors />
                </FormProvider>
            )
        }
    </Row>
)
}
