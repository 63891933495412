 
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { Button, Col, Typography, Avatar } from "antd";
import { PATH_S3_BUCKET } from "../../../../shared/utils/constants";
import { TableCustom, projectsService } from "../../../../shared";
import { IModelProjects } from "../../@types";
import { useTableProjects, useProjects } from "../../hooks";
import { TableColumns, TableProperties } from "../../../../shared/components/ui/table/types";
import React from "react";

const { Title } = Typography;

export function TableProjects() {
    const {
        //state
        //methods
        //functions
        changeProjectsForUpdate,
        showDeleteConfirm,
    } =  useTableProjects()

    const {
        //state
        isRefreshTableProjects,
        isLoading,
        //methods
        //functions
        updateStatus,
    }= useProjects()

    const tableRef = React.useRef<TableProperties>()

    React.useEffect(() => {
      tableRef.current?.refresh()
    }, [isRefreshTableProjects])

    const columns: TableColumns<IModelProjects> = [
        {
            title: 'ID',
            dataIndex: 'id',
            //: 15,
            align: 'left',
            sorter:true
        },
        {
          title: 'Nombre',
          dataIndex: 'name',
              //: 100,
              align: 'left',
            },
            // {
              //     title: 'Slug',
              //     dataIndex: 'slug',
              //     align: 'left',
              // },
              {
                title: 'Texto del Boton',
                //: 50,
          dataIndex: 'text_button',
          align: 'left',
      },
     
       
        // {
        //     title: 'Descripción corta',

        //     dataIndex: 'short_description',
        //     align: 'left',
        //     key: "short_description",
        //     render: (_, item) => {
        //       return (
        //         <Col
        //           style={{
        //             display: "flex",
        //             width: "100%",
        //             justifyContent: "space-evenly",
        //             flexDirection: "row",
        //           }}
        //         >
        //           {
        //             item.short_description &&
        //             <Title
        //             style={{
        //                 marginBottom: "0",
        //               }}
        //               level={5}
        //               >
        //                 {`${item.short_description.substring(0, 130)}...`}
        //             </Title>
        //           }
        //         </Col>
        //       );
        //     },
        // },
        {
          title: "Imagen",
          dataIndex: "path_cover",
          //: 15,
          key: "path_cover",
          render: (_:any, item:any) => {
            return (
              <Col
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "start",
                  flexDirection: "row",
                }}
              >
                <Avatar size={64} src={`${PATH_S3_BUCKET}/${item.path_cover}`} />
              </Col>
            );
          },
      },
      {
        title: "Imagen de banner",
        dataIndex: "banner_path",
        //: 15,
        key: "banner_path",
        render: (_:any, item:any) => {
          return (
            <Col
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-evenly",
                flexDirection: "row",
              }}
            >
              <Avatar size={64} src={`${PATH_S3_BUCKET}/${item.banner_path}`} />
            </Col>
          );
        },
    },
    {
      title: "Imagen de logo",
      // width: 15,
      dataIndex: "path_logo",
      key: "path_logo",
      render: (_:any, item:any) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Avatar size={64} src={`${PATH_S3_BUCKET}/${item.path_logo}`} />
          </Col>
        );
      },
  },
        // {
        //     title: 'Posición',
        //     
        //     dataIndex: 'position',
        //     align: 'left',
        // },
        {
            title: 'Tipo de banner',
            //: 20,
            dataIndex: 'type_banner',
            align: 'left',
        },
        {
            title: 'Link',
            //: 100,
            dataIndex: 'action_link_botton',
            align: 'left',
        },
        {
          title: "Estado",
          dataIndex: "status",
          //: 25,
          key: "status",
          render: (_:any, item:any) => {
            return (
              <Col
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "start",
                  flexDirection: "row",
                }}
              >
                <button
                disabled={isLoading}
                  onClick={()=>{
                    if(item.status === "active"){
                      updateStatus("inactive", item.id)
                    }else if( item.status === "inactive"){
                      updateStatus("active", item.id)
                    }
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 27px",
                    borderWidth: 0,
                    cursor: "pointer",
                    borderRadius: "20px",
                    backgroundColor:
                      item.status === "active" ? "#4bd542" : "#ff4d4f",
                  }}
                >
                  <Title
                    style={{
                      marginBottom: "1.5px",
                      color: "white",
                    }}
                    level={5}
                  >
                    {item.status}
                  </Title>
                </button>
              </Col>
            );
          },
      },
        {
            title: "Acciones",
            key: "operation",
            fixed: "right",
            render: (_:any, item:any) => {
              return (
                <Col
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    flexDirection: "row",
                  }}
                >
                  <Button
                    type="primary"
                    shape="circle"
                    onClick={() => changeProjectsForUpdate(item.id)}
                    icon={<EditOutlined />}
                    size="large"
                  />
                  <Button
                    type="primary"
                    danger
                    onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
                    shape="circle"
                    icon={<DeleteOutlined />}
                    size="large"
                  />
                </Col>
              );
            },
        },
    ]

  return (
    <TableCustom columns={columns}  service={projectsService} ref={tableRef} />
  )
}
