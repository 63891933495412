
import { ProjectsById } from "../modules/projects";
import { AdminLayout } from "../shared";

export function ProjectsByIdPage() {
  return (
    <AdminLayout>
        <ProjectsById />
    </AdminLayout>
  )
}
