 
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { Button, Col, Typography } from "antd";
import { UsekeyAccountManagers, useTablekeyAccountManagers } from "../../hooks";
import { TableCustom, keyAccountManagersService } from "../../../../shared";
import { IModelkeyAccountManagers } from "../../@types";
import { TableColumns, TableProperties } from "../../../../shared/components/ui/table/types";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const { Title } = Typography;

export function TablekeyAccountManagers() {
  const {
    //state
    //methods
    //functions
    changeEventsForUpdate,
    showDeleteConfirm,
} =  useTablekeyAccountManagers()

const {
    //state
    isRefreshTablekeyAccountManagers,
    isLoading,
    updateStatus,
}= UsekeyAccountManagers()

const location = useLocation();
const navigate = useNavigate();

const tableRef = React.useRef<TableProperties>()

    React.useEffect(() => {
      tableRef.current?.refresh()
    }, [isRefreshTablekeyAccountManagers])

    React.useEffect(() => {
      tableRef.current?.refresh();
    }, [location.pathname]);

const columns: TableColumns<any> = [
    {
        title: 'ID',
        dataIndex: 'id',
        align: 'left',
        sorter:true
    },
    {
        title: 'Nombre',
        
        dataIndex: 'first_name',
        align: 'left',
    },
    {
      title: 'Apellido',
      
      dataIndex: 'last_name',
      align: 'left',
  },
    {
      title: 'Email',
      width: 200,
      dataIndex: 'email',
      align: 'left',
  },
    {
      title: 'Telefono',
      width: 200,
      dataIndex: 'telefono',
      align: 'left',
  },
    {
      title: "Estado",
      width: 150,
      dataIndex: "status",
      align: 'left',
      key: "status",
      render: (_:any, item:any) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              flexDirection: "row",
            }}
          >
            <button
            disabled={isLoading}
              onClick={()=>{
                if(item.status === "active"){
                  updateStatus("inactive", item.id)
                }else if( item.status === "inactive"){
                  updateStatus("active", item.id)
                }
              }}
              style={{
                display: "flex",
                padding: "5px 27px",
                borderWidth: 0,
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor:
                  item.status === "active" ? "#4bd542" : "#ff4d4f",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "white",
                }}
                level={5}
              >
                {item.status}
              </Title>
            </button>
          </Col>
        );
      },
  },
    {
      title: 'Fecha de Creación',
      width: 250,
      dataIndex: 'createdAt',
      align: 'left',
      render: (_:any, item:any) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
            flexDirection: "row",
          }}
        >
          <Title level={5}>
            {moment(item.createdAt).utcOffset(0).format("DD/MM/YYYY HH:mm:ss")}
          </Title>
        </Col>
      ),
    },
    {
        title: "Acciones",
        key: "operation",
        fixed: "right",
        
        render: (_:any, item:any) => {
          return (
            <Col
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "start",
                flexDirection: "row",
              }}
            >
              <Button
                type="primary"
                shape="circle"
                onClick={() => navigate(`/key-account-managers/${item.id}`)}
                icon={<EditOutlined />}
                size="large"
              />
              <Button
                type="primary"
                danger
                onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
                shape="circle"
                icon={<DeleteOutlined />}
                size="large"
              />
            </Col>
          );
        },
    },
]

  return (
    <TableCustom columns={columns}  service={keyAccountManagersService} ref={tableRef} />
  )
}
