import React from "react";
import { message } from "antd";
import { IPressResource } from "../@types";
import { pressResourcesService } from "../../../shared";

export default function useUpdatePressResourceStatus(props?: {
  onSuccess: (record: IPressResource) => void;
}) {
  const [loading, setLoading] = React.useState(false);

  const loadingRef = React.useRef(false);

  const updateLoading = (status: boolean) => {
    setLoading(status);
    loadingRef.current = status;
  };

  const updateStatus = async (
    recordId: number,
    status: IPressResource["status"]
  ) => {
    if (loadingRef.current) return;

    updateLoading(true);

    await pressResourcesService
      .patch(recordId, { status })
      .then((record) => {
        props?.onSuccess?.(record as IPressResource);
        message.success("Estado actualizado correctamente");
      })
      .catch((err) => {
        message.error(err?.message);
      })
      .finally(() => {
        updateLoading(false);
      });
  };

  return {
    loading,
    updateStatus,
  };
}
